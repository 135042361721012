import { useGLTF, useAnimations } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useRef, useEffect, useState, useContext } from "react";
import { LoadingManager, REVISION } from "three";
import * as THREE from "three";
import { GLTFLoader } from "three-stdlib";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";

import { ItemUploadDispatchContext } from "../../contexts/ItemUploadContext";
import use3DModelScreenshot from "../../hooks/use3DModelScreenshot";
import { loadModelWithTransform } from "../../utils/gltfConvert";

const manager = new LoadingManager();

type Props = {
  renderFileUrl: string;
  setIsCapturing: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CaptureModel({ renderFileUrl, setIsCapturing }: Props) {
  const { gl, camera } = useThree();
  const { capturePreviewImage, spriteImage, gifImage } = use3DModelScreenshot();
  const ref = useRef<any>();

  const [model, setModel] = useState<GLTF | undefined>();
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);

  const dispatch = useContext(ItemUploadDispatchContext);

  const myLoader = (loader: GLTFLoader) => {
    loader.manager = manager;
    loader.manager.onError = () => {
      console.error("error");
      alert("Invalid file. Please try with a new 3D glb file.");
      window.location.reload();
    };
    const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`;
    const ktx2Loader = new KTX2Loader(manager).setTranscoderPath(
      `${THREE_PATH}/examples/jsm/libs/basis/`
    );
    loader.setKTX2Loader(ktx2Loader.detectSupport(gl));
  };

  const result = useGLTF(renderFileUrl, true, false, myLoader);
  result.scene.updateMatrixWorld();

  const { actions, names, mixer } = useAnimations(
    model ? model.animations : result.animations,
    ref
  );

  const transform = (url: string) => {
    if (!url) return;

    loadModelWithTransform(url, gl)
      .then((gltf) => {
        gltf.scene.updateMatrixWorld();
        setModel(gltf);
      })
      .catch(() => {
        setModel(undefined);
      });
  };

  useEffect(() => {
    setModel(undefined);
    transform(renderFileUrl);
    setIsAnimationPlaying(false);
  }, [renderFileUrl]);

  useEffect(() => {
    if (!isAnimationPlaying) {
      return;
    }

    let i = 0;

    if (model && actions) {
      if (names.length > 1) {
        actions[names[i]]?.reset().fadeIn(0.5).play();
        actions[names[i]]?.setLoop(THREE.LoopRepeat, 1);
      } else {
        actions[names[i]]?.reset().play();
      }
    }

    mixer.addEventListener("finished", (e) => {
      if (names.length > 1) {
        i++;
        if (i === names.length) {
          i = 0;
        }

        actions[names[i]]?.reset().fadeIn(0.5).play();
        actions[names[i]]?.setLoop(THREE.LoopRepeat, 1);
      }
    });

    return () => {
      actions[names[i]]?.reset().fadeOut(0.5);
    };
  }, [actions, mixer, model, names, isAnimationPlaying]);

  useEffect(() => {
    if (!model) {
      return;
    }

    setTimeout(() => {
      capturePreviewImage(gl, camera, model.scene);
    }, 1000);
  }, [model]);

  useEffect(() => {
    if (gifImage) {
      dispatch({ type: "UPLOAD_PREVIEW_GIF_FILE", payload: gifImage });
      setIsCapturing(false);
      setIsAnimationPlaying(true);
    }
  }, [gifImage]);

  useEffect(() => {
    if (spriteImage) {
      dispatch({ type: "CHANGE_SPRITE_IMAGE", payload: spriteImage });
    }
  }, [spriteImage]);

  return model ? (
    <>
      <group ref={ref}>
        <primitive object={model.scene} />
      </group>
    </>
  ) : (
    <>
      <group ref={ref}>
        <primitive object={result.scene} />
      </group>
    </>
  );
}

import { useCallback, useEffect, useState, useContext } from "react";
import { useDropzone } from "react-dropzone";

import {
  ItemUploadContext,
  ItemUploadDispatchContext,
} from "../../contexts/ItemUploadContext";
import { ItemFile } from "../../domains/item/interface";

import UploadIcon from "./UploadIcon";
import { UploadingStatus } from "./types";

const UPLOADABLE_EXTENSIONS = ["glb"];
const MAXIMUM_FILE_SIZE = 5242880; // 5MB

export default function FileDragDrop() {
  const [isInvalidFileExtensionError, setIsInvalidFileExtensionError] =
    useState(false);
  const [isFileSizeExceededError, setIsFileSizeExceededError] = useState(false);
  const [uploadingStatus, setUploadingStatus] =
    useState<UploadingStatus>("general");

  const items = useContext(ItemUploadContext);
  const dispatch = useContext(ItemUploadDispatchContext);

  // drop존에 파일이 들어왔을 떄 호출되는 함수
  // file이 배열인 이유: OnDrop을 쓰려면 배열만 받을 수 있음
  // 현재 multiple: false를 통해 단일 파일만 업로드 가능
  const onDrop = useCallback(
    async (files: File[]) => {
      setIsInvalidFileExtensionError(false);
      setIsFileSizeExceededError(false);
      setUploadingStatus("general");
      let itemFile: ItemFile[] = [];

      const file = files[0];

      if (file.size > MAXIMUM_FILE_SIZE) {
        setIsFileSizeExceededError(true);
        setUploadingStatus("error");
        return;
      }

      const extension = file.name.split(".").pop();

      if (extension && UPLOADABLE_EXTENSIONS.includes(extension)) {
        const preview = URL.createObjectURL(file);
        itemFile = [{ file, preview, extension }];
        dispatch({ type: "UPLOAD_ITEM_FILE", payload: itemFile });
      }

      setUploadingStatus("success");
    },
    [setIsInvalidFileExtensionError]
  );

  // TODO: accept 추가 가능성 : "model/gltf+json": [".gltf"], "text/plain": [".obj", ".mtl"], "image/*": [".jpg", ".jpeg", ".png"],
  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    onDrop,
    accept: {
      "model/glb-binary": [".glb"],
    },
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (!items.uploadItemFile[0]) {
      setUploadingStatus("general");
    }
  }, [items.uploadItemFile]);

  // glb 파일이 아닌 다른 파일을 dnd로 업로드 시도 하였을 때 -> onDrop 함수에서는 file 객체 자체를 undefined로 처리해야 해당 케이스에 대해서 따로 처리해야 함.
  // onDrop 함수에서는 glb 파일일 경우 발생하는 에러에 대해서 처리.
  useEffect(() => {
    if (
      fileRejections.length > 0 &&
      fileRejections[0].errors[0].code === "file-invalid-type"
    ) {
      setIsInvalidFileExtensionError(true);
      setUploadingStatus("error");
    }
  }, [fileRejections]);

  return (
    <div {...getRootProps()}>
      <div
        className={`border-2 ${
          uploadingStatus !== "success" ? "border-dashed" : "border-solid"
        } rounded-3xl w-[530px] h-[262px] text-landing flex flex-col justify-center items-center ${
          isFileSizeExceededError || isInvalidFileExtensionError
            ? " border-twinworldRed"
            : " border-twinworldGray-250"
        } ${uploadingStatus === "success" && "border-[#10AF20]"}`}
      >
        <div className="cursor-pointer " onClick={open}>
          <UploadIcon uploadingStatus={uploadingStatus} />
        </div>
        <input {...getInputProps()} type="file" />
        <div className="flex flex-col items-center mt-6">
          {items.uploadItemFile[0] ? (
            <p className="text-lg leading-none text-center">
              {items.uploadItemFile[0].file.name}
            </p>
          ) : (
            <p className="text-lg leading-none cursor-pointer" onClick={open}>
              Drag & Drop your Item or Browser
            </p>
          )}

          <p className="mt-[10px] text-xs text-twinworldGray-650">
            Check the file types(glb) and guide{" "}
            <a
              className="underline cursor-pointer"
              href={`${process.env.PUBLIC_URL}/guide#upload-your-item`}
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </p>
        </div>
      </div>

      {isFileSizeExceededError && (
        <div className="flex justify-end mt-[10px] text-xs text-twinworldRed">
          Please check the file type and data size. (Limited 5MB)
        </div>
      )}

      {isInvalidFileExtensionError && (
        <div className="flex justify-end mt-[10px] text-xs text-twinworldRed">
          Only embedded glTF and glb files are allowed. Please check the file
          type.
        </div>
      )}
    </div>
  );
}

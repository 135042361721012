import { useState } from "react";
import { useNavigate } from "react-router-dom";

import AvatarIcon from "../common/icons/AvatarIcon";

export default function AvatarEditButton() {
  const navigate = useNavigate();

  const [isAvatarIconHover, setIsAvatarIconHover] = useState(false);

  return (
    <button
      className="absolute -bottom-[3px] right-0 z-10"
      type="button"
      onClick={() => navigate("/setting", { state: { menu: "avatar" } })}
      onMouseOver={() => setIsAvatarIconHover(true)}
      onMouseLeave={() => setIsAvatarIconHover(false)}
    >
      <AvatarIcon isHover={isAvatarIconHover} />
    </button>
  );
}

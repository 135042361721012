export default function MobileRightArrowIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9599 14.655C18.5578 14.2764 18.5578 13.6625 18.9599 13.2839C19.362 12.9054 20.014 12.9054 20.4161 13.2839L24.5983 17.2218C24.64 17.2552 24.6789 17.292 24.7147 17.3317C25.101 17.7113 25.0958 18.314 24.699 18.6876C24.6986 18.688 24.6981 18.6884 24.6976 18.6889C24.6681 18.72 24.6366 18.7493 24.6034 18.7765L20.4151 22.72C20.013 23.0986 19.3611 23.0986 18.959 22.72C18.5569 22.3414 18.5569 21.7275 18.959 21.349L21.4515 19.0021L11.9988 19.0026C11.4465 19.0026 10.9993 18.555 11 18.0027C11.0007 17.4504 11.4489 17.0027 12.0012 17.0026L21.4528 17.0021L18.9599 14.655Z"
        fill="white"
      />
    </svg>
  );
}

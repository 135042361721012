export default function SharedLikeIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.31856 21.5009C2.50483 19.6872 2.50483 16.7386 4.31855 14.9248L8.56119 10.6822L6.54594 8.66695L2.3033 12.9096C-0.624121 15.837 -0.624121 20.5888 2.3033 23.5162C5.23072 26.4436 9.98248 26.4436 12.9099 23.5162L17.1525 19.2735L15.1373 17.2583L10.8946 21.5009C9.08092 23.3147 6.13228 23.3147 4.31856 21.5009ZM9.72792 18.2129L18.2132 9.72761L16.0919 7.60629L7.6066 16.0916L9.72792 18.2129ZM12.9099 2.30299L8.66726 6.54563L10.6825 8.56088L14.9252 4.31824C16.7389 2.50451 19.6875 2.50451 21.5012 4.31824C23.315 6.13197 23.315 9.0806 21.5012 10.8943L17.2586 15.137L19.2739 17.1522L23.5165 12.9096C26.4439 9.98217 26.4439 5.23041 23.5165 2.30299C20.5891 -0.624436 15.8373 -0.624435 12.9099 2.30299Z"
        fill="black"
      />
    </svg>
  );
}

import SignInLogo from "../common/icons/SignInLogo";
import useModals from "../hooks/useModals";
import SignInModal from "../modal/SignInModal";
import SignUpModal from "../modal/SignUpModal";

type Props = {
  closeMenu: () => void;
};

export default function AuthMenu({ closeMenu }: Props) {
  const { openModal, closeModal } = useModals();

  const handleOpenAuthModal = () => {
    closeMenu();
    openModal(SignInModal, {});
  };

  const handleOpenSignUpModal = () => {
    closeMenu();
    closeModal(SignInModal);
    openModal(SignUpModal, {});
  };

  const AUTH_MENU_LIST = [
    {
      key: "login",
      label: "Sign In",
      action: () => {
        handleOpenAuthModal();
      },
    },
    {
      key: "signup",
      label: "Sign Up",
      action: () => {
        handleOpenSignUpModal();
      },
    },
  ];

  return (
    <div className="flex flex-col items-center justify-end w-[280px] py-[28px] bg-white border rounded-[10px] border-black absolute z-50 right-[60px] -top-[10px] shadow-menu">
      <div className="absolute w-[25px] top-[24px] -right-[25px] z-10 overflow-hidden">
        <div className="w-[13px] h-[30px] origin-top-left transform rotate-45 bg-white border border-black shadow-menu" />
      </div>
      <SignInLogo />
      <div className="w-full text-black flex flex-col items-center mt-[36px] gap-[13px]">
        {AUTH_MENU_LIST.map((link) => (
          <button
            onClick={link.action}
            type="button"
            className={`text-[16px] h-10 font-semibold w-[182px] border rounded-full border-black hover:transition-background-color hover:duration-300  ${
              link.label === "Sign In" && "bg-twinworldPrimary-200"
            } hover:bg-black hover:text-twinworldPrimary-200`}
            key={link.key}
          >
            {link.label}
          </button>
        ))}
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "../components/common/Loading";
import { useAuthContext } from "../contexts/AuthContext";
import { useAuthActions } from "../domains/auth/hook/useAuthActions";

const SocialSignInPage = () => {
  const win: Window = window;
  const navigate = useNavigate();
  const { isSignIn } = useAuthContext();
  const [signing, setSigning] = useState(false);
  const { socialSignIn } = useAuthActions();

  useEffect(() => {
    if (isSignIn) {
      navigate("/spaces?type=trend");
    }
  }, []);

  const onSignIn = async (socialAccessToken: string, provider: string) => {
    setSigning(true);

    await socialSignIn(socialAccessToken, provider)
      .then(() => {
        win.location.replace("/");
      })
      .catch(() => {
        alert("Social login failed");
        navigate("/");
      })
      .finally(() => {
        setSigning(false);
      });
  };

  useEffect(() => {
    const url = new URL(win.location.href);
    const provider = url.pathname.split("/")[2];

    const socialAccessToken =
      provider === "google"
        ? url.hash.split("=")[1]?.split("&")[0]
        : url.hash.split("=")[0].split("#")[1];

    if (socialAccessToken === "signin") {
      return;
    }

    if (socialAccessToken) {
      onSignIn(socialAccessToken, provider);
    }
  }, [win.location.href]);

  return <>{signing && <Loading />}</>;
};

export default SocialSignInPage;

export default function TwinWorldStamp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="126"
      height="69"
      viewBox="0 0 126 69"
      fill="none"
    >
      <g clipPath="url(#clip0_909_22797)">
        <path
          d="M90.8601 0.00186587C80.6977 0.129571 71.5923 4.60514 65.3278 11.6427C64.1582 13.1653 61.4517 16.9827 59.3892 21.0182C57.6665 24.3876 55.5981 26.8769 54.1599 28.3779C48.9108 34.217 41.3917 37.5648 33.523 37.5648C27.9954 37.5648 22.6594 35.9498 18.0918 32.8928C17.2503 32.3289 16.944 31.2051 17.4399 30.3249C18.0029 29.3269 19.289 29.0381 20.2215 29.6648C24.1549 32.3073 28.754 33.7042 33.523 33.7042C40.2973 33.7042 46.7712 30.8181 51.2854 25.7845L51.3308 25.7354C52.5913 24.425 54.4108 22.2383 55.93 19.2696C57.062 17.0554 58.3244 14.9885 59.403 13.3421C59.4169 13.3186 59.4267 13.2989 59.4406 13.2734C60.4659 11.5051 59.4445 10.3361 59.2312 10.12C59.2233 10.1122 59.2134 10.1024 59.2055 10.0945C59.1976 10.0866 59.1936 10.0827 59.1936 10.0827C53.0022 3.93911 44.4954 0.105995 35.0857 0.00186587C15.3023 -0.21818 -0.632623 15.9905 0.0193159 35.6571C0.631743 54.1763 15.9207 69.0019 34.6906 69.0019C45.1888 69.0019 54.5965 64.3613 60.9578 57.033C62.2913 55.2176 64.5692 51.8776 66.359 48.3765C68.0817 45.0071 70.1501 42.5178 71.5884 41.0168C76.8375 35.1777 84.3565 31.8299 92.2252 31.8299C97.7528 31.8299 103.089 33.4449 107.656 36.5019C108.498 37.0658 108.804 38.1896 108.308 39.0698C107.745 40.0678 106.459 40.3567 105.527 39.7299C101.593 37.0874 96.9942 35.6905 92.2252 35.6905C85.4509 35.6905 78.977 38.5766 74.4628 43.6102L74.4174 43.6593C73.157 44.9697 71.3375 47.1565 69.8183 50.1251C68.7653 52.1861 67.5977 54.1174 66.5724 55.7048C66.5665 55.7146 66.5625 55.7225 66.5566 55.7323C65.5945 57.3905 66.4341 58.5222 66.7186 58.8346C73.0009 65.1137 81.6954 69.0019 91.3065 69.0019C110.076 69.0019 125.363 54.1783 125.978 35.661C126.634 15.963 110.675 -0.24765 90.8601 0.00186587Z"
          fill="#5DB5F7"
        />
      </g>
      <defs>
        <clipPath id="clip0_909_22797">
          <rect width="126" height="69" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

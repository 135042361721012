import { useState } from "react";

type Props = {
  isLiked: boolean;
  onClick: () => void;
};

export default function LikeButton({ isLiked, onClick }: Props) {
  const [isHover, setIsHover] = useState(isLiked);

  return (
    <button
      type="button"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
    >
      <svg
        width="36"
        height="34"
        viewBox="0 0 36 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {(isLiked || isHover) && (
          <rect width="36" height="34" rx="17" fill="#A6D9FF" />
        )}
        <path
          d="M29.4545 13.1H21.4236L22.6327 7.159L22.6709 6.743C22.6709 6.21 22.4545 5.716 22.1109 5.365L20.7618 4L12.3873 12.567C11.9164 13.035 11.6364 13.685 11.6364 14.4V27.4C11.6364 28.83 12.7818 30 14.1818 30H25.6364C26.6927 30 27.5964 29.35 27.9782 28.414L31.8218 19.249C31.9364 18.95 32 18.638 32 18.3V15.7C32 14.27 30.8545 13.1 29.4545 13.1ZM29.4545 18.3L25.6364 27.4H14.1818V14.4L19.7055 8.758L18.2927 15.7H29.4545V18.3ZM4 14.4H9.09091V30H4V14.4Z"
          fill="black"
        />
      </svg>
    </button>
  );
}

import MobileDeviceCarousel from "./MobileDeviceCarousel";

export default function MobileDevice() {
  return (
    <section className="mt-[98px] flex flex-col items-center">
      <h2 className="text-[28px] leading-[42px] font-semibold text-center">
        Begin your adventure on these devices
      </h2>
      <MobileDeviceCarousel />
    </section>
  );
}

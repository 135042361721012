import { useNavigate } from "react-router-dom";

export default function MobileItemUploadButton() {
  const navigate = useNavigate();
  const handleMobileUpload = () => navigate("/items/mobile-support");

  return (
    <button
      className={`w-max h-[34px] px-4 py-2.5 bg-black text-white rounded-full text-[12px] leading-[14px] font-medium`}
      type="button"
      onClick={handleMobileUpload}
    >
      Item upload
    </button>
  );
}

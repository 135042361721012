import { createContext, useReducer, Dispatch } from "react";

import { ItemFile } from "../domains/item/interface";

type Item = {
  uploadItemFile: ItemFile[];
  uploadPreviewGifFile?: File;
  uploadPreviewSpriteFile?: File;
  title: string;
  category: {
    idx: string;
    name: string;
    unavailable?: boolean;
  };
  previewThumbnail: string;
};

type Action =
  | { type: "UPLOAD_ITEM_FILE"; payload: ItemFile[] }
  | { type: "CHANGE_TITLE"; payload: string }
  | {
      type: "CHANGE_CATEGORY";
      payload: {
        idx: string;
        name: string;
      };
    }
  | { type: "CHANGE_SPRITE_IMAGE"; payload: File }
  | { type: "CHANGE_PREVIEW_THUMBNAIL"; payload: string }
  | { type: "UPLOAD_PREVIEW_GIF_FILE"; payload: File }
  | { type: "INITIALIZATION_ITEM" };

const DEFAULT_ITEM: Item = {
  uploadItemFile: [],
  title: "",
  category: {
    idx: "",
    name: "Choose a category",
    unavailable: true,
  },
  previewThumbnail: "",
};

function itemUploadReducer(item: Item, action: Action): Item {
  switch (action.type) {
    case "UPLOAD_ITEM_FILE":
      return { ...item, uploadItemFile: action.payload };
    case "CHANGE_TITLE":
      return { ...item, title: action.payload };
    case "CHANGE_CATEGORY":
      return { ...item, category: action.payload };
    case "CHANGE_PREVIEW_THUMBNAIL":
      return { ...item, previewThumbnail: action.payload };
    case "CHANGE_SPRITE_IMAGE":
      return { ...item, uploadPreviewSpriteFile: action.payload };
    case "UPLOAD_PREVIEW_GIF_FILE":
      return { ...item, uploadPreviewGifFile: action.payload };
    case "INITIALIZATION_ITEM":
      return DEFAULT_ITEM;
    default:
      return item;
  }
}

type ItemUploadProviderProps = {
  children: React.ReactNode;
};

export const ItemUploadContext = createContext<Item>(DEFAULT_ITEM);
export const ItemUploadDispatchContext = createContext<Dispatch<Action>>(
  () => null
);

export function ItemUploadProvider({ children }: ItemUploadProviderProps) {
  const [items, dispatch] = useReducer(itemUploadReducer, DEFAULT_ITEM);

  return (
    <ItemUploadContext.Provider value={items}>
      <ItemUploadDispatchContext.Provider value={dispatch}>
        {children}
      </ItemUploadDispatchContext.Provider>
    </ItemUploadContext.Provider>
  );
}

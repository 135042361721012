import { useState } from "react";
import { FieldError, FieldValues, UseFormRegister } from "react-hook-form";

import EyeIcon from "../common/icons/EyeIcon";
import EyeSlashIcon from "../common/icons/EyeSlashIcon";

type Props = {
  name: string;
  placeholder?: string;
  error?: FieldError;
  register: UseFormRegister<FieldValues | any>;
  options?: {
    required: boolean | string;
    pattern?: { value: RegExp; message: string };
    minLength?: { value: number; message: string };
    maxLength?: { value: number; message: string };
    max?: { value: number; message: string };
    min?: { value: number; message: string };
  };
};

type InputType = "password" | "text";

export default function PasswordInput({
  name,
  placeholder,
  register,
  error,
  options,
}: Props) {
  const [type, setType] = useState<InputType>("password");

  const handEyeButtonClick = () =>
    setType((prev) => (prev === "password" ? "text" : "password"));

  return (
    <section className="relative flex flex-col">
      <div className="flex items-center justify-between xl:w-[392px] py-[14px] xl:py-4 border rounded-full px-[16px] xl:px-7 border-twinworldGray-300 bg-white ">
        <input
          id={name}
          className="w-full xl:w-[379px] placeholder:text-twinworldGray-400 text-base leading-none font-normal"
          type={type}
          placeholder={placeholder}
          {...register(name, { ...options })}
          autoComplete="new-password"
        />
        <button type="button" onClick={handEyeButtonClick}>
          {type === "password" ? <EyeSlashIcon /> : <EyeIcon />}
        </button>
      </div>

      <p className="absolute text-sm font-normal leading-5 text-twinworldRed -bottom-7 left-4">
        {error && error.message}
      </p>
    </section>
  );
}

import { useEffect } from "react";

export default function MobileHeaderDim() {
  // Dim이 활성화 되었을 때 모바일 touchmove 막기
  useEffect(() => {
    const handleTouchMove = (event: TouchEvent) => {
      event.preventDefault();
    };

    window.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
    return () => window.removeEventListener("touchmove", handleTouchMove);
  }, []);

  return (
    <section
      id="mobile-header-dim"
      className="fixed top-[82px] left-0 right-0 w-full h-full bg-black/70 z-50"
    />
  );
}

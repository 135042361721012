import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { SpaceCard as SpaceCardModel } from "../../domains/space/model/SpaceCard";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import { useInfiniteScrollRef } from "../../hooks/useInfiniteScrollRef";
import SpaceCard from "../card/SpaceCard";
import ScrollToTopButton from "../common/Button/ScrollToTopButton";
import ListWrapper from "../common/ListWrapper";
import EmptyList from "../common/list/EmptyList";
import MobileSpaceCard from "../mobile/MobileSpaceCard";

type Props = {
  title?: string;
  list: InfiniteData<SpaceCardModel[]> | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>;
  subMenu?: string;
};

export default function UserSpaceList({
  title = "You",
  list,
  fetchNextPage,
  subMenu,
}: Props) {
  const { isDesktop } = useDeviceBreakPoint();
  const { targetRef } = useInfiniteScrollRef({ fetchNextPage });
  const { isMobile } = useDeviceBreakPoint();

  const navigate = useNavigate();

  const handleClickCard = (spaceId: string) => {
    navigate(`/spaces/${spaceId}`);
  };

  return (
    <ListWrapper isEmptyData={!list?.pages[0].length}>
      {list && list.pages[0].length > 0 ? (
        list?.pages.map((page, pageIndex) => (
          <Fragment key={pageIndex}>
            {page.map((space) =>
              isDesktop ? (
                <SpaceCard
                  key={space.spaceId}
                  id={space.spaceId}
                  title={space.spaceName}
                  thumbnailSrc={space.spaceImage}
                  creator={space.spaceCreator}
                  creatorId={space.spaceCreatorId}
                  avatarSrc={space.spaceCreatorImage}
                  onClick={() => handleClickCard(space.spaceId)}
                  views={space.views}
                  plays={space.plays}
                  liveUserCount={space.liveUserCount}
                  rounded="rounded-[20px]"
                />
              ) : (
                <MobileSpaceCard
                  key={space.spaceId}
                  id={space.spaceId}
                  title={space.spaceName}
                  thumbnailSrc={space.spaceImage}
                  creator={space.spaceCreator}
                  creatorId={space.spaceCreatorId}
                  avatarSrc={space.spaceCreatorImage}
                  onClick={() => handleClickCard(space.spaceId)}
                  views={space.views}
                  plays={space.plays}
                  liveUserCount={space.liveUserCount}
                  rounded="rounded-[14px]"
                />
              )
            )}
          </Fragment>
        ))
      ) : (
        <div className="flex flex-col items-center justify-center w-full mx-auto text-center xl:mt-32 text-twinworldGray-680">
          <EmptyList type="space" title={title} subTab={subMenu} />
        </div>
      )}
      <div ref={targetRef} />
      {!isMobile && <ScrollToTopButton />}
    </ListWrapper>
  );
}

import PinCode from "../components/common/PinCode";

export default function MobileQRSignIn() {
  return (
    <section>
      <div className="w-full h-[1px] bg-twinworldGray-200 mb-9" />
      <div className="pt-[64px] px-[18px] w-[339px] mx-auto text-center">
        <h2 className="text-[20px] font-semibold">Sign in with QR and Pin</h2>
        <h3 className="text-[16px] text-twinworldGray-700 mt-[16px] mb-[42px] leading-[28px]">
          ‘My profile’ &gt; ‘Sign in’ from app
        </h3>
        <PinCode />
      </div>
    </section>
  );
}

import { useNavigate } from "react-router-dom";

type Props = {
  close: () => void;
  isDisplay: boolean;
};

export default function MobileGnbMenuList({ isDisplay, close }: Props) {
  const navigate = useNavigate();

  const GNB_MENU_LIST = [
    {
      key: "space",
      label: "Space",
      action: () => navigate("/spaces"),
    },
    {
      key: "item",
      label: "Item",
      action: () => navigate("/items"),
    },
    {
      key: "community",
      label: "Community",
      action: () => window.open("https://discord.com/invite/3qVZgBGrxr"),
    },
    {
      key: "support",
      label: "Support",
      action: () => window.open("https://www.doubleme.me/contact"),
    },
    {
      key: "press",
      label: "Press",
      action: () => window.open("https://www.doubleme.me/press"),
    },
  ];

  const handleMobileUpload = () => {
    navigate("/items/mobile-support");
    close();
  };

  return (
    <div
      className={`flex flex-col items-center justify-end w-full min-w-[375px] py-[30px] bg-white 
      absolute z-50 top-[82px] left-0 right-0 box-border transition-opacity ease-out duration-[250ms] ${
        isDisplay ? "opacity-100" : "opacity-0 -z-10"
      } `}
    >
      <div className="flex flex-col w-full gap-[23px] pl-[36px] text-twinworldGray-900 text-[18px] leading-[18px] font-medium">
        {GNB_MENU_LIST.map((link, index) => (
          <div
            key={link.key}
            className="flex items-center cursor-pointer w-max"
          >
            <button
              type="button"
              className={`transition-transform duration-300 snap-back ${
                isDisplay ? "translate-x-0" : "-translate-x-9"
              }`}
              onClick={() => {
                link.action();
                close();
              }}
              style={{
                transitionDelay: `${index * 30}ms`,
              }}
            >
              {link.label}
            </button>
          </div>
        ))}
      </div>

      <div className="flex flex-col w-full pl-[36px] mt-[36px]">
        <button
          className="cursor-pointer w-max px-4 py-2.5 bg-black text-white rounded-full text-[14px] leading-[14px] font-medium"
          onClick={handleMobileUpload}
        >
          Item upload
        </button>
      </div>
    </div>
  );
}

import { useEffect, useRef } from "react";

export default function useLazyLoadVideo() {
  const svgVideoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const callback: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          const videoElement = entry.target.querySelector("video");
          const sourceElements = videoElement.querySelectorAll("source");
          sourceElements.forEach((sourceElement: HTMLSourceElement) => {
            const src = sourceElement.getAttribute("data-src");

            if (src) {
              sourceElement.setAttribute("src", src);
              sourceElement.removeAttribute("data-src");
            }
          });

          videoElement.load();
          videoElement.play();

          observer.unobserve(entry.target);
        }
      });
    };

    const intersectionObserver = new IntersectionObserver(callback);
    const el = svgVideoRef.current as HTMLDivElement;
    intersectionObserver.observe(el);

    return () => intersectionObserver.disconnect();
  }, []);

  return svgVideoRef;
}

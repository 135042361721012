import NoMatchingResults from "../common/NoMatchingResults";
import NoContentIcon from "../common/icons/NoContentIcon";

type Props = {
  spaceType?: string;
};

export default function SpaceEmptyList({ spaceType }: Props) {
  return (
    <>
      {spaceType === "live" ? (
        <div className="flex flex-col items-center justify-center w-full mx-auto mt-32 text-center text-twinworldGray-680">
          <NoContentIcon />
          <p className="mt-[36px]">
            Twinners have not yet started their space journey.
          </p>
          <p className="mt-3">You’ll be able to explore together soon!</p>
        </div>
      ) : (
        <NoMatchingResults />
      )}
    </>
  );
}

import useDeviceBreakPoint from "../../../hooks/useDeviceBreakPoint";
import useLazyLoadImage from "../../../hooks/useLazyLoadImage";
import MobileDeviceShadowIcon from "../../mobile/icons/MobileDeviceShadowIcon";

import ShadowIcon from "./ShadowIcon";

type Props = {
  imageName: string;
  downloadLink: string;
};

export default function HMDDevice({ imageName, downloadLink }: Props) {
  const name = imageName.replace(/_/g, " ");
  const ref = useLazyLoadImage();

  const { isTablet } = useDeviceBreakPoint();

  return (
    <div ref={ref} className="flex flex-col items-center">
      <picture>
        <source
          data-srcset={`/images/landing/devices/${imageName}@2x.webp 2x, /images/landing/devices/${imageName}.webp 1x`}
          type="image/webp"
        />
        <img
          className={`md:w-[130px] md:h-[110px] xl:w-[212px] xl:h-[170px]`}
          data-src={`/images/landing/devices/${imageName}.webp`}
          alt={imageName}
        />
      </picture>

      {isTablet ? <MobileDeviceShadowIcon /> : <ShadowIcon />}
      <h5 className="md:text-[20px] xl:text-[28px] font-semibold capitalize md:my-4 xl:my-8">
        {name}
      </h5>
      <button
        type="button"
        className={`text-[12px] xl:text-[16px] font-medium md:border-[1.5px] xl:border-2 border-black rounded-full px-[28px] xl:px-11 py-[10px] xl:py-3 bg-twinworldPrimary-200 xl:hover:transition-background-color xl:hover:duration-300 xl:hover:bg-black xl:hover:text-twinworldPrimary-200`}
        onClick={() => window.open(downloadLink, "_blank")}
      >
        Download
      </button>
    </div>
  );
}

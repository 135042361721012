import formatNumberToAbbreviation from "../../utils/formatNumberToAbbreviation";

import EyeIcon from "./icons/EyeIcon";
import PeopleIcon from "./icons/PeopleIcon";

type Props = {
  views: number;
  plays: number;
};

export default function AudienceOverview({ views = 0, plays = 0 }: Props) {
  return (
    <section className="flex gap-[10px] text-[12px] leading-[15px] h-[12px] text-twinworldGray-500">
      <span className="flex items-center gap-[4px]">
        <EyeIcon />
        {formatNumberToAbbreviation(views)}
      </span>
      <span className="flex items-center gap-[4px]">
        <PeopleIcon />
        {formatNumberToAbbreviation(plays)}
      </span>
    </section>
  );
}

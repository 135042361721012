import HMDDevice from "./HMDDevice";

const LIST = [
  {
    imageName: "hololens_2",
    downloadLink:
      "https://apps.microsoft.com/store/detail/twinworld-beta/9NCBR51C5M67?hl=en-us&gl=us",
  },
  {
    imageName: "meta_quest_2",
    downloadLink:
      "https://www.meta.com/ko-kr/experiences/6464922583573383/?ranking_trace=116836404732608_6464922583573383_SKYLINEWEB_33225171-95f5-49a1-9889-a97a2fdb183f",
  },
  {
    imageName: "meta_quest_3",
    downloadLink:
      "https://www.meta.com/ko-kr/experiences/6464922583573383/?ranking_trace=116836404732608_6464922583573383_SKYLINEWEB_33225171-95f5-49a1-9889-a97a2fdb183f",
  },
  {
    imageName: "meta_quest_pro",
    downloadLink:
      "https://www.meta.com/ko-kr/experiences/6464922583573383/?ranking_trace=116836404732608_6464922583573383_SKYLINEWEB_33225171-95f5-49a1-9889-a97a2fdb183f",
  },
];

export default function HMDList() {
  return (
    <ul className="flex md:px-[24px] pt-20 w-full justify-evenly">
      {LIST.map((item, index) => (
        <li key={index}>
          <HMDDevice
            imageName={item.imageName}
            downloadLink={item.downloadLink}
          />
        </li>
      ))}
    </ul>
  );
}

import React from "react";

import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export default function MainWrapper({ children }: Props) {
  const { isMobile } = useDeviceBreakPoint();

  return (
    <section className="flex flex-col items-center w-full xl:min-h-[922px] max-w-[1440px] mx-auto">
      {isMobile ? (
        <div className="w-full h-[1px] bg-twinworldGray-200 mb-9" />
      ) : (
        <div className="w-full h-[1px] bg-black mb-[73px] shadow-header max-w-[1440px] mx-auto" />
      )}
      {children}
    </section>
  );
}

export type valueObj = {
  idx: string;
  name: string;
  unavailable?: boolean;
};

export const ITEM_LIST_TYPE = {
  RELEVANCE: "relevance",
  POPULAR: "popular",
  NEW: "new-list",
};

export const defaultValueObj: valueObj = {
  idx: "all",
  name: "Choose a category",
  unavailable: true,
};

export type ItemType = "relevance" | "new-list" | "popular";

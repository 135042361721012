export default function CellIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <g clipPath="url(#clip0_2277_29018)">
        <mask
          id="mask0_2277_29018"
          mask-type="luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="80"
          height="80"
        >
          <path d="M80 0H0V80H80V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2277_29018)">
          <path
            d="M41 69C49.2843 69 56 55.5685 56 39C56 22.4315 49.2843 9 41 9C32.7157 9 26 22.4315 26 39C26 55.5685 32.7157 69 41 69Z"
            stroke="#4298D9"
            strokeWidth="4.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5385 53.9904C18.6806 61.1648 33.6705 60.265 48.0192 51.9808C62.368 43.6965 70.6421 31.1648 66.5 23.9904C62.3579 16.816 47.368 17.7157 33.0192 26C18.6705 34.2843 10.3963 46.816 14.5385 53.9904Z"
            stroke="#4298D9"
            strokeWidth="4.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5365 23.9904C10.3944 31.1648 18.6685 43.6965 33.0173 51.9808C47.3661 60.265 62.3559 61.1648 66.498 53.9904C70.6402 46.816 62.3661 34.2843 48.0173 26C33.6685 17.7157 18.6787 16.816 14.5365 23.9904Z"
            stroke="#4298D9"
            strokeWidth="4.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.4444 43.8889C42.899 43.8889 44.8889 41.899 44.8889 39.4444C44.8889 36.9898 42.899 35 40.4444 35C37.9898 35 36 36.9898 36 39.4444C36 41.899 37.9898 43.8889 40.4444 43.8889Z"
            fill="#4298D9"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2277_29018">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

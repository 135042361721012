type Props = {
  onClick?: () => void;
};

export default function PrevButton({ onClick }: Props) {
  return (
    <button
      aria-label="space_carousel_prev_button"
      type="button"
      className="absolute left-[18px] top-[145px]"
      onClick={onClick}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="19.5" fill="white" stroke="black" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0919 25.5974C19.4828 25.9875 19.4828 26.62 19.0919 27.0101C18.7009 27.4003 18.0671 27.4003 17.6762 27.0101L11.4934 20.8398C11.4392 20.7893 11.3906 20.7327 11.3487 20.6712C11.082 20.2823 11.1217 19.7469 11.4678 19.4016C11.479 19.3904 11.4903 19.3796 11.5019 19.3691L17.6774 13.2061C18.0684 12.816 18.7022 12.816 19.0931 13.2061C19.484 13.5962 19.484 14.2287 19.0931 14.6189L14.5987 19.1042L27.6632 19.0798C28.2155 19.0788 28.664 19.5256 28.665 20.0779C28.6661 20.6302 28.2192 21.0788 27.6669 21.0798L14.5895 21.1042L19.0919 25.5974Z"
          fill="black"
        />
      </svg>
    </button>
  );
}

import { cva } from "class-variance-authority";

import { classNameMerge } from "../../../utils/tailwindClassMerge";

type Props = {
  index: string;
  position: "left" | "right";
};

const SEQUENCE_VARIANTS = cva(
  "absolute top-0 flex flex-col items-end justify-center 3xl:w-48 2xl:w-44 w-36 3xl:h-24 2xl:h-[85px] h-[70px] bg-black rounded-full 3xl:pr-10 2xl:pr-8 pr-6",
  {
    variants: {
      position: {
        left: "left-0",
        right: "right-0",
      },
    },
    defaultVariants: {
      position: "left",
    },
  }
);

export default function Sequence({ index, position }: Props) {
  const sectionClasses = classNameMerge(SEQUENCE_VARIANTS({ position }));

  return (
    <section className={`${sectionClasses}`}>
      <p className="flex items-center justify-between w-24 text-3xl font-semibold leading-none text-white h- w-">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="9"
          viewBox="0 0 9 9"
          fill="none"
        >
          <circle cx="4.5" cy="4.5" r="4.5" fill="white" />
        </svg>
        {index}
      </p>
      <p className="text-base font-normal leading-tight text-white">Value</p>
    </section>
  );
}

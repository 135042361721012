import React from "react";
import { useNavigate } from "react-router-dom";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";

import MobileDisLikeIcon from "./icons/MobileDisLikeIcon";
import MobileLikeIcon from "./icons/MobileLikeIcon";

type Props = {
  type: "items" | "spaces";
  id: string;
  likeStatus: "like" | "dislike" | "none";
  handleClickLike: () => void;
  handleClickDisLike: () => void;
};

export default function MobileLikeDisLike({
  type,
  id,
  likeStatus,
  handleClickLike,
  handleClickDisLike,
}: Props) {
  const authActions = useAuthActions();
  const isSignIn = authActions.getLocalStorage("isSignIn");
  const navigate = useNavigate();

  const onClickLike = (event: React.MouseEvent | undefined) => {
    // prevent click event from bubbling up to parent
    if (event) {
      event.stopPropagation();
    }

    if (isSignIn) {
      handleClickLike();
    } else {
      navigate("/sign-in");
      navigate("/sign-in", { state: { redirect: `/${type}/${id}` } });
    }
  };

  const onClickDisLike = (event: React.MouseEvent | undefined) => {
    // prevent click event from bubbling up to parent
    if (event) {
      event.stopPropagation();
    }

    if (isSignIn) {
      handleClickDisLike();
    } else {
      navigate("/sign-in");
      navigate("/sign-in", { state: { redirect: `/${type}/${id}` } });
    }
  };

  return (
    <div className="flex flex-row justify-between items-center w-24 h-[42px] ml-auto mr-[24px] px-3 py-[6px] gap-[20px] bg-twinworldGray-150 rounded-full">
      <button className="cursor-pointer" onClick={onClickLike}>
        <MobileLikeIcon like={likeStatus === "like"} />
      </button>
      <button className="cursor-pointer" onClick={onClickDisLike}>
        <MobileDisLikeIcon disLike={likeStatus === "dislike"} />
      </button>
    </div>
  );
}

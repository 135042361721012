import Slider from "react-slick";

import NextButton from "./NextButton";
import PrevButton from "./PrevButton";
import SpaceCarouselItem from "./SpaceCarouselItem";
import "slick-carousel/slick/slick.css";
import SpaceCarouselPaging from "./SpaceCarouselPaging";

const SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  variableWidth: true,
  className: "relative my-[40px] lg:my-[46px] xl:my-32",
  dotsClass: "slick-dots !flex justify-center items-center mt-[32px] xl:mt-32",
  customPaging: (pageNumber: number) => (
    <SpaceCarouselPaging pageNumber={pageNumber} />
  ),
  autoplay: true,
  autoplaySpeed: 10000,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
};

const LIST = [
  {
    title: "Puzzle in Egypt",
    file: "/videos/egypt.webm",
    poster: "/images/landing/thumbnails/egypt.webp",
  },
  {
    title: "Chinese chess world",
    file: "/videos/chinese.webm",
    poster: "/images/landing/thumbnails/chinese.webp",
  },
  {
    title: "Zoo Package",
    file: "/videos/zoo.webm",
    poster: "/images/landing/thumbnails/zoo.webp",
  },
  {
    title: "Aquarium Package",
    file: "/videos/aqua.webm",
    poster: "/images/landing/thumbnails/aqua.webp",
  },
  {
    title: "Word search king-food",
    file: "/videos/crossword.webm",
    poster: "/images/landing/thumbnails/crossword.webp",
  },
  {
    title: "Horror space",
    file: "/videos/horror.webm",
    poster: "/images/landing/thumbnails/horror.webp",
  },
];

export default function SpaceCarousel() {
  return (
    <Slider {...SETTINGS}>
      {LIST.map(({ title, file, poster }, index) => (
        <SpaceCarouselItem
          key={index}
          title={title}
          file={file}
          poster={poster}
        />
      ))}
    </Slider>
  );
}

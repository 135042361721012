import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

type ContextProps = {
  isAnimation: boolean;
  setIsAnimation: Dispatch<SetStateAction<boolean>>;
};

export const ModelContext = createContext<ContextProps>({
  isAnimation: false,
  setIsAnimation: () => {},
});

type ModelProviderProps = {
  children: React.ReactNode;
};

export const ModelContextProvider = ({ children }: ModelProviderProps) => {
  const [isAnimation, setIsAnimation] = useState<boolean>(false);

  return (
    <ModelContext.Provider value={{ isAnimation, setIsAnimation }}>
      {children}
    </ModelContext.Provider>
  );
};

export const useModelContext = () => useContext(ModelContext);

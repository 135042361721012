import { Imaginable } from "./types";

type Props = {
  isDisplayed: boolean;
  hoveredItem: Imaginable;
  position: {
    x: number;
    y: number;
  };
};

export default function Frame({ isDisplayed, hoveredItem, position }: Props) {
  const { x, y } = position;

  return (
    <div
      className={`absolute transition-transform ease-out duration-200`}
      style={{ top: `${y}px`, left: `${x}px` }}
    >
      <div
        className={`overflow-hidden rounded-full transition-all ease-linear duration-300 w-[360px] 2xl:w-[420px] 3xl:w-[480px] h-[230px] 2xl:h-[247px] 3xl:h-[282px] ${
          isDisplayed ? "opacity-100 scale-100" : "opacity-0 scale-90"
        } pointer-events-none`}
      >
        <img
          className="z-10 object-cover w-[360px] 2xl:w-[420px] 3xl:w-[480px] h-[230px] 2xl:h-[247px] 3xl:h-[282px]"
          src={hoveredItem.imageSrc}
          alt={`${hoveredItem.title}_image`}
        />
      </div>
    </div>
  );
}

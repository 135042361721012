export const getErrorCode = (error: any) => error.response.data.code;
/**
 * 에러코드를 확인 후 /sign-up/email-verification로 이동
 * @param error
 */
export const goToEmailVerificationPage = (error: any) => {
  if (getErrorCode(error) === "NOT_CONFIRMED_EMAIL_VERIFICATION") {
    window.location.replace("/sign-up/email-verification");
  }
};

const ERROR_MESSAGE = {
  NOT_CONFIRMED_EMAIL_VERIFICATION: "",
  ACCOUNT_NOT_FOUND_ON_THIS_SERVICE:
    "Please check your email address or username again",
  NO_ACCOUNT_ID_OR_SERVICE_ID:
    "Please check your email address or username again",
  ALREADY_EXISTS_ACCOUNT_ON_OTHER_SERVICE:
    "An account subscribed by another service exists",
  ALREADY_CONFIRMED_EMAIL_VERIFICATION:
    "Email authentication completed. Please email log in",
  "SIGN_UP_ERROR: WAITING_CONFIRMED_EMAIL": "Waiting confirmed email",
  INVALID_PIN: "Invalid pin number",
  WRONG_PASSWORD: "Please check your password again",
  ACCOUNT_NOT_FOUND: "Please check your email address or username again",
  ALREADY_EXISTS_ACCOUNT_ON_THIS_SERVICE: "Please check your email address",
  SAME_PASSWORD: "Please enter a new password",
  "Validation failed: email": "Invalid email address",
  NON_COMPLIANT_PASSWORD_RULE:
    "Please include letters, digits, and special characters",
};

export const getErrorMessage = (error: any) => {
  const errorCode: keyof typeof ERROR_MESSAGE = getErrorCode(error);
  const errorMessage = ERROR_MESSAGE[errorCode] ?? errorCode;
  return errorMessage;
};

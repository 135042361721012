import { useLocation } from "react-router-dom";

import useDeviceBreakPoint from "../../../hooks/useDeviceBreakPoint";
import MobileNoContentIcon from "../../mobile/icons/MobileNoContentIcon";
import NoContentIcon from "../icons/NoContentIcon";

type Props = {
  title: string;
  type: "space" | "item";
  subTab?: string;
};

const LINK = {
  space: "https://www.doubleme.me/board",
  item: `${process.env.PUBLIC_URL}/guide#upload-your-item`,
};

export default function EmptyList({ title, type, subTab }: Props) {
  const { isDesktop } = useDeviceBreakPoint();
  const { pathname } = useLocation();

  const typeText = type === "space" ? "spaces" : "items";
  const defaultMessage = `${title} has not published any`;

  const setEmptyContent = () => {
    if (!subTab || subTab === "created" || subTab === "my-medias") {
      return defaultMessage;
    }

    if (subTab === "bookmarked" || subTab === "my-favorite-list") {
      return `${title} has not favorited any`;
    }

    if (subTab === "visited" && type === "space") {
      return `${title} has not visited any`;
    }
  };

  const guideVisibility = () => {
    const excludedSubTabs = ["my-favorite-list", "bookmarked", "visited"];

    if (!subTab) {
      return true;
    }

    if (excludedSubTabs.includes(subTab) || pathname.includes("user")) {
      return false;
    }

    return true;
  };

  return (
    <section className="flex flex-col items-center gap-2 xl:gap-9">
      {!isDesktop ? <MobileNoContentIcon /> : <NoContentIcon />}
      <div className="flex flex-col items-center gap-3">
        <p className="text-[14px] xl:text-[18px] xl:h-[18px] leading-[20px] xl:leading-[21px] font-normal text-twinworldGray-400 text-center">
          {setEmptyContent()}
          {!isDesktop && <br />}
          {typeText} yet.
        </p>

        {guideVisibility() && (
          <p className="h-[12px] xl:h-[16px] text-[12px] xl:text-[16px] leading-[15px] xl:leading-[19px] font-normal text-twinworldGray-650">
            Check out the {type} publishing guide{" "}
            <a
              className="text-[12px] xl:text-[16px] h-[12px] xl:h-[16px] leading-[15px] xl:leading-[19px] font-semibold text-black underline decoration-solid"
              href={LINK[type]}
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </p>
        )}
      </div>
    </section>
  );
}

import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import SignInButton from "../button/SignInButton";

export default function GoogleLoginButton() {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    const element = ref.current as HTMLDivElement;
    const button = element.querySelector(`[role="button"]`) as HTMLDivElement;
    button.click();
  };

  const handleOnSuccess = (res: CredentialResponse) =>
    navigate(`/sign-in/google/#access_token=${res.credential}`);

  return (
    <div>
      <SignInButton
        icon={<img alt="google" src="/images/google.png" />}
        label="Google"
        onClick={() => handleClick()}
      />
      <div ref={ref} className="hidden">
        <GoogleLogin onSuccess={handleOnSuccess} />
      </div>
    </div>
  );
}

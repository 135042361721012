import { cva } from "class-variance-authority";

import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import { classNameMerge } from "../../utils/tailwindClassMerge";

type Props = {
  type?: "button" | "submit";
  isDisabled?: boolean;
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
};

const VARIANT = cva(
  `flex items-center justify-center rounded-full 
disabled:border-twinworldGray-500 disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 
disabled:cursor-not-allowed transition ease-in-out duration-300`,
  {
    variants: {
      isMobile: {
        false: `w-[320px] h-[58px] px-[80px] py-[21px] 
        border-2 border-black bg-twinworldPrimary-200 text-[16px] font-semibold 
        hover:bg-black hover:text-twinworldPrimary-200`,
        true: `w-[279px] h-[48px] bg-black text-white text-[16px] font-medium 
        border-[1.5px] border-black`,
      },
    },
    defaultVariants: {
      isMobile: false,
    },
  }
);

export default function ModalActionButton({
  type = "button",
  isDisabled = false,
  onClick,
  className,
  children,
}: Props) {
  const { isMobile } = useDeviceBreakPoint();
  const classes = VARIANT({ isMobile });

  return (
    <button
      type={type}
      className={`${classNameMerge(classes, className)}`}
      disabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

import { valueObj } from "./interface";

export const defaultValueObj: valueObj = {
  idx: "all",
  name: "Choose a item category",
  unavailable: true,
};

export const ITEM_LIST_TYPE = {
  RELEVANCE: "relevance",
  POPULAR: "popular",
  NEW: "new-list",
};

export const defaultItemFilter: valueObj = {
  idx: "relevance",
  name: "Relevance",
};

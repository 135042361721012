import formatNumberToAbbreviation from "../../utils/formatNumberToAbbreviation";
import LiveUserIcon from "../common/icons/LiveUserIcon";

type Props = {
  userCount: number;
};

export default function SpaceLiveUser({ userCount }: Props) {
  return (
    <div className="px-[8px] py-[6px] flex items-center justify-center rounded-full bg-white/30 h-[25px] xl:h-[32px] w-[43px] xl:w-[70px]">
      <LiveUserIcon />
      <div className="ml-[4px] text-[13px] font-medium">
        {formatNumberToAbbreviation(userCount)}
      </div>
    </div>
  );
}

export default function Stamp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="108"
      height="108"
      viewBox="0 0 108 108"
      fill="none"
    >
      <g clipPath="url(#clip0_1344_31279)">
        <path
          d="M89.8447 19.7273L86.7501 13.445C83.5249 6.89258 76.4213 3.1612 69.1671 4.19158L62.7769 5.10192L57.1001 2.04079C50.6597 -1.4405 42.7021 -0.390115 37.387 4.63175L32.2829 9.45353L25.32 10.4439C18.0658 11.4743 12.3086 17.0463 11.0526 24.229L9.94742 30.5613L5.26531 34.993C-0.0497859 40.0149 -1.50667 47.8778 1.64823 54.4602L4.68256 60.7825L3.47687 67.6851C2.22094 74.8778 5.75764 82.0504 12.2382 85.4517L17.9452 88.4528L20.7283 94.2549C23.8832 100.837 30.9365 104.649 38.2109 103.698L45.1838 102.778L51.4032 106.049C57.8838 109.461 65.8313 108.32 71.0861 103.238L75.718 98.7566L82.1182 97.9163C89.3825 96.9659 95.2 91.4539 96.5363 84.2812L97.8123 77.3987L102.856 72.5269C108.111 67.445 109.487 59.5721 106.262 53.0197L103.419 47.2475L104.594 40.9252C105.931 33.7525 102.464 26.5399 96.0239 23.0586L89.8447 19.7173V19.7273Z"
          fill="#325FFF"
        />
        <path
          d="M16.5175 41.3453C16.3869 41.4253 16.2965 41.5654 16.2362 41.7755C16.196 41.9055 16.2161 42.0556 16.2764 42.2356C16.3366 42.4157 16.4371 42.5858 16.5677 42.7458L20.8078 43.9563L20.4159 45.3268L16.2663 44.1463C16.0252 44.0763 15.8343 44.0863 15.7137 44.1663C15.5831 44.2464 15.4927 44.3964 15.4324 44.5965C15.3922 44.7266 15.4123 44.8766 15.4726 45.0567C15.5328 45.2367 15.6233 45.3968 15.7438 45.5569L20.004 46.7673L19.6121 48.1378L13.8047 46.4872L14.1162 45.3968L14.6185 45.3768C14.5181 45.2467 14.4477 45.1167 14.4075 44.9766C14.3673 44.8366 14.3472 44.6965 14.3472 44.5665C14.3472 44.4364 14.3473 44.3064 14.3774 44.1964C14.4075 44.0863 14.4176 43.9863 14.4377 43.9163C14.498 43.6962 14.6185 43.4861 14.7893 43.286C14.9601 43.0859 15.1611 42.9459 15.3821 42.8359C15.2415 42.5758 15.1611 42.3057 15.131 42.0356C15.1109 41.7655 15.131 41.5154 15.1912 41.2853C15.352 40.7151 15.6434 40.3049 16.0453 40.0748C16.4472 39.8447 16.9194 39.8047 17.462 39.9648L21.6216 41.1452L21.2297 42.5157L17.0802 41.3353C16.839 41.2653 16.6582 41.2753 16.5275 41.3553L16.5175 41.3453Z"
          fill="white"
        />
        <path
          d="M16.9103 35.6432L15.5137 34.9129L16.1768 33.6525L17.5734 34.3827L16.9103 35.6432ZM18.1963 34.7029L23.5516 37.4839L22.8885 38.7444L17.5332 35.9633L18.1963 34.7029Z"
          fill="white"
        />
        <path
          d="M19.8438 31.7718L20.7882 30.5513L23.1795 30.6314L22.4862 28.3505L23.4307 27.1301L24.5058 30.6714L28.2233 30.7814L27.2789 32.0019L24.8976 31.9318L25.5909 34.2027L24.6464 35.4231L23.5714 31.8818L19.8538 31.7718H19.8438Z"
          fill="white"
        />
        <path
          d="M29.0866 26.6299C29.4383 26.98 29.7397 27.1801 30.0009 27.2401C30.2622 27.3001 30.5234 27.2101 30.7947 26.99C31.0559 26.7699 31.2066 26.5398 31.2468 26.3297C31.287 26.1097 31.2066 25.8796 31.0358 25.6195L32.1109 24.7091C32.5329 25.3294 32.6937 25.8996 32.5831 26.4098C32.4726 26.93 32.131 27.4201 31.5683 27.9003C31.2468 28.1704 30.9354 28.3605 30.6239 28.4805C30.3124 28.6006 30.011 28.6306 29.6895 28.5706C29.378 28.5206 29.0565 28.3705 28.7149 28.1304C28.3833 27.8903 28.0316 27.5602 27.6498 27.11C26.9465 26.2897 26.5848 25.5595 26.5547 24.9392C26.5245 24.309 26.846 23.7188 27.5092 23.1586C27.8106 22.8985 28.112 22.7084 28.4135 22.5884C28.7149 22.4683 29.0163 22.4283 29.3378 22.4683C29.6493 22.5083 29.9708 22.6484 30.3124 22.8785C30.654 23.1086 31.0057 23.4387 31.3875 23.8788L31.7894 24.349L29.0766 26.6399L29.0866 26.6299ZM28.3029 24.0689C28.0517 24.289 27.9211 24.5191 27.9211 24.7792C27.9211 25.0393 28.0618 25.3594 28.323 25.7395L29.9809 24.339C29.6694 23.9789 29.3881 23.7788 29.1268 23.7488C28.8656 23.7188 28.5943 23.8288 28.3029 24.0689Z"
          fill="white"
        />
        <path
          d="M34.4526 18.4368C34.6636 18.3168 34.8746 18.2468 35.0755 18.2167C35.2765 18.1867 35.4674 18.1767 35.6482 18.2067L34.4425 15.9759L35.7085 15.3057L39.6371 22.6084L38.7127 23.0986L38.3711 22.7984C38.3008 23.0285 38.1902 23.2286 38.0395 23.4187C37.8888 23.6087 37.6678 23.7688 37.3965 23.9189C37.0448 24.1089 36.7133 24.229 36.4119 24.279C36.1104 24.329 35.809 24.299 35.5176 24.179C35.2262 24.0589 34.9449 23.8488 34.6736 23.5387C34.4024 23.2286 34.121 22.8084 33.8397 22.2883C33.5684 21.7881 33.3876 21.3479 33.277 20.9578C33.1766 20.5676 33.1464 20.2175 33.2067 19.9074C33.2569 19.5973 33.3876 19.3272 33.5986 19.0871C33.8096 18.847 34.0909 18.6269 34.4526 18.4368ZM35.0554 21.638C35.2162 21.9481 35.377 22.1982 35.5277 22.3883C35.6784 22.5884 35.8291 22.7284 35.9798 22.8284C36.1305 22.9185 36.2812 22.9685 36.4319 22.9685C36.5827 22.9685 36.7535 22.9185 36.9243 22.8284C37.1855 22.6884 37.3764 22.5483 37.4869 22.3983C37.5974 22.2482 37.6577 22.0882 37.6477 21.9181L36.2611 19.3472C36.1205 19.2571 35.9597 19.2271 35.7688 19.2371C35.5779 19.2471 35.3569 19.3272 35.1057 19.4572C34.9248 19.5572 34.7942 19.6573 34.7138 19.7873C34.6234 19.9074 34.5832 20.0574 34.5732 20.2375C34.5732 20.4076 34.6033 20.6176 34.6837 20.8477C34.7641 21.0778 34.8846 21.3479 35.0454 21.648L35.0554 21.638Z"
          fill="white"
        />
        <path
          d="M46.6706 14.8155C46.7208 14.6654 46.7911 14.5354 46.8816 14.4354C46.972 14.3353 47.0624 14.2453 47.1729 14.1853C47.2835 14.1252 47.394 14.0652 47.5246 14.0352C47.6452 13.9952 47.7657 13.9652 47.8964 13.9452C48.1475 13.9052 48.3887 13.9052 48.6298 13.9452C48.871 13.9852 49.0619 14.0452 49.2126 14.1052L48.8911 15.2957C48.8107 15.2657 48.7403 15.2457 48.67 15.2256C48.5997 15.2056 48.5294 15.1856 48.459 15.1656C48.3887 15.1556 48.3083 15.1456 48.2279 15.1456C48.1476 15.1456 48.0471 15.1456 47.9466 15.1656C47.7858 15.1956 47.6452 15.2457 47.5347 15.3357C47.4241 15.4157 47.3438 15.5258 47.2734 15.6358C47.2131 15.7558 47.1729 15.8859 47.1428 16.0259C47.1127 16.166 47.1127 16.306 47.1127 16.4461L47.7557 20.1675L46.3491 20.4076L45.3242 14.4854L46.4093 14.2953L46.6706 14.8055V14.8155Z"
          fill="white"
        />
        <path
          d="M53.7139 17.1664C53.744 17.6565 53.8345 18.0067 53.9852 18.2268C54.1459 18.4468 54.3971 18.5569 54.7387 18.5669C55.0904 18.5669 55.3516 18.5069 55.5224 18.3768C55.6932 18.2368 55.8038 18.0167 55.8339 17.6966L57.2506 17.7366C57.1501 18.4768 56.8889 19.007 56.4568 19.3172C56.0248 19.6273 55.442 19.7773 54.7086 19.7573C54.2866 19.7473 53.9249 19.6873 53.6235 19.5672C53.312 19.4472 53.0608 19.2671 52.8699 19.017C52.6689 18.767 52.5283 18.4468 52.4379 18.0467C52.3474 17.6465 52.3072 17.1664 52.3273 16.5861C52.3575 15.5057 52.5685 14.7255 52.9704 14.2353C53.3723 13.7451 54.0053 13.515 54.8794 13.535C55.2813 13.535 55.6329 13.605 55.9444 13.7151C56.2559 13.8251 56.5071 13.9952 56.708 14.2353C56.909 14.4754 57.0597 14.7955 57.1602 15.1856C57.2606 15.5858 57.3008 16.066 57.2908 16.6462L57.2707 17.2664L53.7239 17.1764L53.7139 17.1664ZM54.8392 14.7355C54.5076 14.7355 54.2564 14.8155 54.0856 15.0156C53.9148 15.2056 53.8043 15.5358 53.744 15.9959L55.9143 16.0459C55.9243 15.5658 55.8439 15.2356 55.6631 15.0456C55.4923 14.8555 55.211 14.7455 54.8392 14.7355Z"
          fill="white"
        />
        <path
          d="M63.1786 14.5954C63.4801 14.6654 63.7312 14.7755 63.9221 14.9155C64.113 15.0556 64.2738 15.2256 64.3843 15.4157L64.6757 15.0456L65.6905 15.2857L64.2839 21.1378L63.2691 20.8977L63.2088 20.4476C63.0078 20.5676 62.7868 20.6577 62.5456 20.6877C62.3045 20.7277 62.0332 20.7077 61.7318 20.6376C61.34 20.5476 61.0184 20.4176 60.7572 20.2475C60.496 20.0874 60.295 19.8574 60.1544 19.5873C60.0137 19.3072 59.9434 18.967 59.9434 18.5569C59.9434 18.1467 60.0137 17.6465 60.1544 17.0663C60.285 16.5161 60.4457 16.066 60.6266 15.7058C60.8074 15.3457 61.0184 15.0656 61.2696 14.8755C61.5108 14.6754 61.7921 14.5654 62.1136 14.5254C62.4251 14.4854 62.7868 14.5154 63.1887 14.6054L63.1786 14.5954ZM61.4907 17.3864C61.4103 17.7266 61.36 18.0167 61.34 18.2668C61.3199 18.5169 61.34 18.7269 61.3801 18.897C61.4304 19.0671 61.5108 19.1971 61.6213 19.3072C61.7318 19.4072 61.8926 19.4772 62.0835 19.5272C62.3748 19.5973 62.6059 19.6173 62.7968 19.5773C62.9777 19.5372 63.1284 19.4572 63.2389 19.3272L63.9221 16.4861C63.8719 16.3261 63.7714 16.196 63.6308 16.076C63.4801 15.9659 63.2691 15.8659 62.9877 15.8059C62.7968 15.7558 62.626 15.7558 62.4753 15.7859C62.3246 15.8159 62.194 15.8959 62.0734 16.0259C61.9528 16.156 61.8423 16.3261 61.7519 16.5561C61.6615 16.7862 61.571 17.0663 61.4907 17.3964V17.3864Z"
          fill="white"
        />
        <path
          d="M68.1619 22.6984L66.8457 22.1382L70.1212 14.5154L71.4374 15.0756L68.1619 22.6984Z"
          fill="white"
        />
        <path
          d="M75.1636 19.6073L72.0991 24.7892L70.8633 24.0689L73.9278 18.887L75.1636 19.6073ZM74.2895 18.2768L75.0933 16.9263L76.3291 17.6465L75.5253 18.997L74.2895 18.2768Z"
          fill="white"
        />
        <path
          d="M79.9069 22.9985L80.9619 23.8489L80.2284 24.7492L79.1735 23.8989L77.1037 26.4298C77.0535 26.4898 77.0133 26.5499 76.9731 26.6099C76.9329 26.6699 76.9128 26.7299 76.9027 26.7899C76.9027 26.85 76.9027 26.91 76.9429 26.97C76.9831 27.03 77.0334 27.1001 77.1137 27.1701C77.1941 27.2301 77.2946 27.3101 77.4252 27.4002C77.5558 27.4902 77.6965 27.5702 77.8472 27.6503L77.2745 28.5406C77.1941 28.5206 77.0936 28.4806 76.9831 28.4205C76.8726 28.3605 76.7621 28.3005 76.6516 28.2305C76.541 28.1604 76.4406 28.1004 76.3401 28.0304C76.2396 27.9604 76.1693 27.9104 76.119 27.8703C75.8578 27.6503 75.677 27.4402 75.5865 27.2301C75.4861 27.02 75.4459 26.82 75.466 26.6299C75.476 26.4298 75.5363 26.2497 75.6368 26.0597C75.7372 25.8796 75.8578 25.6995 75.9985 25.5295L78.0682 23.0085L77.2745 22.3683L78.008 21.468L78.8017 22.1182L79.6457 21.0878L80.761 21.9982L79.917 23.0285L79.9069 22.9985Z"
          fill="white"
        />
        <path
          d="M83.3032 26.0096L84.1974 27.08L82.1076 30.8414L86.0662 29.3309L86.9605 30.4013L80.3292 32.9222C79.9172 33.0723 79.5354 33.0923 79.1737 32.9822C78.812 32.8622 78.5106 32.6621 78.2594 32.362C78.1589 32.242 78.0484 32.0819 77.9379 31.8918C77.8273 31.7018 77.7369 31.5017 77.6465 31.3116L78.6814 30.7214C78.7015 30.7814 78.7216 30.8514 78.7618 30.9215C78.8019 30.9915 78.8321 31.0715 78.8723 31.1315C78.9125 31.2016 78.9426 31.2616 78.9828 31.3216C79.023 31.3816 79.0531 31.4216 79.0732 31.4517C79.1636 31.5617 79.2742 31.6417 79.4048 31.7118C79.5354 31.7718 79.6962 31.7718 79.877 31.7018L80.3593 31.5117L83.2731 26.0196L83.3032 26.0096Z"
          fill="white"
        />
        <path
          d="M85.5742 40.2449L87.0311 39.6447L87.6741 41.2052L86.2173 41.8055L85.5742 40.2449Z"
          fill="white"
        />
        <path
          d="M91.2702 49.6984C90.9186 49.7284 90.6272 49.7784 90.3861 49.8384C90.1449 49.8984 89.954 49.9785 89.8033 50.0785C89.6526 50.1786 89.5521 50.2986 89.4918 50.4386C89.4316 50.5787 89.4115 50.7488 89.4316 50.9488C89.4617 51.319 89.5722 51.5791 89.7531 51.7291C89.9339 51.8792 90.2052 51.9492 90.577 51.9292L90.6975 53.3297C89.9038 53.3497 89.311 53.1597 88.9292 52.7695C88.5474 52.3694 88.3163 51.7992 88.256 51.0489C88.2158 50.6287 88.246 50.2686 88.3263 49.9485C88.4067 49.6284 88.5574 49.3582 88.7885 49.1382C89.0196 48.9181 89.321 48.738 89.7028 48.598C90.0846 48.4679 90.577 48.3679 91.1497 48.3179C92.2248 48.2178 93.0386 48.3479 93.5611 48.688C94.0936 49.0281 94.395 49.6283 94.4754 50.4987C94.5457 51.309 94.4151 51.9192 94.0936 52.3494C93.762 52.7695 93.2395 53.0496 92.5061 53.1597L92.3855 51.7892C92.707 51.7391 92.9482 51.6291 93.0989 51.439C93.2496 51.259 93.3099 50.9789 93.2697 50.6087C93.2496 50.4086 93.1994 50.2486 93.119 50.1185C93.0386 49.9885 92.918 49.8884 92.7573 49.8184C92.5965 49.7484 92.3956 49.6984 92.1544 49.6784C91.9133 49.6584 91.6119 49.6784 91.2702 49.6984Z"
          fill="white"
        />
        <path
          d="M94.3441 58.8818C94.304 59.1919 94.2236 59.442 94.103 59.6521C93.9824 59.8622 93.8317 60.0322 93.6509 60.1623L94.0528 60.4024L93.9222 61.4328L87.9238 60.6925L88.0544 59.6621L88.4965 59.5521C88.3559 59.362 88.2453 59.1519 88.1851 58.9218C88.1248 58.6917 88.1047 58.4216 88.1449 58.1115C88.1951 57.7114 88.2855 57.3813 88.4262 57.1012C88.5568 56.8211 88.7578 56.601 89.019 56.4309C89.2802 56.2608 89.6218 56.1508 90.0338 56.1108C90.4457 56.0608 90.9481 56.0808 91.5409 56.1508C92.1036 56.2208 92.5758 56.3209 92.9576 56.4709C93.3394 56.611 93.6408 56.791 93.8619 57.0111C94.0829 57.2312 94.2336 57.5013 94.314 57.8014C94.3944 58.1115 94.4044 58.4617 94.3542 58.8718L94.3441 58.8818ZM91.3701 57.5213C91.0285 57.4813 90.7271 57.4613 90.4759 57.4713C90.2247 57.4813 90.0237 57.5213 89.8529 57.5813C89.6922 57.6514 89.5616 57.7414 89.4711 57.8714C89.3807 58.0015 89.3305 58.1616 89.3003 58.3516C89.2601 58.6417 89.2702 58.8818 89.3305 59.0619C89.3908 59.242 89.4912 59.382 89.6319 59.472L92.5457 59.8322C92.6964 59.7621 92.8169 59.6521 92.9174 59.492C93.0179 59.332 93.0782 59.1119 93.1184 58.8218C93.1385 58.6217 93.1284 58.4517 93.0782 58.3116C93.0279 58.1716 92.9275 58.0415 92.7968 57.9415C92.6562 57.8414 92.4653 57.7514 92.2342 57.6814C92.0031 57.6113 91.7117 57.5613 91.3601 57.5113L91.3701 57.5213Z"
          fill="white"
        />
        <path
          d="M91.3814 69.9259L86.3477 66.0245L86.85 64.5839L93.2201 64.644L92.7378 66.0245L88.1662 65.8644L91.8537 68.5854L91.3915 69.9259H91.3814Z"
          fill="white"
        />
        <path
          d="M86.1865 72.2068C85.7444 71.9867 85.3928 71.8966 85.1315 71.9267C84.8703 71.9567 84.6392 72.1167 84.4684 72.4168C84.2875 72.7169 84.2172 72.967 84.2574 73.1871C84.2875 73.4072 84.4382 73.6073 84.6894 73.7973L83.966 75.0078C83.3632 74.5576 83.0316 74.0674 82.9713 73.5473C82.911 73.0171 83.0718 72.4468 83.4536 71.8166C83.6646 71.4565 83.9057 71.1764 84.1569 70.9663C84.4081 70.7562 84.6894 70.6262 85.0109 70.5862C85.3224 70.5361 85.6841 70.5762 86.076 70.6962C86.4678 70.8162 86.9099 71.0263 87.4123 71.3164C88.3366 71.8766 88.9194 72.4368 89.1505 73.0271C89.3816 73.6173 89.2711 74.2775 88.8189 75.0178C88.618 75.3579 88.3869 75.638 88.1458 75.8481C87.9046 76.0582 87.6233 76.1982 87.3118 76.2583C87.0003 76.3183 86.6487 76.2883 86.2568 76.1782C85.865 76.0682 85.4229 75.8681 84.9205 75.568L84.388 75.2479L86.2166 72.2168L86.1865 72.2068ZM87.7539 74.3776C87.9247 74.0875 87.9749 73.8274 87.8845 73.5873C87.7941 73.3472 87.573 73.0871 87.1912 72.807L86.076 74.6577C86.4879 74.8978 86.8195 74.9978 87.0707 74.9378C87.3319 74.8778 87.553 74.6977 87.7439 74.3776H87.7539Z"
          fill="white"
        />
        <path
          d="M77.5355 79.2594L78.5905 80.4298L77.3245 81.5502L76.2695 80.3798L77.5355 79.2594Z"
          fill="white"
        />
        <path
          d="M66.9859 91.1838C67.1265 91.2338 67.2973 91.2138 67.4983 91.1338C67.6188 91.0838 67.7394 90.9737 67.8399 90.8137C67.9404 90.6536 68.0207 90.4735 68.071 90.2735L66.3931 86.2119L67.7193 85.6717L69.3571 89.6432C69.4575 89.8733 69.5781 90.0134 69.7188 90.0634C69.8594 90.1134 70.0403 90.0934 70.2312 90.0134C70.3518 89.9633 70.4723 89.8533 70.5728 89.6932C70.6733 89.5332 70.7537 89.3631 70.7938 89.173L69.1059 85.0915L70.4321 84.5513L72.733 90.1134L71.678 90.5436L71.3565 90.1634C71.3163 90.3235 71.256 90.4535 71.1656 90.5736C71.0752 90.6936 70.9847 90.7936 70.8742 90.8837C70.7737 90.9637 70.6632 91.0337 70.5628 91.0938C70.4623 91.1538 70.3719 91.1938 70.3015 91.2238C70.0905 91.3138 69.8494 91.3539 69.5882 91.3338C69.3269 91.3238 69.0858 91.2638 68.8647 91.1538C68.7442 91.4239 68.5834 91.654 68.3825 91.844C68.1815 92.0341 67.9705 92.1742 67.7495 92.2642C67.1969 92.4943 66.7045 92.5143 66.2725 92.3442C65.8405 92.1742 65.5189 91.824 65.3079 91.3038L63.6602 87.3224L64.9864 86.7822L66.6241 90.7536C66.7246 90.9837 66.8351 91.1238 66.9859 91.1738V91.1838Z"
          fill="white"
        />
        <path
          d="M60.3442 90.4435C60.2337 89.9633 60.083 89.6332 59.8921 89.4431C59.7012 89.253 59.4299 89.193 59.0883 89.243C58.7467 89.2931 58.4955 89.4031 58.3548 89.5632C58.2142 89.7232 58.1438 89.9633 58.1539 90.2834L56.7573 90.4935C56.7272 89.7432 56.898 89.183 57.2597 88.8029C57.6314 88.4227 58.184 88.1826 58.9075 88.0726C59.3194 88.0126 59.6912 88.0126 60.0127 88.0726C60.3342 88.1326 60.6155 88.2727 60.8567 88.4828C61.0978 88.6928 61.2887 88.9929 61.4495 89.3631C61.6102 89.7332 61.7308 90.2134 61.8112 90.7836C61.9719 91.854 61.8915 92.6543 61.5801 93.2045C61.2686 93.7447 60.6859 94.0848 59.8218 94.2149C59.4299 94.2749 59.0682 94.2749 58.7467 94.2149C58.4252 94.1649 58.1438 94.0348 57.9027 93.8347C57.6616 93.6347 57.4606 93.3446 57.2898 92.9744C57.119 92.6043 56.9984 92.1341 56.908 91.5539L56.8176 90.9437L60.3342 90.4235L60.3442 90.4435ZM59.651 93.0244C59.9825 92.9744 60.2136 92.8444 60.3543 92.6243C60.4849 92.4042 60.5452 92.0641 60.515 91.6039L58.3749 91.924C58.4453 92.3942 58.5859 92.7143 58.7869 92.8744C58.9979 93.0344 59.2792 93.0845 59.651 93.0344V93.0244Z"
          fill="white"
        />
        <path
          d="M51.6533 94.505L50.2969 94.445L50.3471 93.2846L51.7035 93.3446L51.8442 90.0934C51.8442 90.0133 51.8442 89.9433 51.8442 89.8733C51.8442 89.8033 51.8241 89.7432 51.7939 89.6832C51.7638 89.6332 51.7136 89.5832 51.6533 89.5532C51.593 89.5232 51.5026 89.5032 51.392 89.5032C51.2916 89.5032 51.161 89.5032 51.0002 89.5032C50.8394 89.5032 50.6787 89.5232 50.5179 89.5532L50.4476 88.5028C50.528 88.4728 50.6284 88.4528 50.749 88.4328C50.8696 88.4128 51.0002 88.3927 51.1208 88.3827C51.2514 88.3727 51.372 88.3627 51.4925 88.3627C51.6131 88.3627 51.7035 88.3627 51.7638 88.3627C52.1054 88.3827 52.3767 88.4528 52.5776 88.5628C52.7786 88.6728 52.9293 88.8129 53.0398 88.973C53.1503 89.133 53.2106 89.3231 53.2408 89.5332C53.2709 89.7432 53.281 89.9533 53.2709 90.1734L53.1303 93.4246L54.1551 93.4746L54.1049 94.6351L53.08 94.595L53.0197 95.9255L51.5829 95.8655L51.6432 94.535L51.6533 94.505Z"
          fill="white"
        />
        <path
          d="M44.5001 93.5646C44.1987 93.4946 43.9475 93.3846 43.7566 93.2445C43.5657 93.1045 43.405 92.9344 43.2944 92.7443L43.0031 93.1145L41.9883 92.8744L43.405 87.0322L44.4198 87.2723L44.48 87.7225C44.681 87.6024 44.902 87.5224 45.1432 87.4824C45.3843 87.4424 45.6556 87.4624 45.957 87.5324C46.3489 87.6224 46.6704 87.7525 46.9316 87.9226C47.1929 88.0826 47.3938 88.3127 47.5345 88.5828C47.6751 88.8629 47.7455 89.203 47.7455 89.6232C47.7455 90.0333 47.6751 90.5335 47.5345 91.1137C47.4039 91.6639 47.2431 92.1141 47.0622 92.4742C46.8814 92.8344 46.6603 93.1145 46.4192 93.3045C46.168 93.5046 45.8867 93.6147 45.5752 93.6547C45.2637 93.6947 44.902 93.6647 44.5001 93.5646ZM46.1881 90.7836C46.2685 90.4435 46.3187 90.1534 46.3388 89.9033C46.3589 89.6532 46.3388 89.4531 46.2986 89.2831C46.2484 89.113 46.168 88.9829 46.0575 88.8729C45.947 88.7729 45.7862 88.7028 45.5953 88.6528C45.3039 88.5828 45.0728 88.5628 44.8819 88.6028C44.7011 88.6428 44.5504 88.7229 44.4399 88.8529L43.7466 91.694C43.7968 91.854 43.8973 91.9841 44.038 92.1041C44.1887 92.2141 44.3997 92.3142 44.681 92.3742C44.8719 92.4242 45.0427 92.4242 45.1934 92.3942C45.3441 92.3642 45.4747 92.2842 45.5953 92.1541C45.7159 92.0241 45.8264 91.854 45.9168 91.6239C46.0073 91.4038 46.0977 91.1137 46.1781 90.7836H46.1881Z"
          fill="white"
        />
        <path
          d="M33.7796 89.3631L38.2406 84.8214L39.6171 85.4817L38.8334 91.774L37.5172 91.1438L38.1904 86.6421L35.0556 89.9733L33.7695 89.3631H33.7796Z"
          fill="white"
        />
        <path
          d="M32.1109 83.9711C32.3822 83.5609 32.5128 83.2208 32.5128 82.9607C32.5128 82.6906 32.3822 82.4605 32.1009 82.2404C31.8296 82.0304 31.5784 81.9303 31.3574 81.9403C31.1363 81.9503 30.9153 82.0704 30.7043 82.3005L29.579 81.4402C30.1015 80.9 30.6239 80.6299 31.1564 80.6299C31.6889 80.6299 32.2416 80.8599 32.8243 81.3001C33.1559 81.5502 33.4171 81.8203 33.5879 82.0904C33.7688 82.3605 33.8592 82.6606 33.8692 82.9807C33.8793 83.3008 33.809 83.641 33.6382 84.0211C33.4774 84.3912 33.2162 84.8114 32.8645 85.2716C32.2014 86.1219 31.5684 86.6321 30.9555 86.7921C30.3426 86.9522 29.6996 86.7621 29.0063 86.2319C28.6848 85.9918 28.4336 85.7317 28.2527 85.4716C28.0719 85.2015 27.9613 84.9114 27.9412 84.6013C27.9212 84.2912 27.9814 83.9411 28.1322 83.5609C28.2829 83.1808 28.534 82.7606 28.8958 82.3105L29.2776 81.8203L32.0908 83.9711H32.1109ZM29.7598 85.2716C30.0311 85.4716 30.2823 85.5517 30.5335 85.4917C30.7847 85.4316 31.066 85.2416 31.3875 84.9014L29.6694 83.5909C29.378 83.9711 29.2474 84.2812 29.2776 84.5413C29.3077 84.8014 29.4685 85.0415 29.7598 85.2716Z"
          fill="white"
        />
        <path
          d="M24.0834 80.7999C23.9226 80.8399 23.782 80.8399 23.6514 80.8199C23.5208 80.7999 23.3901 80.7599 23.2796 80.6999C23.1691 80.6399 23.0586 80.5698 22.9681 80.4898C22.8777 80.4098 22.7873 80.3197 22.6969 80.2197C22.5261 80.0296 22.4055 79.8196 22.3151 79.5995C22.2246 79.3694 22.1744 79.1793 22.1543 79.0193L23.3399 78.6691C23.36 78.7492 23.37 78.8192 23.3901 78.8892C23.4102 78.9592 23.4303 79.0293 23.4504 79.0993C23.4705 79.1693 23.5107 79.2393 23.5509 79.3094C23.5911 79.3794 23.6514 79.4594 23.7217 79.5395C23.8322 79.6695 23.9528 79.7495 24.0834 79.7995C24.214 79.8496 24.3446 79.8696 24.4753 79.8596C24.6059 79.8496 24.7365 79.8196 24.8772 79.7695C25.0078 79.7195 25.1384 79.6495 25.259 79.5695L28.1024 77.0685L29.0468 78.1389L24.5154 82.1104L23.782 81.2901L24.0834 80.7999Z"
          fill="white"
        />
        <path
          d="M19.5625 73.5072C19.5324 73.7073 19.5826 73.9274 19.7233 74.1575C19.8539 74.3776 19.9946 74.5276 20.1453 74.6076C20.3061 74.6977 20.4668 74.6877 20.6276 74.5976C20.7481 74.5276 20.8285 74.4476 20.8788 74.3676C20.929 74.2875 20.9591 74.1875 20.9591 74.0775C20.9591 73.9674 20.9491 73.8474 20.9189 73.7173C20.8888 73.5873 20.8587 73.4372 20.8185 73.2772L20.6477 72.4769C20.5974 72.2568 20.5673 72.0467 20.5572 71.8466C20.5472 71.6365 20.5572 71.4465 20.6075 71.2664C20.6577 71.0863 20.7381 70.9063 20.8687 70.7562C20.9893 70.5962 21.1601 70.4561 21.3811 70.3261C21.6424 70.176 21.8936 70.096 22.1447 70.086C22.3959 70.076 22.6371 70.126 22.8682 70.236C23.0992 70.3461 23.3203 70.5061 23.5313 70.7262C23.7423 70.9463 23.9432 71.2064 24.1241 71.5165C24.3351 71.8766 24.4757 72.2168 24.536 72.5169C24.5963 72.827 24.5963 73.1171 24.536 73.3672C24.4757 73.6273 24.3552 73.8574 24.1844 74.0674C24.0136 74.2775 23.7925 74.4676 23.5212 74.6377L22.8079 73.4272C23.1394 73.2071 23.3102 72.977 23.3504 72.757C23.3806 72.5269 23.3203 72.2868 23.1696 72.0167C23.1093 71.9166 23.039 71.8166 22.9586 71.7266C22.8782 71.6365 22.7978 71.5665 22.7074 71.5065C22.617 71.4565 22.5265 71.4265 22.4261 71.4165C22.3256 71.4165 22.2352 71.4365 22.1347 71.4965C22.0141 71.5665 21.9237 71.6465 21.8735 71.7366C21.8232 71.8266 21.7931 71.9166 21.7931 72.0267C21.7931 72.1267 21.7931 72.2368 21.8232 72.3468C21.8534 72.4568 21.8735 72.5769 21.9036 72.6969L22.0744 73.5072C22.1347 73.7673 22.1749 73.9974 22.1849 74.2275C22.195 74.4476 22.1849 74.6577 22.1246 74.8477C22.0744 75.0378 21.984 75.2179 21.8634 75.3679C21.7428 75.528 21.5821 75.668 21.3711 75.7881C20.919 76.0482 20.4668 76.0882 20.0046 75.9081C19.5424 75.7281 19.1406 75.3279 18.7788 74.7177C18.4171 74.0975 18.2564 73.5372 18.3267 73.0471C18.397 72.5569 18.6985 72.1267 19.2511 71.7666L19.9644 72.977C19.7233 73.1371 19.5826 73.3172 19.5525 73.5172L19.5625 73.5072Z"
          fill="white"
        />
        <path
          d="M19.0896 66.8448C19.5417 66.6547 19.8431 66.4446 19.9938 66.2245C20.1446 66.0045 20.1646 65.7344 20.0541 65.4042C19.9436 65.0741 19.7929 64.854 19.602 64.744C19.4111 64.634 19.17 64.614 18.8585 64.674L18.4063 63.3435C19.1398 63.1734 19.7326 63.2434 20.1646 63.5436C20.6067 63.8437 20.9383 64.3338 21.1794 65.0241C21.3101 65.4243 21.3804 65.7844 21.3703 66.1045C21.3703 66.4346 21.2799 66.7247 21.1091 67.0048C20.9383 67.2749 20.6871 67.515 20.3455 67.7351C20.0039 67.9552 19.5518 68.1553 18.9991 68.3453C17.9743 68.6955 17.1605 68.7555 16.5677 68.5554C15.9749 68.3453 15.5328 67.8351 15.2615 67.0148C15.1309 66.6347 15.0706 66.2846 15.0605 65.9645C15.0605 65.6443 15.1309 65.3442 15.2916 65.0741C15.4524 64.804 15.6935 64.5539 16.0351 64.3238C16.3768 64.0938 16.8189 63.8937 17.3715 63.7036L17.9542 63.5035L19.0896 66.8548V66.8448ZM16.4069 66.6247C16.5174 66.9448 16.6882 67.1449 16.9294 67.2349C17.1705 67.325 17.5121 67.325 17.9743 67.2149L17.281 65.1742C16.8289 65.3242 16.5375 65.5143 16.417 65.7444C16.2964 65.9745 16.2964 66.2646 16.417 66.6247H16.4069Z"
          fill="white"
        />
        <path
          d="M19.3012 55.7106L17.7238 55.7507L17.6836 54.06L19.261 54.02L19.3012 55.7106Z"
          fill="white"
        />
        <path
          d="M40.0696 73.8874C38.3214 73.8874 36.5731 73.5272 34.9253 72.807C31.7704 71.4365 29.349 68.9456 28.1031 65.7744L27.9524 65.3842C25.3702 58.8218 28.6256 51.389 35.2066 48.8181L62.1639 38.2842C65.3389 37.0437 68.8254 37.1138 71.9803 38.4743C75.1352 39.8448 77.5566 42.3357 78.8025 45.5069L78.9532 45.897C81.5354 52.4594 78.28 59.8922 71.699 62.4632L64.5552 65.2542C64.5552 65.2542 64.5351 65.2542 64.5251 65.2642L64.0127 65.4643C63.9524 65.4943 63.8821 65.5143 63.8117 65.5343C62.174 65.9845 60.4961 65.5943 59.1999 64.4739C59.1598 64.4339 59.1196 64.3939 59.0794 64.3539C58.4564 63.7136 57.512 63.5135 56.6881 63.8337C55.9546 64.1138 55.4522 64.764 55.3216 65.5543C55.3216 65.6043 55.3015 65.6543 55.2915 65.6943C55.191 67.545 54.2164 69.0756 52.6088 69.9059C52.5485 69.9359 52.4882 69.9659 52.4179 69.9959L51.9155 70.196C51.9155 70.196 51.9155 70.196 51.9055 70.196L44.7517 72.9971C43.2446 73.5873 41.6571 73.8774 40.0797 73.8774L40.0696 73.8874ZM66.826 41.3953C65.7408 41.3953 64.6658 41.5954 63.6309 42.0056L36.6736 52.5395C32.1522 54.3101 29.9116 59.412 31.69 63.9237L31.8407 64.3138C32.6948 66.4846 34.3627 68.1953 36.5329 69.1456C38.6931 70.086 41.0944 70.136 43.2747 69.2857L50.318 66.5347C50.3883 66.5047 50.4587 66.4746 50.529 66.4546L50.8204 66.3346C51.1921 66.1145 51.2625 65.7944 51.2725 65.5043C51.2826 65.2742 51.3127 65.0541 51.3629 64.854C51.7347 62.7232 53.2016 60.9126 55.2111 60.1323C57.522 59.232 60.1243 59.7722 61.8826 61.5128C62.0635 61.6529 62.3046 61.7929 62.6663 61.7129L62.9778 61.5928C63.0381 61.5628 63.1084 61.5428 63.1687 61.5128L70.222 58.7518C74.7433 56.9811 76.9839 51.8792 75.2055 47.3676L75.0548 46.9774C74.2008 44.8066 72.5329 43.096 70.3627 42.1456C69.2273 41.6554 68.0216 41.4053 66.8159 41.4053L66.826 41.3953Z"
          fill="white"
        />
        <path
          d="M37.4073 61.6529C38.85 61.6529 40.0196 60.4884 40.0196 59.0519C40.0196 57.6154 38.85 56.4509 37.4073 56.4509C35.9645 56.4509 34.7949 57.6154 34.7949 59.0519C34.7949 60.4884 35.9645 61.6529 37.4073 61.6529Z"
          fill="white"
        />
        <path
          d="M45.8369 47.8577L44.6513 44.8566C43.7973 42.6858 44.8824 40.2149 47.0527 39.3646C49.233 38.5143 51.7147 39.5947 52.5687 41.7555L53.7543 44.7566"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1344_31279">
          <rect width="108" height="108" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

type Props = {
  maxWidth?: string;
  close?: React.ReactNode;
  title?: React.ReactNode;
  children: React.ReactNode;
  isOpen?: boolean;
  closeModal: () => void;
};

export default function Modal({
  close,
  title,
  children,
  isOpen,
  closeModal,
  maxWidth = "max-w-md",
}: Props) {
  return (
    <Transition appear show={isOpen || true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* dim style */}
          <div className="fixed inset-0 bg-black/70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full ${maxWidth} overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl`}
              >
                {close && <>{close}</>}

                {title && <Dialog.Title as="div">{title}</Dialog.Title>}

                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

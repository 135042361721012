import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import { FetchList } from "../../../types/common";
import checkSpecialChar from "../../../utils/checkSpecialChar";
import spaceApi from "../api";
import { SpaceListParams, CreatorSpaceListParams } from "../interface";

export const useSpaceActions = () => {
  let spaceId: string;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const spaceSearchKeyWord = params.get("search") || "";

  const queryClient = useQueryClient();

  const useInfiniteSearchSpaceList = (
    type: string,
    keyword: string,
    take: number,
    skip: number
  ) => {
    const { isPending, fetchNextPage, hasNextPage, isError, data, refetch } =
      useInfiniteQuery({
        queryKey: ["spaces", "searchSpaceList", type, keyword, take, skip],
        queryFn: ({ pageParam }) => {
          const result = spaceApi.getInfiniteSearchSpaceList(
            keyword,
            type,
            take,
            (pageParam - 1) * take
          );

          return result;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
      });

    return { isPending, fetchNextPage, hasNextPage, isError, data, refetch };
  };

  const useFeaturedSpaceList = () => {
    const { isPending, data } = useQuery({
      queryKey: ["spaces", "featuredSpaceList"],
      queryFn: spaceApi.getFeaturedSpaceList,
    });

    return { isPending, data };
  };

  const useInfiniteGroupsSpaceList = (
    groupId: string,
    take: number,
    skip: number
  ) => {
    const { isPending, fetchNextPage, hasNextPage, isError, data, refetch } =
      useInfiniteQuery({
        queryKey: ["spaces", "groupsSpaceList", { groupId, take, skip }],
        queryFn: ({ pageParam }) => {
          const result = spaceApi.getInfiniteGroupsSpaceList(
            groupId,
            take,
            (pageParam - 1) * take
          );

          return result;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
      });

    return { isPending, fetchNextPage, hasNextPage, isError, data, refetch };
  };

  const useSpaceList = (params: SpaceListParams) => {
    const { type, take = 24, skip = 0 } = params;
    const { isPending, data } = useQuery({
      queryKey: ["spaces", "spaceList", { type, take, skip }],
      queryFn: () => spaceApi.getSpaceList({ type, take, skip }),
      enabled: !checkSpecialChar(spaceSearchKeyWord),
    });

    return { isPending, data };
  };

  const useCreatorSpaceList = (params: CreatorSpaceListParams) => {
    const { take = 24, skip = 0, creatorId } = params;
    const { isPending, data, fetchNextPage } = useInfiniteQuery({
      queryKey: ["spaces", "creatorSpaceList", { creatorId, take, skip }],
      queryFn: ({ pageParam }) => {
        const skip = (pageParam - 1) * take;
        const result = spaceApi.getCreatorSpaceList({ creatorId, take, skip });
        return result;
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < take ? undefined : nextPage;
      },
    });

    return { isPending, data, fetchNextPage };
  };

  const useSpaceDetails = (spaceId = "") => {
    const {
      isPending,
      isError,
      data: spaceDetails,
      error: spaceDetailsError,
    } = useQuery({
      queryKey: ["aSpace", "spaceDetails", { spaceId }],
      queryFn: () => {
        const response = spaceApi.getSpaceDetails(spaceId);
        return response;
      },
    });
    return {
      isPending,
      isError,
      spaceDetails,
      spaceDetailsError,
    };
  };

  // bookmark와 like시에 캐시된 모든 space data들에 영향을 끼치기때문에 모두 지워져아하고
  // 각각의 space 경우에는 각각의 space data 캐시만 지우면 됨
  // 🧐 하나의 함수로 여러개의 쿼리를 지운다
  const { mutateAsync: bookmarkMutateAsync } = useMutation({
    mutationFn: spaceApi.updateBookmarkSpace,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["spaces"] });
      queryClient.invalidateQueries({
        queryKey: ["aSpace", "spaceDetails", { spaceId: variables }],
      });
    },
    onError: (error: any) => {
      if (error.response.status === 404 || error.response.status === 400) {
        window.location.href = `/error/${error.response.status}`;
      }
      throw error;
    },
  });

  const handleBookmark = async (id: string) => {
    spaceId = id;
    await bookmarkMutateAsync(spaceId);
  };

  const { mutateAsync: likeMutateAsync } = useMutation({
    mutationFn: spaceApi.updateLikeSpace,
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[1] === "spaceDetails",
      });
      queryClient.invalidateQueries({
        predicate: (query: any) =>
          query.queryKey[0] === "aSpace" &&
          query.queryKey[2]?.spaceId === spaceId,
      });
    },
    onError: (error: any) => {
      if (error.response.status === 404 || error.response.status === 400) {
        window.location.href = `/error/${error.response.status}`;
      }
      throw error;
    },
  });

  const handleLike = async (id: string) => {
    await likeMutateAsync(id);
  };

  const { mutateAsync: disLikeMutateAsync } = useMutation({
    mutationFn: spaceApi.updateDisLikeSpace,
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[1] === "spaceDetails",
      });
      queryClient.invalidateQueries({
        predicate: (query: any) =>
          query.queryKey[0] === "aSpace" &&
          query.queryKey[2]?.spaceId === spaceId,
      });
    },
    onError: (error: any) => {
      if (error.response.status === 404 || error.response.status === 400) {
        window.location.href = `/error/${error.response.status}`;
      }
      throw error;
    },
  });

  const handleDisLike = async (id: string) => {
    await disLikeMutateAsync(id);
  };

  const mySpaceSearchKeyWord =
    new URLSearchParams(location.search).get("search") || "";

  const useMyVisitedSpaceList = ({ take, skip }: FetchList) => {
    const { isPending, data } = useQuery({
      queryKey: [
        "spaces",
        "myVisitedSpaceList",
        { mySpaceSearchKeyWord, take, skip },
      ],
      queryFn: () =>
        spaceApi.getMyVisitedSpaceList(mySpaceSearchKeyWord, take, skip),
      enabled: !checkSpecialChar(mySpaceSearchKeyWord),
    });

    return { isPending, data };
  };

  const useMyOwnSpaceList = ({ take, skip }: FetchList) => {
    const { isPending, data } = useQuery({
      queryKey: [
        "spaces",
        "myOwnSpaceList",
        { mySpaceSearchKeyWord, take, skip },
      ],
      queryFn: () =>
        spaceApi.getMyOwnSpaceList(mySpaceSearchKeyWord, take, skip),
      enabled: !checkSpecialChar(mySpaceSearchKeyWord),
    });

    return { isPending, data };
  };

  const useMyFavoriteSpaceList = ({ take, skip }: FetchList) => {
    const { isPending, data } = useQuery({
      queryKey: [
        "spaces",
        "myFavoriteSpaceList",
        { mySpaceSearchKeyWord, take, skip },
      ],
      queryFn: () =>
        spaceApi.getMyFavoriteSpaceList(mySpaceSearchKeyWord, take, skip),
      enabled: !checkSpecialChar(mySpaceSearchKeyWord),
    });
    return { isPending, data };
  };

  return {
    useSpaceList,
    useCreatorSpaceList,
    useSpaceDetails,
    useInfiniteSearchSpaceList,
    useFeaturedSpaceList,
    useInfiniteGroupsSpaceList,
    handleBookmark,
    handleLike,
    handleDisLike,
    useMyVisitedSpaceList,
    useMyOwnSpaceList,
    useMyFavoriteSpaceList,
  };
};

import { client } from "../../utils/axiosInstance";
import { UserBasicInfo } from "../auth/interface";

import { PatchProfile, PatchUserSettingInfo, UserInfo } from "./interface";
import User from "./model/User";

const getUserId = async (): Promise<{ userId: string }> => {
  const response = await client({ url: "/user/user-id", method: "GET" }).catch(
    (error: any) => {
      if (error.response.status === 404 || error.response.status === 400) {
        window.location.href = `/error/${error.response.status}`;
      }

      throw error;
    }
  );
  return response.data;
};

const getMyProfile = async (): Promise<UserInfo> => {
  const response = await client({
    url: "/auth/web/profile",
    method: "GET",
  }).catch((error) => {
    throw error;
  });

  const user = new User(response.data.data);

  return user;
};

const getFollowingList = async (): Promise<UserInfo[]> => {
  const response = await client({
    url: "/friend/following_list",
    method: "GET",
  }).catch((error) => {
    throw error;
  });

  return response.data;
};

const getFollowerList = async (): Promise<UserInfo[]> => {
  const response = await client({
    url: "/friend/follower_list",
    method: "GET",
  }).catch((error) => {
    throw error;
  });

  return response.data;
};

const patchUserProfile = async (profile: PatchProfile): Promise<void> => {
  await client({
    url: "/auth/web/profile",
    method: "PATCH",
    data: profile,
  }).catch((error) => {
    throw error;
  });
};

const deleteUserAccount = async (): Promise<void> => {
  await client({
    url: "/user/account",
    method: "DELETE",
  });
};

const getUserSetting = async (): Promise<UserBasicInfo> => {
  const response = await client({
    url: "/account/web/profile",
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return response.data;
};

const patchUserSetting = async (
  patchInfo: PatchUserSettingInfo
): Promise<void> => {
  const response = await client({
    url: "/auth/web/profile",
    method: "PATCH",
    data: patchInfo,
  });
  return response.data.data;
};

const sendChangeEmailConfirmMail = async (email: string): Promise<void> => {
  await client({
    url: "/user/client-change-email",
    method: "POST",
    data: {
      email,
    },
  });
};

const getUserProfile = async (userId: string): Promise<UserInfo> => {
  const response = await client({
    url: `/auth/web/profile?userId=${userId}`,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return new User(response.data.data);
};

const userApi = {
  getUserId,
  getMyProfile,
  getFollowingList,
  getFollowerList,
  getUserProfile,
  getUserSetting,
  sendChangeEmailConfirmMail,
  patchUserProfile,
  patchUserSetting,
  deleteUserAccount,
};

export default userApi;

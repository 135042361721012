import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import Footer from "../Footer";
import Header from "../header/Header";
import MobileFooter from "../mobile/MobileFooter";

const Layout = () => {
  const { pathname } = useLocation();
  const { isDesktop } = useDeviceBreakPoint();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!pathname.includes("spaces")) {
      sessionStorage.removeItem("spaceKeyword");
    }

    if (!pathname.includes("items")) {
      sessionStorage.removeItem("type");
      sessionStorage.removeItem("typeName");
      sessionStorage.removeItem("categoryId");
      sessionStorage.removeItem("categoryName");
      sessionStorage.removeItem("keyword");
    }
  }, [pathname]);

  return (
    <div className="flex flex-col justify-center min-w-[375px] md:w-full xl:min-w-[1440px] overflow-x-hidden">
      <Header />
      <main className="w-full mx-auto max-w-screen-3xl pb-[140px] xl:pb-60 xl:min-w-[1440px]">
        <Outlet />
      </main>
      {isDesktop ? <Footer /> : <MobileFooter />}
    </div>
  );
};

export default Layout;

import { SpaceDetailsResponse } from "../interface";

export class SpaceDetails {
  spaceId: string;

  createdAt: Date;

  spaceName: string;

  spaceCode: string;

  spaceCreator: string;

  spaceCreatorImage: string;

  spaceImage: string;

  spaceDetailImage: string;

  spaceDescription: string;

  isExistsStore: boolean;

  spaceAppSchema: string;

  spaceSceneObject: string;

  spaceAppStoreWebUrl: string;

  spaceAppIconUrl: string;

  isBookmarked: boolean;

  spaceType: string;

  totalLike = "0";

  tagList: string[] = [];

  isLiked: boolean;

  views: string;

  plays: string;

  liveUserCount: number;

  isPublic: boolean;

  likeStatus: "like" | "dislike" | "none";

  creatorId: string;

  constructor(data: SpaceDetailsResponse) {
    const {
      id,
      createdAt,
      name,
      code,
      creatorNickname,
      creatorAvatarImageUrl,
      isPublic,
      thumbnail,
      isExistsStore,
      appSchema,
      appStoreUrl,
      iconMediaId,
      sceneObject,
      isBookmarked,
      isLiked,
      totalLike,
      viewCount,
      joinCount,
      liveUserCount,
      likeStatus,
      creatorId,
      description,
    } = data;
    this.spaceId = id;
    this.createdAt = new Date(createdAt);
    this.spaceName = name;
    this.spaceDescription = description;
    this.spaceCode = code;
    this.spaceCreator = creatorNickname;
    this.spaceCreatorImage = creatorAvatarImageUrl;
    this.spaceImage = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${thumbnail}?size=original&fileType=space-thumbnail`;
    this.spaceDetailImage = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${thumbnail}?size=original&fileType=space-thumbnail`;
    this.isExistsStore = isExistsStore;
    this.spaceAppSchema = appSchema;
    this.spaceSceneObject = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${sceneObject}?size=original&fileType=model`;
    this.spaceAppStoreWebUrl = appStoreUrl;
    this.spaceAppIconUrl = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${iconMediaId}?size=original&fileType=space-thumbnail`;
    this.isBookmarked = isBookmarked;
    this.spaceType = isPublic === false ? "private" : "public";
    this.isLiked = isLiked;
    this.totalLike = totalLike;
    this.views = viewCount;
    this.plays = joinCount;
    this.liveUserCount = liveUserCount;
    this.isPublic = isPublic;
    this.likeStatus = likeStatus;
    this.creatorId = creatorId;
  }
}

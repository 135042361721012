import Value01 from "./Value01";
import Value02 from "./Value02";
import Value03 from "./Value03";

export default function Feature() {
  return (
    <section className="flex flex-col mt-60 gap-52">
      <Value01 />
      <Value02 />
      <Value03 />
    </section>
  );
}

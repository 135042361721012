const EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
const NICKNAME = /^[A-Za-z0-9]*$/; // 영문, 숫자 둘 중 하나 이상 포함
const PASSWORD =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/; // 영문, 숫자, 특수문자 모두 모함 총 8자 이상 20 이하

export const REGEX_FORMAT = {
  EMAIL,
  NICKNAME,
  PASSWORD,
};

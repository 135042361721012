import { RefObject, useState } from "react";
import { Link } from "react-router-dom";

import { useAuthActions } from "../../../domains/auth/hook/useAuthActions";
import { UserInfo } from "../../../domains/user/interface";
import { useOutsideDetectRef } from "../../../hooks/useOutsideDetectRef";
import Avatar from "../../common/Avatar";
import MobileHeaderDim from "../../header/MobileHeaderDim";
import MobilePersonIcon from "../icons/MobilePersonIcon";

import MobileSettingMenu from "./MobileSettingMenu";
import MobileUserMenuList from "./MobileUserMenuList";

type Props = {
  profile: UserInfo | undefined;
};

export default function MobileUserMenu({ profile }: Props) {
  const { signOut } = useAuthActions();

  const [isMenuActive, setIsMenuActive] = useState(false);
  const setMenuActiveToggle = () => setIsMenuActive((prev) => !prev);
  const closeMenu = () => setIsMenuActive(false);

  const ref: RefObject<HTMLDivElement> = useOutsideDetectRef(
    closeMenu,
    isMenuActive
  );

  return (
    <div>
      {isMenuActive && <MobileHeaderDim />}
      <div ref={ref} className="flex items-center h-[36px]">
        <button type="button" className="relative cursor-pointer">
          {profile ? (
            <div onClick={setMenuActiveToggle}>
              <Avatar size="md" src={profile.avatarImageUrl} />
            </div>
          ) : (
            <Link to="/sign-in">
              <MobilePersonIcon />
            </Link>
          )}
        </button>

        {profile && isMenuActive && (
          <div className="box-border absolute left-0 right-0 z-50 flex flex-col items-center justify-end w-full bg-white min-w-[375px] py-[28px] top-[82px]">
            <Avatar size="xl" src={profile.avatarImageUrl} />
            <div className="flex flex-col items-center mt-[12px] gap-[7px] leading-[22px]">
              <span
                className="text-[18px] text-twinworldGray-800 font-medium max-w-[220px] truncate"
                key={profile.nickname}
              >
                {profile.nickname}
              </span>
            </div>

            <MobileSettingMenu onClick={closeMenu} />
            <MobileUserMenuList onClick={closeMenu} />
            <div className="flex flex-col w-full pt-[26px] pl-[36px] text-[18px] leading-[18px] font-medium text-twinworldGray-900">
              <div className="cursor-pointer w-max" onClick={signOut}>
                <div>Sign Out</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import { ConversionStatus } from "../../../components/item/type";
import { ItemInfo } from "../interface";

export interface ItemDataProps {
  id: string;
  name: string;
  uploaderId: string;
  uploaderName: string;
  uploaderAvatarImageUrl: string;
  fileInfo: {
    fileId: string;
    originalFileName: string;
    thumbnailFileName: string;
  };
  categoryInfo: {
    categoryId: string;
    categoryName: string;
  };
  likeUsers: string[];
  savedUsers: string[];
  createdAt: Date;
  updatedAt: Date;
  isPublic: boolean;
  conversionStatus: ConversionStatus;
  uploaderImageMediaId: string;
}

class ItemCard implements ItemInfo {
  itemId: string;

  name: string;

  uploaderId: string;

  uploaderName: string;

  uploaderAvatarImageUrl: string;

  categoryId: string;

  categoryName: string;

  thumbnailUrl: string;

  spriteUrl: string;

  createdAt: Date;

  updatedAt: Date;

  conversionStatus: ConversionStatus;

  constructor(data: ItemDataProps) {
    const {
      id,
      name,
      uploaderId,
      uploaderName,
      categoryInfo,
      createdAt,
      updatedAt,
      conversionStatus,
      uploaderImageMediaId,
    } = data;

    const { categoryId, categoryName } = categoryInfo;

    this.itemId = id;
    this.name = name;

    this.uploaderId = uploaderId;
    this.uploaderName = uploaderName;
    this.uploaderAvatarImageUrl = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${uploaderImageMediaId}?size=original&fileType=profile`;

    this.categoryId = categoryId;
    this.categoryName = categoryName;

    this.thumbnailUrl = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${id}?size=original&fileType=thumbnail&thumbnailType=normal`;
    this.spriteUrl = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${id}?size=original&fileType=thumbnail&thumbnailType=sprite`;

    this.createdAt = createdAt;
    this.updatedAt = updatedAt;

    this.conversionStatus = conversionStatus;
  }
}

export default ItemCard;

const PeopleIcon = () => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Mask group">
      <mask
        id="mask0_3793_26068"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="10"
        height="14"
      >
        <rect id="Rectangle 295" width="10" height="14" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3793_26068)">
        <path
          id="Vector"
          d="M5 7.03646C6.61198 7.03646 7.91667 5.65838 7.91667 3.95573C7.91667 2.25308 6.61198 0.875 5 0.875C3.38802 0.875 2.08333 2.25308 2.08333 3.95573C2.08333 5.65838 3.38802 7.03646 5 7.03646ZM7 6.96875H6.78385C6.24219 7.24382 5.64062 7.40885 5 7.40885C4.35938 7.40885 3.76042 7.24382 3.21615 6.96875H3C1.34375 6.96875 0 8.38809 0 10.1375V10.9297C0 11.6586 0.559896 12.25 1.25 12.25H8.75C9.4401 12.25 10 11.6586 10 10.9297V10.1375C10 8.38809 8.65625 6.96875 7 6.96875Z"
          fill="#949494"
        />
      </g>
    </g>
  </svg>
);

export default PeopleIcon;

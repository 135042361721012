import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";

type Props = {
  closeModal: () => void;
};

export default function ItemUploadErrorModal({ closeModal }: Props) {
  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex justify-center w-[480px] mt-[60px] text-[24px] font-semibold px-[85px] box-border leading-8">
      Failed to upload the item. Try uploading again.
    </div>
  );

  return (
    <Modal
      closeModal={closeModal}
      title={title}
      close={close}
      maxWidth="w-[480px]"
    >
      <div className="flex flex-col items-center mt-4 mb-[60px]">
        <div className="flex flex-col items-center gap-[18px] mt-[37px] w-fit">
          <div className="text-[18px] text-twinworldGray-700 leading-[30px] px-[117px] w-[420px] box-border text-center">
            If the issue persists, please contact us.
          </div>
          <a
            className="text-[18px] leading-[30px] text-twinworldGray-700 mt-[10px]"
            href={"https://discord.gg/c68TfaRD"}
            target={"_blank"}
            rel="noreferrer"
          >
            디스코드 링크
          </a>
          <button
            className="px-[130px] py-[21px] text-black bg-twinworldPrimary-200 font-semibold text-[16px] rounded-full border-2 border-black hover:text-twinworldPrimary-200 hover:bg-black mt-[102px]"
            onClick={closeModal}
          >
            Okay
          </button>
        </div>
      </div>
    </Modal>
  );
}

import useLazyLoadSvgVideo from "../../../hooks/useLazyLoadSvgVideo";

export default function Value01Mask() {
  const ref = useLazyLoadSvgVideo();

  return (
    <section ref={ref} className="relative max-w-[1800px] w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1800 719">
        <defs>
          <clipPath id="value01">
            <path
              d="m171,0c-27.61,0-50,22.39-50,50h-.04c-1.05,26.69-23.02,48-49.96,48h-22C21.85,98.54,0,120.72,0,148v138c0,27.61,22.39,50,50,50h669s47,0,47,0c27.61,0,50,22.39,50,50v283c0,27.61,22.39,50,50,50h884c27.61,0,50-22.39,50-50V161c0-27.61-22.39-50-50-50h-113.13c-27.44-.07-49.68-22.24-49.87-49.65v-16.35h-.25c-2.5-25.27-23.82-45-49.75-45H171Z"
              fill="#FFFFFF"
            />
          </clipPath>
        </defs>

        <foreignObject clipPath="url(#value01)" width="1800" height="719">
          <video
            className="block w-full"
            autoPlay
            muted
            loop
            disablePictureInPicture
            poster="/images/landing/thumbnails/value_1.webp"
          >
            <source data-src="/videos/value_1.webm" type="video/webm" />
          </video>
        </foreignObject>
      </svg>
    </section>
  );
}

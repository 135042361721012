import ModalActionButton from "../button/ModalActionButton";

type Props = {
  closeModal: () => void;
};

export default function EmailConfirmSuccessModal({ closeModal }: Props) {
  return (
    <div className="relative flex flex-col items-center justify-center w-full min-h-full text-center px-[80px]">
      <div className="mt-[8px] mb-[64px] md:mt-[65px] md:mb-[116px]">
        <ModalActionButton type="button" onClick={closeModal}>
          Okay
        </ModalActionButton>
      </div>
    </div>
  );
}

import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import MobileNoResultIcon from "../mobile/icons/MobileNoResultIcon";

import NoResultIcon from "./icons/NoResultIcon";

const NoMatchingResults = () => {
  const { isDesktop } = useDeviceBreakPoint();

  return (
    <div className="flex flex-col items-center justify-center w-full mx-auto mt-[100px] xl:mt-[126px] text-center">
      {isDesktop ? <NoResultIcon /> : <MobileNoResultIcon />}
      <p className="mt-[16px] xl:mt-[25px] text-[14px] xl:text-[18px] h-[14px] xl:h-[18px] font-normal leading-[17px] xl:leading-[21px] text-twinworldGray-400 xl:text-twinworldGray-650">
        No matching results found.
      </p>
    </div>
  );
};

export default NoMatchingResults;

import { useEffect } from "react";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { useCountdown } from "../../hooks/useCountdown";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import QR from "../qrcode/QR";

export default function PinCode() {
  const { useGetQRSignInToken } = useAuthActions();
  const { qrSignInToken, refreshQRSignInToken } = useGetQRSignInToken();
  const [countdown, setIsRunning] = useCountdown(60, refreshQRSignInToken);
  const { isDesktop } = useDeviceBreakPoint();

  useEffect(() => {
    refreshQRSignInToken();
    setIsRunning(true);
  }, [refreshQRSignInToken, setIsRunning]);

  return (
    <section className="h-[346px] xl:h-[386px]">
      {qrSignInToken && (
        <>
          <div className="m-auto w-fit">
            <QR code={qrSignInToken} size={isDesktop ? 200 : 160} />
          </div>
          <div className="flex justify-center mt-[23px] font-semibold text-[#6791D0] xl:text-[#2C4B99] text-[40px]">
            {qrSignInToken}
          </div>

          <p className="mt-[33px] xl:mt-[27px] mb-0 xl:mb-[53px] text-twinworldGray-700 text-[16px] xl:text-[18px] ">
            {countdown}s left
          </p>
        </>
      )}
    </section>
  );
}

type Props = {
  icon: JSX.Element;
  title: React.ReactNode;
  description: string;
  borderColor: string;
};

export default function MobileExperienceItem({
  icon,
  title,
  description,
  borderColor,
}: Props) {
  return (
    <li
      className={`w-full basis-5/12 grow py-10 px-9 flex flex-col border-2 rounded-[20px] shadow-[4px_4px_4px_0px_rgba(0,0,0,0.05)] ${borderColor}`}
    >
      <div className="flex flex-row items-center text-[22px] leading-[28px] font-semibold w-full">
        {icon}
        {title}
      </div>
      <div className="text-[16px] font-normal leading-[27.2px] mt-[25px] white-space w-fit">
        {description}
      </div>
    </li>
  );
}

import { Size } from "../../../types/common";

import Button from "./Button";

type Props = {
  type: "button" | "submit" | "reset";
  buttonStyle: "primary" | "secondary";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size: Size;
  label: string;
  icon: JSX.Element;
  position?: "left" | "right";
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

/**
 *
 * @param label: label
 * @param icon: icon component
 * @param position: icon position
 * @param onMouseEnter: If icon component need change icon color, etc... then pass state icon component like isHover. (example: ItemPlusIcon component)
 * @param onMouseLeave: Change isHover state false.
 * @returns
 */
export default function ButtonWithIcon({
  label,
  icon,
  position = "right",
  ...props
}: Props) {
  const iconStyles = position === "left" ? "mr-2" : "ml-[6px]";

  return (
    <Button {...props}>
      {position === "left" && <span className={iconStyles}>{icon}</span>}
      {label}
      {position === "right" && <span className={iconStyles}>{icon}</span>}
    </Button>
  );
}

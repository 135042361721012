import { Profile, UserInfo } from "../interface";

class User implements UserInfo {
  userId: string;

  nickname: string;

  description: string;

  avatarUrl: string;

  avatarImageUrl: string;

  constructor(data: Profile) {
    const { userId, nickname, description, avatarUrl, imageMediaId } = data;

    this.userId = userId;
    this.nickname = nickname;
    this.description = description;
    this.avatarUrl = avatarUrl;
    this.avatarImageUrl = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${imageMediaId}?size=original&fileType=profile`;
  }
}

export default User;

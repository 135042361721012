type Props = {
  children: React.ReactNode | React.ReactNode[];
  isEmptyData?: boolean;
};

export default function ItemListWrapper({
  children,
  isEmptyData = false,
}: Props) {
  const classes = () => {
    if (isEmptyData) {
      return `flex items-center h-auto`;
    }

    return `w-full grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 
    gap-x-[11px] md:gap-x-[14px] lg:gap-x-[16px] xl:gap-x-[50px] gap-y-[34px] md:gap-y-[69px] lg:gap-y-[104px] xl:gap-y-[140px] 
    xl:w-[1440px] h-auto px-[18px] md:portrait:px-[32px] md:landscape:px-[58px] xl:portrait:px-0 xl:landscape:px-0 xl:mx-auto`;
  };

  return <div className={`${classes()}`}>{children}</div>;
}

import { useCallback, useEffect, useRef } from "react";

export const useOutsideDetectRef = <T extends HTMLElement>(
  onClick: () => void,
  isOpen = true
) => {
  const ref = useRef<T>(null);

  // Click outside of element, execute onClick function
  // callback으로 감싸서 handleClickOutside가 의존하는 값들이 바뀌지 않으면 실행하지 않도록 함
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as Node) && isOpen) {
        onClick();
      }
    },
    [isOpen, onClick]
  );

  useEffect(() => {
    // Bind event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  return ref;
};

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";

import FunctionalityList from "./FunctionalityList";

gsap.registerPlugin(ScrollTrigger);

export default function Experience() {
  useEffect(() => {
    const horizontal = document.querySelector("#horizontal") as HTMLElement;
    const liList = gsap.utils.toArray("#horizontal li");
    gsap.to(liList, {
      xPercent: -100 * (liList.length - 2), // 수평요소 위치
      ease: "none", // 애니메이션 효과
      scrollTrigger: {
        trigger: horizontal, // 트리거
        start: "top top", // 스크롤이 시작되는 지점 위치
        end: () => `+=${horizontal.offsetWidth / 2.7}`, // 스크롤이 끝나는 지점 위치
        pin: true, // 트리거 요소를 고정
        scrub: 1, // 스크롤 속도에 따라 애니메이션 속도 조절
        snap: {
          // 스크롤 위치에 따라 스냅
          snapTo: 1 / (liList.length - 1), // 스냅 위치
          inertia: false, // 스냅 위치에 따라 관성 효과
          duration: { min: 100, max: 100 }, // 스냅 위치에 따라 애니메이션 속도 조절
        },
        invalidateOnRefresh: true, // 새로고침시 트리거 요소 위치 재계산
        anticipatePin: 1, // 트리거 요소가 고정되기 전에 애니메이션 실행
        markers: false, // 트리거 요소 위치 표시
      },
    });
  }, []);

  return (
    <section
      id="horizontal"
      className="flex flex-col mt-[244px] pt-[44px] h-[987px]"
    >
      <h3 className="w-[923px] text-[62px] font-semibold leading-tight text-center align-middle 3xl:ml-[500px] 2xl:ml-[400px] ml-[250px] mb-24">
        Experience the capabilities of TwinWorld for{" "}
        <span className="inline-block w-[153px] h-[70px] px-6 leading-tight text-white bg-black rounded-full text-[52px]">
          Free
        </span>
      </h3>
      <FunctionalityList />
    </section>
  );
}

import { useState } from "react";

type Props = {
  isLiked: boolean;
  onClick: () => void;
};

export default function DisLikeButton({ isLiked, onClick }: Props) {
  const [isHover, setIsHover] = useState(isLiked);

  return (
    <button
      type="button"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
    >
      <svg
        width="36"
        height="34"
        viewBox="0 0 36 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {(isLiked || isHover) && (
          <rect
            x="36"
            y="34"
            width="36"
            height="34"
            rx="17"
            transform="rotate(180 36 34)"
            fill="#A6D9FF"
          />
        )}
        <path
          d="M6.54546 20.9H14.5764L13.3673 26.841L13.3291 27.257C13.3291 27.79 13.5455 28.284 13.8891 28.635L15.2382 30L23.6127 21.433C24.0836 20.965 24.3636 20.315 24.3636 19.6V6.6C24.3636 5.17 23.2182 4 21.8182 4H10.3636C9.30727 4 8.40364 4.65 8.02182 5.586L4.17818 14.751C4.06363 15.05 4 15.362 4 15.7V18.3C4 19.73 5.14546 20.9 6.54546 20.9ZM6.54546 15.7L10.3636 6.6H21.8182V19.6L16.2945 25.242L17.7073 18.3H6.54546V15.7ZM32 19.6H26.9091V4H32V19.6Z"
          fill="black"
        />
      </svg>
    </button>
  );
}

export default function PhysicsIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4868_9188"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="60"
        height="60"
      >
        <path d="M60 0H0V60H60V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_4868_9188)">
        <path
          d="M30.2878 47.8407C35.2364 47.8407 39.248 39.8175 39.248 29.9204C39.248 20.0232 35.2364 12 30.2878 12C25.3392 12 21.3276 20.0232 21.3276 29.9204C21.3276 39.8175 25.3392 47.8407 30.2878 47.8407Z"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4816 38.8749C16.9559 43.1605 25.91 42.6231 34.4812 37.6745C43.0524 32.7259 47.9949 25.2402 45.5206 20.9546C43.0463 16.669 34.0922 17.2064 25.521 22.155C16.9498 27.1036 12.0073 34.5894 14.4816 38.8749Z"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4799 20.9546C12.0056 25.2402 16.9482 32.726 25.5193 37.6745C34.0905 42.6231 43.0446 43.1606 45.5189 38.875C47.9932 34.5894 43.0507 27.1036 34.4795 22.155C25.9083 17.2065 16.9542 16.669 14.4799 20.9546Z"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.9561 32.8405C31.4224 32.8405 32.611 31.6519 32.611 30.1856C32.611 28.7194 31.4224 27.5308 29.9561 27.5308C28.4899 27.5308 27.3013 28.7194 27.3013 30.1856C27.3013 31.6519 28.4899 32.8405 29.9561 32.8405Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import MainWrapper from "../components/common/MainWrapper";
import QuestionMarkIcon from "../components/common/icons/QuestionMarkIcon";
import FileDragDrop from "../components/fileDragDown";
import ItemUploadErrorModal from "../components/modal/ItemUploadErrorModal";
import ItemUploadSuccessModal from "../components/modal/ItemUploadSuccessModal";
import ItemCategorySelector from "../components/selector/ItemCategorySelector";
import UploadModelViewer from "../components/viewer/UploadModelViewer";
import {
  ItemUploadContext,
  ItemUploadDispatchContext,
} from "../contexts/ItemUploadContext";
import { useItemActions } from "../domains/item/hook/useItemActions";
import { useItemUploadActions } from "../domains/item/hook/useItemUploadActions";
import { uploadGLBItemInfo, ItemCategory } from "../domains/item/interface";
import use3DModelScreenshot from "../hooks/use3DModelScreenshot";

const MAX_TITLE_LENGTH = 30;

export default function ItemUpload() {
  const navigate = useNavigate();
  const { itemCategoryList } = useItemActions();
  const { fiberCanvasCapture } = use3DModelScreenshot();

  const { uploadItem } = useItemUploadActions();

  const items = useContext(ItemUploadContext);
  const dispatch = useContext(ItemUploadDispatchContext);

  const [isDisabledPublishBtn, setIsDisabledPublishBtn] = useState(true);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const [isCaptureFinished, setIsCaptureFinished] = useState(false);
  const [uploadItemId, setUploadItemId] = useState<string>("");

  const isEmpty: boolean =
    isCaptureFinished &&
    items.title !== "" &&
    items.category.idx !== "" &&
    items.uploadItemFile.length > 0;

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= MAX_TITLE_LENGTH) {
      dispatch({ type: "CHANGE_TITLE", payload: e.target.value });
    }
  };

  const handleChangeCategory = (category: ItemCategory) => {
    dispatch({ type: "CHANGE_CATEGORY", payload: category });
  };

  const handleUploadItem = async () => {
    if (items.uploadItemFile[0].extension !== "glb") {
      alert("Please upload only GLB files.");
      return;
    }

    setIsDisabledPublishBtn(true);

    const { dataURL, thumbnailFile } = await fiberCanvasCapture(
      "uploadModelViewer"
    );

    dispatch({ type: "CHANGE_PREVIEW_THUMBNAIL", payload: dataURL });

    const thumbnailSprite = items.uploadPreviewSpriteFile!;
    const thumbnailGif = items.uploadPreviewGifFile!;

    const data: uploadGLBItemInfo = {
      name: items.title,
      categoryId: items.category.idx,
      fileType: "model",
      type: ["public"],
      thumbnail: thumbnailFile,
      thumbnailSprite,
      thumbnailGif,
      media: items.uploadItemFile[0].file,
    };

    try {
      const response = await uploadItem(data);

      if (response) {
        setIsDisabledPublishBtn(false);
        setUploadItemId(response);
        setIsUploadSuccess(true);
      }
    } catch (error: any) {
      console.error(error);
      setIsCaptureFinished(false);
      setIsDisabledPublishBtn(false);
      setIsUploadError(true);
    }
  };

  const closeUploadSuccessModal = () => {
    setIsUploadSuccess(false);
    dispatch({ type: "INITIALIZATION_ITEM" });
  };

  const handleCheckUploadItem = () => navigate(`/items/${uploadItemId}`);

  const handleCloseErrorModal = () => {
    setIsUploadError(false);
    dispatch({ type: "INITIALIZATION_ITEM" });
  };

  useEffect(() => {
    setIsDisabledPublishBtn(!isEmpty);
  }, [isEmpty]);

  useEffect(() => {
    if (!items.uploadItemFile.length) return;

    dispatch({
      type: "CHANGE_CATEGORY",
      payload: {
        idx: "",
        name: "Choose a category",
      },
    });

    dispatch({ type: "CHANGE_TITLE", payload: "" });
  }, [items.uploadItemFile]);

  return (
    <MainWrapper>
      <div className="flex flex-col w-full items-center gap-[84px]">
        <div className="flex gap-[102px]">
          <div className="flex flex-col justify-center gap-4">
            <div className="flex justify-center items-center w-[588px] h-[438px] bg-twinworldGray-150 rounded-3xl border border-twinworldGray-300 overflow-hidden">
              <UploadModelViewer
                renderFileUrl={
                  items.uploadItemFile.length === 0
                    ? ""
                    : items.uploadItemFile[0].preview
                }
                captureFinished={() => setIsCaptureFinished(true)}
              />
            </div>
            <a
              className="flex justify-center text-twinworldGray-650 text-[13px] leading-none cursor-pointer"
              href={`${process.env.PUBLIC_URL}/guide#thumbnail-guide`}
              target="_blank"
              rel="noreferrer"
            >
              Thumbnail image guide
              <div className="ml-[6px]">
                <QuestionMarkIcon />
              </div>
            </a>
          </div>
          <div className="flex flex-col">
            <div className="w-[530px] h-[262px]">
              <FileDragDrop />
            </div>
            <div className="flex items-center justify-between w-[530px] py-4 border rounded-full px-7 border-twinworldGray-350 mt-11">
              <input
                className="w-[430px]"
                type="text"
                placeholder="Title"
                value={items.title}
                onChange={handleChangeTitle}
              />
              <span className="text-[13px] leading-none text-twinworldGray-600">
                {items.title.length}/30
              </span>
            </div>
            <div className="mt-4">
              {itemCategoryList && (
                <ItemCategorySelector
                  selectedCategory={items.category}
                  categoryList={itemCategoryList}
                  handleChangeCategory={handleChangeCategory}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-start">
          <button
            className={`border-2 rounded-full w-80 h-14 font-semibold ${
              isDisabledPublishBtn
                ? "border-twinworldGray-500 bg-twinworldSecondary-200 text-twinworldGray-500 cursor-not-allowed"
                : "cursor-pointer border-black bg-twinworldPrimary-200 hover:text-twinworldPrimary-200 hover:bg-black"
            }`}
            type="button"
            onClick={handleUploadItem}
            disabled={isDisabledPublishBtn}
          >
            Save & Upload
          </button>
        </div>

        {isUploadSuccess && (
          <ItemUploadSuccessModal
            itemThumbnail={items.previewThumbnail}
            itemName={items.title}
            closeModal={closeUploadSuccessModal}
            handleCheckUploadItem={handleCheckUploadItem}
          />
        )}
        {isUploadError && (
          <ItemUploadErrorModal closeModal={handleCloseErrorModal} />
        )}
      </div>
    </MainWrapper>
  );
}

import { UserInfo } from "../../domains/user/interface";
import AvatarEditButton from "../button/AvatarEditButton";
import Avatar from "../common/Avatar";

import EditButton from "./EditButton";

type Props = {
  selectedUserProfile: UserInfo | undefined;
  loggedInUserId?: string;
};

export default function UserDetails({
  selectedUserProfile,
  loggedInUserId,
}: Props) {
  if (!selectedUserProfile) {
    return (
      <section className="flex w-full flex-col xl:flex-row h-[150px] gap-0 xl:gap-[60px]">
        <div className="relative">
          <Avatar size="2xl" />
        </div>
        <div className="flex flex-col gap-[8px] xl:gap-[20px] items-center xl:items-start">
          <h3 className="text-[24px] xl:text-[45px] font-semibold mt-[9px] xl:mt-0">
            Selected user information is not available.
          </h3>
        </div>
      </section>
    );
  }

  const { avatarImageUrl, nickname, description } = selectedUserProfile;

  return (
    <section className="flex min-w-[339px] w-full px-[18px] xl:px-0 flex-col items-center xl:flex-row gap-0 xl:gap-[60px] mx-auto">
      <div className="relative">
        <Avatar size="2xl" src={avatarImageUrl} />
        {loggedInUserId === selectedUserProfile.userId && <AvatarEditButton />}
      </div>
      <div className="flex flex-col items-center xl:items-start">
        <h3 className="text-[22px] xl:text-[45px] font-semibold mt-[12px] xl:mt-0">
          {nickname}
        </h3>
        <p className="text-[14px] leading-[24px] xl:leading-[30px] xl:text-[18px] px-[6px] xl:px-0 text-twinworldGray-650 break-all mt-[12px] xl:mt-[20px] mb-[24px] xl:mb-[42px]">
          {description}
        </p>
        {loggedInUserId === selectedUserProfile.userId && <EditButton />}
      </div>
    </section>
  );
}

import useLazyLoadVideo from "../../../../hooks/useLazyLoadVideo";

export default function MobileValue01Mask() {
  const ref = useLazyLoadVideo();

  return (
    <section ref={ref} className="relative w-full">
      <svg
        className="absolute z-10 -top-[0.5px] -right-[0.3px]"
        xmlns="http://www.w3.org/2000/svg"
        width="104"
        height="63"
        viewBox="0 0 104 63"
        fill="none"
      >
        <path
          d="M22.4088 20.3058C22.4088 7.28926 6.89503 0 0 0H104V63C104 49.6711 90.7845 41.6529 82.7403 41.6529H42.5193C21.8343 41.6529 22.4088 21.8678 22.4088 20.3058Z"
          fill="#F5FAFD"
        />
      </svg>
      <video
        className="block w-full rounded-[20px]"
        autoPlay
        muted
        loop
        disablePictureInPicture
        playsInline
        poster="/images/landing/thumbnails/value_1.webp"
      >
        <source data-src="/videos/value_1.webm" type="video/webm" />
        <source data-src="/videos/value_1.mp4" type="video/mp4" />
      </video>
    </section>
  );
}

type Props = {
  pageNumber: number;
};

export default function SpaceCarouselPaging({ pageNumber }: Props) {
  return (
    <button
      className={`w-[10px] h-[6px] xl:w-[14px] xl:h-2 rounded-full bg-twinworldGray-150 mx-[5px] xl:mx-[8.5px] before:content-[${pageNumber}]`}
      aria-label="space_carouse_paging_button"
      key={pageNumber}
    />
  );
}

import MobileValue from "./MobileValue";
import MobileValue01Mask from "./MobileValue01Mask";
import MobileValue02Mask from "./MobileValue02Mask";
import MobileValue03Mask from "./MobileValue03Mask";

const CONTENTS = [
  {
    number: 1,
    title: (
      <>
        Chat with Global
        <br />
        Twinworld Friends
        <br />
        in Reality
      </>
    ),
    frame: <MobileValue01Mask />,
    paragraphs: [
      "TwinWorld facilitates real-time conversations with friends from around the world, transcending the boundaries between reality and virtuality.",
      "This platform offers an immersive experience that feels as if you are actually together through avatars.",
    ],
  },
  {
    number: 2,
    title: (
      <>
        Experience the <br />
        Unreal in Reality
      </>
    ),
    frame: <MobileValue02Mask />,
    paragraphs: [
      "TwinWorld offers extraordinary experiences such as space travel, underwater exploration, and dinosaur expeditions that go beyond your imagination.",
      "Immerse yourself in TwinWorld for a realistic experience.",
    ],
  },
  {
    number: 3,
    title: (
      <>
        Create Your Own
        <br />
        3D Space in Reality
      </>
    ),
    frame: <MobileValue03Mask />,
    paragraphs: [
      "Create your own board game room and 3D art gallery.",
      "Invite your friends, share various activities, and start new hobbies and adventures.",
    ],
  },
];

export default function index() {
  return (
    <section className="flex flex-col w-full min-w-[375px] mt-[78px]">
      {CONTENTS.map(({ number, title, frame, paragraphs }, index) => (
        <MobileValue
          key={index}
          number={number}
          title={title}
          frame={frame}
          paragraphs={paragraphs}
        />
      ))}
    </section>
  );
}

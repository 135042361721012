import FacebookIcon from "./icons/FacebookIcon";
import InstagramIcon from "./icons/InstagramIcon";
import LinkedInIcon from "./icons/LinkedInIcon";
import TwitterIcon from "./icons/TwitterIcon";
import YoutubeIcon from "./icons/YoutubeIcon";

type Menu = {
  label: string;
  url: string;
};

const TOP_MENU: Menu[] = [
  {
    label: "Press",
    url: "https://www.doubleme.me/press",
  },
  {
    label: "Careers",
    url: "https://www.linkedin.com/company/twinworld",
  },
  {
    label: "Partnership",
    url: "https://www.doubleme.me/contact",
  },
];

const CENTER_MENU: Menu[] = [
  {
    label: "Download for Meta Quest",
    url: "https://www.oculus.com/experiences/quest/6464922583573383/?ranking_trace=116836404732608_6464922583573383_SKYLINEWEB_33225171-95f5-49a1-9889-a97a2fdb183f",
  },
  {
    label: "Double Me",
    url: "https://www.doubleme.me/",
  },
  {
    label: "Terms & Conditions",
    url: "/terms-and-conditions/service",
  },
  {
    label: "Privacy Policy",
    url: "/terms-and-conditions/privacy",
  },
];

type SNS = {
  url: string;
  icon: React.ReactNode;
  ariaLabel: string;
};

const SNS_LIST: SNS[] = [
  {
    url: "https://www.facebook.com/TwinWorldglobal/",
    icon: <FacebookIcon />,
    ariaLabel: "Facebook",
  },
  {
    url: "https://twitter.com/TwinWorldglobal",
    icon: <TwitterIcon />,
    ariaLabel: "Twitter",
  },
  {
    url: "https://www.youtube.com/channel/UCMVOmsqN3D_F4LKT5S0G2xQ",
    icon: <YoutubeIcon />,
    ariaLabel: "Youtube",
  },

  {
    url: "https://www.linkedin.com/company/twinworld/",
    icon: <LinkedInIcon />,
    ariaLabel: "LinkedIn",
  },
  {
    url: "https://www.instagram.com/twinworldglobal/",
    icon: <InstagramIcon />,
    ariaLabel: "Instagram",
  },
];

export default function MobileFooter() {
  return (
    <footer className="w-full h-[340px] min-w-[375px] bg-twinworldGray-100">
      <div className="h-full flex flex-col py-[32px] items-center w-full border-t border-twinworldGray-200">
        <ul className="flex gap-[32px] h-[14px] mb-[36px]">
          {TOP_MENU.map(({ label, url }, index) => (
            <li key={index}>
              <a
                href={url}
                target={"_blank"}
                rel="noreferrer"
                className="text-twinworldGray-600 font-normal text-[14px] leading-[18px]"
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
        <ul className="flex flex-col gap-[22px] text-center mb-[36px]">
          {CENTER_MENU.map(({ label, url }, index) => (
            <li key={index} className="h-[13px]">
              <a
                href={url}
                target={"_blank"}
                rel="noreferrer"
                className="text-twinworldGray-400 font-normal text-[13px] leading-[16px]"
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
        <ul className="flex gap-[22px] ">
          {SNS_LIST.map(({ url, icon, ariaLabel }, index) => (
            <li key={index}>
              <a
                key={index}
                href={url}
                target={"_blank"}
                rel="noreferrer"
                aria-label={ariaLabel}
              >
                {icon}
              </a>
            </li>
          ))}
        </ul>
        <p className="mt-[31px] text-[13px] leading-[16px] text-twinworldGray-500 font-normal">
          © 2023. TwinWorld.
        </p>
      </div>
    </footer>
  );
}

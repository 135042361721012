export default function MoreArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="26"
      viewBox="0 0 32 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2678 1.70537C17.8768 1.31524 17.8768 0.682723 18.2678 0.292596C18.6587 -0.0975318 19.2925 -0.0975319 19.6834 0.292595L31.6923 12.2771C31.7136 12.2975 31.7339 12.3189 31.7534 12.3411C31.7838 12.3757 31.8113 12.4119 31.8361 12.4493C32.0283 12.7398 32.0525 13.1084 31.9088 13.4186C31.8606 13.5224 31.7936 13.6198 31.7078 13.7054C31.7062 13.707 31.7046 13.7086 31.703 13.7101L19.6834 25.7054C19.2925 26.0955 18.6587 26.0955 18.2678 25.7054C17.8768 25.3152 17.8768 24.6827 18.2678 24.2926L28.5781 14.0032L1.00184 14.0546C0.44956 14.0557 0.00101983 13.6088 1.73609e-06 13.0565C-0.00101635 12.5042 0.445873 12.0557 0.998157 12.0546L28.5865 12.0032L18.2678 1.70537Z"
        fill="black"
      />
    </svg>
  );
}

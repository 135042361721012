export default function MinusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="2"
      viewBox="0 0 20 2"
      fill="none"
    >
      <path d="M0 1H20" stroke="white" strokeWidth="2" />
    </svg>
  );
}

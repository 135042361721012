import toast, { Toaster } from "react-hot-toast";

import copyToClipboard from "../../utils/copyToClipboard";

import CheckIcon from "./icons/CheckIcon";
import SharedLikeIcon from "./icons/SharedLiknIcon";

// TODO: 필요 시 확장 custom 예정
const customToast = () => {
  toast.custom(
    (t) => (
      <div
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          gap: "5px",
          border: "1px solid black",
          padding: "16px 40px",
          borderRadius: "10px",
          boxShadow: "3px 3px 3px 0px rgba(0, 0, 0, .25)",
          position: "absolute",
          right: "-20px",
          opacity: t.visible ? 1 : 0,
          transition: "opacity 1s ease-in-out",
        }}
      >
        <div className="absolute overflow-hidden w-[25px] -top-[16px] right-[12px] z-10">
          <div className="w-[16px] h-[16px] origin-bottom-left transform rotate-45 bg-white border border-black " />
        </div>
        <CheckIcon />
        Copy link to clipboard
      </div>
    ),
    {
      duration: 500,
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    }
  );
};

export default function SharedLinkToast() {
  const pageLink = window.location.href;

  return (
    <div
      className="flex cursor-pointer my-auto relative"
      onClick={async () => {
        await copyToClipboard(pageLink);
        customToast();
      }}
    >
      <SharedLikeIcon />
      <Toaster
        containerStyle={{
          position: "absolute",
          top: 40,
          width: "fit-content",
        }}
        containerClassName="toaster-wrapper"
      />
    </div>
  );
}

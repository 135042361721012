import Input from "./Input";

type Props = {
  value: string | number;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  width?: string;
  height?: string;
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
};

export default function SearchInput({
  value,
  setValue,
  width = "w-[491px]",
  height = "h-[64px]",
  handleKeyPress,
  placeholder,
}: Props) {
  return (
    <section className={`${width} ${height}`}>
      <Input
        value={value}
        setValue={setValue}
        placeholder={placeholder}
        variant="solid"
        color="primary"
        type="text"
        size="lg"
        className="w-full rounded-full placeholder-twinworldGray-450 text-[14px] h-[64px]"
        handleKeyPress={handleKeyPress}
      />
    </section>
  );
}

export default function AvatarIcon({ isHover }: { isHover: boolean }) {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="40"
        height="40"
        rx="20"
        fill={isHover ? "#A6D9FF" : "#ffffff"}
      />
      <g clipPath="url(#clip0_172_10137)">
        <path
          d="M17.4 20.7C16.572 20.7 15.9 21.372 15.9 22.2C15.9 23.028 16.572 23.7 17.4 23.7C18.228 23.7 18.9 23.028 18.9 22.2C18.9 21.372 18.228 20.7 17.4 20.7ZM24.6 20.7C23.772 20.7 23.1 21.372 23.1 22.2C23.1 23.028 23.772 23.7 24.6 23.7C25.428 23.7 26.1 23.028 26.1 22.2C26.1 21.372 25.428 20.7 24.6 20.7ZM21 9C14.376 9 9 14.376 9 21C9 27.624 14.376 33 21 33C27.624 33 33 27.624 33 21C33 14.376 27.624 9 21 9ZM21 30.6C15.708 30.6 11.4 26.292 11.4 21C11.4 20.652 11.424 20.304 11.46 19.968C14.292 18.708 16.536 16.392 17.712 13.524C19.884 16.596 23.46 18.6 27.504 18.6C28.44 18.6 29.34 18.492 30.204 18.288C30.456 19.14 30.6 20.052 30.6 21C30.6 26.292 26.292 30.6 21 30.6Z"
          fill="black"
        />
      </g>
      <rect
        x="1"
        y="1"
        width="40"
        height="40"
        rx="20"
        stroke={isHover ? "#000000" : "#DDDDDD"}
      />
      <defs>
        <clipPath id="clip0_172_10137">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

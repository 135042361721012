import { Navigate } from "react-router-dom";

import { useAuthContext } from "../contexts/AuthContext";

type ProtectedRouteProps = {
  children: JSX.Element;
  isLoginRequired?: boolean;
};

const ProtectedRoute = ({ children, isLoginRequired }: ProtectedRouteProps) => {
  const { isSignIn } = useAuthContext();

  if (isLoginRequired && isSignIn === false) {
    return <Navigate to="/#signin" replace />;
  }

  return children;
};

export default ProtectedRoute;

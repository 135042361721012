import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserInfo } from "../../domains/user/interface";
import ButtonWithIcon from "../common/Button/ButtonWithIcon";
import ItemPlusIcon from "../common/icons/ItemPlusIcon";
import useModals from "../hooks/useModals";
import SignInModal from "../modal/SignInModal";

type Props = {
  profile?: UserInfo;
};

export default function ItemUploadButton({ profile }: Props) {
  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();

  const { openModal } = useModals();

  const uploadLink = () => {
    if (profile) {
      navigate("/items/upload");
    } else {
      openModal(SignInModal, {});
    }
  };

  return (
    <ButtonWithIcon
      type="button"
      label="Item upload"
      icon={<ItemPlusIcon isHover={isHover} />}
      buttonStyle="primary"
      position="left"
      size="md"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={uploadLink}
    />
  );
}

const EyeIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Mask group">
      <mask
        id="mask0_3793_26060"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="14"
      >
        <rect id="Rectangle 294" width="14" height="14" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3793_26060)">
        <g id="Subtract">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 10.7916C9.53051 10.7916 11.8397 8.81694 13.125 6.8541C11.5607 4.98916 9.72794 2.9166 7 2.9166C4.27208 2.9166 2.43929 4.9892 0.875 6.8541C2.16027 8.81694 4.46949 10.7916 7 10.7916ZM9.625 7.13535C9.625 8.53335 8.44979 9.6666 7 9.6666C5.55021 9.6666 4.375 8.53335 4.375 7.13535C4.375 5.73734 5.55021 4.6041 7 4.6041C8.44979 4.6041 9.625 5.73734 9.625 7.13535Z"
            fill="#949494"
          />
          <path
            d="M13.125 6.8541L13.7524 7.26496C13.9336 6.98825 13.9122 6.62552 13.6996 6.37211L13.125 6.8541ZM0.875 6.8541L0.300384 6.3721C0.0878242 6.62551 0.0663583 6.98824 0.247547 7.26495L0.875 6.8541ZM12.4976 6.44323C11.2711 8.31618 9.16025 10.0416 7 10.0416V11.5416C9.90077 11.5416 12.4083 9.3177 13.7524 7.26496L12.4976 6.44323ZM7 3.6666C9.3279 3.6666 10.947 5.42448 12.5504 7.33608L13.6996 6.37211C12.1745 4.55385 10.128 2.1666 7 2.1666V3.6666ZM1.44962 7.33609C3.05307 5.4245 4.67212 3.6666 7 3.6666V2.1666C3.87204 2.1666 1.82551 4.55389 0.300384 6.3721L1.44962 7.33609ZM7 10.0416C4.83975 10.0416 2.72885 8.31618 1.50245 6.44324L0.247547 7.26495C1.59169 9.3177 4.09923 11.5416 7 11.5416V10.0416ZM7 10.4166C8.83806 10.4166 10.375 8.97304 10.375 7.13535H8.875C8.875 8.09367 8.06152 8.9166 7 8.9166V10.4166ZM3.625 7.13535C3.625 8.97304 5.16194 10.4166 7 10.4166V8.9166C5.93848 8.9166 5.125 8.09367 5.125 7.13535H3.625ZM7 3.8541C5.16194 3.8541 3.625 5.29765 3.625 7.13535H5.125C5.125 6.17702 5.93848 5.3541 7 5.3541V3.8541ZM10.375 7.13535C10.375 5.29765 8.83806 3.8541 7 3.8541V5.3541C8.06152 5.3541 8.875 6.17702 8.875 7.13535H10.375Z"
            fill="#949494"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default EyeIcon;

import { useState } from "react";

import { SpaceCard as SpaceCardType } from "../../../domains/space/model/SpaceCard";
import useDeviceBreakPoint from "../../../hooks/useDeviceBreakPoint";
import SpaceCard from "../../card/SpaceCard";
import GuideArrow from "../../mobile/icons/GuideArrow";

type Props = {
  list: SpaceCardType[] | undefined;
  handleClickCard: (id: string) => void;
};

export default function ScrollableSpaceList({ list, handleClickCard }: Props) {
  const { isDesktop } = useDeviceBreakPoint();
  const [showSpaceListGuideArrow, setShowSpaceListGuideArrow] = useState(true);

  return (
    <section className="relative w-full">
      {list && list.length > 0 && (
        <div
          onScroll={() => setShowSpaceListGuideArrow(false)}
          className="relative w-full overflow-x-auto overflow-y-hidden scrollbar-hidden px-[18px] md:portrait:px-[32px] md:landscape:px-[58px] xl:portrait:px-0 xl:landscape:px-0"
        >
          <ul className="flex items-center gap-x-[10px] xl:gap-x-[20px] mx-auto max-w-[1440px] w-[864px] lg:w-[968px] xl:w-full">
            {list.map((space) => (
              <li key={space.spaceId}>
                <SpaceCard
                  id={space.spaceId}
                  thumbnailSrc={space.spaceImage}
                  avatarSrc={space.spaceCreatorImage}
                  title={space.spaceName}
                  creator={space.spaceCreator}
                  creatorId={space.spaceCreatorId}
                  views={space.views}
                  plays={space.plays}
                  onClick={() => handleClickCard(space.spaceId)}
                  liveUserCount={space.liveUserCount}
                  rounded={`${isDesktop ? "rounded-[20px]" : "rounded-[10px]"}`}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div
        className={`absolute block right-2 top-[62px] lg:hidden transition-opacity ${
          showSpaceListGuideArrow ? "opacity-100" : "opacity-0"
        }`}
      >
        <GuideArrow />
      </div>
    </section>
  );
}

import React, { createContext, useContext } from "react";

import { useAuthActions } from "../domains/auth/hook/useAuthActions";
import { useProfileActions } from "../domains/user/hook/useProfileActions";
import { UserInfo } from "../domains/user/interface";

type AuthContextProps = {
  isSignIn: boolean;
  profile: UserInfo | undefined;
  isMyProfileLoading: boolean;
};

type AuthContextProviderProps = {
  children: React.ReactNode;
};

export const AuthContext = createContext<AuthContextProps>({
  isSignIn: false,
  profile: undefined,
  isMyProfileLoading: false,
});

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const { getLocalStorage } = useAuthActions();
  const isSignIn = getLocalStorage("isSignIn") === "true";
  const { useGetMyProfile } = useProfileActions();
  const { isMyProfileLoading, profile } = useGetMyProfile();

  return (
    <AuthContext.Provider value={{ profile, isSignIn, isMyProfileLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextProps => useContext(AuthContext);

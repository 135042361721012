export default function MobileMoreArrow() {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0808 1.24651C9.78036 0.961353 9.78036 0.49902 10.0808 0.213861C10.3812 -0.0712972 10.8683 -0.0712973 11.1687 0.213861L17.7116 6.42411C17.7342 6.44307 17.7558 6.46335 17.7761 6.48482C17.9765 6.67596 18.0424 6.94618 17.9739 7.18933C17.9403 7.30861 17.8744 7.4214 17.7761 7.51517C17.7565 7.53598 17.7356 7.55567 17.7137 7.57412L11.1687 13.7861C10.8683 14.0713 10.3812 14.0713 10.0808 13.7861C9.78034 13.501 9.78034 13.0387 10.0808 12.7535L15.349 7.75326L0.751211 7.77915C0.336998 7.77989 0.00056152 7.4447 -0.000242704 7.03049C-0.00104469 6.61627 0.33409 6.27989 0.748303 6.27916L15.3556 6.25325L10.0808 1.24651Z"
        fill="black"
      />
    </svg>
  );
}

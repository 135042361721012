import { useEffect, useState } from "react";

import { Size } from "../../../types/common";
import { classNameMerge } from "../../../utils/tailwindClassMerge";

type Color = "primary" | "success" | "danger" | "warning";

type Variant = "plain" | "outlined" | "soft" | "solid";

type Theme = {
  [color in Color]: {
    [variant in Variant]: string;
  };
};

const THEME: Theme = {
  primary: {
    plain: "border-0 bg-transparent text-black",
    outlined: "border border-gray-300 text-black",
    soft: "bg-gray-400 text-gray-800",
    solid: "bg-twinworldGray-680 text-white",
  },
  success: {
    plain: "border-0 bg-transparent text-emerald-600",
    outlined: "border border-emerald-700 text-emerald-600",
    soft: "bg-emerald-500 text-emerald-200",
    solid: "bg-emerald-400 text-emerald-100",
  },
  danger: {
    plain: "border-0 bg-transparent text-rose-600",
    outlined: "border border-rose-700 text-rose-600",
    soft: "bg-rose-500 text-rose-200",
    solid: "bg-rose-400 text-rose-100",
  },
  warning: {
    plain: "border-0 bg-transparent text-amber-600",
    outlined: "border border-amber-700 text-amber-600",
    soft: "bg-amber-500 text-amber-200",
    solid: "bg-amber-400 text-amber-100",
  },
};

const SIZE_CLASS = {
  sm: "py-2 px-4",
  md: "py-4 px-6",
  lg: "py-6 px-10",
};

type Props = {
  value: string | number;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
  variant?: Variant;
  color?: Color;
  size?: Size;
  maxLength?: number;
  placeholder?: string;
  type?: string;
  className?: string;
  handleKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export default function Input({
  value,
  setValue,
  disabled,
  variant = "plain",
  color = "primary",
  size = "md",
  maxLength,
  placeholder = "",
  type = "text",
  className = "",
  handleKeyPress,
}: Props) {
  const [inputValue, setInputValue] = useState(value);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;

    if (maxLength && targetValue.length <= maxLength) {
      setInputValue(targetValue);
      setValue(targetValue);
      return;
    }

    setInputValue(targetValue);
    setValue(targetValue);
  };

  const mergeClass = classNameMerge([
    THEME[color][variant],
    SIZE_CLASS[size],
    className,
  ]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <input
      className={mergeClass}
      type={type}
      disabled={disabled}
      value={inputValue}
      placeholder={placeholder}
      onChange={handleChange}
      onKeyUp={handleKeyPress}
    />
  );
}

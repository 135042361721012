import Sequence from "./Sequence";
import Value02Mask from "./Value02Mask";

export default function Value02() {
  return (
    <section className="relative px-[60px]">
      <div className="absolute top-6 2xl:top-7 3xl:top-8 right-[60px] flex flex-col items-end text-right gap-10 2xl:gap-12 3xl:gap-14">
        <h3 className="text-4xl 2xl:text-[45px] 3xl:text-[50px]  font-semibold leading-tight w-[570px] 2xl:w-[580px] 3xl:w-[610px] ">
          Experience the Unreal in Reality
        </h3>
        <p className="text-[15px] 2xl:text-[18px] 3xl:text-[20px] leading-6 2xl:leading-8 3xl:leading-9 w-[600px] 2xl:w-[720px] 3xl:w-[815px]  text-black/70">
          TwinWorld offers extraordinary experiences such as space travel,
          <br />
          underwater exploration, and dinosaur expeditions that
          <br />
          go beyond your imagination. Immerse yourself in TwinWorld
          <br />
          for a realistic experience.
        </p>
      </div>
      <div className="relative">
        <Sequence index="02" position="left" />
        <Value02Mask />
      </div>
    </section>
  );
}

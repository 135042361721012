import { Turn as Hamburger } from "hamburger-react";
import { RefObject, useState } from "react";

import { useOutsideDetectRef } from "../../../hooks/useOutsideDetectRef";
import MobileHeaderDim from "../../header/MobileHeaderDim";

import MobileHeaderMenuList from "./MobileHeaderMenuList";

export default function MobileHeaderMenu() {
  const [isNavigationMenuActive, setIsNavigationMenuActive] = useState(false);
  const closeMenu = () => setIsNavigationMenuActive(false);

  const ref: RefObject<HTMLDivElement> = useOutsideDetectRef(
    closeMenu,
    isNavigationMenuActive
  );

  return (
    <div>
      {isNavigationMenuActive && <MobileHeaderDim />}
      <div ref={ref} className="flex items-center h-[36px]">
        <button type="button" className="ml-3">
          <Hamburger
            toggled={isNavigationMenuActive}
            toggle={setIsNavigationMenuActive}
            size={24}
            color="black"
          />
        </button>

        <MobileHeaderMenuList
          isDisplay={isNavigationMenuActive}
          close={closeMenu}
        />
      </div>
    </div>
  );
}

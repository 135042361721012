import QRCode from "react-qr-code";

type Props = {
  code: string;
  size?: number;
};

export default function QR({ code, size = 200 }: Props) {
  return <QRCode value={code} size={size} />;
}

export default function PersonIcon() {
  return (
    <svg
      width="43"
      height="44"
      viewBox="0 0 43 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 24.5C20.0899 24.5 23 21.5899 23 18C23 14.4101 20.0899 11.5 16.5 11.5C12.9101 11.5 10 14.4101 10 18C10 21.5899 12.9101 24.5 16.5 24.5Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M6 32.5C7.71466 28.3892 11.77 25.5 16.5009 25.5C21.2318 25.5 25.2853 28.3864 27 32.4945"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

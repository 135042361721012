export default function ShadowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="284"
      height="61"
      viewBox="0 0 284 61"
      fill="none"
    >
      <g filter="url(#filter0_f_1344_31190)">
        <ellipse
          cx="142"
          cy="30.5"
          rx="122"
          ry="10.5"
          fill="#343434"
          fillOpacity="0.1"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1344_31190"
          x="0"
          y="0"
          width="284"
          height="61"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="10"
            result="effect1_foregroundBlur_1344_31190"
          />
        </filter>
      </defs>
    </svg>
  );
}

import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import Loading from "../components/common/Loading";
import MainWrapper from "../components/common/MainWrapper";
import ItemGroupList from "../components/common/list/ItemGroupList";
import SpaceGroupList from "../components/common/list/SpaceGroupList";
import TabMenu from "../components/common/menu/TabMenu";
import { Menu } from "../components/menu/types";
import UserDetails from "../components/profile/UserDetails";
import UserItemList from "../components/profile/UserItemList";
import UserSpaceList from "../components/profile/UserSpaceList";
import { useItemListActions } from "../domains/item/hook/useItemListAction";
import { useSpaceActions } from "../domains/space/hook/useSpaceActions";
import { useProfileActions } from "../domains/user/hook/useProfileActions";

type Location = {
  state: {
    menu: string;
  };
};

const MENU_LIST: Menu[] = [
  { label: "home" },
  { label: "spaces" },
  { label: "items" },
];

export default function UserProfile() {
  const { userId } = useParams();
  const selectedUser = userId as string;
  const { state } = useLocation() as Location;

  const { useGetUserProfile } = useProfileActions();
  const { isLoading, data: userProfile } = useGetUserProfile(selectedUser);

  const [menu, setMenu] = useState("home");

  const { useCreatorSpaceList } = useSpaceActions();
  const { useInfiniteUserItemList } = useItemListActions();

  const { data: spaceData, fetchNextPage: fetchNextSpacePage } =
    useCreatorSpaceList({ creatorId: selectedUser, take: 10, skip: 0 });

  const { data: itemData, fetchNextPage: fetchNextItemPage } =
    useInfiniteUserItemList(selectedUser, 10, 0);

  useEffect(() => {
    if (state) {
      setMenu(state.menu);
    }
  }, [state]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MainWrapper>
      <div className="flex flex-col items-center w-full xl:items-start">
        <UserDetails selectedUserProfile={userProfile} />

        <div className="flex flex-col items-center justify-center w-full xl:mt-[64px]">
          <TabMenu
            menuList={MENU_LIST}
            selectedMenu={menu}
            setSelectedMenu={setMenu}
          />
        </div>
      </div>

      <div className="flex flex-col w-full gap-24 mt-[70px] xl:mt-[62px]">
        {menu === "home" && (
          <>
            <SpaceGroupList
              title="Spaces"
              list={spaceData?.pages[0]}
              moreButtonLink={`/user/${selectedUser}`}
              navigateState="spaces"
            />
            <ItemGroupList
              title="Items"
              list={itemData?.pages[0]}
              moreButtonLink={`/user/${selectedUser}`}
              navigateState="items"
            />
          </>
        )}
        {menu === "spaces" && (
          <UserSpaceList list={spaceData} fetchNextPage={fetchNextSpacePage} />
        )}
        {menu === "items" && (
          <div className="flex flex-col w-full items-center">
            <UserItemList list={itemData} fetchNextPage={fetchNextItemPage} />
          </div>
        )}
      </div>
    </MainWrapper>
  );
}

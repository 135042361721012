import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";

import authApi from "../api";
import {
  getErrorCode,
  getErrorMessage,
  goToEmailVerificationPage,
} from "../errors";
import {
  ResetPasswordRequestInfo,
  UserEmailSignInInfo,
  UserSignUpInfo,
} from "../interface";

type LocalStorageKey = "isSignIn" | "refreshToken" | "accessToken";

type Error = {
  [key: string]: string;
};

export const useAuthActions = () => {
  const [errorMessages, setErrorMessages] = useState<Error>({});
  const [errorCode, setErrorCode] = useState<Error>({});
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const variable = useRef({
    isDoubleClick: false,
  });

  const getLocalStorage = (key: LocalStorageKey) => localStorage.getItem(key);

  const setLocalStorage = (key: LocalStorageKey, state: string) =>
    localStorage.setItem(key, state);

  const removeLocalStorage = (key: LocalStorageKey) =>
    localStorage.removeItem(key);

  const socialSignIn = async (oauthAccessToken: string, provider: string) => {
    const response = await authApi.socialSignIn(
      oauthAccessToken,
      provider,
      "Twinworld"
    );

    const { accessToken } = response.data.data;
    const { refreshToken } = response.data.data;

    setLocalStorage("isSignIn", "true");
    setLocalStorage("refreshToken", refreshToken);
    setLocalStorage("accessToken", accessToken);
  };

  const signOut = async () => {
    const refreshToken = getLocalStorage("refreshToken");

    if (refreshToken) {
      await authApi.localSignOut(refreshToken);

      removeLocalStorage("isSignIn");
      removeLocalStorage("refreshToken");
      removeLocalStorage("accessToken");

      window.location.href = "/";
    }
  };

  const isSignInSuccess =
    getLocalStorage("isSignIn") === "true" && !!getLocalStorage("accessToken");

  const useGetQRSignInToken = () => {
    const { data: qrSignInToken, refetch: refreshQRSignInToken } = useQuery({
      queryKey: ["qrSignInToken"],
      queryFn: authApi.getQRSignInToken,
      refetchOnWindowFocus: false,
      enabled: isSignInSuccess,
    });

    return {
      qrSignInToken,
      refreshQRSignInToken,
    };
  };

  const emailSignIn = async (info: UserEmailSignInInfo) => {
    if (variable.current.isDoubleClick) {
      return;
    }

    variable.current.isDoubleClick = true;

    try {
      const accessToken = await authApi.emailSignIn({
        ...info,
      });

      variable.current.isDoubleClick = false;
      setLocalStorage("isSignIn", "true");
      setLocalStorage("refreshToken", accessToken.data.refreshToken);
      setLocalStorage("accessToken", accessToken.data.accessToken);
    } catch (error) {
      variable.current.isDoubleClick = false;
      goToEmailVerificationPage(error);

      const accountError = getErrorMessage(error);

      setErrorMessages((prev) => ({
        ...prev,
        accountError,
      }));

      throw error;
    }
  };

  const signUp = async (info: UserSignUpInfo) => {
    if (variable.current.isDoubleClick) {
      return;
    }

    variable.current.isDoubleClick = true;

    try {
      setIsUpdating(true);
      await authApi.signUp(info);
      setIsUpdating(false);

      variable.current.isDoubleClick = false;
    } catch (error) {
      setIsUpdating(false);
      variable.current.isDoubleClick = false;

      setErrorCode((prev) => ({
        ...prev,
        signUp: getErrorCode(error),
      }));

      const signUp = getErrorMessage(error);

      setErrorMessages((prev) => ({
        ...prev,
        signUp,
      }));

      throw error;
    }
  };

  const resendSignUpConfirmMail = async (email: string) => {
    if (variable.current.isDoubleClick) {
      return;
    }

    variable.current.isDoubleClick = true;
    try {
      await authApi.resendSignUpConfirmMail(email);
      variable.current.isDoubleClick = false;
    } catch (error) {
      variable.current.isDoubleClick = false;
      throw error;
    }
  };

  const sendResetPasswordConfirmMail = async (email: string) => {
    if (variable.current.isDoubleClick) {
      return;
    }

    variable.current.isDoubleClick = true;

    try {
      await authApi.sendResetPasswordConfirmMail(email);
      variable.current.isDoubleClick = false;
    } catch (error) {
      variable.current.isDoubleClick = false;

      const sendResetPasswordConfirmMail = getErrorMessage(error);

      setErrorMessages((prev) => ({
        ...prev,
        sendResetPasswordConfirmMail,
      }));

      throw error;
    }
  };

  const resetPassword = async (
    resetPasswordInfo: ResetPasswordRequestInfo,
    handleResetPwSuccessModal: () => void
  ) => {
    if (variable.current.isDoubleClick) {
      return;
    }

    variable.current.isDoubleClick = true;

    try {
      await authApi.resetPassword(resetPasswordInfo);
      variable.current.isDoubleClick = false;
      handleResetPwSuccessModal();
    } catch (error) {
      variable.current.isDoubleClick = false;

      const resetPassword = getErrorMessage(error);

      setErrorMessages((prev) => ({
        ...prev,
        resetPassword,
      }));
    }
  };

  const changePassword = async (
    currentPassword: string,
    newPassword: string
  ) => {
    try {
      if (variable.current.isDoubleClick) {
        return;
      }
      variable.current.isDoubleClick = true;

      await authApi.changePassword(currentPassword, newPassword);
      variable.current.isDoubleClick = false;
    } catch (error: any) {
      variable.current.isDoubleClick = false;

      const changePassword = getErrorMessage(error);

      setErrorMessages((prev) => ({
        ...prev,
        changePassword,
      }));

      error.response.data.message = changePassword;

      throw error;
    }
  };

  const clearErrorMessages = (errorKey: string) => {
    setErrorMessages((prev) => ({
      ...prev,
      [errorKey]: "",
    }));
  };

  const useGetCoinsSummary = () => {
    const { data, isPending } = useQuery({
      queryKey: ["coinsHistory"],
      queryFn: authApi.getCoinsSummary,
      enabled: isSignInSuccess,
    });

    return {
      data,
      isPending,
    };
  };

  const useInfiniteCoinsUsageHistory = (take: number, skip: number) => {
    const { data, isPending, fetchNextPage } = useInfiniteQuery({
      queryKey: ["coinsHistory", { take, skip }],
      queryFn: ({ pageParam }) => {
        const result = authApi.getCoinsUsageHistory(
          take,
          (pageParam - 1) * take
        );

        return result;
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < take ? undefined : nextPage;
      },
      enabled: isSignInSuccess,
    });

    return {
      data,
      isPending,
      fetchNextPage,
    };
  };

  return {
    socialSignIn,
    emailSignIn,
    signOut,
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage,
    isSignInSuccess,
    useGetQRSignInToken,
    signUp,
    isUpdating,
    resendSignUpConfirmMail,
    sendResetPasswordConfirmMail,
    resetPassword,
    errorMessages,
    setErrorMessages,
    clearErrorMessages,
    errorCode,
    changePassword,
    useGetCoinsSummary,
    useInfiniteCoinsUsageHistory,
  };
};

import { useEffect, useState } from "react";

export const useCountdown = (duration: number, callback: () => void) => {
  const [countdown, setCountdown] = useState(duration);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    if (isRunning && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }

    if (countdown === 0) {
      setCountdown(duration);
      callback();
    }
  }, [isRunning, countdown, duration, callback]);

  return [countdown, setIsRunning] as const;
};

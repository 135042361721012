export default function SearchIcon({ isHover }: { isHover: boolean }) {
  const style = {
    fill: isHover ? "#000000" : "#555555",
    transition: "fill 0.2s ease-in-out",
  };

  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hover:cursor-pointer"
    >
      <rect width="62" height="62" rx="31" style={style} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7217 29.1826C34.7217 32.2202 32.2592 34.6826 29.2217 34.6826C26.1841 34.6826 23.7217 32.2202 23.7217 29.1826C23.7217 26.1451 26.1841 23.6826 29.2217 23.6826C32.2592 23.6826 34.7217 26.1451 34.7217 29.1826ZM36.7217 29.1826C36.7217 33.3248 33.3638 36.6826 29.2217 36.6826C25.0795 36.6826 21.7217 33.3248 21.7217 29.1826C21.7217 25.0405 25.0795 21.6826 29.2217 21.6826C33.3638 21.6826 36.7217 25.0405 36.7217 29.1826ZM36.108 35.2C35.6932 34.8354 35.0613 34.8762 34.6967 35.291C34.3321 35.7059 34.3729 36.3377 34.7877 36.7023L38.6179 40.0683C39.0327 40.4329 39.6646 40.3921 40.0292 39.9773C40.3937 39.5624 40.353 38.9306 39.9381 38.566L36.108 35.2Z"
        fill="white"
      />
    </svg>
  );
}

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import MainWrapper from "../components/common/MainWrapper";
import SubWrapper from "../components/common/SubWrapper";
import TabMenu from "../components/common/menu/TabMenu";
import PasswordForm from "../components/form/PasswordForm";
import ProfileForm from "../components/form/ProfileForm";
import ReadyPlayMeViewer from "../components/viewer/ReadyPlayMeViewer";
import { useAuthContext } from "../contexts/AuthContext";
import useDeviceBreakPoint from "../hooks/useDeviceBreakPoint";

const MENU_LIST = [
  { label: "profile" },
  { label: "avatar" },
  { label: "password" },
];

type Location = {
  state: {
    menu: string;
  };
};

const Title = ({ text }: { text: string }) => (
  <h2 className="text-[45px] font-semibold mt-[62px] mb-10">{text}</h2>
);

export default function SettingPage() {
  const { state } = useLocation() as Location;
  const [selectedMenu, setSelectedMenu] = useState("profile");
  const { profile } = useAuthContext();
  const { isDesktop } = useDeviceBreakPoint();

  useEffect(() => {
    if (state) {
      setSelectedMenu(state.menu);
    }
  }, [state]);

  return (
    <MainWrapper>
      <div className="flex items-center justify-center font-medium">
        <TabMenu
          menuList={MENU_LIST}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
        />
      </div>
      <SubWrapper>
        {selectedMenu === "profile" && profile && (
          <>
            {isDesktop && <Title text="Edit your profile" />}

            <ProfileForm userInfo={profile} />
          </>
        )}
        {selectedMenu === "avatar" && profile && (
          <>
            {isDesktop && <Title text="Edit your Avatar" />}

            <ReadyPlayMeViewer />
          </>
        )}
        {selectedMenu === "password" && profile && (
          <>
            {isDesktop && <Title text="Edit your password" />}

            <PasswordForm />
          </>
        )}
      </SubWrapper>
    </MainWrapper>
  );
}

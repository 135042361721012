import { Fragment } from "react";

import LineIcon from "../common/icons/LineIcon";

type Props = {
  type: string;
  selectedSubMenu: string;
  setSelectedSubMenu: (value: string) => void;
};

type SUB_MENU = {
  key: string;
  label: string;
};

const SUB_MENU_LIST: { [key: string]: SUB_MENU[] } = {
  spaces: [
    { key: "created", label: "published" },
    { key: "bookmarked", label: "favorite" },
    { key: "visited", label: "visited" },
  ],
  items: [
    { key: "my-medias", label: "published" },
    { key: "my-favorite-list", label: "favorite" },
  ],
};

export default function ProfileSubMenu({
  type,
  selectedSubMenu,
  setSelectedSubMenu,
}: Props) {
  return (
    <ul className="flex items-center h-10">
      {SUB_MENU_LIST[type] &&
        SUB_MENU_LIST[type].map((item, index) => (
          <Fragment key={item.label}>
            <li>
              <button
                className={`px-4 xl:px-[34px] py-2 xl:py-3 capitalize text-[13px] font-medium
                 ${
                   selectedSubMenu === item.key
                     ? "text-black"
                     : "text-twinworldGray-600"
                 } hover:text-black`}
                type="button"
                onClick={() => setSelectedSubMenu(item.key)}
              >
                {item.label}
              </button>
            </li>
            {index !== SUB_MENU_LIST[type].length - 1 && (
              <li>
                <LineIcon />
              </li>
            )}
          </Fragment>
        ))}
    </ul>
  );
}

import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { REGEX_FORMAT } from "../../utils/regex";
import ModalActionButton from "../button/ModalActionButton";
import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";
import LoginInput from "../common/input/LoginInput";

import EmailConfirmSuccessModal from "./EmailConfirmSuccessModal";
import { ModalTitle } from "./ModalTitle";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function EmailConfirmModal({ isOpen, closeModal }: Props) {
  const [isSendEmailSuccess, setIsSendEmailSuccess] = useState(false);
  const { sendResetPasswordConfirmMail, errorMessages, setErrorMessages } =
    useAuthActions();

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const isInputValid =
    Object.keys(dirtyFields).length > 0 && Object.keys(errors).length === 0;

  const onSubmit = async (data: FieldValues) => {
    if (!isInputValid) return;

    await sendResetPasswordConfirmMail(data.email)
      .then(() => {
        setIsSendEmailSuccess(true);
      })
      .catch((error) => {
        throw error;
      });
  };

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  return (
    <Modal
      maxWidth="max-w-[339px] md:max-w-[480px]"
      isOpen={isOpen}
      title={<ModalTitle text="Reset Your password" />}
      close={close}
      closeModal={closeModal}
    >
      <div className="text-[16px] md:text-[18px] text-twinworldGray-700 leading-[26px] xl:leading-[30px] mt-[32px] md:mt-[37px] mb-[34px] md:mb-[37px]">
        Please type your email to
        <br />
        reset your password.
      </div>
      {isSendEmailSuccess ? (
        <EmailConfirmSuccessModal closeModal={closeModal} />
      ) : (
        <form
          className="flex flex-col items-center w-[279px] md:w-[320px] mx-auto md:mx-20 mb-[41px] md:mb-[95px] mt-0 md:mt-[37px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <LoginInput
            type="email"
            name="email"
            register={register}
            placeholder="Email"
            options={{
              required: true,
              maxLength: {
                value: 50,
                message: "* X Max length of 50 is allowed",
              },
              pattern: {
                value: REGEX_FORMAT.EMAIL,
                message: "* Please check your email again",
              },
            }}
            onClick={() => {
              setErrorMessages((prevState) => ({
                ...prevState,
                sendResetPasswordConfirmMail: "",
              }));
            }}
            isError={
              !!errors.email || !!errorMessages.sendResetPasswordConfirmMail
            }
          />
          {(errors?.email || errorMessages.sendResetPasswordConfirmMail) && (
            <p className="text-twinworldRed text-[13px] md:text-[14px] text-left w-full whitespace-nowrap mt-[8px] md:mt-[9px] ml-[12px] md:ml-[18px] flex items-center">
              {errors?.email?.message?.toString() ||
                errorMessages.sendResetPasswordConfirmMail}
            </p>
          )}
          <div className="mt-[56px] md:mt-[48px]">
            <ModalActionButton type="submit" isDisabled={!isInputValid}>
              Send
            </ModalActionButton>
          </div>
        </form>
      )}
    </Modal>
  );
}

type Props = {
  like: boolean;
};

export default function MobileLikeIcon({ like }: Props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8182 11.7H17.9345L18.9709 6.673L19.0036 6.321C19.0036 5.87 18.8182 5.452 18.5236 5.155L17.3673 4L10.1891 11.249C9.78545 11.645 9.54545 12.195 9.54545 12.8V23.8C9.54545 25.01 10.5273 26 11.7273 26H21.5455C22.4509 26 23.2255 25.45 23.5527 24.658L26.8473 16.903C26.9455 16.65 27 16.386 27 16.1V13.9C27 12.69 26.0182 11.7 24.8182 11.7ZM24.8182 16.1L21.5455 23.8H11.7273V12.8L16.4618 8.026L15.2509 13.9H24.8182V16.1ZM3 12.8H7.36364V26H3V12.8Z"
        fill={like ? "black" : "#B7B7B7"}
      />
    </svg>
  );
}

import { Link } from "react-router-dom";

import { useAuthContext } from "../../contexts/AuthContext";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import ItemUploadButton from "../button/ItemUploadButton";
import MainLogo from "../common/icons/MainLogo";
import MobileItemUploadButton from "../mobile/buttons/MobileItemUploadButton";
import MobileHeaderMenu from "../mobile/header/MobileHeaderMenu";
import MobileUserMenu from "../mobile/header/MobileUserMenu";

import HeaderMenu from "./HeaderMenu";
import UserMenu from "./UserMenu";

export default function Header() {
  const { profile } = useAuthContext();
  const { isMobile, isTablet, isDesktop } = useDeviceBreakPoint();

  return (
    <header className="min-w-[375px] xl:mx-auto xl:min-w-[1440px]">
      <div className="flex justify-between items-center pt-[25px] pb-[20px] xl:py-[16px] px-[18px] xl:px-[16px]">
        <Link className="h-fit w-fit" to="/">
          <MainLogo
            width={!isDesktop ? 150 : 200}
            height={!isDesktop ? 36 : 50}
          />
        </Link>

        {!isMobile && <HeaderMenu />}

        <div className="flex">
          {!isMobile ? (
            <div className="flex items-center">
              {isDesktop && <ItemUploadButton profile={profile} />}
              {isTablet && <MobileItemUploadButton />}

              <UserMenu profile={profile} />
            </div>
          ) : (
            <>
              <MobileUserMenu profile={profile} />
              <MobileHeaderMenu />
            </>
          )}
        </div>
      </div>
    </header>
  );
}

type Props = {
  pageNumber: number;
};

export default function MobileSpaceCarouselPaging({ pageNumber }: Props) {
  return (
    <button
      type="button"
      className={`w-[8px] h-[8px] rounded-full mx-[4px] cursor-pointer bg-[#DADADA] before:content-[${pageNumber}]`}
      aria-label="space_carouse_paging_button"
      key={pageNumber}
    />
  );
}

import { useState } from "react";

import { UserInfo } from "../../domains/user/interface";
import Avatar from "../common/Avatar";
import PersonIcon from "../common/icons/PersonIcon";

import AuthMenu from "./AuthMenu";
import HeaderDim from "./HeaderDim";
import UserMenuList from "./UserMenuList";

type Props = {
  profile: UserInfo | undefined;
};

export default function UserMenu({ profile }: Props) {
  const [isUserMenuListActive, setIsUserMenuListActive] = useState(false);
  const [isAuthMenuActive, setIsAuthMenuActive] = useState(false);

  const setNavigationMenuActiveToggle = () =>
    setIsUserMenuListActive((prev) => !prev);

  const setAuthMenuActiveToggle = () => setIsAuthMenuActive((prev) => !prev);

  const handleCloseMenu = () => {
    setIsUserMenuListActive(false);
    setIsAuthMenuActive(false);
  };

  return (
    <div className="flex items-center">
      <div className="relative cursor-pointer md:ml-4 xl:ml-5">
        {profile ? (
          <div onClick={setNavigationMenuActiveToggle}>
            <Avatar size="md" src={profile.avatarImageUrl} />
          </div>
        ) : (
          <div onClick={setAuthMenuActiveToggle}>
            <PersonIcon />
          </div>
        )}

        <div onClick={handleCloseMenu}>
          <HeaderDim isDisplay={isUserMenuListActive || isAuthMenuActive} />
        </div>

        {profile && isUserMenuListActive && (
          <UserMenuList profile={profile} closeMenu={handleCloseMenu} />
        )}

        {isAuthMenuActive && <AuthMenu closeMenu={handleCloseMenu} />}
      </div>
    </div>
  );
}

export default function MobileMainMask() {
  return (
    <section className="relative w-full">
      <div className="w-full h-[460px] relative">
        <img
          src="/images/landing/introduce.webp"
          alt="introduce rectangle"
          className="absolute -top-[0.3px] -left-[0.3px] z-10"
        />
        <video
          className="block w-full h-full absolute object-cover rounded-[20px]"
          autoPlay
          muted
          loop
          disablePictureInPicture
          playsInline
          poster="/images/landing/thumbnails/background.webp"
        >
          <source src="/videos/background.webm" type="video/webm" />
          <source src="/videos/background.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
  );
}

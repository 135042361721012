export default function MobileDeviceShadowIcon() {
  return (
    <svg
      width="125"
      height="44"
      viewBox="0 0 125 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_4811_35951)">
        <ellipse
          cx="67.5"
          cy="22"
          rx="51.5"
          ry="6"
          fill="#343434"
          fillOpacity="0.1"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_4811_35951"
          x="0"
          y="0"
          width="135"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="8"
            result="effect1_foregroundBlur_4811_35951"
          />
        </filter>
      </defs>
    </svg>
  );
}

type Props = {
  columnStyle: "single" | "multiple";
};

export default function SelectDropdownIcon({ columnStyle }: Props) {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2948 0.792893C10.6854 0.402369 11.3185 0.402369 11.7091 0.792893C12.0996 1.18342 12.0996 1.81658 11.7091 2.20711L6.72024 7.19593C6.71658 7.19971 6.71288 7.20346 6.70915 7.20719C6.51768 7.39866 6.26788 7.49626 6.01695 7.49998C5.75602 7.50389 5.49386 7.4063 5.29476 7.2072C5.29095 7.20339 5.28719 7.19957 5.28346 7.19572L0.294846 2.20711C-0.0956779 1.81658 -0.095678 1.18342 0.294846 0.792893C0.685371 0.402369 1.31854 0.402369 1.70906 0.792893L6.00195 5.08579L10.2948 0.792893Z"
        fill={`${columnStyle === "multiple" ? "#A6D9FF" : "white"}`}
      />
    </svg>
  );
}

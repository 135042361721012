export default function formatNumberToAbbreviation(number: number): string {
  if (number >= 1000000000) {
    const billions = (number / 1000000000).toFixed(1);
    return `${
      parseInt(billions, 10) === parseFloat(billions)
        ? parseInt(billions, 10)
        : billions
    }B`;
  }

  if (number >= 1000000) {
    const millions = (number / 1000000).toFixed(1);
    return `${
      parseInt(millions, 10) === parseFloat(millions)
        ? parseInt(millions, 10)
        : millions
    }M`;
  }

  if (number >= 1000) {
    const thousands = (number / 1000).toFixed(1);
    return `${
      parseInt(thousands, 10) === parseFloat(thousands)
        ? parseInt(thousands, 10)
        : thousands
    }K`;
  }

  return number.toString();
}

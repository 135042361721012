export default function NoResultIcon() {
  return (
    <svg
      width="87"
      height="92"
      viewBox="0 0 87 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.891 73.8097C58.1595 73.8097 73.782 58.1836 73.782 38.9048C73.782 19.6261 58.1595 4 38.891 4C19.6226 4 4 19.6261 4 38.9048C4 58.1836 19.6226 73.8097 38.891 73.8097ZM38.891 77.8097C60.3699 77.8097 77.782 60.3914 77.782 38.9048C77.782 17.4183 60.3699 0 38.891 0C17.4121 0 0 17.4183 0 38.9048C0 60.3914 17.4121 77.8097 38.891 77.8097ZM66.8094 71.3528C67.5861 70.5675 68.8523 70.5607 69.6374 71.3377L86.069 87.5984C86.8542 88.3754 86.8611 89.6419 86.0844 90.4272C85.3077 91.2125 84.0415 91.2192 83.2564 90.4422L66.8248 74.1815C66.0396 73.4045 66.0328 72.1381 66.8094 71.3528ZM24.9034 25.9065C24.1225 26.6876 24.1225 27.9542 24.9034 28.7354L36.1301 39.966L24.9039 51.1962C24.123 51.9774 24.123 53.244 24.9039 54.0252C25.6848 54.8063 26.9509 54.8063 27.7318 54.0252L38.958 42.795L50.1842 54.0252C50.9651 54.8063 52.2312 54.8063 53.0121 54.0252C53.793 53.244 53.793 51.9774 53.0121 51.1962L41.7859 39.966L53.0126 28.7354C53.7935 27.9542 53.7935 26.6876 53.0126 25.9065C52.2317 25.1253 50.9656 25.1253 50.1847 25.9065L38.958 37.1371L27.7313 25.9065C26.9504 25.1253 25.6843 25.1253 24.9034 25.9065Z"
        fill="#DFDFDF"
      />
    </svg>
  );
}

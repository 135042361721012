import { useEffect, useRef } from "react";

export default function useLazyLoadSvgVideo() {
  const svgVideoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const callback: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          const videoElement = entry.target.querySelector("svg video");
          const sourceElement = videoElement.querySelector("source");
          const src = sourceElement.getAttribute("data-src");

          sourceElement.setAttribute("src", src);
          videoElement.load();
          videoElement.play();

          sourceElement.removeAttribute("data-src");
          observer.unobserve(entry.target);
        }
      });
    };

    const intersectionObserver = new IntersectionObserver(callback);
    const el = svgVideoRef.current as HTMLDivElement;
    intersectionObserver.observe(el);

    return () => intersectionObserver.disconnect();
  }, []);

  return svgVideoRef;
}

import React, { createContext, useState, ComponentType, useMemo } from "react";

type ModalsProps = {
  Component: ComponentType<any>;
  props: { [key: string]: any };
};

type ProviderProps = {
  children: React.ReactNode;
};

export const ModalsStateContext = createContext<
  { Component: ComponentType<any>; props: { [key: string]: any } }[]
>([]);

export const ModalsDispatchContext = createContext<{
  open: (Component: ComponentType<any>, props: { [key: string]: any }) => void;
  close: (Component: ComponentType<any>) => void;
}>({
  open: () => {},
  close: () => {},
});

const ModalsProvider = ({ children }: ProviderProps) => {
  const [openedModals, setOpenedModals] = useState<ModalsProps[]>([]);

  const open = (
    Component: ComponentType<any>,
    props: { [key: string]: any }
  ) => {
    setOpenedModals((modals) => [...modals, { Component, props }]);
  };

  const close = (Component: ComponentType<any>) => {
    setOpenedModals((modals) =>
      modals.filter((modal) => modal.Component !== Component)
    );
  };

  const dispatch = useMemo(() => ({ open, close }), []);

  return (
    <ModalsDispatchContext.Provider value={dispatch}>
      <ModalsStateContext.Provider value={openedModals}>
        {children}
      </ModalsStateContext.Provider>
    </ModalsDispatchContext.Provider>
  );
};

export default ModalsProvider;

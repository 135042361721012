export default function QuestionMarkIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7" fill="white" stroke="#616161" />
      <path
        d="M10.3 5.89C10.3 6.77 9.79 7.37 9.22 7.84C8.63 8.32 8.27 8.64 8.27 9.17V9.69H6.95V9.17C6.95 8.34 7.44 7.78 8 7.32C8.53 6.89 8.96 6.5 8.96 5.89C8.96 5.15 8.57 4.71 7.75 4.71C7.06 4.71 6.59 5.32 6.59 6.18H5.25C5.25 4.75 6.32 3.57 7.75 3.57C9.55 3.57 10.3 4.61 10.3 5.89ZM8.52 11.33C8.52 11.82 8.15 12.23 7.61 12.23C7.07 12.23 6.7 11.82 6.7 11.33C6.7 10.84 7.07 10.43 7.61 10.43C8.15 10.43 8.52 10.84 8.52 11.33Z"
        fill="#616161"
      />
    </svg>
  );
}

import { UploadingStatus } from "./types";

type Props = {
  uploadingStatus: UploadingStatus;
  generalStatusText?: string;
};

export default function UploadIcon({
  uploadingStatus = "general",
  generalStatusText = "5MB",
}: Props) {
  const renderUploadingStatusIcon = () => {
    if (uploadingStatus === "general")
      return (
        <path
          d="M64 48C64 48.5523 64.4477 49 65 49C65.5523 49 66 48.5523 66 48L64 48ZM65.7071 23.2929C65.3166 22.9024 64.6834 22.9024 64.2929 23.2929L57.9289 29.6569C57.5384 30.0474 57.5384 30.6805 57.9289 31.0711C58.3195 31.4616 58.9526 31.4616 59.3431 31.0711L65 25.4142L70.6569 31.0711C71.0474 31.4616 71.6805 31.4616 72.0711 31.0711C72.4616 30.6805 72.4616 30.0474 72.0711 29.6569L65.7071 23.2929ZM66 48L66 24L64 24L64 48L66 48Z"
          fill="black"
        />
      );

    if (uploadingStatus === "success") {
      return (
        <path
          d="M50 45L62 58L85 31"
          stroke="#10AF20"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    }

    if (uploadingStatus === "error") {
      return (
        <>
          <path
            d="M53 32L77 56"
            stroke="#DE3341"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M77 32L53 56"
            stroke="#DE3341"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </>
      );
    }
  };

  return (
    <div className="relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="130"
        height="81"
        viewBox="0 0 130 81"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.494 40.1363C116.52 39.5677 116.533 38.9957 116.533 38.4207C116.533 17.7538 99.7869 1 79.13 1C67.5325 1 57.1679 6.28096 50.3074 14.5705C47.8086 12.3368 44.511 10.9789 40.8965 10.9789C33.0928 10.9789 26.7666 17.3081 26.7666 25.1156C26.7666 25.682 26.7999 26.2406 26.8646 26.7896C12.513 27.1845 1 38.9426 1 53.3897C1 68.0862 12.9138 80 27.6103 80H62.4936C62.5562 80 62.6188 79.9998 62.6813 79.9994H108.211C119.693 79.9994 129 70.6917 129 59.21C129 50.6725 123.854 43.3369 116.494 40.1363Z"
          fill="#A6D9FF"
          fillOpacity="0.3"
        />
        <path
          d="M116.494 40.1363L115.745 40.1026L115.722 40.6183L116.195 40.8241L116.494 40.1363ZM50.3074 14.5705L49.8075 15.1296L50.3884 15.6489L50.8852 15.0486L50.3074 14.5705ZM26.8646 26.7896L26.8853 27.5394L27.7056 27.5168L27.6095 26.7018L26.8646 26.7896ZM62.6813 79.9994V79.2493L62.6761 79.2494L62.6813 79.9994ZM115.783 38.4207C115.783 38.9845 115.77 39.5452 115.745 40.1026L117.243 40.1701C117.269 39.5902 117.283 39.007 117.283 38.4207H115.783ZM79.13 1.75C99.3724 1.75 115.783 18.1677 115.783 38.4207H117.283C117.283 17.34 100.201 0.25 79.13 0.25V1.75ZM50.8852 15.0486C57.6093 6.92387 67.7655 1.75 79.13 1.75V0.25C67.2996 0.25 56.7264 5.63806 49.7296 14.0923L50.8852 15.0486ZM40.8965 11.7289C44.3196 11.7289 47.4409 13.014 49.8075 15.1296L50.8072 14.0113C48.1763 11.6595 44.7025 10.2289 40.8965 10.2289V11.7289ZM27.5166 25.1156C27.5166 17.722 33.5073 11.7289 40.8965 11.7289V10.2289C32.6782 10.2289 26.0166 16.8942 26.0166 25.1156H27.5166ZM27.6095 26.7018C27.5482 26.1819 27.5166 25.6526 27.5166 25.1156H26.0166C26.0166 25.7114 26.0516 26.2994 26.1198 26.8775L27.6095 26.7018ZM1.75 53.3897C1.75 39.3499 12.9385 27.9231 26.8853 27.5394L26.844 26.0399C12.0874 26.4459 0.25 38.5353 0.25 53.3897H1.75ZM27.6103 79.25C13.328 79.25 1.75 67.6719 1.75 53.3897H0.25C0.25 68.5004 12.4996 80.75 27.6103 80.75V79.25ZM62.4936 79.25H27.6103V80.75H62.4936V79.25ZM62.6761 79.2494C62.6154 79.2498 62.5545 79.25 62.4936 79.25V80.75C62.5579 80.75 62.6222 80.7498 62.6866 80.7493L62.6761 79.2494ZM108.211 79.2494H62.6813V80.7494H108.211V79.2494ZM128.25 59.21C128.25 70.2775 119.278 79.2494 108.211 79.2494V80.7494C120.107 80.7494 129.75 71.1059 129.75 59.21H128.25ZM116.195 40.8241C123.291 43.9099 128.25 50.9816 128.25 59.21H129.75C129.75 50.3633 124.417 42.7639 116.793 39.4486L116.195 40.8241Z"
          fill="#D7E0ED"
        />
        {renderUploadingStatusIcon()}
      </svg>
      {uploadingStatus === "general" && (
        <p className="text-black absolute top-[55px] left-[50px]">
          {generalStatusText}
        </p>
      )}
    </div>
  );
}

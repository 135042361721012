import { useNavigate } from "react-router-dom";

import ModalActionButton from "../button/ModalActionButton";
import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";

import { ModalTitle } from "./ModalTitle";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function CheckEmailModal({ isOpen, closeModal }: Props) {
  const navigate = useNavigate();

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  return (
    <Modal
      maxWidth="max-w-[339px] md:max-w-[480px]"
      isOpen={isOpen}
      title={<ModalTitle text="Email Verification" />}
      close={close}
      closeModal={closeModal}
    >
      <div className="flex flex-col items-center justify-center w-full min-h-full text-center pb-[64px] md:pb-[95px]">
        <div className="text-[16px] md:text-[18px] text-twinworldGray-700 leading-[28px] md:leading-[30px] mt-[40px] md:mt-[37px] mb-[40px] md:mb-[131px] px-[30px] md:px-[100px]">
          Please check your email <br /> and verify your account.
        </div>

        <ModalActionButton type="button" onClick={() => navigate("/")}>
          Go to Main
        </ModalActionButton>
      </div>
    </Modal>
  );
}

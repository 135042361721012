import { ChangeEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  ItemEditContext,
  ItemEditDispatchContext,
} from "../../contexts/ItemEditContext";
import { useItemActions } from "../../domains/item/hook/useItemActions";
// import { useMyBoxActions } from "../../domains/item/hook/useMyBoxActions";
import { useMyItemActions } from "../../domains/item/hook/useMyItemActions";
import { ItemCategory } from "../../domains/item/interface";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import getByteSize from "../../utils/getByteSize";
import Viewer3D from "../common/Viewer3D";
import useModals from "../hooks/useModals";
import MobileItemSelectDropdown from "../mobile/MobileItemSelectDropdown";
import ItemEditDeleteModal from "../modal/ItemEditDeleteModal";
import ItemEditUpdateModal from "../modal/ItemEditUpdateModal";
import ItemCategorySelector from "../selector/ItemCategorySelector";

import { ItemDetailsInfo } from "./type";

type Props = {
  itemDetail: ItemDetailsInfo;
};

const TITLE_MAX_BYTE = 30;

const characterCheck = (word: string) =>
  word.replace(/[^a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣\s]/g, "");

export default function ItemEditSection({ itemDetail }: Props) {
  const navigate = useNavigate();

  const itemDetails = useContext(ItemEditContext);
  const dispatch = useContext(ItemEditDispatchContext);

  const { itemId, name, itemUrl, thumbnailUrl } = itemDetail;

  const { itemCategoryList } = useItemActions();
  const { updateMyItemInfo, deleteMyItem } = useMyItemActions();
  // const { deleteFromMyBox } = useMyBoxActions();
  const { openModal, closeModal } = useModals();

  const { isMobile } = useDeviceBreakPoint();

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const word = characterCheck(e.target.value);

    if (getByteSize(word) <= TITLE_MAX_BYTE) {
      dispatch({ type: "CHANGE_TITLE", payload: word });
    }
  };

  const handleChangeCategory = (category: ItemCategory) => {
    dispatch({ type: "CHANGE_CATEGORY", payload: category });
  };

  const handleUpdateItemInfo = async () => {
    await updateMyItemInfo({
      itemId,
      name: itemDetails.getTitle,
      categoryId: itemDetails.category.idx,
      isPublic: true,
    })
      .then(() => {
        closeModal(ItemEditUpdateModal);
        navigate(`/items/${itemDetail.itemId}`);
      })
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 400) {
          window.location.href = `/error/${error.response.status}`;
        }
        throw error;
      });
  };

  const handleOpenItemUpdateModal = () => {
    openModal(ItemEditUpdateModal, {
      itemThumbnail: thumbnailUrl,
      itemName: itemDetails.getTitle,
      handleUpdate: handleUpdateItemInfo,
    });
  };

  const handleDeleteMyItem = async () => {
    await deleteMyItem(itemId)
      .then(() => {
        closeModal(ItemEditDeleteModal);
        navigate("/myprofile", { state: { menu: "items" } });
      })
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 400) {
          window.location.href = `/error/${error.response.status}`;
        }
        throw error;
      });
  };

  const handleOpenItemDeleteModal = () => {
    openModal(ItemEditDeleteModal, {
      itemThumbnail: thumbnailUrl,
      itemName: name,
      handleDelete: handleDeleteMyItem,
    });
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="w-full px-[18px] flex justify-center">
        <Viewer3D
          renderFileUrl={itemUrl}
          size={"w-full md:w-[522px] h-[258px] md:h-[388px]"}
          rounded
        />
      </div>

      <div className="w-full md:w-[530px] flex flex-col gap-4 mt-[30px] md:mt-[39px] px-[18px] md:px-0">
        <div className="flex justify-between items-center w-full py-4 border rounded-full px-[16px] md:px-7 border-twinworldGray-350">
          <input
            className="w-[220px] md:w-[390px] text-[14px] md:text-[16px] font-normal leading-none text-twinworldGray-700 md:text-twinworldGray-600"
            type="text"
            placeholder="Title"
            defaultValue={itemDetail.name}
            onChange={onChangeInput}
            value={itemDetails.getTitle}
          />
          <span className="text-[12px] md:text-[13px] font-normal text-twinworldGray-600">
            {getByteSize(itemDetails.getTitle)} / 30 byte
          </span>
        </div>
        <div className="w-full">
          {itemCategoryList && !isMobile && (
            <ItemCategorySelector
              selectedCategory={itemDetails.category}
              categoryList={itemCategoryList}
              handleChangeCategory={handleChangeCategory}
            />
          )}

          {itemCategoryList && isMobile && (
            <MobileItemSelectDropdown
              value={itemDetails.category}
              list={itemCategoryList}
              setValue={handleChangeCategory}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-[24px] items-center mt-[53px] md:mt-[81px]">
        <button
          className="px-[65px] md:px-[100px] py-[14px] md:py-[21px] text-white md:text-black bg-black md:bg-twinworldPrimary-200 font-medium md:font-semibold text-[16px] rounded-full border-2 border-black md:hover:text-twinworldPrimary-200 md:hover:bg-black"
          onClick={handleOpenItemUpdateModal}
        >
          Save Item
        </button>
        <button
          className="text-[14px] md:text-[16px] text-twinworld-700 underline font-medium"
          onClick={handleOpenItemDeleteModal}
        >
          Delete my item
        </button>
      </div>
    </div>
  );
}

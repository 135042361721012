type Props = {
  isHovered?: boolean;
};

export default function EmailIcon({ isHovered = false }: Props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.334 3.66699H3.66732C2.65898 3.66699 1.84315 4.49199 1.84315 5.50033L1.83398 16.5003C1.83398 17.5087 2.65898 18.3337 3.66732 18.3337H18.334C19.3423 18.3337 20.1673 17.5087 20.1673 16.5003V5.50033C20.1673 4.49199 19.3423 3.66699 18.334 3.66699ZM18.334 7.33366L11.0007 11.917L3.66732 7.33366V5.50033L11.0007 10.0837L18.334 5.50033V7.33366Z"
        fill={`${isHovered ? "#A6D9FF" : "#000000"}`}
      />
    </svg>
  );
}

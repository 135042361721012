import { Navigate, useLocation } from "react-router-dom";

import { useAuthContext } from "../contexts/AuthContext";
import useDeviceBreakPoint from "../hooks/useDeviceBreakPoint";

type ProtectedRouteProps = {
  children: JSX.Element;
};

const MOBILE_PAGES = [
  "/sign-in",
  "/email-sign-in",
  "/sign-up",
  "/qr-sign-in",
  "/email-verification",
];

const MobileRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  const { isDesktop } = useDeviceBreakPoint();
  const { isSignIn } = useAuthContext();

  const { pathname } = location;

  if (
    (isDesktop && MOBILE_PAGES.includes(pathname)) ||
    (isSignIn &&
      pathname !== "/qr-sign-in" &&
      pathname !== "/items/mobile-support")
  ) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default MobileRoute;

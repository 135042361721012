import { useState } from "react";

import FacebookIcon from "./common/icons/FacebookIcon";
import InstagramIcon from "./common/icons/InstargramIcon";
import LinkedInIcon from "./common/icons/LinkedInIcon";
import TwitterIcon from "./common/icons/TwitterIcon";
import YoutubeIcon from "./common/icons/YoutubeIcon";

type SNS = {
  url: string;
  icon: (isHover: boolean) => React.ReactNode;
  ariaLabel: string;
};

const SNS_LIST: SNS[] = [
  {
    url: "https://www.facebook.com/TwinWorldglobal/",
    icon: (isHover: boolean) => <FacebookIcon isHover={isHover} />,
    ariaLabel: "Facebook",
  },
  {
    url: "https://twitter.com/TwinWorldglobal",
    icon: (isHover: boolean) => <TwitterIcon isHover={isHover} />,
    ariaLabel: "Twitter",
  },
  {
    url: "https://www.youtube.com/channel/UCMVOmsqN3D_F4LKT5S0G2xQ",
    icon: (isHover: boolean) => <YoutubeIcon isHover={isHover} />,
    ariaLabel: "Youtube",
  },

  {
    url: "https://www.linkedin.com/company/twinworld/",
    icon: (isHover: boolean) => <LinkedInIcon isHover={isHover} />,
    ariaLabel: "LinkedIn",
  },
  {
    url: "https://www.instagram.com/twinworldglobal/",
    icon: (isHover: boolean) => <InstagramIcon isHover={isHover} />,
    ariaLabel: "Instagram",
  },
];

type Menu = {
  label: string;
  url: string;
};

const LEFT_MENU: Menu[] = [
  {
    label: "Press",
    url: "https://www.doubleme.me/press",
  },
  {
    label: "Careers",
    url: "https://www.linkedin.com/company/twinworld",
  },
  {
    label: "Partnership",
    url: "https://www.doubleme.me/contact",
  },
];

const RIGHT_MENU: Menu[] = [
  {
    label: "Download for Meta Quest",
    url: "https://www.oculus.com/experiences/quest/6464922583573383/?ranking_trace=116836404732608_6464922583573383_SKYLINEWEB_33225171-95f5-49a1-9889-a97a2fdb183f",
  },
  {
    label: "Double Me",
    url: "https://www.doubleme.me/",
  },
  {
    label: "Terms & Conditions",
    url: "/terms-and-conditions/service",
  },
  {
    label: "Privacy Policy",
    url: "/terms-and-conditions/privacy",
  },
];

export default function Footer() {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  return (
    <footer className="w-full bg-twinworldGray-770 min-w-[1440px]">
      <div className="flex flex-col min-w-full px-60 pb-14 max-w-screen-3xl">
        <div className="flex">
          <div className="flex flex-col">
            <div className="flex items-center mt-[84px] space-x-9">
              {SNS_LIST.map((sns, index) => (
                <a
                  key={index}
                  href={sns.url}
                  target={"_blank"}
                  rel="noreferrer"
                  aria-label={sns.ariaLabel}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  {sns.icon(index === hoveredIndex)}
                </a>
              ))}
            </div>
          </div>

          <div className="py-20 ml-6 pl-80 3xl:pl-96">
            <ul className="flex flex-col gap-x-48 gap-y-7 min-w-fit">
              {LEFT_MENU.map((menu) => (
                <li key={menu.label} className="cursor-pointer min-w-max">
                  <a
                    href={menu.url}
                    target={"_blank"}
                    rel="noreferrer"
                    className="text-twinworldGray-500 font-normal text-[15px] hover:transition-background-color hover:duration-300 hover:text-white"
                  >
                    {menu.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="py-20 pl-52">
            <ul className="flex flex-col text-base leading-5 gap-x-48 gap-y-7">
              {RIGHT_MENU.map((menu) => (
                <li key={menu.label} className="cursor-pointer min-w-max">
                  <a
                    href={menu.url}
                    target={"_blank"}
                    rel="noreferrer"
                    className="text-twinworldGray-500 font-normal text-[15px] hover:transition-background-color hover:duration-300 hover:text-white"
                  >
                    {menu.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="w-full border-t border-[#464646]">
          <p className="mt-[18px] text-[15px] text-twinworldGray-650 font-light">
            © 2023. TwinWorld CORP. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default function MobileSharedIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.26775 19.7322C2.57494 18.0394 2.52579 15.3365 4.15815 13.7041L7.97652 9.88576L6.09562 8.00486L2.27724 11.8232C-0.357437 14.4579 -0.278241 18.8137 2.45402 21.546C5.18628 24.2782 9.54206 24.3574 12.1767 21.7227L15.9951 17.9044L14.1142 16.0234L10.2958 19.8418C8.66348 21.4742 5.96056 21.425 4.26775 19.7322ZM9.17153 16.8083L16.8083 9.17158L14.8284 7.19169L7.19164 14.8284L9.17153 16.8083ZM11.8232 2.27729L8.00481 6.09567L9.88571 7.97657L13.7041 4.1582C15.3364 2.52584 18.0394 2.57498 19.7322 4.2678C21.425 5.96061 21.4741 8.66353 19.8418 10.2959L16.0234 14.1143L17.9043 15.9952L21.7227 12.1768C24.3574 9.54211 24.2782 5.18633 21.5459 2.45407C18.8136 -0.278191 14.4579 -0.357387 11.8232 2.27729Z"
        fill="#B7B7B7"
      />
    </svg>
  );
}

export default function MobilePersonIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 17C18.0376 17 20.5 14.5376 20.5 11.5C20.5 8.46243 18.0376 6 15 6C11.9624 6 9.5 8.46243 9.5 11.5C9.5 14.5376 11.9624 17 15 17Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M7 23C8.30641 20.0637 11.3962 18 15.0007 18C18.6052 18 21.6936 20.0617 23 22.996"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default function getByteSize(text: string) {
  let byteSize = 0;

  for (let i = 0; i < text.length; i += 1) {
    const char = text.charAt(i);
    if (escape(char).length > 4) {
      byteSize += 2;
    } else {
      byteSize += 1;
    }
  }

  return byteSize;
}

type Props = {
  title: string;
  description: React.ReactNode;
  imageSrc: string;
  isActive: boolean;
  onClick: () => void;
  position?: string;
};

export default function AnythingItem({
  title,
  description,
  imageSrc,
  isActive,
  onClick,
  position,
}: Props) {
  return (
    <div
      className={`relative flex py-6 px-[30px] bg-cover ${position} ${
        isActive
          ? `flex-col items-start box-border rounded-[25px] h-[510px] md:h-[717px] transition-height duration-500 ease-in-out overflow-hidden`
          : `items-center rounded-full h-[68px] border border-black w-full transition-all duration-100 ease-in-out`
      }`}
      onClick={onClick}
      style={{
        backgroundImage: isActive ? `url(${imageSrc})` : "none",
      }}
    >
      <h4
        className={`text-[20px] font-medium ${
          isActive && "text-white font-bold text-[28px]"
        }`}
      >
        {title}
      </h4>
      {!isActive && (
        <div className="ml-auto">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 5H10" stroke="black" strokeWidth="1.5" />
            <path d="M5 0L5 10" stroke="black" strokeWidth="1.5" />
          </svg>
        </div>
      )}
      {isActive && (
        <p className="text-white flex mt-auto text-[16px] font-normal leading-[29px]">
          {description}
        </p>
      )}
    </div>
  );
}

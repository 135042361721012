export default function LiveUserIcon() {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4 6.5C7.14094 6.5 8.55 5.15804 8.55 3.5C8.55 1.84196 7.14094 0.5 5.4 0.5C3.65906 0.5 2.25 1.84196 2.25 3.5C2.25 5.15804 3.65906 6.5 5.4 6.5ZM7.56 7.35714H7.32656C6.74156 7.625 6.09188 7.78571 5.4 7.78571C4.70812 7.78571 4.06125 7.625 3.47344 7.35714H3.24C1.45125 7.35714 0 8.73929 0 10.4429V11.2143C0 11.9241 0.604688 12.5 1.35 12.5H9.45C10.1953 12.5 10.8 11.9241 10.8 11.2143V10.4429C10.8 8.73929 9.34875 7.35714 7.56 7.35714ZM13.5 6.5C14.9906 6.5 16.2 5.34821 16.2 3.92857C16.2 2.50893 14.9906 1.35714 13.5 1.35714C12.0094 1.35714 10.8 2.50893 10.8 3.92857C10.8 5.34821 12.0094 6.5 13.5 6.5ZM14.85 7.35714H14.7431C14.3522 7.48571 13.9387 7.57143 13.5 7.57143C13.0612 7.57143 12.6478 7.48571 12.2569 7.35714H12.15C11.5763 7.35714 11.0475 7.51518 10.5834 7.76964C11.2697 8.47411 11.7 9.40893 11.7 10.4429V11.4714C11.7 11.5304 11.6859 11.5866 11.6831 11.6429H16.65C17.3953 11.6429 18 11.067 18 10.3571C18 8.69911 16.5909 7.35714 14.85 7.35714Z"
        fill="black"
      />
    </svg>
  );
}

import { useNavigate } from "react-router-dom";

type Props = {
  onClick: () => void;
};

export default function MobileSettingMenu({ onClick }: Props) {
  const navigate = useNavigate();

  const SETTING_MENU_LIST = [
    {
      key: "profile",
      label: "Profile",
      action: () => {
        navigate("/myprofile", { state: { menu: "home" } });
        onClick();
      },
    },
    {
      key: "pinCode",
      label: "Pin Code",
      action: () => {
        navigate("/qr-sign-in");
        onClick();
      },
    },
  ];

  return (
    <div className="flex flex-row gap-2.5 mt-5 font-medium">
      {SETTING_MENU_LIST.map((link) => (
        <button
          className="box-border px-[20px] py-2.5 rounded-full border border-twinworldGray-250 bg-twinworldGray-150 text-[14px] font-medium"
          key={link.key}
          onClick={link.action}
        >
          {link.label}
        </button>
      ))}
    </div>
  );
}

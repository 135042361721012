export default function CheckIcon() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 7.99219C2 7.99219 6 13.4863 6.5 13.9922C7.00049 14.4985 13 1.99219 13 1.99219"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}

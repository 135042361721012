import ModalActionButton from "../button/ModalActionButton";
import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";

import { ModalTitle } from "./ModalTitle";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function ResetPwSuccessModal({ isOpen, closeModal }: Props) {
  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  return (
    <Modal
      maxWidth="max-w-[339px] md:max-w-[480px]"
      isOpen={isOpen}
      title={<ModalTitle text="Success!" />}
      close={close}
      closeModal={closeModal}
    >
      <div className="relative flex flex-col items-center justify-center w-full min-h-full px-[30px] md:px-[80px] pb-[64px] md:pb-[95px] text-center">
        <div className="text-[18px] text-twinworldGray-700 leading-[30px] px-[37px] mt-[40px] md:mt-[37px] mb-[68px] md:mb-[161px]">
          Your new password saved successfully.
        </div>

        <ModalActionButton type="button" onClick={closeModal}>
          Go to Main
        </ModalActionButton>
      </div>
    </Modal>
  );
}

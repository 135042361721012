import { Children, cloneElement, isValidElement, useState } from "react";

type Props = {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
};

export default function SignInButton({ icon, label, onClick }: Props) {
  const [isHovered, setIsHovered] = useState(false);

  const iconAddHoveredProp = Children.map(icon, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child as React.ReactElement, { isHovered });
    }
    return child;
  });

  return (
    <button
      className="border-2 rounded-full w-80 h-14 border-twinworldGray-300 hover:transition-background-color hover:duration-300 hover:text-twinworldPrimary-200 hover:bg-black"
      type="button"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <div className="flex items-center justify-center gap-5">
        {iconAddHoveredProp}
        <span className="w-[58px] text-left font-semibold">{label}</span>
      </div>
    </button>
  );
}

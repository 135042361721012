import Introduce from "./Introduce";
import Anything from "./anything";
import Devices from "./devices";
import Experience from "./experience";
import Space from "./space";
import Value from "./value";

export default function Landing() {
  return (
    <section className="w-full">
      <Introduce />
      <Space />
      <Value />
      <Anything />
      <Experience />
      <Devices />
    </section>
  );
}

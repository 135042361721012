import { Html } from "@react-three/drei";

import LoadingSpinner from "./LoadingSpinner";

export default function ModelLoader() {
  return (
    <Html center>
      <LoadingSpinner />
    </Html>
  );
}

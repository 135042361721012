const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M12.5029 25C9.6185 25.0022 6.82214 24.0059 4.58849 22.1801C2.35484 20.3543 0.821526 17.8116 0.248783 14.9835C-0.32396 12.1554 0.0991594 9.21618 1.44633 6.66467C2.7935 4.11316 4.98171 2.10659 7.63957 0.985528C10.6927 -0.304815 14.1332 -0.328984 17.2042 0.918336C20.2751 2.16566 22.725 4.58229 24.0149 7.63661C25.3047 10.6909 25.3289 14.1327 24.082 17.2049C22.8352 20.277 20.4195 22.7278 17.3663 24.0182C15.8277 24.6694 14.1736 25.0034 12.5029 25ZM9.17139 10.2918V12.6345H10.5762V19.4341H13.3886V12.6345H15.2626L15.5118 10.2911H13.3886V9.11833C13.3886 8.51912 13.4323 8.17958 14.3242 8.17958H15.4965V5.83619H13.6225C13.1931 5.79548 12.7599 5.84634 12.3515 5.98541C11.9432 6.12449 11.569 6.34862 11.2536 6.64301C10.7521 7.27729 10.5097 8.07829 10.5755 8.88434V10.2918H9.17139Z"
      fill="#555555"
    />
  </svg>
);

export default FacebookIcon;

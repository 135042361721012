import useLazyLoadSvgVideo from "../../../hooks/useLazyLoadSvgVideo";

export default function Value02Mask() {
  const ref = useLazyLoadSvgVideo();

  return (
    <section ref={ref} className="relative max-w-[1800px] w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1800 812">
        <defs>
          <clipPath id="value02">
            <path
              d="m936.06,812H50c-3.45,0-6.82-.35-10.08-1.02C17.14,806.32,0,786.16,0,762v-152S0,164,0,164c0-27.61,22.39-50,50-50h115.08c27.58-.04,49.92-22.41,49.92-50v-16h.04C216.09,21.31,238.06,0,265,0h671c27.61,0,50,22.39,50,50v297.05c6.07,20.78,25.26,35.95,48,35.95h716c27.61,0,50,22.39,50,50v211c0,27.61-22.39,50-50,50h-321c-27.61,0-50,22.39-50,50v18c0,27.61-22.39,50-50,50h-392.94Z"
              fill="#FFFFFF"
            />
          </clipPath>
        </defs>

        <foreignObject clipPath="url(#value02)" width="1800" height="812">
          <video
            className="block w-full"
            autoPlay
            muted
            loop
            disablePictureInPicture
            poster="/images/landing/thumbnails/value_2.webp"
          >
            <source data-src="/videos/value_2.webm" type="video/webm" />
          </video>
        </foreignObject>
      </svg>
    </section>
  );
}

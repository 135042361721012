import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "./components/common/Layout";
import Modals from "./components/modal/Modals";
import { ItemUploadProvider } from "./contexts/ItemUploadContext";
import { ASpace } from "./pages/ASpace";
import { AnItem } from "./pages/AnItem";
import EmailSignIn from "./pages/EmailSignIn";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import ErrorPage from "./pages/Error";
import Guide from "./pages/Guide";
import ItemEdit from "./pages/ItemEdit";
import ItemUpload from "./pages/ItemUpload";
import ItemsPage from "./pages/ItemsPage";
import Main from "./pages/Main";
import MobileQRSignIn from "./pages/MobileQRSignIn";
import MobileRoute from "./pages/MobileRoute";
import MobileUploadEditNotification from "./pages/MobileUploadEditNotification";
import { MyCoins } from "./pages/MyCoins";
import { MyProfile } from "./pages/MyProfile";
import ProtectedRoute from "./pages/ProtectedRoute";
import SettingPage from "./pages/SettingPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import SocialSignInPage from "./pages/SocialSignIn";
import Spaces from "./pages/Spaces";
import TosPrivacy from "./pages/TosPrivacy";
import TosService from "./pages/TosService";
import UserProfile from "./pages/UserProfile";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Main />
            </ProtectedRoute>
          }
        />

        <Route element={<Layout />}>
          <Route
            path="/spaces"
            element={
              <ProtectedRoute>
                <Spaces />
              </ProtectedRoute>
            }
          />

          <Route
            path="/myspace"
            element={
              <ProtectedRoute isLoginRequired>
                <Spaces />
              </ProtectedRoute>
            }
          />

          <Route
            path="/spaces/:spaceId"
            element={
              <ProtectedRoute>
                <ASpace />
              </ProtectedRoute>
            }
          />

          {/* item */}
          <Route
            path="/items"
            element={
              <ProtectedRoute>
                <ItemsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/items/:itemId"
            element={
              <ProtectedRoute>
                <AnItem />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mybox/:itemId"
            element={
              <ProtectedRoute isLoginRequired>
                <AnItem />
              </ProtectedRoute>
            }
          />
          <Route
            path="/items/upload"
            element={
              <ProtectedRoute isLoginRequired>
                <ItemUploadProvider>
                  <ItemUpload />
                </ItemUploadProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/items/:itemId/edit"
            element={
              <ProtectedRoute isLoginRequired>
                <ItemEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/items/mobile-support"
            element={
              <MobileRoute>
                <MobileUploadEditNotification />
              </MobileRoute>
            }
          />

          {/* account & profile */}
          <Route
            path="/myprofile"
            element={
              <ProtectedRoute isLoginRequired>
                <MyProfile />
              </ProtectedRoute>
            }
          />

          {/* User profile */}
          <Route
            path="/user/:userId"
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            }
          />

          {/* My coins */}
          {/* 이번 배포 주석 처리. */}
          {/* <Route
            path="/mycoins"
            element={
              <ProtectedRoute isLoginRequired>
                <MyCoins />
              </ProtectedRoute>
            }
          /> */}

          {/* settings */}
          <Route
            path="/setting"
            element={
              <ProtectedRoute isLoginRequired>
                <SettingPage />
              </ProtectedRoute>
            }
          />

          {/* guide */}
          <Route path="/guide" element={<Guide />} />

          {/* authentication */}
          <Route
            path="/sign-in"
            element={
              <MobileRoute>
                <SignInPage />
              </MobileRoute>
            }
          />
          <Route
            path="/email-sign-in"
            element={
              <MobileRoute>
                <EmailSignIn />
              </MobileRoute>
            }
          />
          <Route
            path="/sign-up"
            element={
              <MobileRoute>
                <SignUpPage />
              </MobileRoute>
            }
          />
          <Route
            path="/qr-sign-in"
            element={
              <MobileRoute>
                <MobileQRSignIn />
              </MobileRoute>
            }
          />
          {/* 모바일 이메일 확인 페이지 */}
          <Route
            path="/email-verification"
            element={
              <MobileRoute>
                <EmailVerificationPage />
              </MobileRoute>
            }
          />
          <Route path="/sign-in/:provider" element={<SocialSignInPage />} />
          <Route
            path="/sign-up/email-verification"
            element={<Navigate to="/#email-verification" />}
          />

          {/* Terms and Conditions */}
          <Route
            path="/terms-and-conditions/service"
            element={<TosService />}
          />
          <Route
            path="/terms-and-conditions/privacy"
            element={<TosPrivacy />}
          />

          <Route
            path="/myprofile/"
            element={
              <ProtectedRoute>
                <MyProfile />
              </ProtectedRoute>
            }
          />

          {/* errors - 400, 401, 500 */}
          <Route path="/error/:errorCode" element={<ErrorPage />} />
          {/* 404 Not found error */}
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
      <Modals />
    </div>
  );
}

export default App;

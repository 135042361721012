import { cva } from "class-variance-authority";
import FadeLoader from "react-spinners/FadeLoader";

import { classNameMerge } from "../../../utils/tailwindClassMerge";

type Props = {
  type: "card" | "details";
};

const VARIANTS = cva("", {
  variants: {
    type: {
      card: "w-12 h-12",
      details: "w-28 h-28",
    },
  },
  defaultVariants: {
    type: "card",
  },
});

export default function OptimizingIcon({ type = "card" }: Props) {
  const classes = classNameMerge(VARIANTS({ type }));

  return (
    <div className={`${classes}`}>
      <FadeLoader
        color="#2D2D2D"
        width={type === "card" ? 4 : 9}
        height={type === "card" ? 12 : 24}
        radius="10px"
        margin={type === "card" ? 0 : 15}
      />
    </div>
  );
}

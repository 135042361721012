type Props = {
  isHover: boolean;
};

export default function ItemPlusIcon({ isHover }: Props) {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 1C6.5 0.447715 6.05228 0 5.5 0C4.94772 0 4.5 0.447715 4.5 1V4.5L1 4.5C0.447715 4.5 0 4.94771 0 5.5C0 6.05228 0.447715 6.5 1 6.5L4.5 6.5V10C4.5 10.5523 4.94771 11 5.5 11C6.05228 11 6.5 10.5523 6.5 10V6.5L10 6.5C10.5523 6.5 11 6.05228 11 5.5C11 4.94772 10.5523 4.5 10 4.5L6.5 4.5V1Z"
        fill={isHover ? "#000000" : "#A6D9FF"}
      />
    </svg>
  );
}

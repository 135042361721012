import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CheckIcon from "../components/common/icons/CheckIcon";
import { useAuthActions } from "../domains/auth/hook/useAuthActions";

type Location = {
  state: {
    email: string;
  };
};

export default function EmailVerificationPage() {
  const { state } = useLocation() as Location;
  const navigate = useNavigate();

  const [isResendSuccess, setIsResendSuccess] = useState(false);
  const [email, setEmail] = useState("");

  const { resendSignUpConfirmMail } = useAuthActions();

  useEffect(() => {
    if (state) {
      setEmail(state.email);
    }
  }, [state]);

  const handleClickResendButton = async () => {
    await resendSignUpConfirmMail(email)
      .then(() => {
        setIsResendSuccess(true);

        setTimeout(() => {
          setIsResendSuccess(false);
        }, 1000);
      })
      .catch(() => {
        alert("Failed to check email");
      });
  };

  return (
    <section className="flex flex-col items-center w-full mx-auto">
      <h2 className="text-[20px] font-semibold leading-[25px] px-[80px] text-center mt-[64px]">
        Email Verification
      </h2>
      <p className="text-[16px] text-twinworldGray-700 leading-[28px] px-[80px] mt-[40px] text-center">
        Please check your email and verify your account.
      </p>
      <div className="flex flex-col gap-4 mt-[40px] relative">
        <button
          type="button"
          className="flex items-center justify-center w-[250px] py-[16px] bg-black rounded-full text-[16px] font-medium text-white
           disabled:border-twinworldGray-500 disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 disabled:text-[16px] disabled:font-semibold disabled:cursor-not-allowed"
          onClick={() => navigate("/")}
        >
          Okay
        </button>
        <button
          type="button"
          className="relative flex items-center justify-center w-[250px] py-[16px] border-2 border-black bg-white rounded-full text-[16px] font-medium 
           disabled:border-twinworldGray-500 disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 disabled:text-[16px] disabled:font-semibold disabled:cursor-not-allowed"
          onClick={handleClickResendButton}
        >
          Resend Email
        </button>
      </div>
      <div
        className={`absolute flex items-center justify-center top-[430px] cursor-pointer text-[14px] font-medium w-fit border border-black px-4 py-2 rounded-[5px] mt-[24px] shadow-[3px_3px_3px_0_rgba(0,0,0,0.2)] ${
          isResendSuccess ? "opacity-100" : "opacity-0"
        } transition-opacity duration-[1000ms] ease-in-out`}
      >
        <CheckIcon />
        <div className="ml-[6px]">Verification email sent successfully</div>
      </div>
    </section>
  );
}

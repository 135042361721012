import useDeviceBreakPoint from "../../../hooks/useDeviceBreakPoint";

export default function DividerIcon() {
  const { isDesktop } = useDeviceBreakPoint();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height={isDesktop ? "30" : "24"}
      viewBox={isDesktop ? "0 0 2 30" : "0 0 2 24"}
      fill="none"
    >
      <path d="M1 0V30" stroke="black" strokeWidth={isDesktop ? "2" : "1"} />
    </svg>
  );
}

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import MobileSpaceCarouselItem from "./MobileSpaceCarouselItem";
import MobileSpaceCarouselPaging from "./MobileSpaceCarouselPaging";

const SETTINGS = {
  adaptiveHeight: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  variableWidth: true,
  className: "relative mt-[25px]",
  dotsClass: "mobile-slick-dots !flex justify-center items-center mt-[16px]",
  arrows: false,
  customPaging: (pageNumber: number) => (
    <MobileSpaceCarouselPaging pageNumber={pageNumber} />
  ),
  autoplay: false,
  autoplaySpeed: 10000,
};

const LIST = [
  {
    title: "Puzzle in Egypt",
    file: {
      webm: "/videos/egypt.webm",
      mp4: "/videos/egypt.mp4",
    },
    poster: "/images/landing/thumbnails/egypt.webp",
  },
  {
    title: "Chinese chess world",
    file: {
      webm: "/videos/chinese.webm",
      mp4: "/videos/chinese.mp4",
    },
    poster: "/images/landing/thumbnails/chinese.webp",
  },
  {
    title: "Zoo Package",
    file: {
      webm: "/videos/zoo.webm",
      mp4: "/videos/zoo.mp4",
    },
    poster: "/images/landing/thumbnails/zoo.webp",
  },
  {
    title: "Aquarium Package",
    file: {
      webm: "/videos/aqua.webm",
      mp4: "/videos/aqua.mp4",
    },
    poster: "/images/landing/thumbnails/aqua.webp",
  },
  {
    title: "Word search king-food",
    file: {
      webm: "/videos/crossword.webm",
      mp4: "/videos/crowssword.mp4",
    },
    poster: "/images/landing/thumbnails/crossword.webp",
  },
  {
    title: "Horror space",
    file: {
      webm: "/videos/horror.webm",
      mp4: "/videos/horror.mp4",
    },
    poster: "/images/landing/thumbnails/horror.webp",
  },
];

export default function MobileSpaceCarousel() {
  return (
    <Slider {...SETTINGS}>
      {LIST.map(({ title, file, poster }, index) => (
        <MobileSpaceCarouselItem
          key={index}
          title={title}
          file={file}
          poster={poster}
        />
      ))}
    </Slider>
  );
}

import { useMutation, useQueryClient } from "@tanstack/react-query";

import itemApi from "../api";
import { UpdateItemInfo } from "../interface";

export const useMyItemActions = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: itemApi.deleteMyItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["myItemList", "itemList"] });
    },
  });

  const deleteMyItem = (itemId: string) => {
    const response = mutateAsync(itemId);
    return response;
  };

  const { mutateAsync: updateMyItemMutateAsync } = useMutation({
    mutationFn: itemApi.updateMyItemInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["myItemList"] });
    },
  });

  const updateMyItemInfo = (updateItemInfo: UpdateItemInfo) => {
    const response = updateMyItemMutateAsync(updateItemInfo);
    return response;
  };

  return {
    deleteMyItem,
    updateMyItemInfo,
  };
};

export default function SelectorArrow() {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector 4"
        d="M1 1.5C1 1.5 5.84483 8.5 6.17241 8.5C6.5 8.5 11 1.5 11 1.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

import DotLoader from "react-spinners/DotLoader";

export default function Loading() {
  return (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-black/70">
      <div className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
        <DotLoader color="#A6D9FF" loading={true} size={70} />
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { getErrorCode } from "../../domains/auth/errors";
import { useProfileActions } from "../../domains/user/hook/useProfileActions";
import { UserInfo } from "../../domains/user/interface";
import getByteSize from "../../utils/getByteSize";
import ProfileSaveButton from "../profile/ProfileSaveButton";
import ButtonToast from "../toast/ButtonToast";

type Form = {
  nickname: string;
  description: string;
  emptyNickname: string;
};

type updateProfileInfo = {
  nickname?: string;
  description?: string;
};

type Props = {
  userInfo: UserInfo;
};

const NAME_MAX_BYTE = 20;
const DESCRIPTION_MAX_BYTE = 100;

export default function ProfileForm({ userInfo }: Props) {
  const { updateUserProfile } = useProfileActions();
  const [isDisabled, setIsDisabled] = useState(true);
  const [nickname, setNickname] = useState(userInfo.nickname);
  const [description, setDescription] = useState(userInfo.description);
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<Form>({
    defaultValues: {
      nickname: userInfo.nickname,
      description: userInfo.description,
    },
  });

  const handleNicknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (getByteSize(e.target.value) <= NAME_MAX_BYTE) {
      setNickname(e.target.value);
      setValue("nickname", e.target.value);
      return;
    }

    setValue("nickname", nickname);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (getByteSize(e.target.value) <= DESCRIPTION_MAX_BYTE) {
      setDescription(e.target.value);
      setValue("description", e.target.value);
      return;
    }

    setValue("description", description);
  };

  const onSubmit = async ({ nickname, description }: Form) => {
    const updateProfileInfo: updateProfileInfo = {};

    if (userInfo.nickname !== nickname) {
      updateProfileInfo.nickname = nickname;
    }

    if (userInfo.description !== description) {
      updateProfileInfo.description = description;
    }

    await updateUserProfile(updateProfileInfo)
      .then(() => {
        setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 1000);
      })
      .catch((error) => {
        const code = getErrorCode(error);
        if (code === "INVALID_NICKNAME") {
          alert(`Nicknames cannot be spaces only. Please re-enter.`);
          return;
        }

        alert("Failed to save profile information.");
      });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      if (!userInfo) {
        return;
      }

      if (userInfo.description === null && value.description === "") {
        setIsDisabled(true);
        return;
      }

      if (value.nickname !== userInfo.nickname) {
        setIsDisabled(false);
        return;
      }

      if (value.description !== userInfo.description) {
        setIsDisabled(false);
        return;
      }

      setIsDisabled(true);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <form className="px-[18px] md:px-[24px] lg:px-[32px] xl:px-[100px] md:w-[500px] md:self-center xl:w-full pt-[42px] pb-[30px] xl:pt-[80px] xl:pb-[80px] bg-white xl:bg-twinworldGray-150 rounded-2xl">
        <div className="relative flex flex-col mb-5 xl:items-center xl:justify-between xl:flex-row">
          <label
            htmlFor="name"
            className="text-[15px] xl:text-lg leading-none font-medium"
          >
            User Name
          </label>
          <div
            className={`flex items-center w-full xl:w-[786px] py-4 border rounded-full px-[16px] xl:px-7 mt-[12px] xl:mt-0 bg-white ${
              errors.nickname
                ? "border-twinworldRed"
                : "border-twinworldGray-300"
            }`}
          >
            <input
              className="w-full xl:w-[650px]"
              type="text"
              {...register("nickname", {
                required: true,
                onChange: handleNicknameChange,
              })}
            />
            <span className="flex ml-auto min-w-[72px] text-[13px] leading-none text-twinworldGray-600">
              {watch("nickname") ? getByteSize(watch("nickname")) : 0}/
              {NAME_MAX_BYTE} byte
            </span>
          </div>
          {errors.nickname && errors.nickname.type === "maxLength" && (
            <p className="absolute text-sm leading-none text-twinworldRed">
              Your input exceed maximum length
            </p>
          )}
        </div>

        <div className="flex flex-col items-start justify-between w-full mb-0 xl:mb-5 xl:items-start xl:flex-row">
          <label
            htmlFor="name"
            className="text-[15px] xl:text-[18px] leading-none font-medium"
          >
            Description
          </label>
          <div className="flex flex-col items-end w-full xl:w-[786px] h-[144px] mt-[12px] xl:mt-0 border rounded-xl px-[16px] py-[14px] xl:px-[30px] xl:py-[30px] border-twinworldGray-300 bg-white ">
            <textarea
              className="w-full h-full resize-none leading-[24px]"
              {...register("description", {
                onChange: handleDescriptionChange,
              })}
            />
            <span className="mt-2 text-[13px] leading-none text-twinworldGray-600">
              {watch("description") ? getByteSize(watch("description")) : 0}/
              {DESCRIPTION_MAX_BYTE} byte
            </span>
          </div>
        </div>
      </form>

      <div className="relative flex justify-center items-center mt-0 xl:mt-[50px]">
        <ProfileSaveButton
          isDisabled={isDisabled}
          onClick={handleSubmit(onSubmit)}
        >
          Save Profile
        </ProfileSaveButton>

        <ButtonToast idDisplay={isSuccess} message={"Saved successfully"} />
      </div>
    </>
  );
}

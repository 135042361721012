import React from "react";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export default function SubWrapper({ children }: Props) {
  return (
    <section className="flex flex-col w-full max-w-[1140px] mx-auto">
      {children}
    </section>
  );
}

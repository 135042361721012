export default function MobileNoResultIcon() {
  return (
    <svg
      width="62"
      height="64"
      viewBox="0 0 62 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="ic / no_search"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1286 49.7868C39.9351 49.7868 50.2572 39.5043 50.2572 26.8934C50.2572 14.2825 39.9351 4 27.1286 4C14.3221 4 4 14.2825 4 26.8934C4 39.5043 14.3221 49.7868 27.1286 49.7868ZM27.1286 53.7868C42.1113 53.7868 54.2572 41.7462 54.2572 26.8934C54.2572 12.0406 42.1113 0 27.1286 0C12.1459 0 0 12.0406 0 26.8934C0 41.7462 12.1459 53.7868 27.1286 53.7868ZM46.169 48.8976C46.9495 48.1137 48.2206 48.1043 49.0081 48.8766L60.47 60.117C61.2575 60.8892 61.2632 62.1507 60.4827 62.9346C59.7023 63.7185 58.4312 63.7279 57.6437 62.9557L46.1817 51.7153C45.3943 50.943 45.3886 49.6815 46.169 48.8976ZM16.9379 17.4779C16.1534 18.2555 16.1534 19.5163 16.9379 20.294L24.3349 27.6269L16.938 34.9597C16.1536 35.7374 16.1536 36.9982 16.938 37.7758C17.7224 38.5535 18.9943 38.5535 19.7787 37.7758L27.1757 30.443L34.5726 37.7758C35.3571 38.5535 36.6289 38.5535 37.4133 37.7758C38.1978 36.9982 38.1978 35.7374 37.4133 34.9597L30.0164 27.6269L37.4134 20.294C38.1979 19.5163 38.1979 18.2555 37.4134 17.4779C36.629 16.7003 35.3572 16.7003 34.5727 17.4779L27.1757 24.8108L19.7786 17.4779C18.9942 16.7003 17.7223 16.7003 16.9379 17.4779Z"
        fill="#E8E8E8"
      />
    </svg>
  );
}

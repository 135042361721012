import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function SignUpSuccessModal({ isOpen, closeModal }: Props) {
  const close = (
    <div className="flex items-center justify-end pt-[20px] md:pt-[26px] pr-[20px] md:pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex justify-center w-full mt-[30px] md:mt-[115px] text-[20px] md:text-[24px] font-semibold">
      Welcome to TwinWorld!
    </div>
  );

  return (
    <Modal
      maxWidth="max-w-[480px]"
      isOpen={isOpen}
      title={title}
      close={close}
      closeModal={closeModal}
    >
      <div className="relative flex flex-col items-center justify-center w-full md:min-h-full px-[30px] md:px-[80px] text-center">
        <div className="text-[18px] text-twinworldGray-700 leading-[30px] px-[6px] md:px-[37px] mt-[40px] md:mt-[37px] mb-[40px] md:mb-[131px]">
          Email verification is done! Explore and create your space now.
        </div>

        <button
          type="button"
          className="flex items-center justify-center w-[279px] md:w-[320px] h-[48px] md:h-[58px] py-4 md:py-[21px] border-2 text-white md:text-black border-black bg-black md:bg-twinworldPrimary-200 rounded-full text-[16px] font-medium md:font-semibold mb-[64px] md:mb-[95px]
          md:hover:bg-black md:hover:text-twinworldPrimary-200 disabled:border-2 disabled:border-twinworldGray-500 disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 disabled:text-[16px] disabled:font-semibold disabled:cursor-not-allowed"
          onClick={closeModal}
        >
          Go to Main
        </button>
      </div>
    </Modal>
  );
}

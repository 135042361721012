import DividerIcon from "../common/icons/DividerIcon";

type ItemInformationData = {
  title: string;
  value: number | string | boolean;
};

type Props = {
  data: ItemInformationData[];
};

export default function ItemInformation({ data }: Props) {
  return (
    <div className="flex flex-col gap-[17px] text-[18px] w-full mt-[36px]">
      {data.map((item) => (
        <div className="flex items-center py-[5px]" key={item.title}>
          <DividerIcon />
          <h4 className="ml-[10px] text-twinworldGray-700 w-24 font-normal">
            {item.title}
          </h4>
          <span className="ml-[94px] text-black font-medium">{item.value}</span>
        </div>
      ))}
    </div>
  );
}

import { useContext, ComponentType } from "react";

import { ModalsDispatchContext } from "../../contexts/ModalContext";

export default function useModals() {
  const { open, close } = useContext(ModalsDispatchContext);

  const openModal = (Component: ComponentType<any>, props: any) => {
    open(Component, props);
  };

  const closeModal = (Component: ComponentType<any>) => {
    close(Component);
  };

  return { openModal, closeModal };
}

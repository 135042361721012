import SearchIcon from "./icons/SearchIcon";

type Props = {
  value: string | number;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  handleClick: () => void;
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
  showInput: boolean;
  keyword: string;
};

export default function MobileSearch({
  value,
  setValue,
  handleClick,
  handleKeyPress,
  placeholder,
  showInput,
  keyword,
}: Props) {
  return (
    <section className="flex flex-row items-center min-w-[375px] box-border h-[38px] px-[18px] relative">
      <div onClick={handleClick} className="absolute">
        <SearchIcon />
      </div>
      {!showInput ? (
        <span className="block text-[25px] leading-[30px] pl-2 font-semibold ml-auto mr-auto max-w-[275px] truncate">
          {keyword}
        </span>
      ) : (
        <input
          className={`w-full h-full px-4 py-2 text-[14px] leading-[16.8px] ml-[30px] ${
            showInput ? "block animate-search" : "hidden"
          }`}
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
          enterKeyHint="search"
        />
      )}
    </section>
  );
}

import Modal from "../common/Modal";
import PinCode from "../common/PinCode";
import ModalClose from "../common/icons/ModalClose";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function QRSignInModal({ isOpen, closeModal }: Props) {
  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex flex-col justify-center w-full mt-[52px] mb-[42px] text-[24px] font-semibold">
      <h2>Sign in with QR and Pin</h2>
      <h3 className="text-[18px] text-twinworldGray-700 mt-[15px]">
        ‘My profile’ &gt; ‘Sign in’ from app
      </h3>
    </div>
  );

  return (
    <Modal
      maxWidth="max-w-[480px]"
      isOpen={isOpen}
      title={title}
      close={close}
      closeModal={closeModal}
    >
      <PinCode />
    </Modal>
  );
}

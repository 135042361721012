import { createContext, useReducer, Dispatch } from "react";

import { ItemDetailsInfo } from "../components/item/type";

type Item = {
  getTitle: string;
  category: {
    idx: string;
    name: string;
    unavailable?: boolean;
  };
};

type Action =
  | { type: "CHANGE_TITLE"; payload: string }
  | {
      type: "CHANGE_CATEGORY";
      payload: {
        idx: string;
        name: string;
      };
    };

function itemEditReducer(item: Item, action: Action): Item {
  switch (action.type) {
    case "CHANGE_TITLE":
      return { ...item, getTitle: action.payload };
    case "CHANGE_CATEGORY":
      return { ...item, category: action.payload };
    default:
      return item;
  }
}

type ItemEditProviderProps = {
  itemDetailsInfo: ItemDetailsInfo;
  children: React.ReactNode;
};

export const ItemEditContext = createContext<any>(null);
export const ItemEditDispatchContext = createContext<Dispatch<Action>>(
  () => null
);

export function ItemEditProvider({
  itemDetailsInfo,
  children,
}: ItemEditProviderProps) {
  const { name, categoryName, categoryId } = itemDetailsInfo;

  const itemInfo = {
    getTitle: name,
    category: {
      idx: categoryId,
      name: categoryName,
      unavailable: true,
    },
  };

  const [items, dispatch] = useReducer(itemEditReducer, itemInfo);

  return (
    <ItemEditContext.Provider value={items}>
      <ItemEditDispatchContext.Provider value={dispatch}>
        {children}
      </ItemEditDispatchContext.Provider>
    </ItemEditContext.Provider>
  );
}

export default function SearchIcon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5503 17.2402C22.5503 20.1252 20.1976 22.4804 17.2751 22.4804C14.3527 22.4804 12 20.1252 12 17.2402C12 14.3552 14.3527 12 17.2751 12C20.1976 12 22.5503 14.3552 22.5503 17.2402ZM24.5503 17.2402C24.5503 21.2388 21.2931 24.4804 17.2751 24.4804C13.2572 24.4804 10 21.2388 10 17.2402C10 13.2415 13.2572 10 17.2751 10C21.2931 10 24.5503 13.2415 24.5503 17.2402ZM23.9762 23.0235C23.5608 22.6602 22.9278 22.7018 22.5623 23.1164C22.1969 23.5311 22.2373 24.1617 22.6527 24.525L26.368 27.7745C26.7834 28.1378 27.4164 28.0961 27.7819 27.6815C28.1474 27.2668 28.1069 26.6362 27.6915 26.2729L23.9762 23.0235Z"
        fill="black"
      />
    </svg>
  );
}

/**
 * 문자열에 특수문자가 포함되었는지 확인하는 함수
 * @param {string} word 문자열
 * @returns {boolean} 특수문자가 포함 true, 없음 false 를 리턴
 */

export default function checkSpecialChar(word: string) {
  const expText = /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/gi;
  return expText.test(word);
}

import { Listbox } from "@headlessui/react";

import { ItemCategory } from "../../domains/item/interface";
import SelectorArrow from "../common/icons/SelectorArrow";

type Props = {
  selectedCategory: ItemCategory;
  categoryList: ItemCategory[];
  handleChangeCategory: (category: ItemCategory) => void;
};

export default function ItemCategorySelector({
  selectedCategory,
  categoryList,
  handleChangeCategory,
}: Props) {
  return (
    <Listbox value={selectedCategory} onChange={handleChangeCategory}>
      {({ open }) => (
        <div className="box-border relative w-96">
          <Listbox.Button
            className={`flex flex-row items-center w-[530px] h-16 px-7 py-4 text-sm border text-twinworldGray-400  ${
              open
                ? "rounded-t-3xl border-b-0 border-black"
                : "rounded-full border-twinworldGray-350"
            }`}
          >
            <div
              className={`text-black w-44 flex items-center text-md text-ellipsis overflow-hidden h-6`}
            >
              <div className="text-[16px]">{selectedCategory.name}</div>
            </div>
            <span className={`ml-auto h-6 flex items-center`}>
              <div
                className={`${
                  open ? "rotate-180 transition-all" : "transition-all"
                }`}
              >
                <SelectorArrow />
              </div>
            </span>
          </Listbox.Button>
          {open && (
            <Listbox.Options
              static
              className={`absolute z-10 flex flex-wrap w-[530px] overflow-y-auto bg-white border top-12 rounded-b-3xl border-black h-96 max-h-72 px-[30px] ${
                open ? "rounded-b-3xl border-t-0" : "rounded-full"
              }`}
            >
              <div className="w-full border border-t-0 border-l-0 border-r-0 border-dotted border-twinworldGray-770" />
              <div
                className={`pb-[33px] pt-[27px] w-full flex flex-col gap-[25px] flex-wrap max-h-72`}
              >
                {categoryList.map(({ idx, name }) => (
                  <Listbox.Option
                    className="flex items-center truncate w-fit cursor-pointer first:hover:border-t-full last:hover:border-b-full text-[14px] text-twinworldGray-500 hover:text-black"
                    key={idx}
                    value={{ idx, name }}
                  >
                    <span className="overflow-hidden text-ellipsis">
                      {name}
                    </span>
                  </Listbox.Option>
                ))}
              </div>
            </Listbox.Options>
          )}
        </div>
      )}
    </Listbox>
  );
}

const YoutubeIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3864_22482)">
      <path
        d="M12.502 25C9.61757 25.0017 6.82141 24.005 4.58799 22.1792C2.35456 20.3533 0.821429 17.8108 0.248756 14.9829C-0.323918 12.1551 0.0991371 9.21612 1.44613 6.66477C2.79313 4.11342 4.9811 2.10682 7.63877 0.985478C10.6921 -0.304812 14.1327 -0.328968 17.2038 0.918326C20.2749 2.16562 22.7249 4.58219 24.0148 7.63642C25.3047 10.6907 25.3289 14.1324 24.082 17.2044C22.835 20.2764 20.4192 22.7272 17.3659 24.0175C15.8272 24.669 14.1729 25.0032 12.502 25ZM12.4812 7.91868C12.4534 7.91868 9.66638 7.91868 7.82549 8.05755L7.74081 8.06658C7.26604 8.08497 6.81742 8.2889 6.49134 8.63457C6.20749 9.03527 6.02612 9.49945 5.96309 9.98649C5.88076 10.7186 5.83651 11.4544 5.83051 12.1911V13.225C5.83643 13.9619 5.88068 14.698 5.96309 15.4303C6.02615 15.9173 6.20752 16.3815 6.49134 16.7822C6.83632 17.121 7.29517 17.3191 7.77829 17.3377C7.84771 17.346 7.90879 17.3537 7.95877 17.3634C9.01319 17.4668 12.4492 17.5023 12.4839 17.5023C12.5186 17.5023 15.3029 17.496 17.1431 17.3599L17.2285 17.3509C17.7032 17.3325 18.1518 17.1286 18.4779 16.7829C18.762 16.3822 18.9436 15.9181 19.0069 15.431C19.0892 14.6987 19.1335 13.9626 19.1395 13.2257V12.1904C19.1335 11.4537 19.0892 10.7179 19.0069 9.98579C18.9437 9.49868 18.7621 9.03449 18.4779 8.63387C18.152 8.28796 17.7033 8.08398 17.2285 8.06588L17.1438 8.05686C15.3029 7.91798 12.5159 7.91798 12.4881 7.91798L12.4812 7.91868Z"
        fill="#555555"
      />
      <path
        d="M10.6377 14.9511V10.1656L15.1405 12.5663L10.6377 14.9511Z"
        fill="#555555"
      />
    </g>
    <defs>
      <clipPath id="clip0_3864_22482">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default YoutubeIcon;

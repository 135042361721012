import MainMask from "../mask/MainMask";

import DividerIcon from "./DividerIcon";
import MetaQuestButton from "./MetaQuestButton";
import TIcon from "./TIcon";
import TwinWorldStamp from "./TwinWorldStamp";

export default function Introduce() {
  return (
    <section className="px-[60px] mt-24">
      <div className="flex items-center justify-between mb-12">
        <h1 className="font-bold min-w-[760px] w-[760px] h-[240px] text-[120px]">
          Live Beyond Reality
        </h1>
        <div className="flex flex-col items-end gap-10">
          <TwinWorldStamp />
          <div className="flex items-center gap-10">
            <p className="w-[470px] h-[80px] text-3xl text-right">
              Create, enjoy, and share a new realm beyond reality!
            </p>
            <DividerIcon />
            <TIcon />
          </div>
        </div>
      </div>

      <div className="w-full h-[698px] 2xl:h-[830px] 3xl:h-[929px]">
        <div className="relative">
          <MainMask />
          <MetaQuestButton />
        </div>
      </div>
    </section>
  );
}

import useDeviceBreakPoint from "../../../hooks/useDeviceBreakPoint";

import { Menu } from "./types";

type Props = {
  menuList: Menu[];
  selectedMenu: string;
  setSelectedMenu: React.Dispatch<React.SetStateAction<string>>;
};

export default function TabMenu({
  menuList,
  selectedMenu,
  setSelectedMenu,
}: Props) {
  const { isDesktop } = useDeviceBreakPoint();

  const buttonClasses = (selected: boolean) => {
    if (isDesktop) {
      return `hover:bg-black hover:text-white ${
        selected ? "bg-black text-white" : "bg-white text-twinworldGray-700"
      } `;
    }

    return `border-[1.8px] border-black font-medium ${
      selected ? "bg-twinworldSecondary-200" : "bg-white"
    } `;
  };

  return (
    <section className="flex gap-[8px] xl:gap-[16px] p-0 xl:p-2 font-medium bg-transparent xl:bg-twinworldSecondary-200 rounded-full">
      {menuList.map(({ label }) => (
        <button
          key={label}
          className={`px-[18px] xl:px-[26px] py-[12px] xl:py-[16px] text-[13px] xl:text-[14px] capitalize rounded-full ${buttonClasses(
            selectedMenu === label
          )} xl:hover:transition-background-color xl:hover:duration-300 xl:hover:bg-black xl:hover:text-white`}
          type="button"
          onClick={() => setSelectedMenu(label)}
        >
          {label}
        </button>
      ))}
    </section>
  );
}

export default function MainMask() {
  return (
    <section className="relative max-w-[1800px] w-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1800 872"
        width={"100%"}
      >
        <defs>
          <clipPath id="main-clip">
            <path d="m406.14,51c2.05-28.51,25.83-51,54.86-51h1284c30.38,0,55,24.62,55,55v762c0,30.38-24.62,55-55,55h-666c-29.71,0-53.91-23.55-54.96-53h-.04v-6c0-26.94-21.31-48.91-48-49.96v-.04H55c-30.38,0-55-24.62-55-55V154c0-30.38,24.62-55,55-55h301c26.94,0,48.91-21.31,49.96-48h.18Z" />
          </clipPath>
        </defs>

        <foreignObject clipPath="url(#main-clip)" width="1800" height="872">
          <video
            className="block w-full"
            autoPlay
            muted
            loop
            disablePictureInPicture
            poster="/images/landing/thumbnails/background.webp"
          >
            <source src="/videos/background.webm" type="video/webm" />
          </video>
        </foreignObject>
      </svg>
    </section>
  );
}

export default function convertFileSizeToString(fileSize: number): string {
  if (fileSize < 1024) {
    // 파일 크기가 1KB 미만인 경우, 바이트 단위로 표시
    return `${fileSize} bytes`;
  } if (fileSize < 1024 * 1024) {
    // 파일 크기가 1MB 미만인 경우, 킬로바이트(KB)로 변환하여 표시 (1KB = 1024 bytes)
    const fileSizeInKB = fileSize / 1024;
    return `${fileSizeInKB.toFixed(1)} KB`;
  } 
    // 파일 크기가 1MB 이상인 경우, 메가바이트(MB)로 변환하여 표시 (1MB = 1024 KB)
    const fileSizeInMB = fileSize / (1024 * 1024);
    return `${fileSizeInMB.toFixed(1)} MB`;
  
}

import MainSpace from "./MainSpace";
import SpaceCarousel from "./SpaceCarousel";

export default function Space() {
  return (
    <section>
      <div className="flex flex-col px-[70px]">
        <p className="pb-3 text-[28px] 2xl:text-[28px] 3xl:text-[28px] font-extrabold w-[252px] border-b-[3px] border-black min-w-fit mb-[70px]">
          OUR CAPABILITIES
        </p>
        <h2 className="w-[898px] max-w-[898px] text-[65px] 2xl:text-[75px] 3xl:text-[86px] font-semibold leading-tight h-52 mb-[30px] 2xl:mb-[80px] 3xl:mb-[90px]">
          Discover Enchanting Spaces in Reality
        </h2>
        <p className="text-[19px] 2xl:text-[22px] 3xl:text-[26px] font-light leading-9 w-[720px] 2xl:w-[820px] 3xl:w-[950px] break-keep">
          TwinWorld is a mixed reality platform that transcends the boundaries
          of
          <br />
          traditional AR/VR, enabling you to create and share your own digital
          world
          <br />
          in real-time.
        </p>
      </div>
      <SpaceCarousel />
      <MainSpace />
    </section>
  );
}

import { useState } from "react";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import CheckIcon from "../common/icons/CheckIcon";

type Props = {
  email: string;
  closeModal: () => void;
};

export default function EmailVerificationModal({ email, closeModal }: Props) {
  const [isResendSuccess, setIsResendSuccess] = useState(false);
  const { resendSignUpConfirmMail } = useAuthActions();

  const handleClickResendButton = async () => {
    await resendSignUpConfirmMail(email)
      .then(() => {
        setIsResendSuccess(true);

        setTimeout(() => {
          setIsResendSuccess(false);
        }, 1000);
      })
      .catch((error) => {
        alert("Failed to check email");
      });
  };

  return (
    <div className="relative flex flex-col items-center justify-center w-full min-h-full px-[80px] text-center">
      <div className="text-[18px] text-twinworldGray-700 leading-[30px] px-[50px] mt-[37px]">
        Please check your email and verify your account.
      </div>

      <div className="flex flex-col gap-4 mt-[65px] mb-[116px] relative">
        <button
          type="button"
          className="flex items-center justify-center w-[320px] h-[58px] py-[21px] border-2 border-black bg-twinworldPrimary-200 rounded-full  text-[16px] font-semibold 
          hover:bg-black hover:text-twinworldPrimary-200 disabled:border-2 disabled:border-twinworldGray-500 disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 disabled:text-[16px] disabled:font-semibold disabled:cursor-not-allowed"
          onClick={closeModal}
        >
          Okay
        </button>
        <button
          type="button"
          className="relative flex items-center justify-center w-[320px] h-[58px]  py-[21px] border-2 border-black bg-white rounded-full  text-[16px] font-semibold 
          hover:bg-black hover:text-twinworldPrimary-200 disabled:border-2 disabled:border-twinworldGray-500 disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 disabled:text-[16px] disabled:font-semibold disabled:cursor-not-allowed"
          onClick={handleClickResendButton}
        >
          Resend Email
        </button>
      </div>
      <div
        className={`absolute flex items-center justify-center top-[320px] cursor-pointer my-auto text-[18px] w-fit border border-black px-10 py-4 rounded-2xl ${
          isResendSuccess ? "opacity-100" : "opacity-0"
        } transition-opacity duration-[1000ms] ease-in-out`}
      >
        <div className="absolute overflow-hidden w-[25px] -top-[16px] z-10">
          <div className="w-[16px] h-[16px] origin-bottom-left transform rotate-45 bg-white border border-black " />
        </div>
        <CheckIcon />
        <div className="ml-3">Verification email sent successfully</div>
      </div>
    </div>
  );
}

import { Space } from "../interface";

import { SpaceCard } from "./SpaceCard";

export type FeaturedSpacesData = {
  group: {
    id: string;
    name: string;
  };
  spaces: Space[];
};

export class FeaturedSpaces {
  group;

  spaces;

  constructor(data: FeaturedSpacesData) {
    this.group = data.group;
    this.spaces = data.spaces.map((item: Space) => new SpaceCard(item));
  }
}

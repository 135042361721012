export default function AudioIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <mask
        id="mask0_1343_27292"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="80"
        height="80"
      >
        <rect width="80" height="80" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1343_27292)">
        <path
          d="M29.9993 63.3333C23.4993 63.3333 17.9855 61.0694 13.4577 56.5416C8.9299 52.0139 6.66602 46.5 6.66602 40C6.66602 33.5 8.9299 27.9861 13.4577 23.4583C17.9855 18.9305 23.4993 16.6666 29.9993 16.6666C36.4993 16.6666 42.0132 18.9305 46.541 23.4583C51.0688 27.9861 53.3327 33.5 53.3327 40C53.3327 46.5 51.0688 52.0139 46.541 56.5416C42.0132 61.0694 36.4993 63.3333 29.9993 63.3333ZM56.916 62C56.0827 62.2777 55.3466 62.1527 54.7077 61.625C54.0688 61.0972 53.7493 60.3611 53.7493 59.4166C53.7493 58.9722 53.916 58.5416 54.2494 58.125C54.5827 57.7083 54.9716 57.4444 55.416 57.3333C59.2493 56.2222 62.3605 54.0416 64.7494 50.7916C67.1382 47.5416 68.3327 43.8611 68.3327 39.75C68.3327 35.6388 67.1382 31.9722 64.7494 28.75C62.3605 25.5277 59.2493 23.3611 55.416 22.25C54.9716 22.1388 54.5827 21.875 54.2494 21.4583C53.916 21.0416 53.7493 20.5833 53.7493 20.0833C53.7493 19.1388 54.0688 18.4166 54.7077 17.9166C55.3466 17.4166 56.0827 17.3055 56.916 17.5833C61.7493 19.0277 65.6938 21.7777 68.7493 25.8333C71.8049 29.8888 73.3327 34.5277 73.3327 39.75C73.3327 44.9722 71.8049 49.6111 68.7493 53.6666C65.6938 57.7222 61.7493 60.5 56.916 62ZM29.9993 58.3333C35.1105 58.3333 39.4438 56.5555 42.9994 53C46.5549 49.4444 48.3327 45.1111 48.3327 40C48.3327 34.8888 46.5549 30.5555 42.9994 27C39.4438 23.4444 35.1105 21.6666 29.9993 21.6666C24.8882 21.6666 20.5549 23.4444 16.9993 27C13.4438 30.5555 11.666 34.8888 11.666 40C11.666 45.1111 13.4438 49.4444 16.9993 53C20.5549 56.5555 24.8882 58.3333 29.9993 58.3333Z"
          fill="#4298D9"
        />
      </g>
    </svg>
  );
}

type Props = {
  children: React.ReactNode | React.ReactNode[];
  isDetailPage?: boolean;
  isEmptyData?: boolean;
};

export default function ListWrapper({
  children,
  isDetailPage = false,
  isEmptyData = false,
}: Props) {
  const classes = () => {
    if (isEmptyData) {
      return `flex items-center h-auto`;
    }

    if (isDetailPage) {
      return `grid grid-cols-4 gap-5 h-auto`;
    }

    return `grid px-[18px] md:portrait:px-[32px] md:landscape:px-[58px] xl:portrait:px-0 xl:landscape:px-0 xl:w-[1440px] 
    grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 
    gap-x-[18px] md:gap-x-[20px]  
    gap-y-[36px] md:gap-y-[65px] lg:gap-y-[94px] xl:gap-y-[122px] 
    h-auto xl:mx-auto`;
  };

  return <div className={`${classes()}`}>{children}</div>;
}

export default function TIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
    >
      <path
        d="M21.6286 34.1017V23.8495H27.3517V18.494H21.6286V12.1631H16.2258V18.494H10.9473V19.1267C10.9521 19.2619 10.9558 19.408 10.9558 19.5746C10.9558 23.1457 13.511 23.7566 14.6912 23.8495H16.2258V34.1017C16.2258 39.1228 20.3461 43.207 25.4115 43.207H27.5807V37.8515H25.4115C23.3264 37.8515 21.6298 36.1698 21.6298 34.1029L21.6286 34.1017Z"
        fill="#22212A"
      />
      <path
        d="M36.3562 12.0353L32.4609 15.8965L36.3562 19.7577L40.2515 15.8965L36.3562 12.0353Z"
        fill="#22212A"
      />
      <circle cx="26" cy="26" r="24" stroke="black" strokeWidth="4" />
    </svg>
  );
}

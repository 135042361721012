import { useState } from "react";

type Props = {
  onClick?: () => void;
};

export default function PrevButton({ onClick }: Props) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className="absolute -top-[110px] lg:-top-[125px] xl:-top-[210px] right-[83px] lg:right-[117px] xl:right-[164px]"
      aria-label="space_carousel_prev_button"
      type="button"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="85"
        height="85"
        viewBox="0 0 85 85"
        fill={`${isHovered ? "black" : "none"}`}
        className="w-[42px] lg:w-[58px] xl:w-[85px]"
      >
        <circle cx="42.5" cy="42.5" r="42" stroke="black" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.7747 55.1882C40.1657 55.5783 40.1657 56.2108 39.7747 56.601C39.3838 56.9911 38.75 56.9911 38.3591 56.601L25.192 43.4606C25.1347 43.4071 25.0837 43.3471 25.0403 43.2816C24.7812 42.8935 24.8232 42.3645 25.1663 42.0221C25.1821 42.0063 25.1984 41.9911 25.215 41.9765L38.3617 28.8565C38.7526 28.4664 39.3864 28.4664 39.7773 28.8565C40.1683 29.2467 40.1683 29.8792 39.7773 30.2693L28.2987 41.7247L58.7872 41.6678C59.3395 41.6668 59.788 42.1136 59.7891 42.6659C59.7901 43.2182 59.3432 43.6668 58.7909 43.6678L28.2879 43.7247L39.7747 55.1882Z"
          fill={`${isHovered ? "white" : "black"}`}
        />
      </svg>
    </button>
  );
}

export default function FacebookIcon({ isHover }: { isHover: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
    >
      <g clipPath="url(#clip0_1_1393)">
        <path
          d="M16.6738 33.3475C12.8242 33.3504 9.09218 32.0212 6.11114 29.5855C3.1301 27.1497 1.08372 23.7575 0.319332 19.9846C-0.445055 16.2116 0.119644 12.2904 1.91758 8.88642C3.71552 5.48247 6.63593 2.80551 10.1831 1.3099C14.2579 -0.411535 18.8496 -0.443779 22.9481 1.22026C27.0466 2.88431 30.3163 6.10833 32.0377 10.1831C33.7591 14.2578 33.7914 18.8495 32.1273 22.9481C30.4633 27.0466 27.2393 30.3162 23.1645 32.0377C21.1111 32.9065 18.9035 33.352 16.6738 33.3475ZM12.2275 13.7253V16.8507H14.1024V25.9221H17.8558V16.8507H20.3569L20.6894 13.7244H17.8558V12.1598C17.8558 11.3604 17.9142 10.9075 19.1045 10.9075H20.669V7.78115H18.168C17.5949 7.72683 17.0167 7.79469 16.4718 7.98023C15.9268 8.16577 15.4274 8.46478 15.0065 8.85753C14.3371 9.70372 14.0137 10.7723 14.1014 11.8477V13.7253H12.2275Z"
          fill="white"
          fillOpacity={isHover ? "1" : "0.4"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_1393">
          <rect width="33.3474" height="33.3474" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { Fragment } from "react";

import AudioIcon from "./AudioIcon";
import AvatarIcon from "./AvatarIcon";
import BackgroundIcon from "./BackgroundIcon";
import CellIcon from "./CellIcon";
import DrawIcon from "./DrawIcon";
import FunctionalityItem from "./FunctionalityItem";
import MultiplayerIcon from "./MultiplayerIcon";
import ObjectIcon from "./ObjectIcon";
import RealityIcon from "./RealityIcon";

const LIST = [
  {
    icon: <ObjectIcon />,
    title: (
      <Fragment>
        Use your Own
        <br />
        3D Object
      </Fragment>
    ),
    description:
      "Rather than using existing templates, unleash your creativity by designing your own 3D objects to create a space that reflects your personality.",
    top: "mt-10",
  },
  {
    icon: <RealityIcon />,
    title: (
      <Fragment>
        Create as Easily
        <br />
        as Reality
      </Fragment>
    ),
    description:
      "Thanks to its user-friendly interface, even beginners can easily control and stack blocks just like they would in real life.",
    top: "mt-40",
  },
  {
    icon: <MultiplayerIcon />,
    title: <Fragment>Multiplayer</Fragment>,
    description:
      "Team up or go head-to-head! Connect with friends or users from all around the world simultaneously and enjoy a variety of activities.",
    top: "-mt-16",
  },
  {
    icon: <AvatarIcon />,
    title: (
      <Fragment>
        Avatar
        <br />
        Customization
      </Fragment>
    ),
    description:
      "Design and customize your avatar. Express your unique style through a variety of outfits, accessories, skin tones, and hairstyles. ",
    top: "mt-40",
  },
  {
    icon: <AudioIcon />,
    title: <Fragment>Add Audio</Fragment>,
    description:
      "Amplify your virtual experience with background music, sound effects, and voice chat. By using the voice bubble feature, integrate your voice as a playable sound within the space.",
    top: "-mt-16",
  },
  {
    icon: <DrawIcon />,
    title: (
      <Fragment>
        Draw
        <br />
        3D Object
      </Fragment>
    ),
    description:
      "Without the need for special 3D design skills, you can draw 3D objects simply with a mouse and keyboard.",
    top: "mt-40",
  },
  {
    icon: <CellIcon />,
    title: "Physics Item",
    description:
      "TwinWorld objects replicate real-world physics, enabling behaviors such as tossing, gripping, and colliding. ",
    top: "mt-10",
  },
];

export default function FunctionalityList() {
  return (
    <section>
      <div className="relative">
        <div className="absolute transform -translate-x-1/2 left-1/2">
          <BackgroundIcon />
        </div>
      </div>
      <ul className={`flex flex-nowrap gap-9`}>
        {LIST.map((item, index) => (
          <FunctionalityItem
            key={index}
            icon={item.icon}
            title={item.title}
            description={item.description}
            marginTop={item.top}
          />
        ))}
      </ul>
    </section>
  );
}

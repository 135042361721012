import Divider from "../components/layout/Divider";

const TosService = () => (
  <section className="flex flex-col justify-center w-full pt-[40px] xl:pt-40 px-[24px] xl:px-60">
    <h2 className="font-semibold text-left text-[24px] xl:text-[40px] text-black">
      Terms & Conditions
    </h2>

    <div className="text-left">
      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black mt-[24px] xl:mt-[72px]">
        1. Purpose
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        These Terms of Service are intended to define the rights, obligations,
        and responsibilities of both the company, DoubleMe Corp. (hereinafter
        referred to as the “Company”), and its members in relation to the use of
        all services provided by the Company, as well as other necessary matters
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        2. Definitions
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        The main terms used in these Terms of Service are defined as follows:
      </p>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            ‘Service’ refers to any service provided by the Company that the
            ‘User’ can utilize, regardless of the device implemented (including
            various wired and wireless devices such as PCs, TVs, portable
            devices, etc.).
          </li>
          <li>
            ‘User’ denotes both ‘Individual Members’ and ‘Non-members’ who
            receive the services provided by the Company in accordance with
            these Terms.
          </li>
          <li>
            ‘Individual Member’ means a person who has provided personal
            information to the Company for membership registration and can
            continually receive information from the Company and use the
            services offered by the Company.
          </li>
          <li>
            ‘Non-member’ refers to an individual who uses the services provided
            by the Company without registering for membership.
          </li>
          <li>
            ‘ID’ implies a combination of characters or a mix of characters and
            numbers determined by the member for identification and service
            usage, which is approved by the Company.
          </li>
          <li>
            ‘Password’ indicates a combination of characters (including special
            characters) and numbers set by the member to confirm the
            authenticity of the corresponding ID and to protect its
            confidentiality.
          </li>
          <li>
            ‘Paid Service’ pertains to any service that the Company provides for
            a fee.
          </li>
          <li>
            ‘Payment’ is the act of selecting a payment method and entering
            financial information by the member to utilize the Paid Service
            provided by the Company.
          </li>
          <li>
            ‘Coin’ refers to points granted based on a notified accrual rate
            when a member purchases the Company’s paid service or points
            acquired by a member in relation to marketing activities, events
            hosted by the Company or its affiliated companies, and other similar
            initiatives.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        3. Supplementary Rules to the Terms
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        For matters not specified in these Terms of Service, the provisions of
        laws and regulations, or individual terms of service, operational
        policies, and rules, etc., established by the Company (hereinafter
        referred to as “Detailed Guidelines”) shall apply. In the event of a
        conflict between these Terms of Service and the Detailed Guidelines, the
        Detailed Guidelines shall prevail.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        4. Effectiveness and Modification of the Terms
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            These terms are posted and disclosed on all internet services
            provided by the company “Company.” The Company can amend these terms
            within the limits that do not violate the laws related to this
            service such as the ‘Act on Consumer Protection in Electronic
            Commerce, etc. (hereinafter referred to as the ‘E-commerce Act’),’
            ‘Act on the Regulation of Terms and Conditions (hereinafter referred
            to as the ‘Terms Regulation Act’),’ and the ‘Act on Promotion of
            Information and Communications Network Utilization and Information
            Protection, etc. (hereinafter referred to as the ‘Information
            Communications Network Act’).’ When the terms are changed, the
            Company will specify the contents of the amended terms and their
            effective date and will announce them from at least 7 days prior to
            the effective date (30 days for significant or disadvantageous
            changes to users) until a reasonable period after the effective
            date. Existing users will be individually notified of the amended
            terms, the application date, and the reason for the change
            (including an explanation of significant changes) by separate
            electronic means (e-mail, text message, electronic note delivery
            within the service, pop-up notification, etc.). The amended terms
            will take effect from the announced or notified effective date.
          </li>
          <li>
            When the Company notifies or announces the revised terms according
            to Paragraph 1, it will also notify that ‘If you do not agree with
            the changes, you can terminate the contract within 7 days (or 30
            days in case of significant or disadvantageous changes to users)
            from the date of notice or receipt. If you do not express your
            intention to terminate the contract, you are deemed to have agreed
            to the changes.’
          </li>
          <li>
            If the user does not express rejection of the amended terms within 7
            days (or 30 days in case of significant or disadvantageous changes
            to users) from the date of notice or receipt, they are deemed to
            have consented to the changes in the terms.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        5. Notification to Users
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            Unless otherwise specified in these Terms, the Company may notify
            users via electronic means such as email, text message (SMS),
            electronic notes, and push notifications.
          </li>
          <li>
            For notifications to all users, the Company may replace the
            notification method mentioned in Paragraph 1 by posting on the
            bulletin board within the website operated by the Company for more
            than 7 days. However, for matters that have a significant impact
            related to an individual user’s transaction, the Company will
            provide individual notification as per Paragraph 1.
          </li>
          <li>
            If individual notifications are challenging due to the user not
            providing contact details, failing to update after changes, or
            providing incorrect information, a public announcement as per the
            previous paragraph will be considered as an individual notification.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        6. Conclusion of the Service Agreement
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        The service agreement is concluded in the following cases:
      </p>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            When a user wishes to register as a member, the user agrees to the
            terms and conditions and applies for membership. The agreement is
            concluded when the Company accepts this application.
          </li>
          <li>
            For services that can be used without member registration, the
            agreement is concluded when the user pays for using the Company’s
            services without applying for membership.
          </li>
          <li>
            For services that can be used without member registration and
            without a membership application, when the user wishes to use the
            free service and proceeds with the procedures in Paragraphs 1 and 2
            above while using additional services related to saving details for
            the free service, the agreement is concluded.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        7. Acceptance of Membership Registration
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            The Company principally accepts the request for the service
            agreement.
          </li>
          <li>
            Notwithstanding the previous clause, the Company may withhold,
            reject, or restrict membership registration in the following cases:
            <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc flex flex-col gap-1">
              <li>
                If the applicant has previously lost membership under these
                terms and conditions (exceptions are made if the applicant has
                obtained approval for re-registration from the Company).
              </li>
              <li>If not using a real name or impersonating another person.</li>
              <li>
                If the applicant omits or falsely provides mandatory information
                set by the Company.
              </li>
              <li>
                If approval is impossible due to the applicant’s fault or if the
                applicant violates other operating principles set by the Company
                or these terms and conditions.
              </li>
              <li>
                If registered as a credit delinquent for PC communication and
                internet services under the Credit Information Use and
                Protection Act.
              </li>
              <li>
                If registered as a malicious user for PC communication and
                internet services with the Information and Communication Ethics
                Committee.
              </li>
              <li>
                If attempting to use an ID that is already in use or that is
                offensive to public morals.
              </li>
            </ul>
          </li>
          <li>
            In accordance with Clause 1, the Company may request real-name and
            identity verification through a specialized institution if necessary
            for providing the service.
          </li>
          <li>
            The Company may withhold approval if there is no available
            service-related equipment, or there are technical or
            business-related issues.
          </li>
          <li>
            If the Company does not accept or withholds service usage according
            to Clauses 2 and 4, it will generally notify the applicant. However,
            exceptions are made if the Company cannot notify the user without
            the Company’s fault.
          </li>
          <li>
            The conclusion of the service contract is, in the case of Clause
            1(1), when the Company indicates completion of registration in the
            application process, and for Clause 1(2), when the payment
            completion is indicated.
          </li>
          <li>
            The Company may differentiate users based on grades, distinguishing
            usage time, frequency, service menu, etc., according to the
            Company’s policy.
          </li>
          <li>
            The Company can impose usage restrictions or grade-based
            restrictions on members in compliance with laws such as the
            “Promotion of Films and Video Materials Act” and the “Youth
            Protection Act”.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        8. Change of Member Information
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            Members can access and modify their personal information at any time
            through the personal information management screen. However, details
            necessary for service management, such as real names and IDs, cannot
            be modified.
          </li>
          <li>
            If the details provided during the membership registration change,
            the member must update them online or notify the Company of the
            changes via email or other methods.
          </li>
          <li>
            Any disadvantages caused by not informing the Company of the changes
            as mentioned in Clause 2 shall be the responsibility of the member.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        9. Management and Protection of Member Information
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            The responsibility for managing a member’s ID and password lies with
            the member, and it should not be allowed for use by any third party.
          </li>
          <li>
            The Company can restrict the use of a member’s ID if there’s a risk
            of personal information leakage, if it’s against social norms or
            public decency, or if there’s a possibility of being mistaken for
            the Company or the service operator.
          </li>
          <li>
            If a member realizes that their ID and password have been stolen or
            are being used by a third party, they must immediately notify the
            Company and follow the given instructions.
          </li>
          <li>
            In the case of Clause 3, if the member does not notify the Company
            or notifies the Company but does not follow the Company’s guidance,
            the Company will not be responsible for any disadvantages incurred.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        10. Company’s Obligation
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            The Company shall promptly repair or restore any malfunction or
            destruction of facilities to provide continuous and stable services.
            However, under the following circumstances, the Company may
            temporarily suspend the provision of all or part of its services
            without prior notice. In such cases, the Company will notify users
            of the reasons and duration of the suspension as soon as possible.
            <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc flex flex-col gap-1">
              <li>
                When urgent system maintenance, expansion, replacement,
                maintenance or construction is deemed necessary.
              </li>
              <li>
                When the Company deems it necessary to replace the system in
                order to provide a new service.
              </li>
              <li>
                When the provision of regular services becomes impossible due to
                system or other service equipment failures, wireless and wired
                network failures, etc.
              </li>
              <li>
                In case of national emergencies, power outages, or events beyond
                the Company’s control.
              </li>
            </ul>
          </li>
          <li>
            The Company strives to provide convenience to users regarding
            contract-related procedures, such as the conclusion of a usage
            agreement, changes in contractual terms, and termination.
          </li>
          <li>
            The Company shall post the representative’s name, business name,
            address, telephone number, fax number, telemarketing business
            registration number, terms of use, and privacy policy on the initial
            screen of the online service so that users can easily access this
            information.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        11. Protection of Personal Information
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            The Company places great importance on the personal information of
            users and strives to comply with the Act on Promotion of Information
            and Communications Network Utilization and Information Protection,
            Personal Information Protection Act, and other relevant regulations.
            Through its privacy policy, the Company informs users how their
            personal information is being used, the purposes and methods of its
            use, and what measures are being taken to protect personal
            information.
          </li>
          <li>
            If a user does not use the service for a continuous period of 1 year
            from the last usage date, the Company may, in accordance with the
            provisions of the ‘Personal Information Protection Act’ and its
            enforcement decree, separate and store the user’s information from
            other user’s personal information. Personal information stored
            separately in this manner will be retained until the user requests
            membership withdrawal or deletion of personal information.
          </li>
          <li>
            The Company applies relevant laws and the Company’s personal
            information processing policy regarding the protection and use of
            user’s personal information. However, the Company’s privacy policy
            does not apply to external web pages linked from websites operated
            by the Company.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        12. Duties of Users
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            When users apply for registration through user registration, they
            must fill out the application form based on facts. If a user
            registers false information or information of someone else, they
            cannot claim any rights against the Company, and the Company is not
            responsible for any damages caused by such actions.
          </li>
          <li>
            Users must comply with matters stipulated in these terms, various
            regulations set by the Company, and announcements made by the
            Company. Moreover, users must not engage in activities that
            interfere with the Company’s operations or defame the Company’s
            reputation.
          </li>
          <li>
            If there are changes in user information, such as address, contact
            details, or email addresses, users must promptly modify this online.
            The user will be responsible for any issues arising from not
            updating or delaying the update of this information.
          </li>
          <li>
            Users are responsible for managing their assigned IDs and passwords.
            The Company is not responsible for issues arising from the user’s
            negligence in managing these credentials.
          </li>
          <li>
            When users choose an ID, nickname, or any other names used within
            the service, they must not engage in the following:
            <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc flex flex-col gap-1">
              <li>
                Impersonating the official operators of the service provided by
                the Company or using a name that could confuse other users.
              </li>
              <li>Using a name containing explicit or obscene content.</li>
              <li>
                Using a name that might infringe on third-party rights, such as
                trademark or copyright.
              </li>
              <li>
                Using a name that could defame a third party or interfere with
                their operations.
              </li>
              <li>
                Using a name containing antisocial content or content that
                violates relevant laws.
              </li>
            </ul>
          </li>
          <li>
            Without the explicit consent of the Company, users may not transfer,
            gift, use as collateral, or undertake any disposition actions
            regarding their service usage rights or any other status under the
            user agreement.
          </li>
          <li>
            Detailed matters related to this article, such as precautions
            regarding service use, will be determined by the operational policy.
            If users violate the terms of service or operational policies, they
            may face service restrictions and bear civil and criminal
            responsibilities, leading to disadvantages.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        13. Provision of Service
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            The principle of the Company’s service provision is 24 hours a day,
            365 days a year. However, temporary interruptions in the provision
            of all or part of the service may occur due to special reasons such
            as maintenance checks for the company’s system, replacement of
            communication equipment, etc.
          </li>
          <li>
            Detailed guidance on individual services provided by the Company can
            be checked on the screen of each service.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        14. Limitation or Suspension of Services
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            In the event of war, civil unrest, natural disasters or any national
            emergencies, or the potential occurrence of such events, or if there
            are unavoidable circumstances such as a telecommunication service
            provider suspending its services under the Telecommunications
            Business Act, the Company may limit or suspend all or part of its
            services.
          </li>
          <li>
            Regardless of the provisions of the preceding paragraph, free
            services may be restricted or suspended in whole or in part due to
            the Company’s operational policies and other reasons, and may be
            converted to paid services.
          </li>
          <li>
            When the Company restricts or suspends the use of the service, it
            will promptly notify users of the reasons, duration of restriction,
            and scheduled time, if any.
          </li>
          <li>
            If the Company intends to convert a service provided for free into a
            paid service after obtaining prior payment information, it will
            notify users of the reasons and the scheduled date for the
            conversion to paid service and will obtain user’s consent for the
            conversion to a paid service.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        15. Provision of Information and Posting of Advertisements
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            The Company may provide (or transmit) various information and
            advertisements deemed necessary during the user’s use of the service
            through methods such as banner placements, email, mobile messages,
            phone calls, and postal mail. However, users who do not wish to
            receive these can refuse reception according to the method provided
            by the Company.
          </li>
          <li>
            Even if a user opts out of receiving information, in case of changes
            to the Terms of Service, Privacy Policy, or other matters that could
            impact the user’s interests, or matters that the user must be made
            aware of as stipulated in the “Act on the Promotion of Information
            and Communication Network Utilization and Information Protection”,
            information can still be provided through methods like email.
          </li>
          <li>
            If a user refuses reception as per the exception in paragraph 1 and
            the Company fails to deliver transaction-related information,
            answers to inquiries about use, etc., the Company bears no
            responsibility for such non-delivery.
          </li>
          <li>
            In accordance with the enforcement regulations of the “Information
            and Communications Network Act”, the Company verifies every 2 years
            whether users consent to receiving advertisement information for
            commercial purposes.
          </li>
          <li>
            The Company does not assume responsibility for any loss or damages
            arising from user participation in promotional activities of
            advertisers or as a result of transactions.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        16. Coins and More
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            When members purchase a paid service and make the corresponding
            payment, or in relation to events hosted by the company or its
            affiliated companies, additional accumulation services, and other
            marketing activities, they are awarded “coins” in accordance with
            the company’s coin accumulation policy.
          </li>
          <li>
            The company allows users to utilize “coins” as a payment method for
            purchasing paid services, defining the target, method, and period of
            their use. The specifics of coin usage may vary according to the
            company’s operational policies.
          </li>
          <li>
            The company can display or notify details such as the purpose,
            method, duration, and discount amounts for “coin” usage separately
            on the service screen.
          </li>
          <li>
            “Coins” cannot be exchanged for cash. Coins will expire when their
            usage period ends, upon purchase cancellation, or at the conclusion
            of a usage agreement.
          </li>
          <li>
            The company does not offer interest to members on the amount charged
            to “coins”.
          </li>
          <li>
            The validity period of the “coins” is one year from the last day a
            member used the coin. After this period, the “coin” will
            automatically expire. However, if the company sets a separate
            validity period for “coins” that are given to members for free or
            for a fee, each “coin” can be used within its specific validity
            period, and “coins” will expire automatically after their respective
            periods.
          </li>
          <li>
            “Coins” provided to members by the company for free cannot be
            refunded.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        17. Payment for Paid Services
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            When utilizing paid services provided by the company, users are
            primarily required to make a payment. The payment methods for these
            services are as follows:
            <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc flex flex-col gap-1">
              <li>
                Various card payments such as prepaid cards, debit cards, credit
                cards, etc.
              </li>
              <li>Payments using ARS, mobile phones, etc.</li>
              <li>
                Various bank transfers like phone banking, internet banking, and
                online direct deposit.
              </li>
            </ul>
          </li>
          <li>
            The company can verify whether the user holds the legitimate rights
            to the payment method. Transactions may be halted until this
            verification is complete or canceled if verification is
            unattainable.
          </li>
          <li>
            Depending on the company’s policy and standards of the payment
            service provider (e.g., mobile carriers, card companies) and the
            payment agency, there may be monthly cumulative payment limits and
            top-up limits per user. Exceeding these standards can prevent
            further use of the paid services.
          </li>
          <li>
            Users bear the responsibility for the information they provide for
            payment or settlement.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        18. Refunds
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            The following procedures apply when users cancel or seek a refund
            due to their reasons:
            <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc flex flex-col gap-1">
              <li>
                Services consumed or purchased in a single transaction cannot be
                refunded.
              </li>
              <li>
                For subscription-based services, users can continue to avail the
                benefits until the end of the payment cycle. Refunds are not
                provided for the period between the cancellation of a monthly
                payment and the end of that service month. No refunds or credits
                are given once any portion of the payment period has passed.
              </li>
            </ul>
          </li>
          <li>
            Despite the above stipulations, a full refund is provided in the
            following scenarios:
            <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc flex flex-col gap-1">
              <li>No service usage after payment completion.</li>
              <li>
                Inability to use the service due to company-related issues or
                service failures.
              </li>
              <li>Non-provision of the purchased service.</li>
              <li>
                Significant discrepancies between the service and its
                description or advertising.
              </li>
              <li>
                Service being substantially unusable due to its inherent
                defects.
              </li>
            </ul>
          </li>
          <li>
            Refunds are primarily made using the same method as the initial
            payment. However, if it’s not possible to refund through the
            original method, the company will use a different method specified
            in their individual service policies.
          </li>
          <li>
            The company begins the refund process within 3 business days from
            when the obligation arises. However, if a user’s cooperation is
            required for the refund and the refund is delayed due to the user’s
            fault, the company isn’t liable for any interest due to the delay.
          </li>
          <li>
            Any costs associated with the refund process are borne by the party
            at fault.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        19. Ownership of Rights
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            The intellectual property rights, including copyrights, related to
            the services provided by the company, belong to the company.
          </li>
          <li>
            The company grants users the right to use the services according to
            the terms set by the company. Users cannot transfer, sell, or use
            these rights as collateral.
          </li>
          <li>
            Notwithstanding the provisions of the first clause, intellectual
            property rights of content created directly by users and copyrighted
            material provided under the company’s affiliate agreements do not
            belong to the company.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        20. Management of Content
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            If the content created or developed by a member contains elements
            that violate relevant laws such as the ‘Personal Information
            Protection Act’ and the ‘Copyright Act’, an administrator can
            request the suspension or deletion of such content following the
            procedures stipulated by the relevant laws. The company must then
            take actions in accordance with these laws.
          </li>
          <li>
            Even in the absence of a request from the rights holder as per the
            previous clause, if there are reasons to believe that there has been
            an infringement of rights, or if it otherwise violates the company’s
            policies and relevant laws, the company may take interim measures
            against the content in accordance with the applicable laws.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        21. Copyright of Content
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            Users shall not reproduce, transmit, publish, distribute, broadcast,
            or use in any other way the information obtained while using the
            “Service”, which is copyrighted or otherwise subject to intellectual
            property rights of the “Company”, without prior consent from the
            “Company”. They also must not allow third parties to use such
            information.
          </li>
          <li>
            Users grant the “Company” permission to use the copyrighted works
            they provide to the “Company” for the purpose of using the “Service”
            for the following objectives:
            <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc flex flex-col gap-1">
              <li>Acts to provide the “Service” upon the user’s request.</li>
              <li>
                Acts to utilize for the improvement of the “Service” including
                artificial intelligence learning.
              </li>
              <li>
                Acts that allow media, telecommunications providers, and others
                to broadcast or report the content of the copyrighted work for
                the purpose of promoting the company’s services.
              </li>
              <li>
                Acts where the “Company” uses the copyrighted works in its other
                services.
              </li>
            </ul>
          </li>
          <li>
            If the “Company” wishes to use the copyrighted works that the user
            has provided for using the “Service” for purposes other than those
            specified in the preceding paragraph, it shall obtain the user’s
            consent in advance through methods such as phone calls or e-mails.
          </li>
          <li>
            The “Company” has the right to establish and implement a separate
            copyright policy to define the rights related to the copyrighted
            results (including 3D asset data) produced and derived from the
            user’s utilization of the “Service”. The “Company” can publish this
            copyright policy on the “Companys” website or through web/apps so
            that users can easily access and review it.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        22. Community Guidelines
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        Our goal in creating these rules is to accommodate the broadest range of
        self-expression while balancing the need for TwinWorld users to be able
        to use our service safely and enjoyably.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        Do not send messages to people who do not want to receive messages which
        are especially mean.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        Be mindful of what you send in a message and who you send it to. It is
        okay with us if someone takes a screenshot, but we cannot speak for you
        or your friends. TwinWorld attempts to detect screenshots and notify the
        sender, but it does not always work perfectly - and your friends can
        always capture an image with a camera.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        Keep it legal. Do not use TwinWorld for any illegal shenanigans, and if
        you are messaging with someone who might be, you should not view or send
        the following contents.
      </p>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc flex flex-col gap-2">
          <li>Pornography</li>
          <li>
            Nudity or sexually suggestive content involving minors (people under
            18 years old)
          </li>
          <li>
            Minors engaged in activities that are physically dangerous and
            harmful
          </li>
          <li>Invasions of privacy</li>
          <li>Threats</li>
          <li>Harassment or Bullying</li>
          <li>Impersonation</li>
          <li>Self-Harm</li>
        </ul>
      </div>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        Violating these rules may result in the removal of User Content,
        suspension of your account and being prohibited from using TwinWorld in
        the future. Please take these rules seriously and respect them in the
        spirit in which they are intended. The rules will change and evolve
        along with the TwinWorld user community.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        We will do our best to enforce them consistently and fairly, and
        ultimately we will try to do what we think is best in each situation, at
        our own discretion.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        23. Disclaimer
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            Users may be exposed to content that is unpleasant, sexually
            explicit, defamatory, or potentially objectionable while using the
            service. By accessing and utilizing the service, users agree to
            accept these risks. Content displayed on the company’s website or
            service may not reflect the opinions of the “Company”, and the
            “Company” does not endorse or support any content posted by the
            users of the service.
          </li>
          <li>
            The “Company” takes reasonable measures to ensure the protection of
            user content. However, it cannot guarantee 100% prevention of all
            risks that may arise from the exposure of such content.
          </li>
          <li>
            If the “Company” wishes to use the copyrighted works that the user
            has provided for using the “Service” for purposes other than those
            specified in the preceding paragraph, it shall obtain the user’s
            consent in advance through methods such as phone calls or e-mails.
          </li>
          <li>
            Disclaimer: The services, website, apps, content, and all other
            materials provided by the “Company” are offered to the users “as is”
            and without any explicit, implicit, or legal warranties, to the
            fullest extent permitted by applicable law. The “Company” does not
            warrant that: (1) the services and materials will meet the user’s
            requirements, will be uninterrupted, timely, secure, or error-free;
            (2) the results obtained from the use of the website, services, or
            materials will be effective, accurate, or reliable; (3) any errors
            or defects in the website, services, or materials will be promptly
            corrected.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        24. Limitation of Liability
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        The “Company”, its suppliers, and their respective executives,
        directors, employees, representatives, and agents shall not be liable
        for (1) the use or inability to use the service, (2) the provision of
        the service and the materials accessible through the service, and (3)
        any indirect, special, punitive, incidental, or consequential damages
        resulting from the actions of service users, unless caused by the gross
        negligence or intentional misconduct of the Company.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        25. Indemnification of the Company
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        Users agree, to the extent permitted by law, to defend, indemnify, and
        hold harmless the Company, its affiliates, directors, officers,
        shareholders, employees, users, and agents from and against all claims,
        liabilities, damages, costs, and expenses, including attorney’s fees and
        all other costs allowed by law, arising out of or related to:
      </p>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>The use of “User Content” by the Company or third parties;</li>
          <li>
            The user’s use of Twinworld and activities related to Twinworld;
          </li>
          <li>
            Any allegations or violations of laws, rules, and regulations
            related to the user’s use of Twinworld or activities related to
            Twinworld;
          </li>
          <li>
            All claims alleging that the “User Content” infringes, violates, or
            misappropriates any copyrights, trademarks, trade secrets, designs,
            trade dress, patents, rights of publicity, privacy rights,
            confidentiality obligations, or any other third-party rights;
          </li>
          <li>
            The use of products or services provided by third parties related to
            the development and submission of the user’s “User Content”;
          </li>
          <li>
            Any misrepresentations, fraudulent activities, etc., conducted by
            the user;
          </li>
          <li>
            Any breach or alleged breach of the user’s representations,
            warranties, or obligations, including allegations of violation of
            these terms and conditions.
          </li>
        </ul>
      </div>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        The Company reserves the right, at its own expense, to exclusively
        defend and control any matters that the user is required to indemnify,
        and in such cases, the user agrees to cooperate with the Company in
        defending such claims.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        26. Termination
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            Users can terminate their use of the service at any time by opting
            to withdraw from the service. Upon termination of service usage, the
            following will occur:
            <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc flex flex-col gap-1">
              <li>
                The user’s Twinworld account will be deleted, and personal
                information will be deleted in accordance with the Personal
                Information Protection Act and other related laws.
              </li>
              <li>
                The assets held by the user within Twinworld will be
                extinguished. Users must obtain necessary refunds before
                terminating the service. If a user voluntarily withdraws without
                requesting a refund, the company is not responsible for recovery
                or refunds.
              </li>
              <li>
                After 30 days of a user’s withdrawal from the service, all
                records and related information of the user’s activities within
                the service will be deleted (items, customization, purchase
                history, linked services, etc.).
              </li>
            </ul>
          </li>
          <li>
            The company can add, modify, or change all or part of the service at
            any time for the operation or improvement of the service.
            Additionally, the company may temporarily or permanently discontinue
            the provision of the service or introduce new restrictions. If all
            or part of a service provided for free is modified, changed, or
            terminated, no compensation will be provided unless specified by
            relevant laws.
          </li>
          <li>
            Even if the use of a user’s account is terminated, payment
            obligations incurred by the user before termination remain valid
            (e.g., payments for paid services purchased before termination).
          </li>
          <li>
            In cases where the service undergoes significant modifications,
            changes, or is discontinued or terminated, the company will make
            reasonable efforts to notify in advance if foreseeable, and post
            facto if unpredictable. In the event of service discontinuation or
            termination, the company will cooperate to facilitate the recovery
            of user content.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        27. Dispute Resolution
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            Unless otherwise specified in writing, this “Terms of Use” is
            governed by and construed in accordance with the laws of the
            Republic of Korea. In the event of a dispute between the “Company”
            and the user, jurisdiction will be based on the user’s address at
            the time of filing, and in the absence of an address, the
            jurisdiction will be exclusively conferred to the district court
            that has jurisdiction over the user’s residence. However, if the
            user’s address or residence is unclear at the time of filing, the
            competent court will be determined according to the Civil Procedure
            Act.
          </li>
          <li>
            For users with addresses or residences overseas, notwithstanding the
            preceding paragraph, any litigation concerning disputes arising
            between the “Company” and the user shall fall under the jurisdiction
            of the Seoul Central District Court in the Republic of Korea.
          </li>
          <li>
            Exemption Clause: Both the “Company” and the user deny rights and
            responsibilities under the UN Convention on Contracts for the
            International Sale of Goods, and agree not to apply it in the use of
            this service.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        28. Compensation for Damages
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            The Company or the user may claim compensation for damages when
            damage occurs due to the fault of the other party. However, the
            Company shall not be liable for damages resulting from defects,
            discontinuation of provision, destruction or deletion of stored
            data, alteration, etc., of free services.
          </li>
          <li>
            With respect to the use of the services provided by the Company,
            unless it violates the Company’s operational policies, personal
            information protection policies, and other service-specific terms of
            use, the Company shall not be liable for any damages.
          </li>
        </ul>
      </div>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text-[28px] text-black">
        Supplementary Provision
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-decimal flex flex-col gap-2">
          <li>
            These terms and conditions shall be effective from October 20, 2023.
          </li>
        </ul>
      </div>
    </div>
  </section>
);
export default TosService;

import { useState } from "react";

type Props = {
  like: boolean;
  isDesktop?: boolean;
  onClick: () => void;
};

export default function FavoriteButton({
  like = false,
  onClick,
  isDesktop = true,
}: Props) {
  const [isHover, setIsHover] = useState(false);

  const checkFillState = () => {
    if (like) {
      return true;
    }

    if (!isDesktop) {
      return false;
    }

    if (isHover) {
      return true;
    }

    return false;
  };

  return (
    <button
      type="button"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
    >
      <svg
        width={isDesktop ? "32" : "28"}
        height={isDesktop ? "33" : "28"}
        viewBox={"0 0 32 33"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8811 10.0568L14.7773 1.79541C15.2774 0.734863 16.7225 0.734863 17.2226 1.79541L21.1189 10.0568L29.8322 11.3897C30.9502 11.5608 31.3957 12.9991 30.5864 13.8241L24.2825 20.2502L25.7702 29.3286C25.9613 30.4944 24.7921 31.3834 23.7917 30.8328L16 26.5442L8.2082 30.8328C7.2079 31.3834 6.03864 30.4944 6.22968 29.3286L7.71737 20.2502L1.41363 13.8241C0.604258 12.9991 1.04983 11.5608 2.16778 11.3897L10.8811 10.0568Z"
          fill={checkFillState() ? "#FFE600" : "#DFDFDF"}
          stroke={checkFillState() ? "black" : "none"}
          strokeWidth={checkFillState() ? "2" : "0"}
          strokeLinecap={checkFillState() ? "round" : undefined}
          strokeLinejoin={checkFillState() ? "round" : undefined}
        />
      </svg>
    </button>
  );
}

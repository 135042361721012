import { useParams } from "react-router-dom";

import Loading from "../components/common/Loading";
import MainWrapper from "../components/common/MainWrapper";
import SpaceGroupList from "../components/common/list/SpaceGroupList";
import MobileSpaceDetail from "../components/mobile/MobileSpaceDetail";
import { SpaceDetail } from "../components/space/SpaceDetail";
import { useSpaceActions } from "../domains/space/hook/useSpaceActions";
import useDeviceBreakPoint from "../hooks/useDeviceBreakPoint";

export const ASpace = () => {
  const spaceId = useParams().spaceId || "";

  const { useSpaceList, useSpaceDetails, useCreatorSpaceList } =
    useSpaceActions();
  const { spaceDetails } = useSpaceDetails(spaceId);

  const { data: trendSpaceList, isPending } = useSpaceList({
    type: "trending",
    take: 5,
    except: spaceId,
  });

  const { data: creatorSpaceList, isPending: isCratorSpaceListLoading } =
    useCreatorSpaceList({
      take: 5,
      creatorId: spaceDetails && spaceDetails!.creatorId,
    });

  const { isDesktop } = useDeviceBreakPoint();

  if (isPending || isCratorSpaceListLoading)
    return (
      <div className="h-screen">
        <div className="flex w-full h-full">
          <Loading />
        </div>
      </div>
    );

  return (
    <MainWrapper>
      {isDesktop ? (
        <SpaceDetail spaceId={spaceId} />
      ) : (
        <MobileSpaceDetail spaceId={spaceId} />
      )}

      <div className="xl:mt-[125px] flex flex-col gap-[46px] xl:gap-24 w-full">
        {spaceDetails && creatorSpaceList && (
          <SpaceGroupList
            title={`Creator: ${spaceDetails.spaceCreator}`}
            moreButtonLink={`/user/${spaceDetails.creatorId}`}
            list={creatorSpaceList.pages[0]}
            navigateState="spaces"
          />
        )}
        {trendSpaceList && (
          <SpaceGroupList
            title="Trending"
            moreButtonLink="/spaces?type=trend"
            list={trendSpaceList}
          />
        )}
      </div>
    </MainWrapper>
  );
};

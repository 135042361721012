import Sequence from "./Sequence";
import Value01Mask from "./Value01Mask";

export default function Value01() {
  return (
    <section className="relative px-[60px]">
      <div className="absolute flex flex-col gap-10 2xl:gap-12 3xl:gap-14 bottom-0 t-[75px]">
        <h3 className="text-4xl 2xl:text-[45px] 3xl:text-[50px] font-semibold leading-tight w-[845px]">
          Chat with Global <br /> Twinworld Friends in Reality
        </h3>
        <p className="text-[15px] 2xl:text-[18px] 3xl:text-[20px] leading-6 2xl:leading-8 3xl:leading-9 w-[600px] 2xl:w-[720px] 3xl:w-[815px] text-black/70">
          TwinWorld facilitates real-time conversations with friends from around
          the
          <br />
          world, transcending the boundaries between reality and virtuality.
          <br />
          This platform offers an immersive experience that feels as if you are
          actually
          <br />
          together through avatars.
        </p>
      </div>
      <div className="relative">
        <Sequence index="01" position="right" />
        <Value01Mask />
      </div>
    </section>
  );
}

export default function YoutubeIcon({ isHover }: { isHover: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
    >
      <g clipPath="url(#clip0_1_1397)">
        <path
          d="M16.9998 33.3474C13.1507 33.3496 9.41931 32.0201 6.43889 29.5843C3.45847 27.1486 1.41257 23.7566 0.64836 19.9841C-0.115849 16.2116 0.448701 12.2909 2.24621 8.88726C4.04373 5.48361 6.96348 2.80671 10.51 1.31077C14.5845 -0.410542 19.176 -0.442767 23.2742 1.22119C27.3725 2.88515 30.6419 6.10898 32.3632 10.1835C34.0845 14.258 34.1167 18.8494 32.4528 22.9477C30.7888 27.0459 27.565 30.3154 23.4905 32.0367C21.4372 32.9059 19.2295 33.3517 16.9998 33.3474ZM16.972 10.56C16.935 10.56 13.2158 10.56 10.7592 10.7453L10.6462 10.7573C10.0126 10.7819 9.41398 11.0539 8.97883 11.5151C8.60005 12.0496 8.35802 12.6689 8.2739 13.3186C8.16404 14.2952 8.10499 15.2769 8.09698 16.2597V17.6389C8.10489 18.622 8.16394 19.604 8.2739 20.5809C8.35806 21.2307 8.60008 21.8499 8.97883 22.3845C9.4392 22.8365 10.0515 23.1007 10.6962 23.1255C10.7889 23.1366 10.8704 23.1468 10.9371 23.1598C12.3441 23.2978 16.9294 23.345 16.9757 23.345C17.022 23.345 20.7375 23.3367 23.1931 23.1552L23.3071 23.1431C23.9406 23.1186 24.5393 22.8465 24.9745 22.3854C25.3535 21.8509 25.5958 21.2317 25.6803 20.5818C25.7902 19.6049 25.8493 18.6229 25.8572 17.6399V16.2587C25.8493 15.276 25.7902 14.2943 25.6803 13.3177C25.596 12.6678 25.3536 12.0486 24.9745 11.5141C24.5395 11.0527 23.9407 10.7806 23.3071 10.7564L23.1941 10.7444C20.7375 10.5591 17.0183 10.5591 16.9813 10.5591L16.972 10.56Z"
          fill="white"
          fillOpacity={isHover ? "1" : "0.4"}
        />
        <path
          d="M14.5146 19.9438V13.5605L20.5209 16.7628L14.5146 19.9438Z"
          fill="white"
          fillOpacity={isHover ? "1" : "0.4"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_1397">
          <rect
            width="33.3474"
            height="33.3474"
            fill="white"
            transform="translate(0.326172)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

import ImaginableList from "./ImaginableList";

export default function Anything() {
  return (
    <section className="flex flex-col items-center mt-60">
      <h3 className="mb-[50px] 2xl:mb-[60px] 3xl:mb-[70px] font-semibold leading-none text-[110px] 2xl:text-[180px] 3xl:text-[250px]">
        Anything
      </h3>
      <p className="text-[30px] 2xl:text-[35px] 3xl:text-[40px] font-semibold leading-snug text-center mb-[72px] 2xl:mb-[90px] 3xl:mb-[108px]">
        Anything you can imagine is
        <br />
        possible in TwinWorld
      </p>
      <ImaginableList />
    </section>
  );
}

import { useNavigate, useParams } from "react-router-dom";

type ErrorInfo = {
  [key: string]: {
    title: string;
    message: string;
  };
};

const Error = () => {
  const navigate = useNavigate();

  const errorCode: string = useParams().errorCode || "404";

  const defaultErrorInfo = {
    title: "Sorry, Something went wrong.",
    message: "An unexpected error has occurred.",
  };

  const errorInfo: ErrorInfo = {
    "400": {
      title: "bad request",
      message: "Sorry, your request resulted is an error.",
    },
    "401": {
      title: "authorization error",
      message: "Sorry, your request could not be processed.",
    },
    "404": {
      title: "Not found",
      message: "Sorry, this page isn’t available now.",
    },
    "500": {
      title: "Internal server error",
      message: "Sorry, this page isn’t working now.",
    },
  };

  return (
    <div>
      <div className="flex flex-col items-center w-full h-full">
        <div className="mt-[40px]">
          <picture>
            <source
              type="image/webp"
              srcSet="https://s3.ap-northeast-2.amazonaws.com/twinworld-2.0-bucket/resource/image/error_page.webp"
            />
            <img
              src="https://s3.ap-northeast-2.amazonaws.com/twinworld-2.0-bucket/resource/image/error_page.png"
              alt=""
              className="ml-auto mr-auto"
            />
          </picture>
        </div>

        <div className="font-semibold text-[100px] leading-none text-twinworldGray-680 w-295 mt-[30px] xl:-mt-[20px]">
          {errorCode}
        </div>

        <p className="font-semibold text-[40px] text-twinworldGray-680 -mt-[10px]">
          {errorInfo[errorCode]
            ? errorInfo[errorCode].title
            : defaultErrorInfo.title}
        </p>

        <p className="text-[16px] text-twinworldGray-680 pb-10 mt-[15px]">
          {errorInfo[errorCode]
            ? errorInfo[errorCode].message
            : defaultErrorInfo.message}
        </p>

        <button
          type="button"
          className="py-3 font-medium border-2 border-black rounded-full px-11 bg-twinworldPrimary-200 hover:transition-background-color hover:duration-300 hover:bg-black hover:text-twinworldPrimary-200"
          onClick={() => navigate("/")}
        >
          Go to Main Page
        </button>
      </div>
    </div>
  );
};

export default Error;

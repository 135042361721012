export default function SelectDropdownEditIcon() {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="0.001" height="16" fill="#555555" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2939 4.79289C10.6844 4.40237 11.3176 4.40237 11.7081 4.79289C12.0986 5.18342 12.0986 5.81658 11.7081 6.20711L6.71925 11.1959C6.71559 11.1997 6.7119 11.2035 6.70816 11.2072C6.51669 11.3987 6.26689 11.4963 6.01596 11.5C5.75504 11.5039 5.49287 11.4063 5.29377 11.2072C5.28997 11.2034 5.2862 11.1996 5.28247 11.1957L0.293862 6.20711C-0.0966622 5.81658 -0.0966622 5.18342 0.293862 4.79289C0.684386 4.40237 1.31755 4.40237 1.70808 4.79289L6.00097 9.08579L10.2939 4.79289Z"
        fill="black"
      />
    </svg>
  );
}

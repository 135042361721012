import { useLocation } from "react-router-dom";

type Props = {
  label: string;
  path: string;
};

/**
 *
 * @param label: category label
 * @param path: route pathname
 * @param category: current page category
 * If router pathname include category then change background color of CategoryItem component.
 * @returns
 */
export default function CategoryItem({ label, path }: Props) {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const type = urlSearchParams.get("type");

  return (
    <div
      className={`text-[13px] leading-[14px] xl:text-[14px] px-[15px] xl:px-[26px] py-[12px] xl:py-[16px] rounded-full transition ease-in-out duration-300 font-medium border-[1.8px] xl:border-0 border-black ${
        path.includes(type!)
          ? `bg-black text-white xl:bg-twinworldBlack xl:text-twinworldPrimary-200`
          : `text-black xl:text-white bg-transparent xl:hover:bg-twinworldBlack xl:hover:text-twinworldPrimary-200 xl:hover:cursor-pointer`
      }`}
    >
      {label}
    </div>
  );
}

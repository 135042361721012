import { ModelContextProvider } from "../../contexts/ModelContext";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import Viewer3D from "../common/Viewer3D";
import MobileItemDescription from "../mobile/MobileItemDescription";
import ConversionStatusThumbnail from "../thumbnail/ConversionStatusThumbnail";

import ItemDescription from "./ItemDescriptions";
import { ItemDetailsInfo } from "./type";

type ItemDetailsProps = ItemDetailsInfo & {
  userId: string;
};

export default function ItemDetails(itemDetailsData: ItemDetailsProps) {
  const { isDesktop } = useDeviceBreakPoint();

  return (
    <ModelContextProvider>
      <div className="flex flex-col w-full gap-2 xl:flex-row xl:gap-20 px-[18px] md:portrait:px-[32px] md:landscape:px-[58px] xl:portrait:px-0 xl:landscape:px-0">
        {!isDesktop && (
          <div className="font-semibold text-[24px] leading-[30px] text-black overflow-hidden break-words mb-[16px]">
            {itemDetailsData.name}
          </div>
        )}

        {itemDetailsData.conversionStatus === "success" ? (
          <Viewer3D
            id={itemDetailsData.itemId}
            renderFileUrl={itemDetailsData.itemUrl}
            size={
              "w-full xl:w-[700px] h-[258px] md:h-[456px] lg:h-[616px] xl:h-[522px] min-w-[339px]"
            }
            rounded
          />
        ) : (
          <ConversionStatusThumbnail
            type="details"
            conversionStatus={itemDetailsData.conversionStatus}
            rounded={isDesktop ? "rounded-[25px]" : "rounded-[14px]"}
          />
        )}

        {isDesktop ? (
          <ItemDescription itemDetailsData={itemDetailsData} />
        ) : (
          <MobileItemDescription itemDetailsData={itemDetailsData} />
        )}
      </div>
    </ModelContextProvider>
  );
}

import { useEffect, useState } from "react";

export default function useDeviceBreakPoint() {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    setIsMobile(screenWidth < 768);
    setIsTablet(screenWidth >= 768 && screenWidth < 1280);
    setIsDesktop(screenWidth >= 1280);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { isMobile, isTablet, isDesktop };
}

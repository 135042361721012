export default function GuideArrow() {
  return (
    <svg
      className="animate-pulse"
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m2 2 8 8-8 8"
        stroke="#000"
        strokeOpacity=".5"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="m12 2 8 8-8 8"
        stroke="#000"
        strokeOpacity=".7"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
}

import { FieldValues, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import LineIcon from "../components/common/icons/LineIcon";
import LoginInput from "../components/common/input/LoginInput";
import useModals from "../components/hooks/useModals";
import EmailConfirmModal from "../components/modal/EmailConfirmModal";
import { UserEmailSignInInfo } from "../components/modal/interface";
import { useAuthActions } from "../domains/auth/hook/useAuthActions";
import { REGEX_FORMAT } from "../utils/regex";

type LocationState = {
  redirect: string;
};

export default function EmailSignIn() {
  const navigate = useNavigate();
  const { emailSignIn, errorMessages, setErrorMessages } = useAuthActions();
  const { openModal } = useModals();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const emailPattern = REGEX_FORMAT.EMAIL;

  const onSubmit = async (data: FieldValues) => {
    const signInInfo: UserEmailSignInInfo = {
      password: data.password,
      email: data.email,
      serviceName: "Twinworld",
    };

    const isEmail = emailPattern.test(getValues("email"));

    if (isEmail) {
      signInInfo.email = getValues("email");
    }

    await emailSignIn(signInInfo).then(() => {
      if (locationState.redirect) {
        window.location.replace(locationState.redirect);
        return;
      }

      window.location.replace("/");
    });
  };

  const isInputValid =
    Object.keys(dirtyFields).length === 2 && Object.keys(errors).length === 0;

  const handleOpenResetPwModal = () => {
    openModal(EmailConfirmModal, {});
  };

  return (
    <section className="flex flex-col items-center w-full mx-auto">
      <h2 className="text-[20px] font-semibold leading-[25px] px-[80.5px] text-center mt-[64px]">
        Sign in with Email
      </h2>
      <form
        className="flex flex-col items-center px-[18px] mt-[40px] gap-4 w-[375px] md:w-[482px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <LoginInput
          type="email"
          name="email"
          register={register}
          placeholder="Email"
          options={{
            required: true,
            maxLength: {
              value: 50,
              message: "* X Max length of 50 is allowed",
            },
            pattern: {
              value: emailPattern,
              message: "* Email address must be a valid address",
            },
          }}
          onClick={() => {
            setErrorMessages((prev) => ({
              ...prev,
              accountError: "",
            }));
          }}
          isError={!!errors?.email?.message || !!errorMessages.accountError}
        />
        {(errors?.email || errorMessages.accountError) && (
          <p className="px-[16px] text-twinworldRed text-[14px] text-left w-full flex white-space">
            {errorMessages.accountError || errors?.email?.message?.toString()}
          </p>
        )}

        <LoginInput
          type="password"
          name="password"
          register={register}
          placeholder="Password"
          options={{
            required: true,
            minLength: {
              value: 8,
              message: "* Password should be more than 8 letters.",
            },
          }}
          onClick={() => {
            setErrorMessages((prev) => ({
              ...prev,
              wrongPassword: "",
            }));
          }}
          isError={
            !!errors?.password?.message ||
            !!errorMessages.wrongPassword ||
            !!errorMessages.accountError
          }
        />
        {(errors?.password || errorMessages.wrongPassword) && (
          <p className="px-[16px] text-twinworldRed text-[14px] leading-5 text-left w-full flex white-space">
            {errorMessages.wrongPassword ||
              errors?.password?.message?.toString()}
          </p>
        )}
        <button
          type="submit"
          className="flex items-center justify-center w-[339px] py-[16px] bg-black rounded-full mt-[32px] text-[16px] text-white font-medium 
           disabled:border-twinworldGray-500 disabled:border-[1.5px] disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 disabled:font-medium disabled:cursor-not-allowed"
          disabled={!isInputValid}
        >
          Sign In
        </button>
        <div className="flex flex-row items-center mt-[24px] text-[14px] ">
          <span
            role="button"
            className="mr-6 cursor-pointer text-twinworldGray-700 active:text-black focus:text-black"
            onClick={handleOpenResetPwModal}
          >
            Forgot my Password
          </span>
          <LineIcon height={18} />
          <span
            role="button"
            className="ml-6 cursor-pointer text-twinworldGray-700 active:text-black focus:text-black"
            onClick={() => navigate("/sign-up")}
          >
            Sign up
          </span>
        </div>
      </form>
    </section>
  );
}

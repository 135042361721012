import { useState } from "react";

type Props = {
  onClick?: () => void;
};

export default function NextButton({ onClick }: Props) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className="absolute -top-[110px] lg:-top-[125px] xl:-top-[210px] right-[30px] lg:right-[43px] xl:right-[60px]"
      aria-label="space_carousel_next_button"
      type="button"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="86"
        height="85"
        viewBox="0 0 86 85"
        fill="none"
        className="w-[42px] lg:w-[58px] xl:w-[85px]"
      >
        <circle
          cx="43.3594"
          cy="42.5"
          r="42"
          transform="rotate(-180 43.3594 42.5)"
          fill={`${isHovered ? "black" : "none"}`}
          stroke="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.0846 29.8118C45.6937 29.4217 45.6937 28.7892 46.0846 28.399C46.4755 28.0089 47.1094 28.0089 47.5003 28.399L60.6674 41.5395C60.7242 41.5924 60.7747 41.6519 60.8179 41.7166C61.0783 42.1049 61.0367 42.635 60.6931 42.9779C60.6773 42.9937 60.661 43.0089 60.6444 43.0234L47.4977 56.1435C47.1068 56.5336 46.473 56.5336 46.082 56.1435C45.6911 55.7533 45.6911 55.1208 46.082 54.7307L57.5607 43.2753L27.0722 43.3322C26.5199 43.3332 26.0713 42.8864 26.0703 42.3341C26.0693 41.7818 26.5162 41.3332 27.0685 41.3322L57.5714 41.2753L46.0846 29.8118Z"
          fill={`${isHovered ? "white" : "black"}`}
        />
      </svg>
    </button>
  );
}

import useDeviceBreakPoint from "../../../../hooks/useDeviceBreakPoint";

import MobileExperienceItem from "./MobileExperienceItem";
import AddAudioIcon from "./icons/AddAudioIcon";
import CustomizationIcon from "./icons/CustomizationIcon";
import DrawObjectIcon from "./icons/DrawObjectIcon";
import MultiplayerIcon from "./icons/MultiPlayerIcon";
import ObjectIcon from "./icons/ObjectIcon";
import PhysicsIcon from "./icons/PhysicsIcon";
import RealityIcon from "./icons/RealityIcon";

const LIST = [
  {
    icon: <ObjectIcon />,
    title: (
      <div className="ml-[22px]">
        Use your Own <br /> 3D Object
      </div>
    ),
    description:
      "Rather than using existing templates, unleash your creativity by designing your own 3D objects to create a space that reflects your personality.",
    borderColor: "border-[#C0DFF6]",
  },
  {
    icon: <RealityIcon />,
    title: (
      <div className="ml-[22px] w-[210px] md:w-full">
        Create as Easily <br /> as Reality
      </div>
    ),
    description:
      "Thanks to its user-friendly interface, even beginners can easily control and stack blocks just like they would in real life.",
    borderColor: "border-[#D6D0C6]",
  },
  {
    icon: <MultiplayerIcon />,
    title: <div className="ml-[22px]">Multiplayer</div>,
    description:
      "Team up or go head-to-head! Connect with friends or users from all around the world simultaneously and enjoy a variety of activities.",
    borderColor: "border-[#F5EB95]",
  },
  {
    icon: <CustomizationIcon />,
    title: (
      <div className="ml-[22px]">
        Avatar <br />
        Customization
      </div>
    ),
    description:
      "Design and customize your avatar. Express your unique style through a variety of outfits, accessories, skin tones, and hairstyles.",
    borderColor: "border-[#FBD8D8]",
  },
  {
    icon: <AddAudioIcon />,
    title: <div className="ml-[22px]">Add Audio</div>,
    description:
      "Rather than using existing templates, unleash your creativity by designing your own 3D objects to create a space that reflects your personality.",
    borderColor: "border-[#C0DFF6]",
  },
  {
    icon: <DrawObjectIcon />,
    title: <div className="ml-[22px] w-[180px] md:w-full">Draw 3D Object</div>,
    description:
      "Without the need for special 3D design skills, you can draw 3D objects simply with a mouse and keyboard.",
    borderColor: "border-[#FFE3C1]",
  },
  {
    icon: <PhysicsIcon />,
    title: <div className="ml-[22px]">Physics Item</div>,
    description:
      "TwinWorld objects replicate real-world physics, enabling behaviors such as tossing, gripping, and colliding.",
    borderColor: "border-[#DBDEFF]",
  },
];

export default function MobileExperience() {
  const { isMobile } = useDeviceBreakPoint();

  return (
    <section className="px-[18px] md:portrait:px-[32px] md:landscape:px-[58px] mt-[92px] text-[28px] leading-[42px] font-semibold">
      <h2>
        Experience <br /> the capabilities of {isMobile && <br />} TwinWorld for{" "}
        <button className="py-[2px] px-[12px] rounded-full bg-black text-twinworldPrimary-200 text-[26px] leading-[26px]">
          Free
        </button>
      </h2>
      <ul className="mt-[30px] flex flex-col md:flex-row md:flex-wrap md:justify-between gap-[22px] [&>*:last-child]:grow-0 [&>*:last-child]:basis-[49%]">
        {LIST.map((item, index) => (
          <MobileExperienceItem
            key={index}
            icon={item.icon}
            title={item.title}
            description={item.description}
            borderColor={item.borderColor}
          />
        ))}
      </ul>
    </section>
  );
}

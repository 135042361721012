import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

import MobileSignInButton from "./MobileSignInButton";

export default function GoogleLoginButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    const button = document.querySelector(
      `#googleLoginButton [role="button"]`
    ) as HTMLDivElement;
    button.click();
  };

  const handleOnSuccess = (res: CredentialResponse) =>
    navigate(`/sign-in/google/#access_token=${res.credential}`);

  return (
    <div>
      <MobileSignInButton
        icon={<img alt="google" src="/images/google.png" />}
        label="Google"
        onClick={() => handleClick()}
      />
      <div className="hidden" id="googleLoginButton">
        <GoogleLogin onSuccess={handleOnSuccess} />
      </div>
    </div>
  );
}

import { useNavigate } from "react-router-dom";

import { useSpaceActions } from "../../domains/space/hook/useSpaceActions";
import Avatar from "../common/Avatar";
import Loading from "../common/Loading";
import SpaceLiveUser from "../space/SpaceLiveUser";

import AudienceOverview from "./AudienceOverview";
import MobileSpaceReactionToolbar from "./MobileSpaceReactionToolbar";

type Props = {
  spaceId: string;
};

export default function MobileSpaceDetail({ spaceId }: Props) {
  const { useSpaceDetails } = useSpaceActions();
  const navigate = useNavigate();

  const { isPending, isError, spaceDetails, spaceDetailsError } =
    useSpaceDetails(spaceId);

  if (isError && spaceDetailsError) {
    if (spaceDetailsError.message) {
      return <div>{spaceDetailsError.message}</div>;
    }
    return <div>undefined error</div>;
  }

  if (isPending) {
    return <Loading />;
  }

  const {
    spaceDetailImage,
    spaceCode,
    spaceName,
    spaceCreatorImage,
    spaceCreator,
    plays,
    views,
    spaceDescription,
    likeStatus,
    isBookmarked,
    creatorId,
  } = spaceDetails!;
  spaceDetails!.spaceId = spaceId;

  const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = "/images/space/img_detail_default.webp";
  };

  const handleClickMoveUserProfile = () => {
    navigate(`/user/${creatorId}`, {
      state: { menu: "spaces" },
    });
  };

  return (
    <section className="px-[18px] md:portrait:px-[32px] md:landscape:px-[58px] pb-[70px] w-full">
      <div className="flex flex-col w-full min-w-[339px]">
        <div className="text-[13px] h-[13px] leading-[16px] text-twinworldGray-800 font-medium">
          Pin Code: {spaceCode}
        </div>
        <h2 className="w-full text-[24px] leading-tight font-semibold max-h-[339px] mt-[12px]">
          {spaceName}
        </h2>

        <div className="w-full relative flex min-h-[258px] rounded-[14px] border border-twinworldGray-300 mt-[16px] overflow-hidden">
          <img
            alt="spaceImage"
            className="object-cover w-full"
            src={spaceDetailImage}
            onError={onError}
          />

          <div className="absolute top-[10px] right-[10px] flex flex-row gap-[10px] xl:gap-[17px]">
            {spaceDetails!.liveUserCount > 0 && (
              <SpaceLiveUser userCount={spaceDetails!.liveUserCount} />
            )}
            <div className="rounded-full border border-black bg-white text-center w-[59px] h-[25px] xl:w-[88px] xl:h-[32px]">
              <span className="text-[14px] h-[14px] leading-[25px] xl:text-[16px] xl:h-[16px] xl:leading-[19px] font-medium">
                {spaceDetails!.isPublic ? "Public" : "Private"}
              </span>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between w-full mt-[12px]">
          <div
            className="flex gap-[12px] items-center"
            onClick={handleClickMoveUserProfile}
          >
            <Avatar size="lg" src={spaceCreatorImage} />
            <div className="text-[16px] font-semibold leading-[19px] w-[200px] sm:w-[400px] truncate">
              {spaceCreator}
            </div>
          </div>
          <AudienceOverview plays={+plays} views={+views} />
        </div>

        <p className="w-full text-twinworldGray-800 text-[16px] font-normal leading-[28px] break-words py-[4px] mt-[12px]">
          {spaceDescription}
        </p>

        <MobileSpaceReactionToolbar
          spaceId={spaceId}
          likeStatus={likeStatus}
          isBookmarked={isBookmarked}
        />
      </div>
    </section>
  );
}

/* eslint-disable no-underscore-dangle */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

/**
 * axios instance
 */
export const client = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  headers: {
    withCredentials: true,
  },
});

/**
 * 로그인 갱신 등에 필요한 local storage를 set up.
 * @param {string} refreshToken isSignIn, refreshToken save localstorage
 */
export const setNewTokens = (accessToken: string, refreshToken: string) => {
  window.localStorage.setItem("isSignIn", "true");
  window.localStorage.setItem("accessToken", accessToken);
  window.localStorage.setItem("refreshToken", refreshToken);
};

/**
 * error, logout 등 localStorage 제거
 */
export const removeToken = () => {
  window.localStorage.removeItem("isSignIn");
  window.localStorage.removeItem("refreshToken");
  window.localStorage.removeItem("accessToken");
};

let isRefreshing = false;
let subscribers: ((token: string) => void)[] = [];

function subscribeTokenRefresh(cb: (token: string) => void) {
  subscribers.push(cb);
}

function onRefreshed(token: string) {
  subscribers.forEach((cb) => cb(token));
}

const getRefreshToken = async (
  refreshToken: string
): Promise<string | void> => {
  try {
    const response = await client.post("/auth/renew-token", {
      refreshToken,
    });

    isRefreshing = false;
    onRefreshed(response.data.data.accessToken);
    subscribers = [];

    const newAccessToken = response.data.data.accessToken;
    const newRefreshToken = response.data.data.refreshToken;

    setNewTokens(newAccessToken, newRefreshToken);

    return newAccessToken;
  } catch (error) {
    isRefreshing = false;
    subscribers = [];
  }
};

client.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const accessToken = window.localStorage.getItem("accessToken");

    if (config.headers && accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = window.localStorage.getItem("refreshToken");

    if (error.response.status === 500) {
      window.location.href = "/error/500";
    }

    switch (error.response.data.code) {
      case "NO_TOKEN":
      case "INVALID_TOKEN":
      case "EXPIRED_TOKEN":
      case "NOT_MATCHED_TOKENS":
      case "Forbidden":
        removeToken();
        alert("Please Login again");
        window.location.href = "/";
        break;
    }

    if (
      originalRequest.url.includes("/renew-token") ||
      error.response.status !== 401
    )
      return Promise.reject(error);

    if (!originalRequest._retry && error.response.status === 401) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          subscribeTokenRefresh((token: string) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(client(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const accessToken = await getRefreshToken(refreshToken!);

      originalRequest.headers.Authorization = `Bearer ${accessToken}`;

      return client(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default function EditIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00195312 10.0003V12.5H2.50161L9.87392 5.12769L7.37426 2.62804L0.00195312 10.0003ZM11.807 3.19463C12.0669 2.93466 12.0669 2.51472 11.807 2.25476L10.2472 0.694973C9.98724 0.435009 9.56729 0.435009 9.30733 0.694973L8.0875 1.9148L10.5872 4.41446L11.807 3.19463Z"
        fill="black"
      />
    </svg>
  );
}

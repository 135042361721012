import { Space } from "../interface";

export class SpaceCard {
  spaceId;

  spaceName;

  spaceImage;

  spaceCreator;

  spaceCreatorId;

  spaceCreatorImage;

  isBookmarked;

  views;

  plays;

  liveUserCount;

  constructor(data: Space) {
    this.spaceId = data.id;
    this.spaceName = data.name;
    this.spaceImage = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${data.thumbnail}?size=original&fileType=space-thumbnail`;
    this.spaceCreatorId = data.creatorId;
    this.spaceCreatorImage = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${data.creatorImageMediaId}?size=original&fileType=profile`;
    this.spaceCreator = data.creatorNickname;
    this.isBookmarked = data.isBookmarked;
    this.views = +data.viewCount ?? 0;
    this.plays = +data.joinCount ?? 0;
    this.liveUserCount = data.liveUserCount;
  }
}

type Props = {
  title: string;
  file: { webm: string; mp4: string };
  poster: string;
};

export default function MobileSpaceCarouselItem({
  title,
  file,
  poster,
}: Props) {
  return (
    <div className="relative w-[339px] h-[270px] mx-[9px] rounded-[20px] overflow-hidden">
      <h3 className="w-full absolute bottom-[23px] text-center font-semibold text-white text-[16px]">
        {title}
      </h3>

      <video
        className="object-cover w-full h-full"
        autoPlay
        muted
        loop
        disablePictureInPicture
        poster={poster}
        playsInline
      >
        <source src={file.webm} type="video/webm" />
        <source src={file.mp4} type="video/mp4" />
      </video>
    </div>
  );
}

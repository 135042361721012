import { useContext, ComponentType } from "react";

import {
  ModalsDispatchContext,
  ModalsStateContext,
} from "../../contexts/ModalContext";

interface ModalProps {
  Component: ComponentType<any>;
  props: {
    onSubmit?: () => Promise<void>;
    [key: string]: any;
  };
}

export default function Modals() {
  const openedModals = useContext<ModalProps[]>(ModalsStateContext);
  const { close } = useContext(ModalsDispatchContext);

  return (
    <>
      {openedModals.map((modal, index) => {
        const { Component, props } = modal;
        const { onSubmit, ...restProps } = props;

        const onClose = () => close(Component);

        const handleSubmit = async () => {
          if (typeof onSubmit === "function") {
            await onSubmit();
          }

          onClose();
        };

        const ModalComponent = Component;

        return (
          <>
            <ModalComponent
              key={index}
              closeModal={onClose}
              handleSubmit={handleSubmit}
              {...restProps}
            />
          </>
        );
      })}
    </>
  );
}

import useLazyLoadVideo from "../../../../hooks/useLazyLoadVideo";

export default function MobileValue02Mask() {
  const ref = useLazyLoadVideo();

  return (
    <section ref={ref} className="relative w-full">
      <svg
        className="absolute -bottom-[0.5px] left-0 z-10"
        xmlns="http://www.w3.org/2000/svg"
        width="104"
        height="64"
        viewBox="0 0 104 64"
        fill="none"
      >
        <path
          d="M81.5912 43.377C81.5912 56.3935 97.105 63.6828 104 63.6828H0V0.682796C0 14.0117 13.2155 22.0299 21.2597 22.0299H61.4807C82.1657 22.0299 81.5912 41.815 81.5912 43.377Z"
          fill="white"
        />
      </svg>
      <video
        className="block w-full rounded-[20px]"
        autoPlay
        muted
        loop
        disablePictureInPicture
        playsInline
        poster="/images/landing/thumbnails/value_2.webp"
      >
        <source data-src="/videos/value_2.webm" type="video/webm" />
        <source data-src="/videos/value_2.mp4" type="video/mp4" />
      </video>
    </section>
  );
}

export interface Space {
  id: string;
  name: string;
  thumbnail: string;
  creatorId: string;
  creatorNickname: string;
  isBookmarked: boolean;
}

export interface MySpaceList {
  spaces: {
    visited: Space[];
    mySpace: Space[];
  };
}

export type SpaceType = "featured" | "recent" | "trend" | "live" | "all";

export const SPACE_TYPE = {
  TRENDING: "trending",
  RECENT: "recent",
  FEATURED: "featured",
  LIVE: "live",
};

export interface SpaceListParams {
  type: SpaceType;
  take?: number;
  skip?: number;
  except?: string;
}

export interface SpaceDetailsResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  code: string;
  creatorId: string;
  creatorNickname: string;
  isPublic: boolean;
  isPartners: string;
  thumbnail: string;
  detailImage: string;
  isExistsStore: boolean;
  appSchema: string;
  appStoreUrl: string;
  iconMediaId: string;
  sceneObject: string;
  isBookmarked: boolean;
  isLiked: boolean;
  totalLike: string;
}

export type SpaceCategoryButton = {
  width: string;
  height: string;
  label: string;
  path: string;
};

export const EXPLORE_SPACE_CATEGORY_BUTTON_LIST = [
  {
    width: "w-144",
    height: "h-44",
    label: "Recommend",
    path: "/spaces?type=trend",
  },
  {
    width: "w-96",
    height: "h-44",
    label: "Explore",
    path: "/spaces?type=explore",
  },
];

export const MY_SPACE_CATEGORY_BUTTON_LIST = [
  {
    width: "w-160",
    height: "h-44",
    label: "My own Space",
    path: "/myspace?type=created",
  },
  {
    width: "w-120",
    height: "h-44",
    label: "Bookmark",
    path: "/myspace?type=bookmarked",
  },
  {
    width: "w-96",
    height: "h-44",
    label: "Visited",
    path: "/myspace?type=visited",
  },
];

export const ITEM_CATEGORY_BUTTON_LIST = [
  {
    width: "w-140",
    height: "h-44",
    label: "Recommend",
    path: "/items?type=recommended-list",
  },
  {
    width: "w-96",
    height: "h-44",
    label: "Explore",
    path: "/items?type=new-list",
  },
];

import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import CheckIcon from "../common/icons/CheckIcon";

type Props = {
  idDisplay: boolean;
  message: string;
};

export default function ButtonToast({ message, idDisplay = false }: Props) {
  const { isDesktop } = useDeviceBreakPoint();

  return (
    <section
      className={`absolute flex items-center justify-center top-[72px] xl:top-20 cursor-pointer my-auto text-[14px] xl:text-[18px] w-fit border border-black px-[16px] xl:px-10 py-[8px] xl:py-4 rounded-[5px] xl:rounded-xl ${
        idDisplay ? "opacity-100" : "opacity-0"
      } transition-opacity duration-[1000ms] ease-in-out shadow-[3px_3px_3px_0_rgba(0,0,0,0.25)] bg-white`}
    >
      {isDesktop && (
        <div className="absolute overflow-hidden w-[25px] -top-[16px] z-10">
          <div className="w-[16px] h-[16px] origin-bottom-left transform rotate-45 bg-white border border-black " />
        </div>
      )}

      <CheckIcon />
      <div className="ml-3">{message}</div>
    </section>
  );
}

export default function RealityIcon() {
  return (
    <svg
      width="63"
      height="57"
      viewBox="0 0 63 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 25.7647L3 14.3003L31.5 2.83594L60 14.3003L31.5 25.7647Z"
        stroke="#4298D9"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.088 25.7902L60 28.1689L31.5 39.6332L3 28.1689L9.25271 25.6514"
        stroke="#4298D9"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.088 39.6584L60 42.0371L31.5 53.5014L3 42.0371L9.25271 39.5259"
        stroke="#4298D9"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const LinkedInIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M12.5 25C10.0278 25 7.61101 24.2669 5.55539 22.8934C3.49977 21.5199 1.89761 19.5676 0.95151 17.2835C0.00541314 14.9995 -0.242127 12.4861 0.240194 10.0614C0.722514 7.6366 1.91303 5.40932 3.6612 3.66117C6.0054 1.31696 9.18482 0 12.5 0C15.8152 0 18.9946 1.31696 21.3388 3.66117C23.683 6.00538 25 9.1848 25 12.5C25 15.8152 23.683 18.9947 21.3388 21.3389C20.1808 22.5032 18.8033 23.4263 17.2861 24.0548C15.769 24.6832 14.1422 25.0045 12.5 25ZM14.7813 11.6917C15.7201 11.6917 16.2167 12.357 16.2167 13.616V18.1694H19.0493V13.2903C19.0493 10.891 17.8298 9.45906 15.7854 9.45906C15.2717 9.43593 14.7616 9.55488 14.3111 9.80285C13.8606 10.0508 13.4872 10.4182 13.232 10.8646V9.65906H10.3986C10.4355 10.4486 10.3986 18.093 10.3986 18.1701H13.232V13.4167C13.2198 13.1827 13.2516 12.9486 13.3257 12.7264C13.4353 12.427 13.6331 12.1679 13.8929 11.9832C14.1528 11.7984 14.4625 11.6968 14.7813 11.6917ZM6.00076 9.65906V18.1701H8.83407V9.65906H6.00076ZM7.43547 5.5556C7.23299 5.53742 7.02894 5.56129 6.83612 5.62571C6.64331 5.69014 6.46589 5.79372 6.31501 5.92997C6.16413 6.06622 6.04304 6.23219 5.95935 6.41746C5.87565 6.60273 5.83116 6.8033 5.82867 7.00658C5.82617 7.20986 5.86572 7.41146 5.94484 7.59872C6.02396 7.78599 6.14094 7.95488 6.28843 8.09479C6.43592 8.23471 6.61074 8.34262 6.80192 8.41176C6.99309 8.4809 7.1965 8.50977 7.39936 8.49656H7.41742C7.6208 8.51491 7.82577 8.49084 8.01936 8.42589C8.21296 8.36094 8.39097 8.25651 8.54213 8.11922C8.69329 7.98193 8.81432 7.81476 8.89754 7.62829C8.98077 7.44182 9.02439 7.2401 9.02564 7.0359C9.0269 6.8317 8.98576 6.62947 8.90483 6.44199C8.82389 6.25451 8.70493 6.08586 8.55547 5.94672C8.406 5.80759 8.22929 5.70099 8.03651 5.63366C7.84372 5.56634 7.63906 5.53976 7.43547 5.5556Z"
      fill="#555555"
    />
  </svg>
);

export default LinkedInIcon;

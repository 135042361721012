import { useEffect } from "react";

type Props = {
  isDisplay: boolean;
};

export default function HeaderDim({ isDisplay = false }: Props) {
  // Dim이 활성화 되었을 때 모바일 touchmove 막기
  useEffect(() => {
    const handleTouchMove = (event: TouchEvent) => {
      if (isDisplay) {
        event.preventDefault();
      }
    };

    window.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
    return () => window.removeEventListener("touchmove", handleTouchMove);
  }, [isDisplay]);

  return (
    <section
      className={`fixed top-[82px] md:top-0 left-0 right-0 w-full xl:min-w-[1440px] h-full bg-black/70 z-50 transition-opacity duration-[250ms] ease-in-out ${
        isDisplay ? "opacity-100" : "opacity-0 -z-10"
      }`}
    />
  );
}

import { useSpaceActions } from "../../domains/space/hook/useSpaceActions";
import SpaceList from "../SpaceList";
import Loading from "../common/Loading";
import NoMatchingResults from "../common/NoMatchingResults";
import SpaceGroupList from "../common/list/SpaceGroupList";

type Props = {
  keyword: string;
};

export default function SpaceFeatureList({ keyword }: Props) {
  const { useFeaturedSpaceList } = useSpaceActions();

  const { isPending, data } = useFeaturedSpaceList();

  if (isPending) {
    return <Loading />;
  }

  // 키워드가 있으면 Space List 를 출력
  if (keyword) {
    return (
      <section className="xl:min-w-[1440px] mx-auto">
        <SpaceList type="all" selectedCategory="" keyword={keyword} />
      </section>
    );
  }

  return (
    <section className="w-full mx-auto xl:min-w-[1440px]">
      {data ? (
        data.map(({ group, spaces }) => (
          <div
            key={group.id}
            className="w-full mt-[46px] xl:mt-[103px] first-of-type:mt-0"
          >
            <SpaceGroupList
              title={group.name}
              list={spaces}
              moreButtonLink={`/spaces?type=featured&groupId=${group.id}`}
            />
          </div>
        ))
      ) : (
        <NoMatchingResults />
      )}
    </section>
  );
}

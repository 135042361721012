import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";

type Props = {
  closeModal: () => void;
  itemThumbnail: string;
  itemName: string;
  handleDelete: () => void;
};

const defaultSrc = "/images/profile/default_profile.webp";

export default function ItemEditDeleteModal({
  closeModal,
  itemThumbnail,
  itemName,
  handleDelete,
}: Props) {
  const { isMobile } = useDeviceBreakPoint();

  const handleImgError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = defaultSrc;
  };

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = () => {
    if (isMobile) {
      return (
        <div className="flex justify-center ">
          <p className="w-[177px] text-[20px] font-semibold leading-tight text-black">
            Do you want to delete your item?
          </p>
        </div>
      );
    }

    return (
      <div className="flex justify-center w-full mt-[30px] text-[24px] font-semibold px-[124px] leading-8">
        Do you want to delete below item?
      </div>
    );
  };

  return (
    <Modal
      closeModal={closeModal}
      title={title()}
      close={close}
      maxWidth="w-[339px] md:w-[480px]"
    >
      <div className="flex flex-col items-center mt-4 mb-[16px]">
        <img
          src={itemThumbnail}
          alt="item"
          className="mx-[75px] w-[264px] md:w-[248px] h-[198px] border border-twinworldGray-3 rounded-[25px]"
          onError={handleImgError}
        />
        <span className="w-[279px] mt-[20px] md:mt-[22px] text-[16px] md:text-[20px] text-black md:text-twinworldGray-770 leading-tight md:leading-6 font-semibold">
          {itemName}
        </span>
        <div className="flex flex-col items-center gap-[16px] md:gap-[18px] mt-[34px] md:mt-[41px] mb-[45px] md:mb-[60px]">
          {isMobile ? (
            <>
              <button
                className="flex items-center justify-center w-[279px] h-[48px] font-medium leading-none bg-black text-white text-[16px] rounded-full"
                onClick={handleDelete}
              >
                Yes
              </button>
              <button
                className="flex items-center justify-center w-[279px] h-[48px] font-medium leading-none bg-white text-black text-[16px] border-black border-[1.5px] rounded-full"
                onClick={closeModal}
              >
                No
              </button>
            </>
          ) : (
            <>
              <button
                className="px-[130px] py-[21px] text-black bg-twinworldPrimary-200 font-semibold text-[16px] rounded-full border-2 border-black hover:text-twinworldPrimary-200 hover:bg-black"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="px-[130px] py-[21px] text-black bg-white font-semibold text-[16px] rounded-full border-2 border-black hover:text-twinworldPrimary-200 hover:bg-black"
                onClick={closeModal}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

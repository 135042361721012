import HMDList from "./HMDList";
import Stamp from "./Stamp";

export default function Devices() {
  return (
    <section className="flex flex-col items-center md:mt-[92px] xl:mt-64 md:mb-[140px] xl:mb-[310px] md:px-[24px] lg:px-[32px]">
      <h4 className="relative md:w-[380px] xl:w-[582px] xl:h-40 md:text-[32px] xl:text-[53px] font-semibold leading-normal text-center">
        Begin your adventure on these devices
        <div className="absolute -top-10 -right-[86px]">
          <Stamp />
        </div>
      </h4>
      <HMDList />
    </section>
  );
}

import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../../contexts/AuthContext";
import { useModelContext } from "../../contexts/ModelContext";
import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { useItemActions } from "../../domains/item/hook/useItemActions";
import { useMyBoxActions } from "../../domains/item/hook/useMyBoxActions";
import convertFileSizeToString from "../../utils/convertFileSizeToString";
import formatNumberToAbbreviation from "../../utils/formatNumberToAbbreviation";
import FavoriteButton from "../button/FavoriteButton";
import Avatar from "../common/Avatar";
import EditIcon from "../common/icons/EditIcon";
import { ItemDetailsInfo } from "../item/type";
import Divider from "../layout/Divider";

import MobileLikeDisLike from "./MobileLikeDisLike";
import MobileSharedLinkToast from "./MobileSharedToast";
import MobileDividerIcon from "./icons/MobileDividerIcon";

type ItemDetailDataProps = ItemDetailsInfo & {
  userId: string;
};

type ItemDescriptionProps = {
  itemDetailsData: ItemDetailDataProps;
};

export default function MobileItemDescription({
  itemDetailsData,
}: ItemDescriptionProps) {
  const {
    itemId,
    creator,
    categoryName,
    isFavorite,
    likeStatus,
    fileSize,
    uploaderImage,
    conversionStatus,
    vertices,
  } = itemDetailsData;

  const { isAnimation } = useModelContext();
  const { profile } = useAuthContext();

  const navigate = useNavigate();
  const { handleLike, handleDisLike } = useItemActions();

  const authActions = useAuthActions();
  const { saveToMyFavorite, deleteFromMyFavorite } = useMyBoxActions();
  const isSignIn = authActions.getLocalStorage("isSignIn");

  const changeCategoryName = (categoryName: string) => categoryName;

  const itemInfo = [
    {
      title: "Category",
      value: changeCategoryName(categoryName),
    },
    {
      title: "Vertices",
      value:
        conversionStatus === "success"
          ? formatNumberToAbbreviation(vertices)
          : "-",
    },
    {
      title: "Size",
      value:
        conversionStatus === "success"
          ? convertFileSizeToString(fileSize)
          : "-",
    },
    {
      title: "Animation",
      value:
        conversionStatus === "success" ? (isAnimation ? "Yes" : "No") : "-",
    },
  ];

  const handleClickSaveItem = () => {
    if (isSignIn) {
      if (isFavorite) {
        deleteFromMyFavorite(itemId).catch((error) => {
          if (error.response.status === 404 || error.response.status === 400) {
            window.location.href = `/error/${error.response.status}`;
          }
          throw error;
        });
      } else {
        saveToMyFavorite(itemId).catch((error) => {
          if (error.response.status === 404 || error.response.status === 400) {
            window.location.href = `/error/${error.response.status}`;
          }
          throw error;
        });
      }
    } else {
      navigate("/sign-in", { state: { redirect: `/items/${itemId}` } });
    }
  };

  const handleClickMoveUserProfile = () => {
    navigate(`/user/${itemDetailsData.creatorId}`, {
      state: { menu: "items" },
    });
  };

  return (
    <div className="mt-[12px]">
      <div className="relative flex flex-col w-full gap-3 cursor-pointer">
        <div
          className="flex items-center gap-3"
          onClick={handleClickMoveUserProfile}
        >
          <Avatar size="lg" src={uploaderImage} />
          <div className="flex gap-2">
            <h3 className="font-semibold truncate text-[16px] leading-tight text-black">
              {creator}
            </h3>
          </div>
        </div>
        <div className="flex flex-col gap-[17px] text-[18px] w-full px-5 mt-2">
          {itemInfo.map((item) => (
            <div className="flex items-center py-[5px]" key={item.title}>
              <MobileDividerIcon />
              <h4 className="ml-[10px] text-twinworldGray-700 w-24 font-normal text-[14px]">
                {item.title}
              </h4>
              <span className="ml-[40px] text-black font-normal text-[14px]">
                {item.value}
              </span>
            </div>
          ))}
        </div>
        <Divider className="h-1 mt-auto border-b border-b-twinworldGray-300" />
        <div className="flex items-center mt-2">
          <div className="flex items-center w-full">
            {profile?.userId === itemDetailsData.creatorId ? (
              <button
                className="py-3 px-4 flex items-center justify-center text-[16px] border border-twinworldGray-300 box-border rounded-full gap-[6px] bg-inherit"
                onClick={() =>
                  navigate(`/items/${itemDetailsData.itemId}/edit`)
                }
              >
                Edit
                <EditIcon />
              </button>
            ) : (
              <FavoriteButton
                like={isFavorite}
                isDesktop={false}
                onClick={handleClickSaveItem}
              />
            )}
          </div>

          <MobileLikeDisLike
            type="items"
            id={itemId}
            likeStatus={likeStatus}
            handleClickLike={() => handleLike(itemId)}
            handleClickDisLike={() => handleDisLike(itemId)}
          />

          <MobileSharedLinkToast />
        </div>
      </div>
    </div>
  );
}

import { ConversionStatus } from "../../../components/item/type";
import { ItemDetailsInfo } from "../interface";

type ItemDetailDataProps = {
  id: string;
  name: string;
  uploaderId: string;
  uploaderName: string;
  uploaderAvatarImageUrl: string;
  uploaderImageMediaId: string;
  fileInfo: {
    fileId: string;
    fileSize: number;
    originalFileName: string;
    thumbnailFileName: string;
  };
  categoryInfo: {
    categoryId: string;
    categoryName: string;
  };
  createdAt: Date;
  updatedAt: Date;
  likeStatus: "like" | "dislike" | "none";
  isFavorite: boolean;
  conversionStatus: ConversionStatus;
  vertices: number;
};

class ItemDetail implements ItemDetailsInfo {
  itemId: string;

  name: string;

  categoryName: string;

  categoryId: string;

  creatorId: string;

  uploaderId: string;

  uploaderImage: string;

  creator: string;

  itemUrl: string;

  thumbnailUrl: string;

  createdAt: Date;

  updatedAt: Date;

  fileSize: number;

  likeStatus: "like" | "dislike" | "none";

  isFavorite: boolean;

  conversionStatus: ConversionStatus;

  vertices: number;

  constructor(data: ItemDetailDataProps) {
    const {
      id,
      name,
      categoryInfo,
      createdAt,
      updatedAt,
      fileInfo,
      likeStatus,
      isFavorite,
      uploaderId,
      uploaderName,
      uploaderAvatarImageUrl,
      uploaderImageMediaId,
      conversionStatus,
      vertices,
    } = data;

    const { categoryId, categoryName } = categoryInfo;
    const { fileSize } = fileInfo;

    this.itemId = id;
    this.name = name;
    this.categoryName = categoryName;
    this.categoryId = categoryId;
    this.creatorId = uploaderId;
    this.uploaderId = uploaderId;
    this.uploaderImage = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${uploaderImageMediaId}?size=original&fileType=profile`;
    this.creator = uploaderName;
    this.itemUrl = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${id}?size=original&fileType=model&isFlipMode=false`;
    this.thumbnailUrl = `${process.env.REACT_APP_API_SERVER_URL}/media-download/${id}?size=original&fileType=thumbnail`;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.likeStatus = likeStatus;
    this.isFavorite = isFavorite;
    this.fileSize = fileSize;
    this.conversionStatus = conversionStatus;
    this.vertices = vertices || 0;
  }
}

export default ItemDetail;

import { OrbitControls, Stage } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useState } from "react";

import CaptureModel from "./CaptureModel";
import LoadingSpinner from "./LoadingSpinner";
import ModelLoader from "./ModelLoader";

type Props = {
  renderFileUrl: string;
  captureFinished: () => void;
};

export default function UploadModelViewer({
  renderFileUrl = "",
  captureFinished,
}: Props) {
  const [isSpriteCapturing, setIsSpriteCapturing] = useState(true);

  useEffect(() => {
    if (isSpriteCapturing) {
      captureFinished();
    }
  }, [isSpriteCapturing]);

  // 파일 경로 변경 시
  useEffect(() => {
    if (renderFileUrl !== "") {
      setIsSpriteCapturing(true);
    }
  }, [renderFileUrl]);

  if (renderFileUrl === "") {
    return (
      <picture>
        <source
          srcSet="/images/upload/upload_default@2x.webp 2x, /images/upload/item_upload_default.webp 1x"
          type="image/webp"
        />
        <img
          className="object-cover"
          src="/images/upload/upload_default.webp"
          alt="3d view default"
        />
      </picture>
    );
  }

  const getLastItemFromUrl = (url: string) => {
    const results = url.split("/");
    return results[results.length - 1];
  };

  const uniqueKey = getLastItemFromUrl(renderFileUrl);

  return (
    <div className="relative w-full h-full">
      {isSpriteCapturing && (
        <div className="absolute z-10 flex items-center justify-center w-full h-full bg-white">
          <LoadingSpinner />
        </div>
      )}

      <Canvas
        key={uniqueKey}
        id="uploadModelViewer"
        gl={{ preserveDrawingBuffer: true }}
        resize={{ scroll: false }}
      >
        <color attach="background" args={[196, 196, 196]} />
        <ambientLight />

        <Suspense fallback={<ModelLoader key={uniqueKey} />}>
          <Stage adjustCamera environment={"sunset"}>
            <CaptureModel
              renderFileUrl={renderFileUrl}
              setIsCapturing={setIsSpriteCapturing}
            />
          </Stage>
        </Suspense>

        <OrbitControls makeDefault />
      </Canvas>
    </div>
  );
}

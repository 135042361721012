export default function RealityIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 28L10 20.5L30 13L50 20.5L30 28Z"
        stroke="black"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.8512 27.1043L50 28.9765L30 38L10 28.9765L14.3879 27"
        stroke="black"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.8512 37.0993L50 38.8005L30 47L10 38.8005L14.3879 37"
        stroke="black"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

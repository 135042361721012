export default function SmileIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 16C4.08172 16 0.5 12.4182 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9182 0 16.5 3.58172 16.5 8C16.5 12.4182 12.9182 16 8.5 16Z"
        fill="#A6D9FF"
      />
      <path
        d="M11.5 10C11.5 10 10.5 11 8.5 11C6.5 11 5.5 10 5.5 10"
        stroke="#545454"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 6C10.7239 6 10.5 5.77614 10.5 5.5C10.5 5.22386 10.7239 5 11 5C11.2761 5 11.5 5.22386 11.5 5.5C11.5 5.77614 11.2761 6 11 6Z"
        fill="#545454"
        stroke="#545454"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6C5.72386 6 5.5 5.77614 5.5 5.5C5.5 5.22386 5.72386 5 6 5C6.27614 5 6.5 5.22386 6.5 5.5C6.5 5.77614 6.27614 6 6 6Z"
        fill="#545454"
        stroke="#545454"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default function CategoryDividerIcon({
  columnStyle,
}: {
  columnStyle: "multiple" | "single";
}) {
  if (columnStyle === "multiple") {
    return (
      <svg
        width="312"
        height="2"
        viewBox="0 0 312 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 1L312 0.999973" stroke="#777777" strokeDasharray="2 2" />
      </svg>
    );
  }
  return (
    <svg
      width="129"
      height="2"
      viewBox="0 0 129 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 1L128.5 0.999989" stroke="#777777" strokeDasharray="2 2" />
    </svg>
  );
}

import GIF from "gif.js";
import { useState } from "react";
import { Camera, MathUtils, WebGLRenderer } from "three";

import workerStr from "../worker/gifWorker";

export default function use3DModelScreenshot() {
  const [spriteImage, setSpriteImage] = useState<File>();
  const [gifImage, setGifImage] = useState<File>();

  let animationId = 0;

  const createGifImage = (images: HTMLImageElement[]) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    const imageWidth = 248;
    const imageHeight = 248;
    canvas.width = imageWidth;
    canvas.height = imageHeight;

    const workerBlob = new Blob([workerStr], {
      type: "application/javascript",
    });

    const encoder = new GIF({
      workers: 2,
      quality: 10,
      width: imageWidth,
      height: imageHeight,
      workerScript: URL.createObjectURL(workerBlob),
    });

    images.forEach((image: any) => {
      context.drawImage(image, 0, 0, imageWidth, imageHeight);
      encoder.addFrame(context, { copy: true, delay: 100 });
    });

    encoder.on("finished", (blob) => {
      const gifFile = new File([blob!], "thumbnailGif.gif", {
        type: "image/gif",
      });
      setGifImage(gifFile);
    });

    encoder.render();
  };

  const loadImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);
      image.src = url;
    });

  const createSpriteImages = (images: HTMLImageElement[]) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d") as CanvasRenderingContext2D;

    // 프레임 이미지 크기
    const imageWidth = 248;
    const imageHeight = 248;

    // 전체 스프라이트 이미지 크기
    canvas.width = imageWidth * images.length;
    canvas.height = imageHeight;

    let offsetX = 0;
    images.forEach((image: any) => {
      context.drawImage(image, offsetX, 0, imageWidth, imageHeight);
      offsetX += imageWidth;
    });

    canvas.toBlob((blob) => {
      const spriteImage = new File([blob!], "thumbnailSprite.webp", {
        type: "image/webp",
      });
      setSpriteImage(spriteImage);
    });
  };

  const makePreviewImage = async (list: string[]) => {
    const promiseImages = list.map((url) => loadImage(url));
    const images = await Promise.all(promiseImages);

    createGifImage(images);
    createSpriteImages(images);
  };

  const capturePreviewImage = (
    gl: WebGLRenderer,
    camera: Camera,
    model: any
  ) => {
    let currentRotation = 0;
    const totalRotation = Math.PI * 2; // 360 degrees in radians
    const incrementDegree = 24; // 증가할 각도
    const rotationIncrement = MathUtils.degToRad(incrementDegree); // 각도 (degree) -> Euler(radian) 으로 변경
    const list: string[] = [];

    const animate = async () => {
      if (currentRotation >= totalRotation) {
        // 모든 회전이 완료되면 애니메이션 중지
        makePreviewImage(list);
        cancelAnimationFrame(animationId);
        return;
      }

      camera.lookAt(model.position);

      // 현재 회전 각도만큼 모델 회전
      model.rotation.y = currentRotation;

      // 스크린샷 찍기
      const blob = await new Promise((resolve) =>
        gl.domElement.toBlob(resolve)
      );
      const result = URL.createObjectURL(blob as Blob);
      list.push(result);

      // 현재 회전한 라디안
      currentRotation += rotationIncrement;

      // 다음 에니메이션 프레인 요청
      animationId = requestAnimationFrame(animate);
    };

    animate();
  };

  const fiberCanvasCapture = async (
    canvasId: string,
    width = 248,
    height = 248
  ) => {
    const selectedCanvas = document.querySelector(
      `#${canvasId} canvas`
    ) as HTMLCanvasElement;
    const selectedCanvasDataURL = selectedCanvas.toDataURL();
    const image = await loadImage(selectedCanvasDataURL);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    canvas.width = width;
    canvas.height = height;

    context.drawImage(image, 0, 0, width, height);
    const dataType = "image/png";
    const dataURL = canvas.toDataURL(dataType);
    const fetchImage = await fetch(dataURL);
    const blobData = await fetchImage.blob();
    const thumbnailFile = new File([blobData], "thumbnail.png", {
      type: dataType,
    });

    return {
      dataURL,
      thumbnailFile,
    };
  };

  return {
    capturePreviewImage,
    spriteImage,
    gifImage,
    fiberCanvasCapture,
  };
}

import ScrollToTopButton from "../components/common/Button/ScrollToTopButton";
import MainWrapper from "../components/common/MainWrapper";
import Items from "../components/itemlist";
import useDeviceBreakPoint from "../hooks/useDeviceBreakPoint";

const categoryList = [
  {
    idx: "relevance",
    name: "Relevance",
  },
  {
    idx: "popular",
    name: "Popular",
  },
  {
    idx: "new-list",
    name: "Recent",
  },
];

const ItemsPage = () => {
  const { isMobile } = useDeviceBreakPoint();
  return (
    <MainWrapper>
      <Items categories={categoryList} />
      {!isMobile && <ScrollToTopButton />}
    </MainWrapper>
  );
};

export default ItemsPage;

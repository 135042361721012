export default function DrawIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <mask
        id="mask0_1343_27317"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="80"
        height="80"
      >
        <rect width="80" height="80" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1343_27317)">
        <path
          d="M41.584 58.4167L71.9173 28.1667L65.1673 21.5L34.9173 51.75L41.584 58.4167ZM8.33398 50.9167C8.33398 52.6389 8.97287 54.0278 10.2507 55.0833C11.5284 56.1389 13.5007 56.8611 16.1673 57.25C16.834 57.3611 17.3757 57.6667 17.7923 58.1667C18.209 58.6667 18.3895 59.25 18.334 59.9167C18.2784 60.6389 18.0007 61.2222 17.5007 61.6667C17.0007 62.1111 16.4173 62.2778 15.7507 62.1667C11.6951 61.5556 8.61176 60.3195 6.50065 58.4583C4.38954 56.5972 3.33398 54.0833 3.33398 50.9167C3.33398 47.5833 4.75065 44.8611 7.58398 42.75C10.4173 40.6389 14.3618 39.3611 19.4173 38.9167C21.8618 38.6945 23.6812 38.25 24.8757 37.5833C26.0701 36.9167 26.6673 36 26.6673 34.8333C26.6673 33.3889 26.0423 32.25 24.7923 31.4167C23.5423 30.5833 21.5007 29.9445 18.6673 29.5C17.9451 29.3889 17.3757 29.0833 16.959 28.5833C16.5423 28.0833 16.3618 27.4722 16.4173 26.75C16.4729 26.0278 16.7784 25.4583 17.334 25.0417C17.8895 24.625 18.5007 24.4722 19.1673 24.5833C23.2784 25.25 26.3895 26.4306 28.5007 28.125C30.6118 29.8195 31.6673 32.0556 31.6673 34.8333C31.6673 37.3889 30.6257 39.4722 28.5423 41.0833C26.459 42.6945 23.5562 43.6389 19.834 43.9167C16.0007 44.1945 13.1257 44.9167 11.209 46.0833C9.29232 47.25 8.33398 48.8611 8.33398 50.9167ZM43.1673 63.9167L29.4173 50.1667L62.584 17.0833C63.3618 16.3056 64.2368 15.9306 65.209 15.9583C66.1812 15.9861 67.0562 16.3611 67.834 17.0833L76.334 25.5C77.1118 26.2778 77.5006 27.1667 77.5006 28.1667C77.5006 29.1667 77.1118 30.0556 76.334 30.8333L43.1673 63.9167ZM29.9173 66.6667C28.9729 66.8889 28.1395 66.6389 27.4173 65.9167C26.6951 65.1945 26.4451 64.3611 26.6673 63.4167L29.4173 50.1667L43.1673 63.9167L29.9173 66.6667Z"
          fill="#4298D9"
        />
      </g>
    </svg>
  );
}

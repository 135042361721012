import { client } from "../../utils/axiosInstance";

import { Space, SpaceListParams, CreatorSpaceListParams } from "./interface";
import { FeaturedSpaces, FeaturedSpacesData } from "./model/FeaturedSpaces";
import { SpaceCard } from "./model/SpaceCard";
import { SpaceDetails } from "./model/SpaceDetails";

const getSpaceList = async (params: SpaceListParams): Promise<SpaceCard[]> => {
  const { type, take, skip } = params;

  const response = await client({
    url: `/space/web?type=${type}&take=${take}&skip=${skip}`,
    method: "GET",
  });

  return response.data.data.map((space: Space) => new SpaceCard(space));
};

const getCreatorSpaceList = async (
  params: CreatorSpaceListParams
): Promise<SpaceCard[]> => {
  const { take, skip, creatorId } = params;

  const response = await client({
    url: `/space/web/spaces-by-creator?creatorId=${creatorId}&take=${take}&skip=${skip}`,
    method: "GET",
  });

  return response.data.data.map((space: Space) => new SpaceCard(space));
};

const getInfiniteSearchSpaceList = async (
  searchKeyword = "",
  type = "",
  take = 12,
  skip = 0
): Promise<SpaceCard[]> => {
  const url =
    searchKeyword === ""
      ? `/space/web?type=${type}&take=${take}&skip=${skip}`
      : `/space/web/search?keyword=${searchKeyword}&type=all&take=${take}&skip=${skip}`;

  const response = await client({
    url,
    method: "GET",
  });

  return response.data.data.map((space: Space) => new SpaceCard(space));
};

const getInfiniteGroupsSpaceList = async (
  groupId = "",
  take = 12,
  skip = 0
): Promise<FeaturedSpaces[]> => {
  const response = await client({
    url: `/space/groups/${groupId}?take=${take}&skip=${skip}`,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return [new FeaturedSpaces(response.data.data)];
};

const getFeaturedSpaceList = async (): Promise<FeaturedSpaces[]> => {
  const response = await client({
    url: `/space/featured-space`,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return response.data.data.map(
    (data: FeaturedSpacesData) => new FeaturedSpaces(data)
  );
};

const updateBookmarkSpace = async (id: string): Promise<void> => {
  await client({
    url: `/space/bookmark`,
    method: "POST",
    data: {
      id,
    },
  });
};

const updateLikeSpace = async (id: string): Promise<{ spaceId: string }> => {
  const response = await client({
    url: `/space/like`,
    method: "POST",
    data: {
      spaceId: id,
    },
  }).catch((error) => {
    throw error;
  });

  return response.data;
};

const updateDisLikeSpace = async (id: string): Promise<{ spaceId: string }> => {
  const response = await client({
    url: `/space/dislike`,
    method: "POST",
    data: {
      spaceId: id,
    },
  }).catch((error) => {
    throw error;
  });

  return response.data;
};

const getPartnersSpaceList = async (
  searchKeyword = "",
  take = 400,
  skip = 0
): Promise<SpaceCard[]> => {
  const url =
    searchKeyword === ""
      ? `/space/web?type=partners&take=${take}&skip=${skip}`
      : `/space/web/search?keyword=${searchKeyword}&&type=partners&take=${take}&skip=${skip}`;

  const response = await client({
    url,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return response.data.data.map((item: Space) => new SpaceCard(item));
};

const getMyVisitedSpaceList = async (
  searchKeyword = "",
  take = 400,
  skip = 0
): Promise<SpaceCard[]> => {
  const url =
    searchKeyword === ""
      ? `/space/web?type=visited&take=${take}&skip=${skip}`
      : `/space/web/search?keyword=${searchKeyword}&type=visited&take=${take}&skip=${skip}`;

  const response = await client({
    url,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return response.data.data.map((item: Space) => new SpaceCard(item));
};

const getMyOwnSpaceList = async (
  searchKeyword = "",
  take = 400,
  skip = 0
): Promise<SpaceCard[]> => {
  const url =
    searchKeyword === ""
      ? `/space/web?type=created&take=${take}&skip=${skip}`
      : `/space/web/search?keyword=${searchKeyword}&type=created&take=${take}&skip=${skip}`;

  const response = await client({
    url,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return response.data.data.map((item: Space) => new SpaceCard(item));
};

const getMyFavoriteSpaceList = async (
  searchKeyword = "",
  take = 400,
  skip = 0
): Promise<SpaceCard[]> => {
  const url =
    searchKeyword === ""
      ? `/space/web?type=bookmarked&take=${take}&skip=${skip}`
      : `/space/web/search?keyword=${searchKeyword}&type=bookmarked&take=${take}&skip=${skip}`;

  const response = await client({
    url,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return response.data.data.map((item: Space) => new SpaceCard(item));
};

const getSpaceDetails = async (spaceId: string): Promise<SpaceDetails> => {
  const response = await client({
    url: `/space/web/spaces/${spaceId}`,
    method: "GET",
  }).catch((error: any) => {
    if (error.response.status === 404 || error.response.status === 400) {
      window.location.href = `/error/${error.response.status}`;
    }

    throw error;
  });

  return new SpaceDetails(response.data.data);
};

const spaceApi = {
  getSpaceList,
  getCreatorSpaceList,
  getInfiniteSearchSpaceList,
  getInfiniteGroupsSpaceList,
  getFeaturedSpaceList,
  updateBookmarkSpace,
  updateLikeSpace,
  updateDisLikeSpace,
  getPartnersSpaceList,
  getMyVisitedSpaceList,
  getMyOwnSpaceList,
  getMyFavoriteSpaceList,
  getSpaceDetails,
};

export default spaceApi;

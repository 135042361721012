import useDeviceBreakPoint from "../../../../hooks/useDeviceBreakPoint";
import MainSpace from "../../../landing/space/MainSpace";
import SpaceCarousel from "../../../landing/space/SpaceCarousel";

import MobileSpaceCarousel from "./MobileSpaceCarousel";

export default function MobileSpace() {
  const { isMobile } = useDeviceBreakPoint();
  return (
    <section className="w-full mt-[74px] mb-[78px]">
      <h2 className="font-semibold text-[28px] lg:text-[48px] break-words px-[18px] md:portrait:px-[32px] md:landscape:px-[58px] leading-[33.6px] lg:leading-[57px]">
        Discover Enchanting <br /> Spaces in Reality
      </h2>
      {isMobile ? <MobileSpaceCarousel /> : <SpaceCarousel />}
      <MainSpace />
    </section>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";

import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import ButtonWithIcon from "../common/Button/ButtonWithIcon";
import EditIcon from "../common/icons/EditIcon";
import MobileProfileEditButton from "../mobile/MobileProfileEditButton";

export default function EditButton() {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceBreakPoint();

  const goToSettingsProfile = () =>
    navigate("/setting", { state: { menu: "profile" } });

  if (isDesktop) {
    return (
      <ButtonWithIcon
        type="button"
        buttonStyle="secondary"
        label="Edit"
        icon={<EditIcon />}
        position="right"
        size="sm"
        onClick={goToSettingsProfile}
      />
    );
  }
  return <MobileProfileEditButton onClick={goToSettingsProfile} />;
}

import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./index.css";

import MobileDeviceCarouselItem from "./MobileDeviceCarouselItem";
import NextButton from "./NextButton";
import PrevButton from "./PrevButton";

const SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  className: "relative mt-[36px] w-full mobile-slick-device",
  variableWidth: true,
  autoplay: true,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  adaptiveHeight: true,
};

const LIST = [
  {
    imageName: "hololens_2",
    downloadLink:
      "https://apps.microsoft.com/store/detail/twinworld-beta/9NCBR51C5M67?hl=en-us&gl=us",
  },
  {
    imageName: "meta_quest_2",
    downloadLink:
      "https://www.meta.com/ko-kr/experiences/6464922583573383/?ranking_trace=116836404732608_6464922583573383_SKYLINEWEB_33225171-95f5-49a1-9889-a97a2fdb183f",
  },
  {
    imageName: "meta_quest_3",
    downloadLink:
      "https://www.meta.com/ko-kr/experiences/6464922583573383/?ranking_trace=116836404732608_6464922583573383_SKYLINEWEB_33225171-95f5-49a1-9889-a97a2fdb183f",
  },
  {
    imageName: "meta_quest_pro",
    downloadLink:
      "https://www.meta.com/ko-kr/experiences/6464922583573383/?ranking_trace=116836404732608_6464922583573383_SKYLINEWEB_33225171-95f5-49a1-9889-a97a2fdb183f",
  },
];

export default function MobileDeviceCarousel() {
  const [deviceName, setDeviceName] = useState(LIST[0].imageName);
  const [deviceLink, setDeviceLink] = useState(LIST[0].downloadLink);

  const name = deviceName.replace(/_/g, " ");

  return (
    <>
      <Slider
        {...SETTINGS}
        beforeChange={(_, next) => {
          setDeviceName(LIST[next].imageName);
          setDeviceLink(LIST[next].downloadLink);
        }}
      >
        {LIST.map((item, index) => (
          <MobileDeviceCarouselItem imageName={item.imageName} key={index} />
        ))}
      </Slider>
      <span className="capitalize text-[20px] font-semibold text-[#242424] min-w-[155px] text-center">
        {name}
      </span>
      <div className="mt-[36px]">
        <button
          type="button"
          className="py-3 font-medium border-[1.5px] border-black rounded-full px-8 bg-twinworldPrimary-200 text-[14px]"
          onClick={() => window.open(deviceLink, "_blank")}
        >
          Download
        </button>
      </div>
    </>
  );
}

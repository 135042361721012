export default function ComputerIcon() {
  return (
    <div className="relative w-[147px] h-[135px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="147"
        height="135"
        viewBox="0 0 147 135"
        fill="none"
      >
        <path
          d="M3 90.075V6.87C3 4.73266 4.89384 3 7.23 3H139.77C142.106 3 144 4.73266 144 6.87V90.075M3 90.075V102.33C3 104.468 4.89384 106.2 7.23 106.2H139.77C142.106 106.2 144 104.468 144 102.33V90.075M3 90.075H144M52.35 132H62.925M62.925 132V106.2M62.925 132H84.075M84.075 132H94.65M84.075 132V106.2"
          stroke="black"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-[50px] left-1/2 w-62 h-46">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="46"
          viewBox="0 0 62 46"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.9454 18.2467C55.9554 18.0078 55.9604 17.7676 55.9604 17.5262C55.9604 7.84676 47.8493 0 37.8437 0C32.226 0 27.2056 2.47351 23.8826 6.35615C22.6723 5.31017 21.0752 4.67433 19.3246 4.67433C15.5447 4.67433 12.4805 7.63866 12.4805 11.2954C12.4805 11.5587 12.4964 11.8184 12.5273 12.0738H12.4631C5.57992 12.0738 0 17.6537 0 24.5369C0 31.42 5.57992 36.9999 12.4631 36.9999H30.2119C30.2258 36.9999 30.2397 36.9999 30.2537 36.9999H52.2629C57.6404 36.9999 61.9997 32.6406 61.9997 27.2631C61.9997 23.1887 59.4972 19.6989 55.9454 18.2467Z"
            fill="#A6D9FF"
          />
          <path
            d="M30.9998 44.9722C30.9998 45.5245 31.4475 45.9722 31.9998 45.9722C32.552 45.9722 32.9998 45.5245 32.9998 44.9722L30.9998 44.9722ZM32.7069 19.293C32.3163 18.9025 31.6832 18.9025 31.2927 19.293L24.9287 25.6569C24.5382 26.0475 24.5382 26.6806 24.9287 27.0712C25.3192 27.4617 25.9524 27.4617 26.3429 27.0712L31.9998 21.4143L37.6566 27.0712C38.0471 27.4617 38.6803 27.4617 39.0708 27.0712C39.4613 26.6806 39.4613 26.0475 39.0708 25.6569L32.7069 19.293ZM32.9998 44.9722L32.9998 20.0001L30.9998 20.0001L30.9998 44.9722L32.9998 44.9722Z"
            fill="#A6D9FF"
          />
          <path
            d="M30.9998 36L30.9998 37L32.9998 37L32.9998 36L30.9998 36ZM32.7069 22.2929C32.3163 21.9024 31.6832 21.9024 31.2927 22.2929L24.9287 28.6569C24.5382 29.0474 24.5382 29.6805 24.9287 30.0711C25.3192 30.4616 25.9524 30.4616 26.3429 30.0711L31.9998 24.4142L37.6566 30.0711C38.0471 30.4616 38.6803 30.4616 39.0708 30.0711C39.4613 29.6805 39.4613 29.0474 39.0708 28.6569L32.7069 22.2929ZM32.9998 36L32.9998 23L30.9998 23L30.9998 36L32.9998 36Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
}

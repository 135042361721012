import { cva } from "class-variance-authority";

import { Size } from "../../../types/common";
import { classNameMerge } from "../../../utils/tailwindClassMerge";

type Props = {
  type: "button" | "submit" | "reset";
  buttonStyle: "primary" | "secondary";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size: Size;
  children: React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const VARIANTS = cva(
  "h-[42px] flex items-center justify-center rounded-full text-[14px] box-border transition ease-in-out hover:duration-300",
  {
    variants: {
      size: {
        sm: "px-4 py-3 min-w-14",
        md: "px-7 py-5 w-15",
        lg: "px-16 py-8 min-w-22",
      },
      style: {
        primary:
          "bg-black text-twinworldPrimary-200 border-2 border-black hover:bg-twinworldPrimary-200 hover:text-black hover:border-black hover:border-2",
        secondary:
          "bg-inherit border border-black hover:bg-twinworldPrimary-200",
      },
    },
    defaultVariants: {
      size: "md",
      style: "primary",
    },
  }
);

export default function Button({
  type = "button",
  buttonStyle = "primary",
  onClick,
  size = "md",
  children,
  ...props
}: Props) {
  const classes = classNameMerge(VARIANTS({ style: buttonStyle, size }));

  return (
    <button className={classes} type={type} onClick={onClick} {...props}>
      {children}
    </button>
  );
}

import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";

type Props = {
  closeModal: () => void;
  itemThumbnail: string;
  itemName: string;
  handleCheckUploadItem: () => void;
};

const defaultSrc = "/images/profile/default_profile.webp";

export default function ItemUploadSuccessModal({
  closeModal,
  itemThumbnail,
  itemName,
  handleCheckUploadItem,
}: Props) {
  const handleImgError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = defaultSrc;
  };

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex justify-center w-full mt-[30px] text-[24px] font-semibold px-[80px] leading-8">
      Successfully uploaded!
    </div>
  );

  return (
    <Modal closeModal={closeModal} title={title} close={close}>
      <div className="flex flex-col items-center mt-[25px] mb-[40px]">
        <img
          src={itemThumbnail}
          alt="item"
          className="mx-[75px] w-[248px] h-[248px] border rounded-[25px] box-border"
          onError={handleImgError}
        />
        <span className="mt-[12px] text-[20px] text-twinworldGray-770 leading-[25px]">
          {itemName}
        </span>
        <div className="flex flex-col items-center gap-[18px] mt-10">
          <button
            className="w-[320px] px-[30px] py-[21px] text-black bg-twinworldPrimary-200 font-semibold text-[16px] rounded-full border-2 border-black hover:text-twinworldPrimary-200 hover:bg-black"
            onClick={handleCheckUploadItem}
          >
            Check item
          </button>
          <button
            className="w-[320px] px-[30px] py-[21px] text-black bg-white font-semibold text-[16px] rounded-full border-2 border-black hover:text-twinworldPrimary-200 hover:bg-black"
            onClick={closeModal}
          >
            Upload more
          </button>
        </div>
      </div>
    </Modal>
  );
}

const EyeSlashIcon = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="&#240;&#159;&#166;&#134; icon &#34;eye off outline&#34;">
      <path
        id="Vector"
        d="M15.6004 14.3888C15.5216 14.389 15.4435 14.3735 15.3707 14.3433C15.2979 14.3131 15.2318 14.2688 15.1763 14.213L1.97653 1.01324C1.86876 0.899807 1.80957 0.74876 1.81157 0.592306C1.81358 0.435853 1.87662 0.286371 1.98726 0.175732C2.09789 0.0650936 2.24738 0.00205233 2.40383 4.92298e-05C2.56028 -0.00195387 2.71133 0.0572398 2.82476 0.165009L16.0245 13.3647C16.1083 13.4486 16.1654 13.5555 16.1886 13.6719C16.2117 13.7882 16.1998 13.9088 16.1544 14.0184C16.109 14.128 16.0322 14.2217 15.9336 14.2876C15.8349 14.3536 15.719 14.3888 15.6004 14.3888Z"
        fill="#949494"
      />
      <path
        id="Vector_2"
        d="M8.98781 11.9884C7.43197 11.9884 5.93162 11.5279 4.5284 10.6197C3.25081 9.79472 2.1007 8.61312 1.20222 7.20727V7.20427C1.94996 6.13292 2.76894 5.22694 3.64867 4.49646C3.65663 4.4898 3.66312 4.48157 3.66774 4.47228C3.67235 4.46299 3.67499 4.45284 3.67548 4.44248C3.67597 4.43212 3.67431 4.42177 3.6706 4.41209C3.66689 4.4024 3.66121 4.39359 3.65392 4.38621L2.90694 3.64035C2.89366 3.62697 2.87584 3.61907 2.85701 3.61824C2.83817 3.6174 2.81972 3.62369 2.80531 3.63585C1.87083 4.42333 1.00348 5.38931 0.214868 6.52029C0.0791906 6.71502 0.00446741 6.9457 0.000194108 7.183C-0.00407919 7.4203 0.0622903 7.65352 0.190869 7.85301C1.18122 9.40285 2.45582 10.7082 3.87629 11.6273C5.47563 12.6634 7.19685 13.1884 8.98781 13.1884C9.95452 13.1854 10.9144 13.0261 11.8303 12.7167C11.8423 12.7126 11.8532 12.7054 11.8617 12.696C11.8703 12.6865 11.8763 12.6751 11.8792 12.6626C11.8821 12.6502 11.8818 12.6372 11.8783 12.625C11.8747 12.6127 11.8682 12.6015 11.8591 12.5925L11.0499 11.7833C11.0313 11.7651 11.0082 11.7521 10.983 11.7455C10.9579 11.739 10.9314 11.7391 10.9063 11.7458C10.2796 11.9072 9.63496 11.9887 8.98781 11.9884Z"
        fill="#949494"
      />
      <path
        id="Vector_3"
        d="M17.8066 6.53587C16.8144 5.0014 15.5271 3.69792 14.0841 2.76607C12.4877 1.73409 10.7253 1.18848 8.98757 1.18848C8.03111 1.19017 7.08174 1.35282 6.17926 1.66959C6.16723 1.67378 6.15647 1.68095 6.14799 1.69044C6.1395 1.69994 6.13357 1.71143 6.13076 1.72385C6.12795 1.73627 6.12834 1.7492 6.1319 1.76142C6.13547 1.77364 6.14209 1.78476 6.15113 1.79371L6.95924 2.60182C6.97805 2.62032 7.00141 2.63352 7.02695 2.6401C7.0525 2.64669 7.07933 2.64642 7.10474 2.63932C7.71859 2.47327 8.35166 2.38892 8.98757 2.38845C10.5134 2.38845 12.0093 2.85457 13.4331 3.77592C14.7347 4.61591 15.8983 5.79638 16.799 7.18835L16.8001 7.19135L16.799 7.19435C16.1452 8.22368 15.3338 9.14399 14.3946 9.92167C14.3865 9.9283 14.38 9.93653 14.3753 9.94584C14.3706 9.95515 14.3679 9.96533 14.3674 9.97574C14.3668 9.98615 14.3685 9.99655 14.3722 10.0063C14.3759 10.016 14.3816 10.0249 14.389 10.0323L15.1352 10.7782C15.1484 10.7915 15.1661 10.7994 15.1849 10.8003C15.2036 10.8012 15.222 10.795 15.2364 10.783C16.2392 9.93874 17.1072 8.94633 17.8104 7.84009C17.9347 7.64515 18.0004 7.41862 17.9997 7.18743C17.9991 6.95623 17.9321 6.73009 17.8066 6.53587Z"
        fill="#949494"
      />
      <path
        id="Vector_4"
        d="M9.00076 3.58887C8.73111 3.58872 8.4623 3.61891 8.1994 3.67887C8.18612 3.68162 8.17384 3.68793 8.16386 3.69711C8.15388 3.7063 8.14658 3.71802 8.14273 3.73102C8.13888 3.74403 8.13863 3.75783 8.14201 3.77097C8.14538 3.7841 8.15226 3.79607 8.1619 3.80561L12.3839 8.02652C12.3935 8.03617 12.4054 8.04304 12.4186 8.04642C12.4317 8.04979 12.4455 8.04954 12.4585 8.04569C12.4715 8.04185 12.4833 8.03455 12.4924 8.02457C12.5016 8.01459 12.5079 8.0023 12.5107 7.98903C12.6309 7.4618 12.6308 6.91427 12.5104 6.3871C12.3899 5.85992 12.1523 5.36665 11.8151 4.94392C11.4779 4.52118 11.0498 4.17985 10.5625 3.94526C10.0753 3.71067 9.54151 3.58886 9.00076 3.58887Z"
        fill="#949494"
      />
      <path
        id="Vector_5"
        d="M5.61789 6.35135C5.60836 6.34171 5.59638 6.33484 5.58325 6.33146C5.57011 6.32809 5.55631 6.32834 5.5433 6.33218C5.5303 6.33603 5.51858 6.34333 5.50939 6.35331C5.50021 6.36329 5.4939 6.37557 5.49115 6.38885C5.35515 6.98297 5.37223 7.60189 5.54078 8.1876C5.70932 8.77332 6.02381 9.30665 6.45478 9.73762C6.88575 10.1686 7.41909 10.4831 8.0048 10.6516C8.59052 10.8202 9.20944 10.8373 9.80356 10.7013C9.81683 10.6985 9.82912 10.6922 9.8391 10.683C9.84908 10.6738 9.85638 10.6621 9.86022 10.6491C9.86407 10.6361 9.86432 10.6223 9.86095 10.6092C9.85757 10.596 9.8507 10.5841 9.84106 10.5745L5.61789 6.35135Z"
        fill="#949494"
      />
    </g>
  </svg>
);

export default EyeSlashIcon;

type Props = {
  icon: JSX.Element;
  title: React.ReactNode;
  description: string;
  marginTop: string;
};

export default function FunctionalityItem({
  icon,
  title,
  description,
  marginTop,
}: Props) {
  return (
    <li
      className={`flex flex-col justify-center items-start gap-7 px-16 w-[534px] h-[424px] ${marginTop} border rounded-[30px] border-1 border-twinworldGray-350 bg-white/20 backdrop-blur-lg first:ml-[60px]`}
    >
      {icon}
      <h4 className="text-3xl font-bold leading-snug">{title}</h4>
      <p className="w-[398px] h-[120px] text-lg leading-snug">{description}</p>
    </li>
  );
}

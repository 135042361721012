import { cva } from "class-variance-authority";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { classNameMerge } from "../../utils/tailwindClassMerge";

type Props = {
  src: string;
  width: string;
  height?: string;
  alt: string;
  defaultSrc?: string;
  border?: boolean;
  rounded?: string;
  hoverEffect?: string;
  aspectRatio?: string;
};

const IMAGE_VARIANTS = cva("", {
  variants: {
    border: {
      true: "border border-twinworldGray-35",
      false: "border-0",
    },
  },
});

export default function Image({
  src,
  width,
  height,
  alt,
  defaultSrc,
  border = false,
  rounded = "none",
  hoverEffect,
  aspectRatio,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);

  const handleImgLoaded = () => setIsLoading(false);

  const handleImgError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    if (defaultSrc) {
      event.currentTarget.src = defaultSrc;
      return;
    }

    event.currentTarget.src = "/images/default.webp";
  };

  const imageClasses = classNameMerge(IMAGE_VARIANTS({ border }));
  const sectionClasses = classNameMerge(
    `${width} ${aspectRatio} ${height} relative flex items-center justify-center overflow-hidden ${hoverEffect} ${rounded}`,
    imageClasses
  );
  return (
    <section className={sectionClasses}>
      {isLoading && (
        <div className={`${width} ${height} absolute z-10 overflow-hidden`}>
          <Skeleton
            height="100%"
            style={{ lineHeight: "inherit", borderRadius: "inherit" }}
          />
        </div>
      )}

      <img
        className={`${width} ${height} object-cover`}
        src={src}
        alt={alt}
        onLoad={handleImgLoaded}
        onError={handleImgError}
      />
    </section>
  );
}

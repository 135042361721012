type Props = {
  isHovered?: boolean;
};

export default function OculusIcon({ isHovered = false }: Props) {
  return (
    <div className="flex items-center justify-center w-[22px] h-[22px] ">
      <svg
        width="20"
        height="12"
        viewBox="0 0 20 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Exclude"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12H14C17.3137 12 20 9.31371 20 6C20 2.68629 17.3137 0 14 0H6ZM6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8H14C15.1046 8 16 7.10457 16 6C16 4.89543 15.1046 4 14 4H6Z"
          fill={`${isHovered ? "#A6D9FF" : "#000000"}`}
        />
      </svg>
    </div>
  );
}

import {
  useQuery,
  useInfiniteQuery,
  keepPreviousData,
} from "@tanstack/react-query";

import { FetchList } from "../../../types/common";
import checkSpecialChar from "../../../utils/checkSpecialChar";
import itemApi from "../api";

export const useItemListActions = () => {
  const useItemList = ({
    condition,
    keyword,
    categoryId,
    take,
    skip,
    uploaderId,
  }: FetchList) => {
    const { isPending, data } = useQuery({
      queryKey: [
        "itemList",
        { condition, keyword, categoryId, take, skip, uploaderId },
      ],
      queryFn: () =>
        itemApi.getItemList({
          condition,
          keyword,
          categoryId,
          take,
          skip,
          uploaderId,
        }),
      enabled:
        (!!condition || uploaderId !== undefined) &&
        !checkSpecialChar(keyword || ""),
    });

    return { isPending, data };
  };

  const useUploaderItemList = ({
    condition,
    take,
    skip,
    uploaderId,
  }: FetchList) => {
    const { isPending, data } = useQuery({
      queryKey: ["itemList", { condition, take, skip, uploaderId }],
      queryFn: () =>
        itemApi.getItemList({
          condition,

          take,
          skip,
          uploaderId,
        }),
      enabled: !!uploaderId,
    });

    return { isPending, data };
  };

  const useInfiniteItemList = (
    condition: string,
    keyword: string,
    categoryId: string,
    take: number,
    skip: number
  ) => {
    const { isPending, fetchNextPage, hasNextPage, isError, data, refetch } =
      useInfiniteQuery({
        queryKey: ["itemList", { condition, keyword, categoryId, take, skip }],
        queryFn: ({ pageParam }) => {
          const result = itemApi.getInfiniteSearchItemList(
            condition,
            keyword,
            categoryId,
            take,
            (pageParam - 1) * take
          );
          return result;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
        placeholderData: keepPreviousData,
      });

    return { isPending, fetchNextPage, hasNextPage, isError, data, refetch };
  };

  // staleTime, gcTime을 짧게 가져가면 Item list에도 영향이 가기 때문에 useInfiniteItemList와 분리.
  const useInfiniteMyItemList = (
    condition: string,
    keyword: string,
    categoryId: string,
    take: number,
    skip: number
  ) => {
    const { isPending, fetchNextPage, hasNextPage, isError, data, refetch } =
      useInfiniteQuery({
        queryKey: ["myItem", { condition, keyword, categoryId, take, skip }],
        queryFn: ({ pageParam }) => {
          const result = itemApi.getInfiniteSearchItemList(
            condition,
            "",
            "all",
            take,
            (pageParam - 1) * take
          );
          return result;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
        refetchOnMount: true,
        staleTime: 500,
        gcTime: 500,
      });

    return { isPending, fetchNextPage, hasNextPage, isError, data, refetch };
  };

  const useInfiniteUserItemList = (
    userId: string,
    take: number,
    skip: number
  ) => {
    const { isPending, fetchNextPage, hasNextPage, isError, data, refetch } =
      useInfiniteQuery({
        queryKey: ["userItemList", { userId, take, skip }],
        queryFn: ({ pageParam }) => {
          const result = itemApi.getInfiniteSearchUserItemList(
            userId,
            take,
            (pageParam - 1) * take
          );
          return result;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.length < take ? undefined : nextPage;
        },
      });

    return { isPending, fetchNextPage, hasNextPage, isError, data, refetch };
  };

  return {
    useItemList,
    useInfiniteItemList,
    useInfiniteUserItemList,
    useInfiniteMyItemList,
    useUploaderItemList,
  };
};

type Props = {
  type: "card" | "details";
};
export default function OptimizingFailIcon({ type = "card" }: Props) {
  return (
    <div className="relative">
      {type === "card" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="102"
          height="63"
          viewBox="0 0 102 63"
          fill="none"
        >
          <path
            d="M91.2317 31.1719L90.4824 31.1389L90.4596 31.6582L90.9378 31.8619L91.2317 31.1719ZM39.5209 11.4791L39.0243 12.0411L39.6015 12.5511L40.096 11.9606L39.5209 11.4791ZM21.205 20.909L21.2172 21.6589L22.0478 21.6453L21.9497 20.8204L21.205 20.909ZM49.4916 61.9996V61.2495L49.4842 61.2496L49.4916 61.9996ZM90.5098 29.8945C90.5098 30.3116 90.5006 30.7264 90.4824 31.1389L91.981 31.2049C92.0001 30.7704 92.0098 30.3336 92.0098 29.8945H90.5098ZM62.0391 1.75C77.7709 1.75 90.5098 14.3587 90.5098 29.8945H92.0098C92.0098 13.5144 78.5835 0.25 62.0391 0.25V1.75ZM40.096 11.9606C45.3182 5.72369 53.2079 1.75 62.0391 1.75V0.25C52.7486 0.25 44.4431 4.43224 38.9459 10.9976L40.096 11.9606ZM32.1678 9.45508C34.8032 9.45508 37.2046 10.433 39.0243 12.0411L40.0176 10.9171C37.9328 9.07475 35.181 7.95508 32.1678 7.95508V9.45508ZM21.8789 19.6208C21.8789 14.0143 26.4775 9.45508 32.1678 9.45508V7.95508C25.6649 7.95508 20.3789 13.1701 20.3789 19.6208H21.8789ZM21.9497 20.8204C21.903 20.4272 21.8789 20.027 21.8789 19.6208H20.3789C20.3789 20.0863 20.4065 20.5457 20.4602 20.9975L21.9497 20.8204ZM1.75 41.4534C1.75 30.6299 10.4359 21.8349 21.2172 21.6589L21.1927 20.159C9.59395 20.3485 0.25 29.8097 0.25 41.4534H1.75ZM21.5472 61.2506C10.6135 61.2506 1.75 52.3871 1.75 41.4534H0.25C0.25 53.2155 9.78509 62.7506 21.5472 62.7506V61.2506ZM49.2837 61.2506H21.5472V62.7506H49.2837V61.2506ZM49.4842 61.2496C49.4175 61.2503 49.3506 61.2506 49.2837 61.2506V62.7506C49.3556 62.7506 49.4274 62.7502 49.4991 62.7495L49.4842 61.2496ZM84.9459 61.2496H49.4916V62.7496H84.9459V61.2496ZM100.248 45.947C100.248 54.3984 93.3972 61.2496 84.9459 61.2496V62.7496C94.2257 62.7496 101.748 55.2268 101.748 45.947H100.248ZM90.9378 31.8619C96.4126 34.194 100.248 39.6234 100.248 45.947H101.748C101.748 39.0011 97.5339 33.0413 91.5256 30.4819L90.9378 31.8619Z"
            fill="#D7E0ED"
          />

          <text
            className="fill-twinworldRed"
            x="50%"
            y="60%"
            textAnchor="middle"
            dominantBaseline="middle"
            fontWeight="bold"
            fontSize="24"
          >
            !
          </text>
        </svg>
      )}
      {type === "details" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="130"
          height="81"
          viewBox="0 0 130 81"
          fill="none"
        >
          <path
            d="M116.496 40.1361L115.747 40.1023L115.724 40.6179L116.197 40.8238L116.496 40.1361ZM50.3079 14.5712L49.808 15.1303L50.3889 15.6497L50.8857 15.0494L50.3079 14.5712ZM26.8638 26.7905L26.8845 27.5402L27.7048 27.5176L27.6087 26.7026L26.8638 26.7905ZM62.8466 79.9977V79.2477L62.8369 79.2478L62.8466 79.9977ZM115.785 38.4202C115.785 38.984 115.772 39.5448 115.747 40.1023L117.245 40.1699C117.272 39.5899 117.285 39.0065 117.285 38.4202H115.785ZM79.1317 1.75C99.3743 1.75 115.785 18.1675 115.785 38.4202H117.285C117.285 17.3397 100.203 0.25 79.1317 0.25V1.75ZM50.8857 15.0494C57.6099 6.92418 67.7665 1.75 79.1317 1.75V0.25C67.3006 0.25 56.727 5.63837 49.7301 14.093L50.8857 15.0494ZM40.8957 11.7286C44.3194 11.7286 47.4412 13.0141 49.808 15.1303L50.8078 14.0121C48.1767 11.6596 44.7024 10.2286 40.8957 10.2286V11.7286ZM27.5156 25.1151C27.5156 17.7216 33.5064 11.7286 40.8957 11.7286V10.2286C32.6773 10.2286 26.0156 16.8938 26.0156 25.1151H27.5156ZM27.6087 26.7026C27.5472 26.1823 27.5156 25.6525 27.5156 25.1151H26.0156C26.0156 25.7114 26.0507 26.2999 26.119 26.8784L27.6087 26.7026ZM1.75 53.3901C1.75 39.3507 12.9381 27.9241 26.8845 27.5402L26.8432 26.0408C12.087 26.447 0.25 38.5361 0.25 53.3901H1.75ZM27.6099 79.25C13.3279 79.25 1.75 67.6721 1.75 53.3901H0.25C0.25 68.5006 12.4994 80.75 27.6099 80.75V79.25ZM62.4951 79.25H27.6099V80.75H62.4951V79.25ZM62.8369 79.2478C62.7232 79.2493 62.6092 79.25 62.4951 79.25V80.75C62.6157 80.75 62.7361 80.7492 62.8563 80.7477L62.8369 79.2478ZM108.212 79.2477H62.8466V80.7477H108.212V79.2477ZM128.251 59.2087C128.251 70.276 119.279 79.2477 108.212 79.2477V80.7477C120.107 80.7477 129.751 71.1044 129.751 59.2087H128.251ZM116.197 40.8238C123.292 43.9099 128.251 50.9811 128.251 59.2087H129.751C129.751 50.3628 124.418 42.764 116.795 39.4483L116.197 40.8238Z"
            fill="#D7E0ED"
          />

          <text
            className="fill-twinworldRed"
            x="50%"
            y="60%"
            textAnchor="middle"
            dominantBaseline="middle"
            fontWeight="bold"
            fontSize="24"
          >
            !
          </text>
        </svg>
      )}
    </div>
  );
}

import { useNavigate } from "react-router-dom";

import { useSpaceActions } from "../../../domains/space/hook/useSpaceActions";
import { SpaceType } from "../../../domains/space/interface";

import ScrollableSpaceList from "./ScrollableSpaceList";

export default function MainSpace() {
  const navigate = useNavigate();

  const { useSpaceList } = useSpaceActions();
  const { data: firstSpaceData } = useSpaceList({
    type: "trending" as SpaceType,
    take: 4,
    skip: 0,
  });

  const { data: secondSpaceData } = useSpaceList({
    type: "trending" as SpaceType,
    take: 4,
    skip: 4,
  });

  const handleClickCard = (spaceId: string) => navigate(`/spaces/${spaceId}`);

  return (
    <section className="flex flex-col items-center">
      <h3 className="mt-[78px] xl:mt-[65px] mb-[24px] xl:mb-[82px] text-[28px] xl:text-[65px] font-semibold leading-tight w-full text-left md:text-center px-[18px] md:portrait:px-[32px] md:landscape:px-[58px] xl:portrait:px-0 xl:landscape:px-0">
        Twinworld Spaces
      </h3>
      <ScrollableSpaceList
        list={firstSpaceData}
        handleClickCard={handleClickCard}
      />
      <div className="w-full mt-[24px] xl:mt-[66px]">
        <ScrollableSpaceList
          list={secondSpaceData}
          handleClickCard={handleClickCard}
        />
      </div>

      <button
        type="button"
        className="px-[32px] xl:px-24 py-[10px] mt-[29px] xl:mt-[76px] border-[1.5px] xl:border-2 border-black rounded-full font-semibold bg-twinworldPrimary-200 hover:bg-black hover:text-twinworldPrimary-200"
        onClick={() => navigate("/spaces?type=trending")}
      >
        more
      </button>
    </section>
  );
}

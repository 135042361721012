import { GoogleOAuthProvider } from "@react-oauth/google";

import SignInButton from "../button/SignInButton";
import Modal from "../common/Modal";
import EmailIcon from "../common/icons/EmailIcon";
import ModalClose from "../common/icons/ModalClose";
import OculusIcon from "../common/icons/OculusIcon";
import SignInLogo from "../common/icons/SignInLogo";
import useModals from "../hooks/useModals";

import EmailSignInModal from "./EmailSignInModal";
import GoogleLoginButton from "./GoogleLoginButton";
import SignUpModal from "./SignUpModal";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function SignInModal({ closeModal }: Props) {
  const win: Window = window;
  const { openModal, closeModal: closeModals } = useModals();

  const handleOpenSignInModal = () => {
    closeModals(SignInModal);
    openModal(EmailSignInModal, {});
  };

  const handleOpenSignUpModal = () => {
    closeModals(SignInModal);
    openModal(SignUpModal, {});
  };

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex justify-center w-full mt-6 mb-14">
      <SignInLogo />
    </div>
  );

  const handleClickOculusSignIn = () => {
    win.location = `https://auth.oculus.com/sso/?redirect_uri=${process.env.REACT_APP_WEB_URL}/sign-in/oculus&organization_id=140381008924433`;
  };

  return (
    <Modal
      maxWidth="max-w-[480px]"
      title={title}
      close={close}
      closeModal={closeModal}
    >
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-col items-center gap-5 mx-20">
          <SignInButton
            icon={<OculusIcon />}
            label="Oculus"
            onClick={() => handleClickOculusSignIn()}
          />
          <GoogleOAuthProvider clientId="952391184400-iuu400ksq5pq118cmdt90b9clvgsm970.apps.googleusercontent.com">
            <GoogleLoginButton />
          </GoogleOAuthProvider>
          <SignInButton
            icon={<EmailIcon />}
            label="Email"
            onClick={handleOpenSignInModal}
          />
        </div>
        <p className="mt-[29px] mb-6">or</p>
        <button
          className="border-2 rounded-full w-80 h-14 mb-[75px] font-semibold border-twinworldGray-300 hover:transition-background-color hover:duration-300 hover:text-twinworldPrimary-200 hover:bg-black"
          type="button"
          onClick={handleOpenSignUpModal}
        >
          Create New Account
        </button>
      </div>
    </Modal>
  );
}

import { Listbox } from "@headlessui/react";

import SelectDropdownEditIcon from "../common/icons/SelectDropdownEditIcon";

type SelectItem = {
  idx: string;
  name: string;
  unavailable?: boolean;
};

type Props = {
  list: SelectItem[];
  value: SelectItem;
  setValue: (selectItem: SelectItem) => void;
};

export default function MobileItemSelectDropdown({
  list,
  value,
  setValue,
}: Props) {
  return (
    <Listbox value={value} onChange={setValue}>
      {({ open }) => (
        <>
          <div
            className={`${
              open &&
              "fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-black/70 z-50"
            }`}
          />
          <div>
            <Listbox.Button className="flex flex-row items-center px-[20px] py-[14px] relative text-twinworldGray-700 border border-twinworldGray-350 w-full md:w-[339px] box-border rounded-full cursor-pointer">
              <div className="text-[14px]">{value.name}</div>
              <span className={`ml-auto h-6 flex items-center`}>
                <div
                  className={`${
                    open ? "rotate-180 transition-all" : "transition-all"
                  }`}
                >
                  <SelectDropdownEditIcon />
                </div>
              </span>
            </Listbox.Button>
          </div>

          {open && (
            <Listbox.Options
              static
              className="fixed bottom-0 left-0 right-0 w-full bg-white text-twinworldGray-900 text-[15px] leading-[18px] px-[36px] pt-[37px] pb-[48px] box-border border border-none rounded-tl-[20px] rounded-tr-[20px] z-50"
            >
              <div className="flex flex-row gap-[32px] flex-wrap justify-between">
                {list.map((item) => (
                  <Listbox.Option
                    key={item.idx}
                    value={item}
                    className="flex w-full truncate cursor-pointer basis-5/12 grow"
                  >
                    <span className="w-full overflow-hidden text-ellipsis">
                      {item.name}
                    </span>
                  </Listbox.Option>
                ))}
              </div>
            </Listbox.Options>
          )}
        </>
      )}
    </Listbox>
  );
}

export default function DrawObjectIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4868_8893"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="60"
        height="60"
      >
        <rect width="60" height="60" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4868_8893)">
        <path
          d="M30.6292 38.8997L46.9888 22.585L43.3483 18.9895L27.0337 35.3041L30.6292 38.8997ZM12.6966 34.8547C12.6966 35.7835 13.0412 36.5326 13.7303 37.1019C14.4195 37.6712 15.4831 38.0607 16.9213 38.2704C17.2809 38.3304 17.573 38.4952 17.7978 38.7648C18.0225 39.0345 18.1199 39.3491 18.0899 39.7086C18.0599 40.0982 17.9101 40.4128 17.6404 40.6525C17.3708 40.8922 17.0562 40.982 16.6966 40.9221C14.5094 40.5925 12.8464 39.9259 11.7079 38.9221C10.5693 37.9184 10 36.5626 10 34.8547C10 33.057 10.764 31.5888 12.2921 30.4502C13.8202 29.3116 15.9476 28.6225 18.6742 28.3828C19.9925 28.2629 20.9738 28.0232 21.618 27.6637C22.2622 27.3041 22.5843 26.8098 22.5843 26.1806C22.5843 25.4015 22.2472 24.7873 21.573 24.3379C20.8989 23.8884 19.7978 23.5438 18.2697 23.3041C17.8801 23.2442 17.573 23.0794 17.3483 22.8098C17.1236 22.5401 17.0262 22.2105 17.0562 21.821C17.0861 21.4315 17.2509 21.1244 17.5506 20.8997C17.8502 20.6749 18.1798 20.5925 18.5393 20.6525C20.7566 21.012 22.4345 21.6487 23.573 22.5626C24.7116 23.4764 25.2809 24.6824 25.2809 26.1806C25.2809 27.5588 24.7191 28.6824 23.5955 29.5513C22.4719 30.4203 20.9064 30.9296 18.8989 31.0794C16.8315 31.2292 15.2809 31.6188 14.2472 32.248C13.2135 32.8772 12.6966 33.7461 12.6966 34.8547ZM31.4831 41.8659L24.0674 34.4502L41.9551 16.6075C42.3745 16.188 42.8464 15.9858 43.3708 16.0008C43.8951 16.0158 44.367 16.218 44.7865 16.6075L49.3708 21.1468C49.7903 21.5663 50 22.0457 50 22.585C50 23.1244 49.7903 23.6038 49.3708 24.0232L31.4831 41.8659ZM24.3371 43.3491C23.8277 43.4689 23.3783 43.3341 22.9888 42.9446C22.5993 42.5551 22.4644 42.1056 22.5843 41.5963L24.0674 34.4502L31.4831 41.8659L24.3371 43.3491Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import MainWrapper from "../components/common/MainWrapper";
import SubWrapper from "../components/common/SubWrapper";
import TabMenu from "../components/common/menu/TabMenu";
import ThumbnailImageGuide from "../components/guide/ThumbnailImageGuide";
import UploadYourItem from "../components/guide/UploadYourItem";

const MENU_LIST = [{ label: "Upload Item" }, { label: "Thumbnail Guide" }];

type Location = {
  hash?: string;
};

const Guide = () => {
  const { hash } = useLocation() as Location;
  const [menu, setMenu] = useState("Upload Item");

  useEffect(() => {
    if (hash) {
      if (hash === "#upload-your-item") setMenu("Upload Item");
      else setMenu("Thumbnail Guide");
    }
  }, [hash]);

  return (
    <MainWrapper>
      <div className="flex flex-col items-center justify-center w-full gap-8">
        <TabMenu
          menuList={MENU_LIST}
          selectedMenu={menu}
          setSelectedMenu={setMenu}
        />
      </div>

      <SubWrapper>
        {menu === "Upload Item" && <UploadYourItem />}
        {menu === "Thumbnail Guide" && <ThumbnailImageGuide />}
      </SubWrapper>
    </MainWrapper>
  );
};
export default Guide;

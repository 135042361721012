import { useEffect, useState } from "react";

const VISIBLE_SCROLL_BAR_DISTANCE = 300;

export default function ScrollToTopButton() {
  const [isHovered, setIsHovered] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const handleScroll = () => {
    const { scrollTop } = document.documentElement;
    if (scrollTop > VISIBLE_SCROLL_BAR_DISTANCE) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsHovered(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      className={`fixed flex flex-col items-center justify-center right-[8px] 3xl:right-40 bottom-[30px] 
        transition-opacity ease-in-out rounded-full ${
          isButtonVisible ? "opacity-100" : "opacity-0"
        }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
      >
        <g filter="url(#filter0_d_3288_56297)">
          <circle
            cx="23"
            cy="23"
            r="20"
            fill={isHovered ? "black" : "#A6D9FF"}
          />
          <circle cx="23" cy="23" r="20" stroke="black" strokeWidth="1.5" />
        </g>
        <path
          d="M29.9995 27L22.9995 20"
          stroke={isHovered ? "#A6D9FF" : "black"}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M16 27L23 20"
          stroke={isHovered ? "#A6D9FF" : "black"}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <defs>
          <filter
            id="filter0_d_3288_56297"
            x="0.25"
            y="0.25"
            width="49.5"
            height="49.5"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3288_56297"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_3288_56297"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </button>
  );
}

export default function LinkedInIcon({ isHover }: { isHover: boolean }) {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2100_2709)">
        <path
          d="M16.7481 33.3475C13.4503 33.3475 10.2266 32.3696 7.48458 30.5375C4.74258 28.7054 2.60545 26.1013 1.34344 23.0546C0.0814394 20.0078 -0.248755 16.6553 0.394614 13.4209C1.03798 10.1865 2.62602 7.2155 4.95791 4.88364C8.08485 1.7567 12.3259 0 16.7481 0C21.1702 0 25.4113 1.7567 28.5382 4.88364C31.6651 8.01058 33.4218 12.2516 33.4218 16.6738C33.4218 21.096 31.6651 25.337 28.5382 28.4639C26.9935 30.0171 25.1561 31.2484 23.1323 32.0867C21.1085 32.925 18.9386 33.3535 16.7481 33.3475ZM19.791 15.5956C21.0434 15.5956 21.7057 16.483 21.7057 18.1624V24.2362H25.4841V17.7279C25.4841 14.5275 23.8575 12.6175 21.1305 12.6175C20.4452 12.5866 19.7648 12.7453 19.1638 13.076C18.5629 13.4068 18.0649 13.8969 17.7244 14.4923V12.8842H13.945C13.9941 13.9375 13.945 24.1343 13.945 24.2372H17.7244V17.8965C17.7082 17.5845 17.7506 17.2722 17.8494 16.9758C17.9956 16.5764 18.2594 16.2308 18.606 15.9844C18.9526 15.738 19.3658 15.6024 19.791 15.5956ZM8.07867 12.8842V24.2372H11.858V12.8842H8.07867ZM9.99243 7.41063C9.72235 7.38637 9.45016 7.41821 9.19296 7.50415C8.93576 7.59008 8.6991 7.72826 8.49784 7.91C8.29658 8.09174 8.13506 8.31313 8.02342 8.56026C7.91178 8.80739 7.85243 9.07493 7.84911 9.34608C7.84578 9.61724 7.89854 9.88615 8.00407 10.136C8.10961 10.3857 8.26564 10.611 8.46238 10.7977C8.65912 10.9843 8.89232 11.1282 9.14733 11.2205C9.40234 11.3127 9.67366 11.3512 9.94427 11.3336H9.96835C10.2396 11.358 10.513 11.3259 10.7713 11.2393C11.0295 11.1527 11.267 11.0134 11.4686 10.8302C11.6702 10.6471 11.8317 10.4241 11.9427 10.1754C12.0537 9.92665 12.1119 9.65758 12.1136 9.3852C12.1152 9.11282 12.0604 8.84305 11.9524 8.59297C11.8445 8.34289 11.6858 8.11794 11.4864 7.93234C11.287 7.74675 11.0513 7.60455 10.7942 7.51475C10.537 7.42495 10.264 7.38949 9.99243 7.41063Z"
          fill={isHover ? "#ffffff" : "#949494"}
        />
      </g>
      <defs>
        <clipPath id="clip0_2100_2709">
          <rect
            width="33.3476"
            height="33.3475"
            fill="white"
            transform="translate(0.0742188)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

import RightArrowIcon from "./RightArrowIcon";

export default function MetaQuestButton() {
  return (
    <button
      className="2xl:w-[575px] w-[550px] flex justify-between items-center gap-[70px] h-24 pr-3 pl-11 rounded-full backdrop-blur bg-[#71c2ff]/25 absolute 2xl:bottom-16 2xl:right-20 bottom-4 right-1"
      type="button"
      onClick={() =>
        window.open(
          "https://www.meta.com/ko-kr/experiences/6464922583573383/?ranking_trace=116836404732608_6464922583573383_SKYLINEWEB_33225171-95f5-49a1-9889-a97a2fdb183f",
          "_blank"
        )
      }
    >
      <span className="text-3xl font-semibold leading-7 text-white">
        Available on Meta Quest
      </span>
      <RightArrowIcon />
    </button>
  );
}

import useLazyLoadVideo from "../../../../hooks/useLazyLoadVideo";

export default function MobileValue03Mask() {
  const ref = useLazyLoadVideo();

  return (
    <section ref={ref} className="relative w-full">
      <svg
        className="absolute -bottom-[0.6px] -right-[0.3px] z-10"
        xmlns="http://www.w3.org/2000/svg"
        width="104"
        height="64"
        viewBox="0 0 104 64"
        fill="none"
      >
        <path
          d="M22.4088 43.3771C22.4088 56.3936 6.89503 63.6829 0 63.6829H104V0.682858C104 14.0118 90.7845 22.03 82.7403 22.03H42.5193C21.8343 22.03 22.4088 41.8151 22.4088 43.3771Z"
          fill="#F5FAFD"
        />
      </svg>
      <video
        className="block w-full rounded-[20px]"
        autoPlay
        muted
        loop
        disablePictureInPicture
        playsInline
        poster="/images/landing/thumbnails/value_3.webp"
      >
        <source data-src="/videos/value_3.webm" type="video/webm" />
        <source data-src="/videos/value_3.mp4" type="video/mp4" />
      </video>
    </section>
  );
}

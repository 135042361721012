import { Fragment, useState, useEffect } from "react";

import AnythingItem from "./AnythingItem";

// TODO: tablet일 때 position 조정, 이미지 크기 변경 (혹은 통일) 필요
const LIST = [
  {
    title: "Exhibition",
    description:
      "A new dimension of mixed reality exhibitions that interact with art objects.",
    imageSrc: "/images/landing/mobileAnything/1.webp",
    position: "bg-left",
  },
  {
    title: "Education",
    description: (
      <Fragment>
        Live education programs using immersive 3D objects in fields such as
        <br />
        architecture, design, medicine, and language.
      </Fragment>
    ),
    imageSrc: "/images/landing/mobileAnything/2.webp",
    position: "bg-center",
  },
  {
    title: "Community",
    description: (
      <Fragment>
        Forming a community where users from different locations share
        <br />
        cultural values, knowledge, and fun experiences.
      </Fragment>
    ),
    imageSrc: "/images/landing/mobileAnything/3.webp",
    position: "bg-center",
  },
  {
    title: "Artwork",
    description: (
      <Fragment>
        Creating various art pieces such as sculptures, paintings,
        <br />
        and animations in a 3D environment.
      </Fragment>
    ),
    imageSrc: "/images/landing/mobileAnything/4.webp",
    position: "bg-right",
  },
  {
    title: "Business",
    description: (
      <Fragment>
        Engaging in business activities with employees from around the world,
        <br />
        including meetings, product demonstrations, education, and training.
      </Fragment>
    ),
    imageSrc: "/images/landing/mobileAnything/5.webp",
    position: "bg-center",
  },
  {
    title: "Entertainment",
    description: (
      <Fragment>
        Immersive experiences that captivate your senses, such as games,
        <br />
        concerts, parties, events, and explorations.
      </Fragment>
    ),
    imageSrc: "/images/landing/mobileAnything/6.webp",
    position: "bg-center",
  },
];

export default function MobileAnything() {
  const [itemIndex, setItemIndex] = useState(-1);

  const handleClick = (index: number) => {
    if (index === itemIndex) {
      setItemIndex(-1);
      return;
    }

    setItemIndex(index);
  };

  useEffect(() => {
    setItemIndex(0);
  }, []);

  return (
    <section className="mt-[90px] px-[18px] md:portrait:px-[32px] md:landscape:px-[58px] flex flex-col items-center justify-center">
      <h2 className="text-[70px] font-bold opacity-90 leading-[80px]">
        Anything
      </h2>
      <div className="mt-[23px] text-[18px] leading-[27px] text-center">
        Anything you can imagine is <br /> possible in TwinWorld
      </div>
      <section className="relative flex flex-col gap-3 mt-[28px] w-full">
        {LIST.map((item, index) => (
          <AnythingItem
            key={index}
            title={item.title}
            description={item.description}
            imageSrc={item.imageSrc}
            isActive={itemIndex === index}
            onClick={() => handleClick(index)}
            position={item.position}
          />
        ))}
      </section>
    </section>
  );
}

type Props = {
  height?: number;
};

export default function LineIcon({ height = 11 }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height={height}
      viewBox={`0 0 2 ${height}`}
      fill="none"
    >
      <path
        d={`M1 0.5 L1 ${height - 0.5}`}
        stroke="#949494"
        strokeLinecap="round"
      />
    </svg>
  );
}

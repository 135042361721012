export default function AddAudioIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4868_9497"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="60"
        height="60"
      >
        <rect width="60" height="60" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4868_9497)">
        <path
          d="M24 44C20.1 44 16.7917 42.6417 14.075 39.925C11.3583 37.2083 10 33.9 10 30C10 26.1 11.3583 22.7917 14.075 20.075C16.7917 17.3583 20.1 16 24 16C27.9 16 31.2083 17.3583 33.925 20.075C36.6417 22.7917 38 26.1 38 30C38 33.9 36.6417 37.2083 33.925 39.925C31.2083 42.6417 27.9 44 24 44ZM40.15 43.2C39.65 43.3667 39.2083 43.2917 38.825 42.975C38.4417 42.6583 38.25 42.2167 38.25 41.65C38.25 41.3833 38.35 41.125 38.55 40.875C38.75 40.625 38.9833 40.4667 39.25 40.4C41.55 39.7333 43.4167 38.425 44.85 36.475C46.2833 34.525 47 32.3167 47 29.85C47 27.3833 46.2833 25.1833 44.85 23.25C43.4167 21.3167 41.55 20.0167 39.25 19.35C38.9833 19.2833 38.75 19.125 38.55 18.875C38.35 18.625 38.25 18.35 38.25 18.05C38.25 17.4833 38.4417 17.05 38.825 16.75C39.2083 16.45 39.65 16.3833 40.15 16.55C43.05 17.4167 45.4167 19.0667 47.25 21.5C49.0833 23.9333 50 26.7167 50 29.85C50 32.9833 49.0833 35.7667 47.25 38.2C45.4167 40.6333 43.05 42.3 40.15 43.2ZM24 41C27.0667 41 29.6667 39.9333 31.8 37.8C33.9333 35.6667 35 33.0667 35 30C35 26.9333 33.9333 24.3333 31.8 22.2C29.6667 20.0667 27.0667 19 24 19C20.9333 19 18.3333 20.0667 16.2 22.2C14.0667 24.3333 13 26.9333 13 30C13 33.0667 14.0667 35.6667 16.2 37.8C18.3333 39.9333 20.9333 41 24 41Z"
          fill="black"
          stroke="black"
        />
      </g>
    </svg>
  );
}

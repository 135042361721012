import { useEffect, useRef } from "react";

export default function useLazyLoadImage() {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const callback: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          const imgTarget = entry.target.querySelector("img");
          if (imgTarget.getAttribute("data-src")) {
            imgTarget.src = imgTarget.getAttribute("data-src");
            imgTarget.removeAttribute("data-src");
          }

          const sourceTarget = entry.target.querySelector("source");
          if (sourceTarget && sourceTarget.getAttribute("data-srcset")) {
            sourceTarget.srcset = sourceTarget.getAttribute("data-srcset");
            sourceTarget.removeAttribute("data-srcset");
          }

          observer.unobserve(entry.target);
        }
      });
    };

    const intersectionObserver = new IntersectionObserver(callback);
    const el = ref.current as HTMLDivElement;
    intersectionObserver.observe(el);

    return () => intersectionObserver.disconnect();
  }, []);

  return ref;
}

import useLazyLoadSvgVideo from "../../../hooks/useLazyLoadSvgVideo";

export default function Value03Mask() {
  const ref = useLazyLoadSvgVideo();

  return (
    <section ref={ref} className="relative max-w-[1800px] w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1800 719">
        <defs>
          <clipPath id="value03">
            <path
              d="m50,0C22.39,0,0,22.39,0,50v236c0,27.61,22.39,50,50,50h587s47,0,47,0c27.61,0,50,22.39,50,50v40h0v243c0,27.61,22.39,50,50,50h851c27.61,0,50-22.39,50-50v-49c0-27.61,22.39-50,50-50h16c27.15-.54,49-22.72,49-50V161c0-27.61-22.39-50-50-50h-113.63c-27.18-.34-49.13-22.35-49.37-49.55v-16.45h-.25c-2.5-25.27-23.82-45-49.75-45H50Z"
              fill="#FFFFFF"
            />
          </clipPath>
        </defs>

        <foreignObject clipPath="url(#value03)" width="1800" height="719">
          <video
            className="block w-full"
            autoPlay
            muted
            loop
            disablePictureInPicture
            poster="/images/landing/thumbnails/value_3.webp"
          >
            <source data-src="/videos/value_3.webm" type="video/webm" />
          </video>
        </foreignObject>
      </svg>
    </section>
  );
}

import Divider from "../components/layout/Divider";

const TosPrivacy = () => (
  <section className="flex flex-col justify-center w-full pt-[40px] xl:pt-40 px-[24px] xl:px-60">
    <h2 className="font-semibold text-left text-[24px] xl:text-[40px] text-black">
      Private Policy
    </h2>

    <div className="text-left">
      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        DoubleMe operates the{" "}
        <a
          href="https://www.twin.world/"
          target="_blank"
          rel="noreferrer"
          className="font-semibold"
        >
          twinWorld
        </a>{" "}
        , which provides the Twin World service. This page is used to inform
        website visitors regarding our policies with the collection, use, and
        disclosure of Personal Information if anyone decided to use our Service,
        the{" "}
        <a
          href="https://www.twin.world/"
          target="_blank"
          rel="noreferrer"
          className="font-semibold"
        >
          twinWorld
        </a>
        . If you choose to use our Service, then you agree to the collection and
        use of information in relation with this policy. The Personal
        Information that we collect are used for providing and improving the
        Service. We will not use or share your information with anyone except as
        described in this Privacy Policy.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        Scope
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        This Privacy Policy applies to information processed by us in the course
        of our business, including on our websites (the “Sites”), our mobile
        applications (the “Apps”) and our related online and offline offerings
        (collectively, the “Services”).
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        Information We Collect
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        When you use our Services, we may collect the following kinds of
        information:
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h4 className="font-semibold text-[20px] xl:text[28px] text-black">
        Information You Provide to us
      </h4>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Account Information.</strong> When you sign up for an account to
        use our Services as an authorized user, you will provide us with
        personal information (also known as “personally identifiable
        information” or “personal data” under applicable laws). This may include
        your name, email address, gender, phone number, profile photo or image
        (to create an avatar), and MR device type. Your avatar or the user name
        which you associate with your account may not be personal information
        depending on the extent to which either item identifies you. For
        enterprise users, we may collect additional personal information from
        you including the name and address of your business and your role at the
        business.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Your Communications with Us.</strong> We collect personal
        information from you such as your email address, name, employer or other
        company with which you are associated, MR device type, and purchasing
        plans when you request information about our Services, register for our
        newsletter, request customer or technical support, or otherwise
        communicate with us.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Virtual Spaces.</strong> When you use our Services, you can
        create and customize virtual rooms and spaces and visit virtual spaces
        created by others (“Spaces”). The Services allow you to upload images,
        and all manner of content (collectively, “Content”) into the Spaces so
        that you may easily share and collaborate with other individuals. We
        collect and store data describing the Spaces and the Content uploaded to
        the Spaces.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Teleconferencing.</strong> The Services allow you to
        teleconference with other users within the Spaces using the sensors on
        your MR device. When you do this, audio and video of you and your
        surroundings will be captured by us and provided to other users along
        with your Avatar to enable MR teleconferencing. We also collect and
        store usage information describing teleconferencing activities within
        the Spaces, such as the number of files uploaded, times and durations of
        the teleconferences, and the identities of the users who participated in
        the teleconferences.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Interactive Features.</strong> We may offer interactive features
        such as forums, blogs, chat and messaging services, and social media
        pages on the Services. We and others who use our Services may collect
        the information you submit or make available through these interactive
        features. Any Content you provide via the public sections of these
        features will be considered “public” and may not be subject to all
        privacy protections referenced herein. By using these interactive
        features, you assume the risk that the personal information provided by
        you may be viewed and used by third parties for their own purposes.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Payment Information.</strong> We may receive payment information
        allowing you to pay us for the Services, but sensitive personal
        information such as your credit card information cannot be accessed by
        us. Payment information goes to a third-party payment provider to
        process payments for the Services. We may receive non-sensitive personal
        information associated with your payment information, such as billing
        address and transaction information, but we do not store payment
        information on the Services.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Surveys.</strong> We may contact you to participate in surveys.
        If you decide to participate, you may be asked to provide certain
        information which may include personal information.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Registration for Sweepstakes or Contests.</strong> We may run
        sweepstakes and contests. Contact information you provide may be used to
        inform you about the sweepstakes or contest and for other promotional,
        marketing and business purposes, if permitted by law. In some
        jurisdictions, we are required to publicly share information about
        winners.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Business Development and Strategic Partnerships.</strong> We may
        collect personal information from individuals and third parties to
        assess and pursue potential business opportunities.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        INFORMATION COLLECTED AUTOMATICALLY
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Automatic Data Collection.</strong> We may collect certain
        personal information automatically when you use the Services. This
        personal information may include your Internet protocol (IP) address,
        user settings, MAC address, cookie identifiers, mobile carrier, mobile
        advertising and other unique identifiers, details about your browser,
        operating system or device, location information (including inferred
        location based on your IP address), Internet service provider, pages
        that you visit before, during and after using the Services, information
        about the links you click, information about how you interact with the
        Services, including the frequency and duration of your activities, and
        other information about how you use the Services. Personal information
        we collect may be associated with accounts, other devices and Spaces.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>MR Device Information.</strong> We automatically collect
        microphone and telemetry information from your MR device when you use
        the Services. The telemetry information describes your MR device and how
        you use it, and may include information about the type of MR device, its
        configuration, and how it is performing. The telemetry information may
        also describe other devices used in connection with the MR device such
        as a computer or mobile device, and information about your network
        connection. The telemetry information may additionally include
        information about your physical environment and movements within the
        environment. With your consent, we may collect personal information
        describing your precise location using a GPS or other sensor on the MR
        device or other device used in connection with the MR device. You may
        opt out of this collection by changing the settings on your MR device or
        other device. If you are using a shared MR device, you should ensure
        that its settings reflect your preferences before participating in a
        teleconference using the Services.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Cookies, Web Beacons, and Personalized Advertising. </strong>{" "}
        We, as well as third parties that provide content, advertising, or other
        functionality on the Services, may use cookies, pixel tags, local
        storage, and other technologies (“Technologies”) to automatically
        collect personal information through the Services. Technologies are
        essentially small data files placed on your computer, tablet, mobile
        phone, or other devices that allow us and our partners to record certain
        pieces of information whenever you visit or interact with the Services.
      </p>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc">
          <li>
            <strong>Cookies.</strong> Cookies are small text files placed in
            visitor’s device browsers to store their preferences. Most browsers
            allow you to block and delete cookies. However, if you do that, the
            Services may not work properly.
          </li>
          <li>
            <strong>Pixel Tags/Web Beacons.</strong> A pixel tag (also known as
            a web beacon) is a piece of code embedded on the Services that
            collects information about user’s engagement. The use of a pixel
            allows us to record, for example, that a user has visited a
            particular web page or clicked on particular content. We may also
            include web beacons in e-mails to understand whether messages have
            been opened, acted on, or forwarded.
          </li>
        </ul>
      </div>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Analytics.</strong> We use service providers such as Google
        Analytics and may use others to collect anonymized or aggregate
        information regarding user behavior and user demographics on the
        Services. For more information about Google Analytics, please visit{" "}
        <a
          href={"https://www.google.com/policies/privacy/partners"}
          target="_blank"
          rel="noreferrer"
          className="font-semibold"
        >
          https://www.google.com/policies/privacy/partners
        </a>
        . You can opt out of Google’s processing of data generated by your use
        of the Services by visiting{" "}
        <a
          href={"https://tools.google.com/dlpage/gaoptout"}
          target="_blank"
          rel="noreferrer"
          className="font-semibold"
        >
          https://tools.google.com/dlpage/gaoptout
        </a>
        .
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        INFORMATION FROM OTHER SOURCES
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        We may obtain personal information about you from other sources,
        including through third party services. doubleme may allow you to
        register for an account and/or use our Services with certain third-party
        login services such as Google (each, a “Third-Party Account”). If you
        use our Services with a Third-Party Account, we will receive basic
        information tied to the account such as your email address, full name,
        profile photo and unique user IDs or tokens used by the third party
        service. We will also receive files and other information from these
        third-party services that you specifically link to while using the
        Services, such as files from Google Drive, Google Docs or Dropbox.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        HOW WE USE YOUR INFORMATION
      </h3>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        We use your personal information for a variety of business purposes,
        including to: Provide the Services or Requested Information, such as:
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc">
          <li>Fulfilling our contract with you;</li>
          <li>Managing your information;</li>
          <li>
            Processing payment card and/or other financial information (through
            a secure third-party - payment processor) to facilitate your use of
            the Services;
          </li>
          <li>Responding to questions, comments, and other requests;</li>
          <li>
            Providing access to certain areas, functionalities, and features of
            the Services;
          </li>
          <li>Answering requests for customer or technical support; and</li>
          <li>Allowing you to register for events.</li>
          <li>
            To serve administrative purposes, such as: pursuing legitimate
            interests, such as direct - marketing, research and development
            (including marketing research), network and information - security,
            and fraud prevention;
          </li>
          <li>
            Providing notifications to you (via the Services, email or text)
            when a Space you follow goes - live, when a user whom you follow is
            online or takes certain other actions, such as public - listing a
            Space, entering a Space, following you or another user;
          </li>
          <li>Measuring interest and engagement in the Services;</li>
          <li>Improving our products and Services;</li>
          <li>Developing new products and services;</li>
          <li>Ensuring internal quality control and safety;</li>
          <li>Authenticating and verifying individual identities;</li>
          <li>Carrying out audits;</li>
          <li>
            Communicating with you about your account, activities on the
            Services and Privacy Policy - changes;
          </li>
          <li>
            Preventing and prosecuting potentially prohibited or illegal
            activities;
          </li>
          <li>Enforcing our agreements; and</li>
          <li>Complying with our legal obligations.</li>
        </ul>
      </div>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Marketing Our Products and Services.</strong> We may use
        personal information to tailor and provide you with content and
        advertisements. We may provide you with these materials as permitted by
        applicable law. If you have any questions about our marketing practices
        or if you would like to opt out of the use of your personal information
        for marketing purposes, you may contact us at any time as set forth
        below.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Consent.</strong> We may use personal information for other
        purposes that are clearly disclosed to you at the time you provide
        personal information or with your consent. At any time you have the
        right to revoke your consent with respect to our collection, use or
        disclosure of your personal information.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>De-identified and Aggregated Information Use.</strong> We may
        use personal information and other information about you to create
        de-identified and/or aggregated information, such as de-identified
        demographic information, de-identified location information,
        de-identified or aggregated trends, reports, or statistics, or other
        analyses we create. and we may use and disclose such information in a
        number of ways, including research, internal analysis, analytics, and
        any other legally permissible purposes.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>
          Sharing Content/Spaces and Profiles with Friends, Colleagues and Third
          Parties.
        </strong>{" "}
        We offer various tools and functionalities for sharing your Profile,
        Content and personal information with others. Your doubleme profile
        consists of the user name that you choose and the avatar that you wish
        to use (whether these are identifiable to you, like a photo, or
        not)(collectively, “Profile”). It also includes the list of people whom
        you follow and who follow you. Your Profile is publicly visible and
        searchable, and you have the option in your sole discretion to add a bio
        (and preferred links), which may include additional personal
        information. You may choose to share your Spaces with other users and
        third parties, along the lines of what you do on other social media
        sites. Most creators of Spaces wish to share Spaces (including your
        Content) with users and third parties, and on other social media sites,
        in order to obtain followers. You can also follow other users and “like”
        their Spaces. The default setting to obtain followers would be your
        public listing of your Space. Publicly listed Spaces are visible and
        searchable on doubleme’s “Explore” page, and on the user Profile page.
        However, you can affirmatively choose to keep your Space(s) private, by
        toggling to ensure that it is not publicly listed. In this manner you
        can control whether most of your Content (other than your Profile) is
        visible (i) only to Your invited Users in a Space, (ii) only to the
        holders of certain crypto tokens (Spaces can be token-gated), or (iii)
        to anyone under the public listing default option, which latter option
        will make Your Customer Content in a given Space visible to any follower
        or third party who has access to the link.{" "}
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        We also allow you to integrate your Spaces with popular business tools
        such as Google Drive and Slack, and to upload Content from applications
        into Spaces to enhance your use of the Services. You control how long
        you use the Content and information, and when to delete it or cease
        using it. doubleme only accesses and stores the Content and personal
        information on your behalf that you specifically import into the
        Services, and we do not share this data outside of the context of your
        use, i.e., your use in Spaces with other individuals of your choosing,
        except for certain special cases listed herein (e.g., law enforcement
        requirement). Moreover you have the right to disconnect doubleme from
        these third party services, such as Google Drive. In addition, we may
        allow you to provide personal information about your friends through our
        referral services. Our referral services may allow you to forward or
        share certain content with a friend or colleague, such as an email
        inviting your friend to use our Services.
      </p>

      <div className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>How We Use Automatic Collection Technologies.</strong>Our uses
        of the automatic collection Technologies fall into the following general
        categories:
        <ul className="ml-[30px] mt-2.5 mb-2.5 list-disc">
          <li>
            Operationally Necessary. This includes Technologies that allow you
            access to our Services that are required to identify irregular
            behavior, prevent fraudulent activity and improve security or that
            allow you to make use of our functions;
          </li>
          <li>
            Performance Related. We may use Technologies to assess the
            performance of our Services, including as part of our analytic
            practices to help us understand how our visitors use the Services;
          </li>
          <li>
            Functionality Related. We may use Technologies that allow us to
            offer you enhanced functionality when accessing or using our
            Services. This may include identifying you when you sign into our
            Services and keeping track of your specified preferences or past
            pages viewed; and
          </li>
          <li>
            Advertising or Targeting Related. We may use first-party or
            third-party Technologies to develop and deliver content, including
            ads relevant to your interests, on our Services or on third-party
            sites. Depending on applicable law, we will seek your express
            consent to such ads. We do not sell your personal information.
          </li>
        </ul>
      </div>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Cross-Device Tracking.</strong> Your browsing activity may be
        tracked across different websites and different devices or apps. For
        example, we may attempt to match your browsing activity on your mobile
        device with your browsing activity on your laptop. To do this our
        technology partners may share data, such as your browsing patterns,
        geo-location and device identifiers, and will match the personal
        information of the browser and devices that appear to be used by the
        same person.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        DISCLOSING YOUR INFORMATION TO THIRD PARTIES
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        We may share your personal information with the following categories of
        third parties:
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Service Providers.</strong> We may share any personal
        information we collect about you with our third-party service providers.
        The types of service providers to whom we entrust information include
        service providers for: (i) the provision of the Services; (ii) the
        provision of information, products, and other services you have
        requested; (iii) marketing and advertising; (iv) payment and transaction
        processing; (v) customer service activities; and (vi) the provision of
        IT and related services. We do so in accordance with applicable law,
        which may require data processing agreements or addenda.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Business Partners.</strong> We may provide personal information
        to business partners to provide you with a product or service you have
        requested. We may also provide personal information to business partners
        with whom we jointly offer products or services.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Advertising Partners.</strong> Through our Services, we may
        allow third party advertising partners to set Technologies and other
        tracking tools to collect personal information regarding your activities
        and your device (e.g., your IP address, cookie identifiers, page(s)
        visited, location, time of day). These advertising partners may use this
        information (and similar information collected from other websites) for
        purposes of delivering targeted advertisements to you when you visit
        third party services within their networks. This practice is commonly
        referred to as “interest-based advertising” or “personalized
        advertising.” Depending on your place of domicile and applicable law, we
        may request your express consent to undertake such activities. If you
        prefer not to share your personal information with third party
        advertising partners, you may follow the instructions below.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Information Shared with Other Users.</strong> When you use the
        Services, certain personal information will be accessible to other users
        and the public/third parties. This personal information will include
        your Profile (which may constitute personal information), and may
        include Content you choose to present via public listing, including any
        audio or images of you or your surroundings. Please be aware that
        doubleme has little or no control over how individuals may use the
        Content you share. Through your control of the public listing of Spaces,
        you choose with whom you wish to share your Content in Spaces while
        using the Services.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>APIs and Software Development Kits.</strong> We may use third
        party APIs and software development kits (“SDKs”) as part of the
        functionality of our Services. APIs and SDKs may allow third parties to
        collect your personal information, subject to applicable law, to provide
        Content that is more relevant to you. For more information about our use
        of APIs and SDKs, please contact us as set forth below.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Apple Specific APIs.</strong> We use certain biometric and audio
        data provided by the microphone, front and rear facing cameras, and
        TrueDepth and ARKit to locate and transmit the user’s voice, camera
        video feed, 3D physical position and facial expression. Data, including
        personal data, is transmitted through our Services and trusted and
        verified third-party services to other participants on your current
        call. All data is encrypted in transit, is never stored on doubleme
        servers and is not used for marketing or advertising purposes.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        We also provide “Scan Functionality” which allows you to create a 3D
        scan of your environment. We use the Camera API and the TrueDepth camera
        to collect depth and color data to generate 3D scans (“Scan Data”).
        doubleme does not use any Face Data, as that term is defined in Apple
        Developer Program License (“Face Data” means information related to
        human faces (e.g., face mesh data, facial map data, face modeling data,
        facial coordinates or facial landmark data, including data from an
        uploaded photo). Scan Data is not shared with third parties. Scan Data
        is stored locally on the device within the app’s container.
        Additionally, if you create an account and sign in, Scan Data is synced
        using encryption to doubleme’s secure servers, where you are able to log
        in and download scans. We will retain this data until you delete your
        account with doubleme, or specifically delete data items. You can remove
        data by visiting the content portal or by emailing support@doubleme.me.
        Subsequent dissemination or sharing of Scan Data from the app or our
        cloud platform is exclusively in your control. You may choose to use the
        Application to record scans or images of your face (“Face Data”), but
        note that you are not required to do so in order to use doubleme (or any
        other part of the Services).
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Disclosures to Protect Us or Others.</strong> We may access,
        preserve, and disclose any personal information we store in association
        with you to external parties if we, in good faith, believe doing so is
        required or appropriate to: (i) comply with law enforcement or national
        security requests and legal process, such as a court order or subpoena;
        (ii) protect your, our, or others’s rights, property, or safety; (iii)
        enforce our policies or contracts; (iv) collect amounts owed to us; or
        (v) assist with an investigation and prosecution of suspected or actual
        illegal activity.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Merger, Sale, or Other Asset Transfers.</strong> If we are
        involved in a merger, acquisition, financing due diligence,
        reorganization, bankruptcy, receivership, purchase or sale of assets, or
        transition of service to another provider, then your personal
        information may be transferred as part of such a transaction as
        permitted by law and/or contract.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        INTERNATIONAL DATA TRANSFERS
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        You agree that all personal information processed by us may be
        transferred, processed, and stored anywhere in the world, including, but
        not limited to, the United States and other countries which may have
        data protection laws that are different from the laws where you live. We
        endeavor to safeguard your information consistent with the requirements
        of applicable laws. If you are a resident of the European Economic Area
        (EEA), Switzerland or the United Kingdom and provide personal data to
        us, we will either seek your express consent to such transfer if you are
        an individual data subject in accordance with applicable law, or we will
        rely on mechanisms such as the applicable Standard Contractual Clauses.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        YOUR CHOICES
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>General.</strong> You may have the right to opt into certain
        uses of your personal information, or based on type of use and/or your
        geographic location, to object to or opt out of certain uses of your
        personal information. Where you have consented to the processing of your
        personal information, you may withdraw that consent at any time by
        contacting us as described below.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Email Communications.</strong> If you receive an unwanted email
        from us, you can use the unsubscribe link found at the bottom of the
        email to opt out of receiving future emails. Note that you will continue
        to receive transaction-related emails regarding products or Services you
        have requested. We may also send you certain non-promotional
        communications regarding us and our Services, and you will not be able
        to opt out of those communications (e.g., communications regarding the
        Services or updates to this Privacy Policy).
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Mobile Devices.</strong> We may send you push notifications
        through our Apps. You may at any time opt out from receiving these types
        of communications by changing the settings on your mobile device. With
        your consent, we may also collect precise location information if you
        use our Apps. You may opt out of this collection by changing the
        settings on your mobile device.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>“Do Not Track”.</strong> Do Not Track (“DNT”) is a privacy
        preference that users can set in certain web browsers. Please note that
        we do not respond to or honor DNT signals or similar mechanisms
        transmitted by web browsers.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Cookies and Interest-Based Advertising.</strong> You may stop or
        restrict the placement of Technologies on your device or remove them by
        adjusting your preferences as your browser or device permits. Depending
        on your domicile and applicable law, we may seek your express consent to
        the placement of cookies. Please note that cookie-based opt-outs are not
        effective on mobile applications. However, you may opt out of
        personalized advertisements on some mobile applications by following the
        instructions for Android and iOS. The online advertising industry also
        provides websites from which you may opt out of receiving targeted ads
        from advertisers that participate in self-regulatory programs. You can
        access these, and also learn more about targeted advertising and
        consumer choice and privacy, at&nbsp;
        <a
          href={"https://www.networkadvertising.org/managing/opt_out.asp"}
          target="_blank"
          rel="noreferrer"
          className="font-semibold"
        >
          https://www.networkadvertising.org/managing/opt_out.asp
        </a>
        ,{" "}
        <a
          href={"https://www.youronlinechoices.eu/"}
          target="_blank"
          rel="noreferrer"
          className="font-semibold"
        >
          https://www.youronlinechoices.eu
        </a>{" "}
        and{" "}
        <a
          href={"https://www.aboutads.info/choices/"}
          target="_blank"
          rel="noreferrer"
          className="font-semibold"
        >
          https://www.aboutads.info/choices
        </a>
        . Please note you must separately opt out in each browser and on each
        device.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        YOUR PRIVACY RIGHTS
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        You have the right to:
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Access</strong> personal information about you consistent with
        legal requirements. In addition, you may have the right in some cases to
        receive or have your electronic personal information transferred to
        another party.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Request Correction</strong> of your personal information where
        it is inaccurate or incomplete.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Request Deletion</strong> of your personal information, subject
        to certain exceptions prescribed by law.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Request Restriction or Object to Processing</strong> by us for
        exercising your privacy rights.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        <strong>Not be Discriminated Against</strong> by us for exercising your
        privacy rights.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        If you would like to exercise any of these rights, please contact us as
        set forth below. We will process such requests in accordance with
        applicable laws. To protect your privacy, we will take steps to verify
        your identity before fulfilling your request.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        DATA RETENTION
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        We retain the personal information we receive as described in this
        Privacy Policy for as long as you use our Services or as necessary to
        fulfill the purpose(s) for which it was collected, provide our Services,
        resolve disputes, establish legal defenses, conduct audits, pursue
        legitimate business purposes, enforce our agreements, and comply with
        applicable laws.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        SECURITY OF YOUR INFORMATION
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        We take steps to ensure that your personal information is treated
        securely and in accordance with this Privacy Policy. We use adequate
        technical, organizational, administrative, logical, physical and
        managerial measures to safeguard your personal information against loss,
        theft and unauthorized access, use and modification. These measures are
        designed to provide a level of security appropriate to the risks of
        processing your personal information. Unfortunately, no measures can be
        guaranteed to be 100% secure, and we cannot ensure or warrant the
        security of any information you provide to us.
      </p>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        To the extent permitted by applicable law, by using the Services or
        providing personal information to us, you agree that we may communicate
        with you electronically regarding security, privacy, and administrative
        issues relating to your use of the Services. If we learn of a security
        system’s breach, we may attempt to notify you electronically by sending
        a notice through the Services or by sending an e-mail to you.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        THIRD PARTY WEBSITES/APPLICATIONS
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        The Services may contain links to other websites/applications and other
        websites/applications may reference or link to our Services. These
        third-party services are not controlled by us. We encourage our users to
        read the privacy policies of each website and application with which
        they interact. We do not endorse, screen or approve, and are not
        responsible for the privacy practices or content of, such other websites
        or applications. Visiting these other websites or applications is at
        your own risk.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        CHILDREN’S INFORMATION
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        The Services are not directed to persons under 14 (or to children as
        required by applicable law), and we do not knowingly collect personal
        information from children. We will try to establish that you are at
        least 14 years old, and we require that if you are not, please do not
        misrepresent your age or use the Services. If you learn that your child
        has provided us with personal information without your consent, you may
        contact us as set forth below. If we learn that we have collected any
        child’s personal information in violation of applicable law, we will
        promptly take steps to delete such information.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        SUPERVISORY AUTHORITY
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        If you are located in the European Union, Switzerland or the UK, you
        have the right to lodge a complaint with a supervisory authority if you
        believe our processing of your personal information violates applicable
        law.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        CHANGES TO OUR PRIVACY NOTICE
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        We may revise this Privacy Policy from time to time in our sole
        discretion, and such revisions may reflect compliance with applicable
        law as it changes. If there are any material changes to this Privacy
        Policy, we will notify you as required by applicable law. You understand
        and agree that you will be deemed to have accepted the updated Privacy
        Policy if you continue to use the Services after the new Privacy Policy
        takes effect.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        Right to erasure of personal information
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        You have the right to request us to delete your personal information for
        specific reasons. For example, if your personal information is no longer
        necessary for the purposes for which it was processed, or if you believe
        that we have processed your personal information unlawfully, you can
        request the deletion of your personal information. If you wish to have
        your personal information erased or want to withdraw from the app,
        please contact us at support@doubleme.me.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        Log Data
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        We want to inform you that whenever you visit our Service, we collect
        information that your browser sends to us that is called Log Data. This
        Log Data may include information such as your computer’s Internet
        Protocol (“IP”) address, browser version, pages of our Service that you
        visit, the time and date of your visit, the time spent on those pages,
        and other statistics.
      </p>

      <Divider className="h-[24px] xl:h-[72px]" />

      <h3 className="font-semibold text-[20px] xl:text[28px] text-black">
        Contact Us
      </h3>

      <p className="text-twinworldGray-770 leading-[24px] xl:leading-[26px] mt-[16px] xl:mt-8">
        If you have any questions about our privacy practices or this Privacy
        Notice, please contact us at: support@doubleme.me
      </p>
    </div>
  </section>
);

export default TosPrivacy;

import { useNavigate } from "react-router-dom";

import ModalActionButton from "../button/ModalActionButton";
import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";

import { ModalTitle } from "./ModalTitle";

type Props = {
  type: "reset-password" | "email-verification";
  isOpen: boolean;
  closeModal: () => void;
};

export default function LinkExpiredModal({
  type = "email-verification",
  isOpen,
  closeModal,
}: Props) {
  const navigate = useNavigate();

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const expiredType = () => {
    if (type === "email-verification") return "verification";

    if (type === "reset-password") return "password reset";
  };

  const retryMessage = () => {
    if (type === "email-verification") return "new email verification.";

    if (type === "reset-password") return "new password reset.";
  };

  return (
    <Modal
      maxWidth="max-w-[339px] md:max-w-[480px]"
      isOpen={isOpen}
      title={<ModalTitle text="The link has expired" />}
      close={close}
      closeModal={closeModal}
    >
      <div className="flex flex-col items-center justify-center w-full min-h-full text-center pb-[64px] md:pb-[95px]">
        <div className="w-[279px] md:w-full text-[16px] md:text-[18px] text-twinworldGray-700 leading-[28px] md:leading-[30px] mt-[40px] md:mt-[37px] mb-[40px] md:mb-[131px] px-[30px] md:px-[100px]">
          Your {expiredType()} link has expired. Please request a{" "}
          {retryMessage()}
        </div>

        <ModalActionButton type="button" onClick={() => navigate("/")}>
          Go to Main
        </ModalActionButton>
      </div>
    </Modal>
  );
}

import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { useSpaceActions } from "../../domains/space/hook/useSpaceActions";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import { useInfiniteScrollRef } from "../../hooks/useInfiniteScrollRef";
import SpaceCard from "../card/SpaceCard";
import ListWrapper from "../common/ListWrapper";
import Loading from "../common/Loading";
import MobileSpaceCard from "../mobile/MobileSpaceCard";
import SpaceEmptyList from "../space/SpaceEmptyList";

import { SpaceType } from "./interface";

type Props = {
  type: SpaceType;
  selectedCategory: string;
  keyword: string;
};

export default function SpaceList({ type, selectedCategory, keyword }: Props) {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceBreakPoint();

  const { useInfiniteSearchSpaceList } = useSpaceActions();
  const { isPending, data, fetchNextPage } = useInfiniteSearchSpaceList(
    type,
    keyword,
    24,
    0
  );

  const { targetRef } = useInfiniteScrollRef({ fetchNextPage });

  const handleClickCard = (spaceId: string) => {
    navigate(`/spaces/${spaceId}`);
  };

  const capitalizeFirstLetter = (text: string) =>
    `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

  const getListTitle = () => {
    if (keyword) {
      return keyword;
    }

    return capitalizeFirstLetter(selectedCategory);
  };

  return (
    <section className="w-full xl:min-w-[1440px] xl:mx-auto">
      <h3 className="hidden xl:block text-[40px] font-semibold mb-[30px]">
        {getListTitle()}
      </h3>

      {isPending && <Loading />}

      <ListWrapper isEmptyData={!data?.pages[0].length}>
        {data && data.pages[0].length > 0 ? (
          data?.pages.map((page, pageIndex) => (
            <Fragment key={pageIndex}>
              {page.map((space) =>
                isDesktop ? (
                  <SpaceCard
                    key={space.spaceId}
                    id={space.spaceId}
                    title={space.spaceName}
                    thumbnailSrc={space.spaceImage}
                    creator={space.spaceCreator}
                    creatorId={space.spaceCreatorId}
                    avatarSrc={space.spaceCreatorImage}
                    onClick={() => handleClickCard(space.spaceId)}
                    views={space.views}
                    plays={space.plays}
                    liveUserCount={space.liveUserCount}
                    rounded="rounded-[20px]"
                  />
                ) : (
                  <MobileSpaceCard
                    key={space.spaceId}
                    id={space.spaceId}
                    title={space.spaceName}
                    thumbnailSrc={space.spaceImage}
                    creator={space.spaceCreator}
                    creatorId={space.spaceCreatorId}
                    avatarSrc={space.spaceCreatorImage}
                    onClick={() => handleClickCard(space.spaceId)}
                    views={space.views}
                    plays={space.plays}
                    liveUserCount={space.liveUserCount}
                    rounded="rounded-[14px]"
                  />
                )
              )}
            </Fragment>
          ))
        ) : (
          <SpaceEmptyList spaceType={type} />
        )}

        <div ref={targetRef} />
      </ListWrapper>
    </section>
  );
}

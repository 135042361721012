import { Link } from "react-router-dom";

import CategoryItem from "./CategoryItem";

type Props = {
  list: {
    label: string;
    path: string;
  }[];
};

/**
 *
 * @param list: category list
 * @returns
 */
export default function CategoryMenu({ list }: Props) {
  return (
    <ul className="w-fit h-[64px] p-2 rounded-full bg-twinworldGray-680 text-sm flex flex-row items-center justify-between gap-4">
      {list.map(({ label, path }) => (
        <Link key={label} to={path}>
          <li>
            <CategoryItem label={label} path={path} />
          </li>
        </Link>
      ))}
    </ul>
  );
}

import EditIcon from "../common/icons/EditIcon";

type Props = {
  onClick: () => void;
};

export default function MobileProfileEditButton({ onClick }: Props) {
  return (
    <button
      className="flex gap-[6px] w-[339px] h-[49px] items-center justify-center border border-twinworldGray-300 rounded-full"
      onClick={onClick}
    >
      <span className="inline-block text-[16px] font-medium leading-[19px]">
        Edit
      </span>
      <EditIcon />
    </button>
  );
}

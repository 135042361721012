import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import itemApi from "../api";

export const useItemActions = () => {
  const queryClient = useQueryClient();

  const { data: itemCategoryList } = useQuery({
    queryKey: ["itemCategoryList"],
    queryFn: itemApi.getItemCategoryList,
  });

  const { mutate: likeMutate } = useMutation({
    mutationFn: async (itemId: string) => {
      await itemApi.updateLikeItem(itemId);
    },
  });

  const handleLike = (itemId: string) => {
    const response = likeMutate(itemId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["itemDetails", itemId] });
      },
      onError: (error: any) => {
        if (error.response.status === 404 || error.response.status === 400) {
          window.location.href = `/error/${error.response.status}`;
        }
        throw error;
      },
    });

    return response;
  };

  const { mutate: disLikeMutate } = useMutation({
    mutationFn: async (itemId: string) => {
      await itemApi.updateDisLikeItem(itemId);
    },
  });

  const handleDisLike = (itemId: string) => {
    const response = disLikeMutate(itemId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["itemDetails", itemId] });
      },
      onError: (error: any) => {
        if (error.response.status === 404 || error.response.status === 400) {
          window.location.href = `/error/${error.response.status}`;
        }
        throw error;
      },
    });

    return response;
  };

  const useItemDetails = (itemId: string) => {
    const {
      isPending,
      isError,
      data: itemDetails,
      error: itemDetailsError,
    } = useQuery({
      queryKey: ["itemDetails", itemId],
      queryFn: () => {
        const response = itemApi.getItemDetails(itemId);
        return response;
      },
    });

    return { isPending, isError, itemDetails, itemDetailsError };
  };

  return {
    itemCategoryList,
    handleLike,
    handleDisLike,
    useItemDetails,
  };
};

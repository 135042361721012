type Props = {
  title: string;
  file: string;
  poster: string;
};

export default function SpaceCarouselItem({ title, file, poster }: Props) {
  return (
    <div className="relative w-[546px] lg:w-[751px] xl:w-[1096px] h-[273px] lg:h-[374px] xl:h-[546px] mx-[14px] lg:mx-[20px] xl:mx-10 rounded-full bg-slate-500 overflow-hidden">
      <h3 className="absolute w-full text-[18px] lg:text-[30px] xl:text-4xl font-semibold leading-none text-white bottom-[25px] lg:bottom-[34px] xl:bottom-9 text-center xl:text-left pl-0 xl:pl-48">
        {title}
      </h3>

      <video autoPlay muted loop disablePictureInPicture poster={poster}>
        <source src={file} type="video/webm" />
      </video>
    </div>
  );
}

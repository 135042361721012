import ComputerIcon from "../components/mobile/icons/ComputerIcon";

export default function MobileUploadEditNotification() {
  return (
    <section className="flex flex-col w-full py-[64px] px-[18px] items-center mx-auto border-t border-twinworldGray-200">
      <div className="px-[10px] py-[10px] pb-[30px]">
        <ComputerIcon />
      </div>
      <h2 className="font-semibold my-[13px] text-[20px] leading-[25px]">
        Item upload
      </h2>
      <p className="font-normal text-center text-[16px] leading-[28px] text-twinworldGray-700 w-[339px]">
        The item upload/edit feature is currently under development for mobile.
        Please upload or edit items by accessing twin.world on your PC.
      </p>
    </section>
  );
}

import { useGLTF, useAnimations } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useRef, useEffect, useState } from "react";
import { LoadingManager, REVISION } from "three";
import * as THREE from "three";
import { GLTFLoader } from "three-stdlib";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";

import { useModelContext } from "../../contexts/ModelContext";
import { loadModelWithTransform } from "../../utils/gltfConvert";

type Props = {
  renderFileUrl: string;
};

const manager = new LoadingManager();

const Model = ({ renderFileUrl }: Props) => {
  const { gl } = useThree();

  const { setIsAnimation } = useModelContext();

  const myLoader = (loader: GLTFLoader) => {
    loader.manager = manager;
    const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`;
    const ktx2Loader = new KTX2Loader(manager).setTranscoderPath(
      `${THREE_PATH}/examples/jsm/libs/basis/`
    );
    loader.setKTX2Loader(ktx2Loader.detectSupport(gl));
  };

  const ref = useRef<THREE.Group>(null);
  const [model, setModel] = useState<GLTF | undefined>();

  const result = useGLTF(renderFileUrl, true, false, myLoader);
  result.scene.updateMatrixWorld();

  const { actions, names, mixer } = useAnimations(
    model ? model.animations : result.animations,
    ref
  );

  const transform = (url: string) => {
    if (!url) return;

    loadModelWithTransform(url, gl)
      .then((gltf) => {
        gltf.scene.updateMatrixWorld();
        setModel(gltf);
      })
      .catch((error) => {
        setModel(undefined);
      });
  };

  useEffect(() => {
    setModel(undefined);
    transform(renderFileUrl);
  }, [renderFileUrl]);

  useEffect(() => {
    if (names.length > 0) {
      setIsAnimation(true);
    }
  }, [names]);

  useEffect(() => {
    let i = 0;

    if (model && actions) {
      if (names.length > 1) {
        actions[names[i]]?.reset().fadeIn(0.5).play();
        actions[names[i]]?.setLoop(THREE.LoopRepeat, 1);
      } else {
        actions[names[i]]?.reset().play();
      }
    }

    mixer.addEventListener("finished", (e) => {
      if (names.length > 1) {
        i++;

        if (i === names.length) {
          i = 0;
        }

        actions[names[i]]?.reset().fadeIn(0.5).play();
        actions[names[i]]?.setLoop(THREE.LoopRepeat, 1);
      }
    });

    return () => {
      actions[names[i]]?.reset().fadeOut(0.5);
    };
  }, [actions, mixer, model, names]);

  return model ? (
    <group ref={ref}>
      <primitive object={model.scene} />
    </group>
  ) : (
    <group ref={ref}>
      <primitive object={result.scene} />
    </group>
  );
};

export default Model;

import { useNavigate } from "react-router-dom";

type Props = {
  onClick: () => void;
};

export default function MobileUserMenuList({ onClick }: Props) {
  const navigate = useNavigate();

  const MENU_LIST = [
    {
      key: "mySpace",
      label: "My Space",
      action: () => {
        navigate("/myprofile", { state: { menu: "spaces" } });
        onClick();
      },
    },
    {
      key: "myItem",
      label: "My Item",
      action: () => {
        navigate("/myprofile", { state: { menu: "items" } });
        onClick();
      },
    },
    // {
    //   key: "mycoins",
    //   label: "My Coins",
    //   action: () => {
    //     navigate("/mycoins", { state: { menu: "redeem" } });
    //     onClick();
    //   },
    // },
    {
      key: "avatar",
      label: "Avatar",
      action: () => {
        navigate("/setting", { state: { menu: "avatar" } });
        onClick();
      },
    },
    {
      key: "setting",
      label: "Settings",
      action: () => {
        navigate("/setting", { state: { menu: "profile" } });
        onClick();
      },
    },
  ];

  return (
    <div className="flex flex-col w-full gap-5 pl-[36px] mt-[26px] py-[26px] text-twinworldGray-900 text-[18px] leading-[18px] font-medium border-y border-y-twinworldGray-150">
      {MENU_LIST.map((link) => (
        <div key={link.key} className="flex items-center cursor-pointer w-max">
          <button type="button" onClick={link.action}>
            {link.label}
          </button>
        </div>
      ))}
    </div>
  );
}

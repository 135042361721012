import Sequence from "./Sequence";
import Value03Mask from "./Value03Mask";

export default function Featured03() {
  return (
    <section className="relative px-[60px]">
      <div className="absolute flex flex-col gap-10 2xl:gap-12 3xl:gap-14 bottom-10 2xl:bottom-[37px] 3xl:bottom-[30px] ">
        <h3 className="text-4xl 2xl:test-[45px] 3xl:text-[50px] font-semibold leading-tight w-[482px]">
          Create Your Own 3D Space in Reality
        </h3>
        <p className="text-sm 2xl:text-[18px] 3xl:text-[20px] leading-6 2xl:leading-8 3xl:leading-9 w-[600px] 2xl:w-[720px] 3xl:w-[815px] text-black/70">
          Create your own board game room and 3D art gallery.
          <br />
          Invite your friends, share various activities, and start new hobbies
          <br />
          and adventures.
        </p>
      </div>
      <div className="relative">
        <Sequence index="03" position="right" />
        <Value03Mask />
      </div>
    </section>
  );
}

const EyeIcon = () => (
  <svg
    width="19"
    height="12"
    viewBox="0 0 19 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Union"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.98787 0C5.8119 0 2.65994 1.81911 0.240269 5.25221L0.240261 5.25221L0.238056 5.25537C0.0867068 5.47247 0.00325233 5.72994 -0.0008201 5.99524C-0.00488079 6.25977 0.0701863 6.51897 0.214058 6.74022C2.08715 9.64165 5.20094 12.0008 8.98787 12.0008C12.735 12.0008 15.9092 9.63746 17.7873 6.72722C17.9274 6.51119 18.0024 6.25909 18.0024 6.00095C18.0024 5.74239 17.9272 5.48988 17.7866 5.27362L17.7854 5.2719C15.9065 2.39949 12.7123 0 8.98787 0ZM8.98787 10.5008C5.8981 10.5008 3.22831 8.59051 1.53497 6.01975C3.73997 2.9403 6.44821 1.5 8.98787 1.5C12.0156 1.5 14.7661 3.44955 16.4697 6.0016C14.7736 8.57891 12.045 10.5008 8.98787 10.5008ZM6.80078 6.0006C6.80078 4.81108 7.77711 3.83371 8.99769 3.83371C10.2183 3.83371 11.1946 4.81108 11.1946 6.0006C11.1946 7.19012 10.2183 8.16749 8.99769 8.16749C7.77711 8.16749 6.80078 7.19012 6.80078 6.0006ZM8.99769 2.33371C6.9632 2.33371 5.30078 3.96821 5.30078 6.0006C5.30078 8.03299 6.9632 9.66749 8.99769 9.66749C11.0322 9.66749 12.6946 8.03299 12.6946 6.0006C12.6946 3.96821 11.0322 2.33371 8.99769 2.33371Z"
      fill="black"
    />
  </svg>
);

export default EyeIcon;

import MinusIcon from "./MinusIcon";
import PlusIcon from "./PlusIcon";
import { Imaginable } from "./types";

type Props = {
  index: number;
  hoveredId: number | undefined;
  setIsHovered: (hoveredId: number) => void;
  imaginable: Imaginable;
};

export default function ImaginableItem({
  index,
  imaginable,
  hoveredId,
  setIsHovered,
}: Props) {
  const { title, description } = imaginable;

  return (
    <div
      className={`flex items-center w-full h-40 border-black gap-[80px] ${
        index === 0 && "border-t-2"
      } border-b-2 px-20 `}
      onMouseEnter={() => setIsHovered(index)}
      style={{
        background: "transfarent",
        backgroundImage: "linear-gradient(to top, black, black)",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        transition: "background-size 0.3s ease-in-out 0s",
        backgroundSize: hoveredId === index ? "100% 100%" : "100% 0",
      }}
    >
      <h4
        className={`${
          hoveredId === index
            ? "text-[35px] 2xl:text-[45px] 3xl:text-[50px] text-white font-bold"
            : "text-[22px] 2xl:text-[30px] 3xl:text-[38px] text-black font-medium"
        } leading-none min-w-[274px] cursor-default`}
      >
        {title}
      </h4>
      <MinusIcon />
      <p
        className={`w-full text-base leading-relaxed text-white cursor-default`}
      >
        {description}
      </p>

      {hoveredId !== index && <PlusIcon />}
    </div>
  );
}
